import createStyles from '@material-ui/core/styles/createStyles';

export default () =>
  createStyles({
    loaderContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 999,
    },
    loaderContainerOverlay: {
      backgroundColor: 'rgba(255, 255, 255, .7)',
    },
    loader: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    miniLoaderRoot: {
      position: 'relative',
      flex: 1,
      width: '100%',
      height: '100%',
    },
    miniLoaderSpin: {
      position: 'absolute',
      backgroundColor: 'rgba(255, 255, 255, .85)',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'not-allowed',
    },
    miniLoaderMessage: {
      marginBottom: 30,
      fontSize: 16,
      fontWeight: 500,
    },
  });
