import React, { useCallback } from 'react';
import { createPortal } from 'react-dom';

import { Dialog } from '@app/ui/dialog';

export interface Props {
  isOpen: boolean;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  content?: string | React.ReactNode;
  hide: () => void;
  onClose?: () => void;
  modalId?: string;
  actions?: React.ReactNode;
  children?: React.ReactNode;
  fullWidth?: boolean;
  scroll?: 'body' | 'paper';
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  dialogActionsClassName?: string;
  paperClass?: string;
}

export const Modal = ({
  isOpen,
  hide,
  content,
  title,
  description,
  onClose,
  modalId = 'simple-modal',
  scroll = 'body',
  actions,
  children,
  maxWidth,
  fullWidth,
  dialogActionsClassName,
  paperClass,
}: Props) => {
  const handleModalClose = useCallback(() => {
    hide();

    if (onClose) {
      onClose();
    }
  }, [hide, onClose]);

  const dialogContent = {
    ...(content ? { text: content } : { children }),
  };

  return isOpen
    ? createPortal(
        <Dialog
          open
          title={title}
          description={description}
          actions={actions}
          onClose={handleModalClose}
          dialogActionsClassName={dialogActionsClassName}
          aria-labelledby={modalId}
          scroll={scroll}
          maxWidth={maxWidth}
          fullWidth={fullWidth}
          paperClass={paperClass}
          {...dialogContent}
        />,
        document.body,
      )
    : null;
};
