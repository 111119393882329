import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import { yup } from '@app/ui/forms';
import { Modal } from '@app/ui/modal';
import { Loader } from '@app/ui/loader';

import { useMemo } from 'react';
import { Availabilities } from './Availabilities';
import { ROLES, STATUSES } from '../../../constants';
import { BulkAvailability } from '../../../types/BulkAvailability';

export interface FormProps {
  readonly isLoading: boolean;
  readonly onCancel: () => void;
  readonly onSubmit: (data: BulkAvailability, helpers: FormikHelpers<BulkAvailability>) => void;
  readonly initialValues?: BulkAvailability;
  readonly sendRequest?: boolean;
}

const defaultInitialValues = { bulk: [{ name: '', email: '', role: ROLES.CANDIDATE, status: STATUSES.WAITING }] };

export const Form = ({
  onCancel,
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
  sendRequest = true,
}: FormProps) => {
  const { t } = useTranslation('common');

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        bulk: yup.array().of(
          yup
            .object()
            // @ts-ignore
            .unique('email', t('availabilities.validations.uniqueEmail'))
            .shape({
              name: yup.string().required(),
              email: yup.string().email().required(),
              role: yup.string().required(),
            }),
        ),
      }),
    [t],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ handleSubmit }) => (
        <Modal
          isOpen
          fullWidth
          title={t(sendRequest ? 'availabilities.list.request.title' : 'availabilities.list.create.title')}
          description={sendRequest ? t('availabilities.list.request.description') : undefined}
          hide={onCancel}
          maxWidth="md"
          scroll="body"
          actions={
            <>
              <Button color="primary" onClick={onCancel}>
                {t('general.buttons.cancel')}
              </Button>
              <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
                {t(sendRequest ? 'availabilities.buttons.sendRequest' : 'availabilities.buttons.createAvailability')}
              </Button>
            </>
          }
        >
          {isLoading && <Loader />}
          <form>
            <Availabilities name="bulk" sendRequest={sendRequest} />
          </form>
        </Modal>
      )}
    </Formik>
  );
};
