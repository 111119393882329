import React from 'react';

import createTheme from '@material-ui/core/styles/createTheme';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as checkboxIcon } from '@app/app/images/icons/icon-checkbox.svg';
import { ReactComponent as checkboxCheckedIcon } from '@app/app/images/icons/icon-checkbox-checked.svg';

import {
  fontFamily,
  colorWhite,
  colorPrimary,
  colorPrimaryDark,
  colorPrimaryLight,
  colorSecondary,
  colorSecondaryDark,
  colorSecondaryLight,
  colorSuccess,
  colorSuccessLight,
  colorDanger,
  colorDangerLight,
  colorWarning,
  colorWarningLight,
  colorGreyLight,
  colorDisabled,
  bgBody,
  textPrimary,
  textDisabled,
  textSecondary,
  borderPrimaryColor,
  boxShadow,
  sliderHeight,
  borderRadius,
  borderRadiusSmall,
  headerHeight,
  dialogHorizontal,
  dialogHorizontalMobile,
  dialogVertical,
  dialogVerticalMobile,
  colorGrey,
  inputBackground,
  inputBackgroundDisabled,
  inputLabelColor,
  inputLabelDisabled,
  inputVerticalPadding,
  inputHorizontalPadding,
  colorGrey10,
} from './variables';

const breakpoints = createBreakpoints({});

export const theme = createTheme({
  breakpoints,
  props: {
    MuiCheckbox: {
      icon: <SvgIcon component={checkboxIcon} viewBox="0 0 18 18" fontSize="inherit" />,
      checkedIcon: <SvgIcon component={checkboxCheckedIcon} viewBox="0 0 18 18" fontSize="inherit" />,
    },
    MuiSwitch: {
      color: 'primary',
    },
  },
  palette: {
    primary: {
      main: colorPrimary,
      dark: colorPrimaryDark,
      contrastText: colorWhite,
    },
    secondary: {
      main: colorSecondary,
      dark: colorSecondaryDark,
      contrastText: colorWhite,
    },
    error: {
      light: colorDangerLight,
      main: colorDanger,
    },
    success: {
      light: colorSuccessLight,
      main: colorSuccess,
    },
    action: {
      active: colorPrimary,
    },
    background: {
      default: bgBody,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
  },
  typography: {
    fontFamily,
    fontSize: 14,
    fontWeightBold: 500,

    h1: {
      fontSize: 24,
      fontWeight: 500,

      [breakpoints.up('sm')]: {
        fontSize: 28,
      },
      [breakpoints.up('md')]: {
        '&.title-large': {
          fontSize: 32,
        },
      },
    },
    h2: {
      fontSize: 20,
      fontWeight: 500,

      [breakpoints.up('sm')]: {
        fontSize: 22,
      },
    },
    h3: {
      fontSize: 16,
      fontWeight: 500,

      [breakpoints.up('sm')]: {
        fontSize: 18,
      },
    },
    h4: {
      fontSize: 14,
      fontWeight: 500,

      [breakpoints.up('sm')]: {
        fontSize: 16,
      },
    },
    h5: {
      fontSize: 14,
      fontWeight: 400,

      [breakpoints.up('sm')]: {
        fontSize: 16,
      },
    },
    h6: {
      fontSize: 14,
      fontWeight: 400,

      [breakpoints.up('sm')]: {
        fontSize: 14,
        fontWeight: 500,
      },
    },
    caption: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 1.4,
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.45,
      textTransform: 'none',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontSize: 14,
          lineHeight: 1.4,
        },
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '0.75em',
      },
      colorSecondary: {
        color: colorGrey,
      },
    },
    MuiContainer: {
      root: {
        [breakpoints.up('sm')]: {
          paddingLeft: 20,
          paddingRight: 20,
        },
        [breakpoints.up('lg')]: {
          paddingLeft: 30,
          paddingRight: 30,
        },
      },
      maxWidthLg: {
        [breakpoints.up('lg')]: {
          maxWidth: 1600,
        },
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
        borderBottom: `1px solid ${borderPrimaryColor}`,
      },
      colorDefault: {
        backgroundColor: colorWhite,
        color: textPrimary,
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: headerHeight,

        '@media (min-width: 0px) and (orientation: landscape)': {
          minHeight: headerHeight,
        },
        [breakpoints.up('sm')]: {
          minHeight: headerHeight,
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius,
      },
      elevation1: {
        boxShadow,
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily,
      },
    },
    MuiButton: {
      root: {
        borderRadius: borderRadiusSmall,
        boxShadow: 'none !important',

        '&.cta-button': {
          maxWidth: '100%',
          textAlign: 'center',

          [breakpoints.up('sm')]: {
            minWidth: 150,
          },
        },
      },
      contained: {
        padding: '8px 24px',

        '&$disabled, &$disabled:hover': {
          backgroundColor: colorDisabled,
          color: textDisabled,
        },
      },
      outlined: {
        padding: '7px 24px',

        '&$disabled, &$disabled:hover, &$disabled$outlinedSecondary, &$disabled$outlinedPrimary': {
          borderColor: colorDisabled,
          color: textDisabled,
        },
      },
      text: {
        padding: 0,
        minWidth: 0,

        '&:hover': {
          background: 'none !important',
        },
        '&$disabled, &$disabled:hover': {
          color: textDisabled,
        },
        '& $startIcon': {
          marginLeft: 0,
          marginRight: 4,
        },
      },
      textPrimary: {
        '&:hover': {
          color: colorPrimaryDark,
        },
      },
      startIcon: {},
    },
    MuiIconButton: {
      root: {
        '&.button-danger:not($disabled)': {
          color: colorDanger,
        },
      },
      sizeSmall: {
        padding: 4,
        fontSize: 18,
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
      underlineAlways: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 16,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 1.2,
        textAlign: 'left',
        maxWidth: '100%',
        marginBottom: 5,
        cursor: 'default',
        display: 'flex',
        alignItems: 'flex-end',

        '&:not($outlined)': {
          position: 'static',
          transform: 'none',
        },
        '&, &$focused:not($error)': {
          color: inputLabelColor,
        },
        '&$disabled': {
          '&, & $asterisk': {
            color: inputLabelDisabled,
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 14,
        lineHeight: 1.35,
        borderRadius: borderRadiusSmall,

        '&$focused, &:focus': {
          outline: 0,
        },
        '&$disabled': {
          backgroundColor: `${inputBackgroundDisabled} !important`,

          '&, & $input': {
            color: textPrimary,
            WebkitTextFillColor: textPrimary, // fix IOS color lighter
          },
        },
      },
      input: {
        height: 'auto',

        'label[data-shrink=false] + $root$formControl &::-webkit-input-placeholder': {
          // always show placeholder
          opacity: '1 !important',
        },
        '&::-webkit-input-placeholder': {
          color: textSecondary,
          opacity: 1,
          visibility: 'visible',
        },
        '&::-moz-placeholder': {
          color: textSecondary,
          opacity: 1,
          visibility: 'visible',
        },
      },
      adornedStart: {
        paddingLeft: inputHorizontalPadding,
      },
      inputAdornedStart: {
        '&$input': {
          paddingLeft: inputHorizontalPadding / 2,
        },
      },
      adornedEnd: {
        paddingRight: inputHorizontalPadding,
      },
      inputAdornedEnd: {
        '&$input': {
          paddingRight: inputHorizontalPadding / 2,
        },
      },
      multiline: {
        padding: 0,
      },
    },
    MuiInput: {
      root: {
        border: `1px solid ${borderPrimaryColor}`,
        backgroundColor: inputBackground,

        '&$focused, &:focus': {
          borderColor: colorPrimary,
        },
        '&$error': {
          borderColor: colorDanger,
        },
      },
      formControl: {
        'label + &': {
          marginTop: 0,
        },
      },
      underline: {
        '&:before,&:after': {
          content: 'none',
        },
        '&$focused': {
          borderWidth: 1,
        },
      },
      input: {
        padding: `${inputVerticalPadding}px ${inputHorizontalPadding}px`,
      },
    },

    MuiInputAdornment: {
      root: {
        '& button': {
          color: inputLabelColor,
          padding: 6,
          fontSize: 18,
        },
        '& svg': {
          fontSize: 'inherit',
        },
      },
      positionStart: {
        color: colorPrimary,
      },
      positionEnd: {
        color: textSecondary,
      },
    },
    MuiSelect: {
      icon: {
        color: inputLabelColor,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 11,
        marginTop: '0.4em',
        lineHeight: 1.2,
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: -9,
      },
    },
    MuiCheckbox: {
      root: {
        color: colorGrey,
        fontSize: 18,

        '&$disabled': {
          color: `${textDisabled} !important`,
        },
      },
    },
    MuiRadio: {
      root: {
        color: colorGrey,
        fontSize: 18,

        '&$disabled': {
          color: `${textDisabled} !important`,
        },
      },
    },
    MuiSwitch: {
      root: {
        padding: 13,
      },
      switchBase: {
        color: colorGrey,

        '&$disabled': {
          color: `${colorDisabled} !important`,
          '& + $track': {
            backgroundColor: `${colorGreyLight} !important`,
            borderColor: `${colorGrey10} !important`,
          },
        },
      },
      thumb: {
        width: 18,
        height: 18,
        boxShadow: 'none',
        marginTop: 1,
      },
      track: {
        backgroundColor: colorWhite,
        border: `1px solid ${colorGrey}`,
        opacity: '1 !important',
      },
      colorPrimary: {
        '&$checked + $track': {
          backgroundColor: colorPrimaryLight,
          borderColor: colorPrimary,
        },
      },
      colorSecondary: {
        '&$checked + $track': {
          backgroundColor: colorSecondaryLight,
          borderColor: colorSecondary,
        },
      },
    },
    MuiChip: {
      root: {
        backgroundColor: colorGreyLight,
        color: textSecondary,
        fontSize: 14,
        fontWeight: 400,
        height: 23,
        borderRadius,
      },
      icon: {
        color: textSecondary,
        fontSize: 12,
        marginLeft: 10,
        marginRight: -8,
      },
      label: {
        paddingLeft: 14,
        paddingRight: 14,
      },
      sizeSmall: {
        fontSize: 12,
      },
    },
    MuiAvatar: {
      rounded: {
        borderRadius,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: borderPrimaryColor,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius,
        boxShadow,

        [breakpoints.down('sm')]: {
          margin: 15,
        },
        [breakpoints.down('xs')]: {
          '&$paperScrollBody': {
            maxWidth: 'calc(100% - 30px) !important',
          },
          '&$paperScrollBody$paperFullScreen': {
            maxWidth: '100% !important',
          },
        },
      },
      paperWidthSm: {
        '&, &$paperScrollBody$paper': {
          [breakpoints.up('sm')]: {
            maxWidth: 450,
          },
        },
      },
      paperWidthMd: {
        '&, &$paperScrollBody$paper': {
          [breakpoints.up('md')]: {
            maxWidth: 720,
          },
        },
      },
      paperScrollBody: {},
      paperFullWidth: {
        width: 'calc(100% - 30px)',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: `${dialogVerticalMobile}px ${dialogHorizontalMobile}px 10px`,

        [breakpoints.up('sm')]: {
          padding: `${dialogVertical}px ${dialogHorizontal}px 10px`,
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: `0 ${dialogHorizontalMobile}px`,
        fontSize: 16,
        color: textSecondary,
        position: 'relative',
        wordBreak: 'break-word',

        [breakpoints.up('sm')]: {
          padding: `0 ${dialogHorizontal}px`,
        },
        '&:last-child': {
          paddingBottom: dialogVerticalMobile,

          [breakpoints.up('sm')]: {
            paddingBottom: dialogVertical,
          },
        },
        '&:first-child': {
          paddingTop: dialogVerticalMobile,

          [breakpoints.up('sm')]: {
            paddingTop: dialogVertical,
          },
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: `20px ${dialogHorizontalMobile}px ${dialogVerticalMobile}px`,

        [breakpoints.up('sm')]: {
          padding: `20px ${dialogHorizontal}px ${dialogVertical}px`,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: colorWhite,
        color: textPrimary,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.4,
        padding: '11px 12px',
        border: `1px solid ${borderPrimaryColor}`,
        borderRadius: 4,
        wordBreak: 'break-word',
        boxShadow,
      },
      touch: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.4,
        padding: '11px 12px',
      },
      tooltipPlacementTop: {
        margin: '10px 0',

        [breakpoints.up('sm')]: {
          margin: '10px 0',
        },
      },
    },
    MuiSlider: {
      root: {
        height: sliderHeight,
        padding: '10px 0',
      },
      rail: {
        height: sliderHeight,
      },
      track: {
        height: sliderHeight,
      },
      thumb: {
        marginTop: -4,
        backgroundColor: textPrimary,
        '&::after': {
          top: -10,
          left: -10,
          right: -10,
          bottom: -10,
        },
        '&$focusVisible, &:hover': {
          boxShadow: '0 0 0 7px rgba(0, 0, 0, 0.07)',
        },
        '&$active, &$active:hover': {
          boxShadow: '0 0 0 10px rgba(0, 0, 0, 0.14)',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        verticalAlign: 'middle',
      },
      fontSizeSmall: {
        height: 14,
        width: 14,
      },
    },

    // Mui Lab
    // @ts-ignore
    MuiPickersCalendar: {
      transitionContainer: {
        overflow: 'hidden',
        [breakpoints.down('xs')]: {
          minHeight: 190,
        },
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        marginLeft: 5,
        marginRight: 5,
        [breakpoints.down('xs')]: {
          '& p': {
            fontSize: 15,
          },
        },
      },
      iconButton: {
        padding: 8,
      },
      dayLabel: {
        [breakpoints.down('xs')]: {
          width: 30,
        },
      },
    },
    MuiPickersDay: {
      day: {
        [breakpoints.down('xs')]: {
          width: 30,
          height: 30,
          '& p': {
            fontSize: 14,
          },
        },
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: '0 !important',
        fontSize: 14,
        lineHeight: 1.4,
        borderRadius,
      },
      input: {
        padding: `${inputVerticalPadding}px 4px ${inputVerticalPadding}px ${inputHorizontalPadding}px !important`,
      },
      endAdornment: {
        color: inputLabelColor,
        position: 'static',
        display: 'flex',
        alignItems: 'center',
        marginRight: 8,
      },
      clearIndicator: {
        padding: 2,
        color: 'inherit',
        marginRight: 3,

        '& svg': {
          fontSize: 16,
        },
      },
      popupIndicator: {
        padding: 2,
        color: 'inherit',
        marginRight: 0,

        '&:hover, &:focus': {
          backgroundColor: 'transparent',
        },
        '& svg': {
          fontSize: 18,
        },
      },
      popupIndicatorOpen: {
        transform: 'none',
      },
      loading: {
        fontSize: 14,
        padding: '5px 12px',
      },
      noOptions: {
        fontSize: 14,
        padding: '0 4px',
      },
      tag: {
        marginLeft: 3,
      },
      clearIndicatorDirty: {
        visibility: 'visible !important',

        '& ~ $popupIndicator': {
          display: 'none',
        },
      },
    },
    MuiAlert: {
      root: {
        color: `${textPrimary} !important`,
        fontSize: 14,
        lineHeight: 1.4,
        fontWeight: 400,
        padding: 14,
        display: 'flex',
        alignItems: 'center',
        borderRadius,

        [breakpoints.up('sm')]: {
          paddingLeft: 20,
          paddingRight: 20,
        },
        '&:not(.alert-inner)': {
          boxShadow,
        },
      },
      icon: {
        fontSize: 24,
        padding: 0,
        marginRight: 10,

        [breakpoints.up('sm')]: {
          marginRight: 15,
        },
      },
      message: {
        padding: 0,
      },
      outlinedError: {
        backgroundColor: colorDangerLight,
        color: `${colorDanger} !important`,
      },
      outlinedSuccess: {
        backgroundColor: colorSuccessLight,
      },
      outlinedWarning: {
        borderColor: colorWarning,
        backgroundColor: colorWarningLight,

        '& $icon': {
          color: colorWarning,
        },
      },
      outlinedInfo: {
        borderColor: colorPrimary,
        backgroundColor: colorPrimaryLight,

        '& $icon': {
          color: colorPrimary,
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: 8,
      },
    },
  },
});
