import React, { memo, useCallback, useMemo } from 'react';
import { useField } from 'formik';
import cx from 'classnames';
import { debounce } from 'debounce';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { useErrorTranslations } from '../hooks/useErrorTranslations';
import { Option } from '../types';
import { styles } from '../styles';
import { INPUT_DEBOUNCE_DELAY } from '../constants';

const useStyles = makeStyles(styles);

export interface Props extends StandardTextFieldProps {
  options?: Option[];
  name: string;
  className?: string;
  isDebounce?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  endIcon?: React.ReactNode;
  readonly offBlur?: boolean;
  readonly defaultValue?: string | Option[] | number;
  readonly snowChips?: boolean;
  readonly startAdornment?: React.ReactNode;
  readonly customErrorMessage?: string;
}

export const Text = memo(
  ({
    id,
    select,
    label,
    margin = 'normal',
    required = false,
    type,
    autoComplete,
    InputProps,
    SelectProps,
    fullWidth = true,
    placeholder,
    autoFocus,
    options,
    multiline,
    rows,
    helperText,
    disabled,
    name,
    variant,
    className = '',
    error: customError,
    customErrorMessage = '',
    onBlur,
    onChange = () => {},
    isDebounce = false,
    endIcon = null,
    defaultValue,
    snowChips = false,
    startAdornment,
  }: Props) => {
    const classes = useStyles();
    const [{ value, onBlur: onBlurFormik, onChange: onChangeFormik, ...rest }, { touched, error: errorFormik }] =
      useField<string>(name);

    const handleBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        onBlurFormik(event);
        if (event.target.value) {
          if (onBlur) {
            onBlur(event);
          }
        }
      },
      [onBlurFormik, onBlur],
    );

    const wrappedOnChange = useMemo(
      () => (isDebounce ? debounce(onChange, INPUT_DEBOUNCE_DELAY) : onChange),
      [onChange, isDebounce],
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeFormik(event);
        wrappedOnChange(event);
      },
      [onChangeFormik, wrappedOnChange],
    );

    // @ts-ignore
    const error = useErrorTranslations(placeholder || label, errorFormik);

    const isError: boolean = Boolean((touched && error) || customError);

    return (
      <TextField
        id={id}
        classes={{ root: cx(classes.textFieldRoot, className, 'text-field') }}
        error={isError}
        helperText={
          isError && (error || customErrorMessage) ? (
            <>
              <span className="error-text">{error || customErrorMessage}</span>
            </>
          ) : (
            helperText
          )
        }
        label={label}
        select={select}
        margin={margin}
        type={type}
        autoComplete={autoComplete}
        InputLabelProps={{
          classes: {
            root: 'input-text-label',
          },
        }}
        InputProps={{
          endAdornment: endIcon && (
            <InputAdornment className="input-end-icon" position="end">
              {endIcon}
            </InputAdornment>
          ),
          ...(startAdornment
            ? {
                startAdornment: (
                  <InputAdornment position="start" className={classes.startAdornment}>
                    {startAdornment}
                  </InputAdornment>
                ),
              }
            : {}),
          ...InputProps,
          classes: {
            root: cx(classes.inputWrapper, 'input-wrapper', { [classes.selectWrapper]: select }),
            input: 'input-text',
            error: 'input-error',
            focused: 'input-focused',
          },
        }}
        SelectProps={{
          ...SelectProps,
          classes: {
            root: cx(classes.selectMain, 'select-main'),
            icon: classes.selectArrowDownIcon,
          },
          MenuProps: {
            classes: {
              paper: cx(classes.selectPaper, 'native-custom-scrollbar'),
              list: 'select-list',
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
          IconComponent: KeyboardArrowDownIcon,
          ...(snowChips
            ? {
                renderValue: selected => (
                  <div className={classes.chips}>
                    {(selected as string[]).map(value => (
                      // @ts-ignore
                      <Chip key={value} label={options.find(({ id }) => id === value)?.name} className={classes.chip} />
                    ))}
                  </div>
                ),
              }
            : {}),
        }}
        fullWidth={fullWidth}
        required={required}
        autoFocus={autoFocus}
        multiline={multiline}
        rows={rows}
        disabled={disabled}
        variant={variant}
        {...rest}
        placeholder={placeholder}
        value={value || defaultValue || ''}
        onBlur={handleBlur}
        onChange={handleChange}
      >
        {select &&
          options &&
          options.map((option: Option) => (
            <MenuItem className="select-option" key={option.id} value={option.id} disabled={option.disabled}>
              {option.name}
            </MenuItem>
          ))}
      </TextField>
    );
  },
);
