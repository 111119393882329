import { createStyles, Theme } from '@material-ui/core/styles';

import {
  borderPrimaryColor,
  colorWhite,
  bgBody,
  textSecondary,
  colorSuccess,
  colorSuccessLight,
  colorPrimary,
} from '@app/app/configs/variables';

export const styles = ({ breakpoints }: Theme) =>
  createStyles({
    groupSchedulerContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    title: {
      display: 'flex',
      flexGrow: 0,
      marginBottom: 13,
    },
    description: {
      display: 'flex',
      flexGrow: 0,
      marginBottom: 32,
    },
    descriptionLink: {
      textDecoration: 'underline',
      color: colorPrimary,
      cursor: 'pointer',
    },
    draggableArea: {
      flexGrow: 1,
      marginBottom: 21,
      border: `1px dashed ${borderPrimaryColor}`,
      backgroundColor: bgBody,
      boxSizing: 'border-box',
      borderRadius: 6,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      overflowY: 'scroll',
    },
    filledDraggableArea: {
      justifyContent: 'flex-start',
      padding: '24px 23px',
    },
    draggableAreaText: {
      marginTop: 29,
      color: '#A2A5B8',
      textAlign: 'center',
    },
    actions: {
      display: 'flex',
      justifyContent: 'space-between',
      flexGrow: 0,
      flexDirection: 'column',

      [breakpoints.up('lg')]: {
        flexDirection: 'row',
      },
    },
    actionButton: {
      marginBottom: 10,

      [breakpoints.up('md')]: {
        marginRight: 10,
      },

      [breakpoints.up('lg')]: {
        marginBottom: 0,
      },
    },
    itemContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      minHeight: 50,
      border: `1px solid ${borderPrimaryColor}`,
      boxSizing: 'border-box',
      borderRadius: 8,
      marginBottom: 10,
      paddingLeft: 16,
    },
    itemStatus: {
      marginRight: 10,
    },
    itemValue: {
      flexGrow: 2,
      wordBreak: 'break-word',
    },
    itemActions: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    itemValuePosition: {
      color: textSecondary,
    },
    modalDescription: {
      marginBottom: 10,
    },
    modalActions: {
      [breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
      },
    },
    divider: {
      borderBottom: `1px solid ${borderPrimaryColor}`,
      marginTop: 10,
      marginBottom: 23,
    },
    actionsButton: {
      paddingLeft: 10,
      paddingRight: 10,
      width: 93,
      backgroundColor: colorSuccessLight,
      color: textSecondary,
      '&:hover': {
        color: colorWhite,
        '& .MuiButton-startIcon': {
          color: colorWhite,
        },
      },
      '& .MuiButton-startIcon': {
        color: colorSuccess,
      },
    },
    copyLinkDescription: {
      marginBottom: 20,
    },
  });
