import { createStyles, Theme } from '@material-ui/core/styles';

import { borderPrimaryColor, colorPrimary, inputLabelColor } from '@app/app/configs/variables';

export default ({ breakpoints }: Theme) =>
  createStyles({
    paper: {
      padding: 20,
      '&:hover': {
        '& svg.iconEdit': {
          opacity: 1,
        },
      },
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      wordBreak: 'break-word',
    },
    gridItemDatesWrapper: {
      [breakpoints.down('sm')]: {
        borderBottom: `1px solid ${borderPrimaryColor}`,
        marginBottom: 15,
        paddingBottom: 15,
      },
      [breakpoints.up('md')]: {
        borderRight: `1px solid ${borderPrimaryColor}`,
        paddingRight: 25,
        height: '100%',
      },
    },
    fieldWrapper: {
      marginBottom: 5,
    },
    statusWrapper: {
      margin: '10px 0',
    },
    gridItemParticipantsWrapper: {
      [breakpoints.down('sm')]: {
        borderBottom: `1px solid ${borderPrimaryColor}`,
        marginBottom: 15,
        paddingBottom: 15,
      },
      [breakpoints.up('md')]: {
        borderRight: `1px solid ${borderPrimaryColor}`,
        padding: '0 25px',
        height: '100%',
      },
    },
    gridItemDescriptionWrapper: {
      padding: '0 28px 0 0',
      [breakpoints.up('md')]: {
        padding: '0 25px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        whiteSpace: 'break-spaces',
        display: '-webkit-box !important',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 11,
      },
    },
    gridItemDescriptionTooltip: {
      wordBreak: 'break-word',
      whiteSpace: 'break-spaces',
    },
    copyButton: {
      cursor: 'pointer',
      fontSize: 18,
      color: colorPrimary,
      marginLeft: 10,
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: 10,
      '&:hover': {
        '& svg': {
          opacity: 1,
        },
      },
    },
    editIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
      cursor: 'pointer',
      color: inputLabelColor,
      opacity: 0,
      [breakpoints.down('sm')]: {
        opacity: 1,
        top: 'unset',
        bottom: 0,
      },
    },
    gridItemIcon: {
      width: 18,
      height: 18,
      cursor: 'pointer',
      color: inputLabelColor,
      opacity: 0,
      [breakpoints.down('sm')]: {
        opacity: 1,
      },
    },
    gridItemDescriptionTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 25px',
      [breakpoints.down('sm')]: {
        padding: 0,
      },
      '&:hover': {
        '& svg': {
          opacity: 1,
        },
      },
    },
    participantInfoContainer: {
      display: 'flex',
      marginBottom: 8,
      wordBreak: 'break-word',
    },
    participantStatus: {
      marginRight: 5,
      marginTop: -1,
      minWidth: 18,
    },
  });
