import { AxiosRequestConfig } from 'axios';
import { CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';

import { apiClient } from '@app/query';

import { Interview, InterviewEntity, RescheduledInterview, UpdateInterviewForm } from '../types/Interview';
import { InterviewInviteForm } from '../types/InterviewInvite';
import { InterviewParticipantsEntity } from '../types/InterviewsParticipants';
import { FreeSlotsResponse } from '../types/Slot';

const INTERVIEWS_URL = '/interviews';

export const INTERVIEW_PARTICIPANTS_URL = '/interviews-participants';

export const getInterviews = <T = InterviewEntity[]>(
  params: CreateQueryParams = {},
  options: AxiosRequestConfig = {},
): Promise<T> => apiClient.get(INTERVIEWS_URL, { ...options, params: RequestQueryBuilder.create(params).queryObject });

export const getInterview = <T = InterviewEntity>(id: number): Promise<T> => apiClient.get(`${INTERVIEWS_URL}/${id}`);

export const getFreeSlots = <T = FreeSlotsResponse[]>(
  params: Record<any, any> = {},
  options: AxiosRequestConfig = {},
): Promise<T> => apiClient.get(`/availabilities/free-slots`, { ...options, params });

export const getInviteFreeSlots = <T = FreeSlotsResponse[]>(
  token: string,
  params: Record<any, any> = {},
  options: AxiosRequestConfig = {},
): Promise<T> => apiClient.get(`/availabilities/free-slots/${token}`, { ...options, params });

export const createInterview = <T = InterviewEntity, D = Interview>(
  data: D,
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
): Promise<T> =>
  apiClient.post(INTERVIEWS_URL, data, {
    params: params && RequestQueryBuilder.create(params).queryObject,
    ...options,
  });

export const updateInterview = (
  { id, ...data }: Partial<UpdateInterviewForm & { id: number }>,
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
): Promise<UpdateInterviewForm> =>
  apiClient.patch(`${INTERVIEWS_URL}/${id}/update`, data, {
    params: params && RequestQueryBuilder.create(params).queryObject,
    ...options,
  });

export const deleteInterview = (
  id: number,
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
) =>
  apiClient.delete(`${INTERVIEWS_URL}/${id}`, {
    params: params && RequestQueryBuilder.create(params).queryObject,
    ...options,
  });

export const cancelInterview = (id: number, options: AxiosRequestConfig = {}) =>
  apiClient.patch(`${INTERVIEWS_URL}/${id}/cancel`, {
    ...options,
  });

export const getInterviewsParticipants = <T = InterviewParticipantsEntity[]>(
  params: CreateQueryParams = {},
  options: AxiosRequestConfig = {},
): Promise<T> =>
  apiClient.get(INTERVIEW_PARTICIPANTS_URL, { ...options, params: RequestQueryBuilder.create(params).queryObject });

export const createInterviewViaInvite = <T = InterviewEntity>(
  { token, ...data }: InterviewInviteForm,
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
): Promise<T> =>
  apiClient.post(`${INTERVIEWS_URL}/invite/${token}`, data, {
    params: params && RequestQueryBuilder.create(params).queryObject,
    ...options,
  });

export const rescheduleInterview = (
  { id, date, time, title, description }: RescheduledInterview,
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
): Promise<InterviewEntity> => {
  const data: Partial<RescheduledInterview> = { date, time };
  if (title) {
    data.title = title;
  }
  if (description !== undefined && description !== null) {
    data.description = description;
  }
  return apiClient.patch(`${INTERVIEWS_URL}/${id}/reschedule`, data, {
    params: params && RequestQueryBuilder.create(params).queryObject,
    ...options,
  });
};
