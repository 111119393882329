import { useCallback, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';

import { createStripeUser, subscribe } from '@app/payments/api';
import { PAYMENT_PLANS } from '@app/payments/constants';
import { STRIPE_TOKEN } from '@app/payments/configs';
import { error } from '@app/snackbars';

const stripePromise = loadStripe(STRIPE_TOKEN as string);

export const useUpgradeToPro = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('common');

  const upgradePlan = useCallback(async () => {
    setIsLoading(true);

    try {
      await createStripeUser({ plan: PAYMENT_PLANS.PRO });

      const result = await subscribe();

      const stripe = await stripePromise;

      if (stripe) {
        await stripe.redirectToCheckout({
          sessionId: result.id,
        });
        setIsLoading(false);
      }
    } catch (e) {
      error(t('general.messages.somethingWentWrong'));
    }

    setIsLoading(false);
  }, []);

  return { upgradePlan, isLoading };
};
