import React from 'react';

import { lazyLoad } from '@app/app/utils/loadable';
import { Loader } from '@app/ui/loader';

export const LoginPage = lazyLoad(
  () => import('./index'),
  module => module.LoginPage,
  {
    fallback: <Loader />,
  },
);
