import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Theme & Global Style
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { theme } from '@app/app/configs/theme';
import { GlobalStyle } from '@app/app/styles/global-styles';

import { QueryProvider } from '@app/query/components/QueryProvider';
import { GuestRoute } from '@app/auth/components/route/GuestRoute';
import { ProtectedRoute } from '@app/auth/components/route/ProtectedRoute';
import { AuthProvider } from '@app/auth/components/AuthProvider';
import { SnackbarsProvider } from '@app/snackbars/components/Provider';
import { LouAssistScript } from '@app/app/components/LouAssistScript';

import { Availability } from './Availability';
import { NewInterviewPage } from './Interview/New';
import { InterviewsListPage } from './Interview/List';
import { InvitePage } from './Interview/Invite';
import { AvailabilityRequest } from './Availability/Request';
import { LoginPage } from './Login/Loadable';
import { RegistrationPage } from './Registration/Loadable';
import { CompleteRegistrationPage } from './CompleteRegistration/Loadable';
import { ResetPassword } from './ResetPassword/Loadable';
import { ResetPasswordConfirm } from './ResetPasswordConfirm/Loadable';
import { ThemePage } from './ThemePage';
import { ProfilePage } from './Users/Profile';
import { EditInterviewPage } from './Interview/Edit';

export const App = () => (
  <QueryProvider>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <AuthProvider>
          <BrowserRouter>
            <SnackbarsProvider>
              <GlobalStyle />
              <CssBaseline />
              <LouAssistScript />
              <DndProvider backend={HTML5Backend}>
                <Switch>
                  <ProtectedRoute exact path="/" component={Availability} />
                  <Route exact path="/a/:token" component={AvailabilityRequest} />
                  <ProtectedRoute exact path="/interviews/new" component={NewInterviewPage} />
                  <ProtectedRoute exact path="/interviews/edit/:id" component={EditInterviewPage} />
                  <Route exact path="/interview-invite/:token" component={InvitePage} />
                  <ProtectedRoute exact path="/interviews" component={InterviewsListPage} />
                  <ProtectedRoute exact path="/profile/:tab?" component={ProfilePage} />
                  <Route exact path="/theme" component={ThemePage} />
                  <GuestRoute exact path="/login" component={LoginPage} />
                  <GuestRoute exact path="/registration" component={RegistrationPage} />
                  <GuestRoute exact path="/registration/:token" component={CompleteRegistrationPage} />
                  <GuestRoute exact path="/reset-password" component={ResetPassword} />
                  <Route exact path="/reset-password/:token" component={ResetPasswordConfirm} />
                </Switch>
              </DndProvider>
            </SnackbarsProvider>
          </BrowserRouter>
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </QueryProvider>
);
