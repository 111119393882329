import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';

import { apiClient, ApiError } from '@app/query';
import { UserEntity } from '@app/users/interfaces';

import { Token, LocalAuthenticateData } from '../interfaces';
import { useAuthUser } from './useAuthUser';

interface LocalProvider {
  authenticate(data: LocalAuthenticateData): void;
  isLoading: boolean;
  user?: UserEntity;
  error?: ApiError;
}

export const useLocalAuth = (): LocalProvider => {
  const [error, setError] = useState<ApiError>();
  const { setToken, isLoading: userIsLoading, user } = useAuthUser();

  const { mutateAsync, isLoading: mutationIsLoading } = useMutation<Token, ApiError, LocalAuthenticateData>(
    ({ email, password }) => apiClient.post('/auth/login', { email, password }),
    {
      onSuccess: input => {
        setToken(input);
      },
      onError: e => setError(e),
    },
  );

  const isLoading = mutationIsLoading || userIsLoading;

  const authenticate = useCallback(
    (input: LocalAuthenticateData) => mutateAsync({ email: input.email, password: input.password }),
    [mutateAsync],
  );

  return { authenticate, isLoading, error, user };
};
