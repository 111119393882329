import React from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiDrawer, { DrawerProps } from '@material-ui/core/Drawer';

import { styles } from '../styles';

const useStyles = makeStyles(styles);

export interface Props extends DrawerProps {
  handleClose: () => void;
  className?: string;
}

export function Drawer({ children, open, anchor, handleClose, className }: Props) {
  const classes = useStyles();

  return (
    <MuiDrawer
      classes={{
        root: cx(classes.drawerRoot, className, {
          [classes.drawerRootTop]: anchor === 'top',
        }),
        paper: cx(classes.drawerPaper, 'drawer-paper'),
        paperAnchorLeft: classes.drawerAnchorLeft,
        paperAnchorRight: classes.drawerAnchorRight,
      }}
      anchor={anchor}
      open={open}
      onClose={handleClose}
      elevation={0}
      variant="temporary"
      ModalProps={{
        keepMounted: true,
        hideBackdrop: true,
      }}
    >
      <div className={classes.drawerContent}>{children}</div>
    </MuiDrawer>
  );
}
