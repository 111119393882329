import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useErrorTranslations(
  field: string,
  error: { key: string; values?: Record<string, string | number> } | string,
) {
  const { t } = useTranslation('common');

  return useMemo(() => {
    if (typeof error === 'object') {
      return t(`general.validations.${error.key}`, {
        ...error.values,
        field: field || t('general.this'),
      });
    }

    return error;
  }, [error]);
}
