import { useQuery } from 'react-query';
import { CreateQueryParams } from '@nestjsx/crud-request';
import { UseQueryOptions } from 'react-query/types/react/types';
import { AxiosRequestConfig } from 'axios';

import { ApiError } from '@app/query';

import { getTimezone, getTimezones } from '../api';
import { Timezone } from '../interfaces';

export const useTimezonesIndex = <T = { data: Timezone[] }>({
  params,
  options = {},
  queryBuilderOptions = {},
}: {
  params?: CreateQueryParams;
  options?: AxiosRequestConfig;
  queryBuilderOptions?: UseQueryOptions<T, ApiError>;
} = {}) =>
  useQuery<T, ApiError>(['timezones', params], () => getTimezones<T>(params, options), {
    cacheTime: 1000,
    ...queryBuilderOptions,
  });

export const useTimezone = <T = Timezone>({
  id,
  params = {},
  options = {},
  queryBuilderOptions = {},
}: {
  id: number;
  params?: CreateQueryParams;
  options?: AxiosRequestConfig;
  queryBuilderOptions?: UseQueryOptions<T, ApiError>;
}) =>
  useQuery<T, ApiError>(['timezone', id, params], () => getTimezone<T>(id, params, options), {
    cacheTime: 5000,
    ...queryBuilderOptions,
  });
