import { ConnectDragSource } from 'react-dnd';
import { Dispatch, SetStateAction, useCallback, useState, useMemo, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import copyTextToClipboard from 'copy-text-to-clipboard';
import cx from 'classnames';
import { useQueryClient } from 'react-query';
import { produce } from 'immer';
import { Formik } from 'formik';

import {
  Box,
  Button,
  Card as MaterialCard,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup as RadioGroupMaterial,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Chip } from '@app/ui/chip';
import { success } from '@app/snackbars';
import { GroupSchedulerEntity } from '@app/groupScheduler/types/GroupScheduler';
import { RESOURCES, STATUSES } from '@app/availabilities/constants';
import { INVITE_LINK_SEND_ME, INVITE_LINK_TYPE } from '@app/interview/constants';
import { useGroupSchedulerDelete, useGroupSchedulerUpdate } from '@app/groupScheduler/hooks/crud';
import { GROUP_SCHEDULER_LIST_KEY } from '@app/groupScheduler/constants';
import { useAuthUser } from '@app/auth';
import { Text } from '@app/ui/forms';

import { styles } from './styles';
import { ReactComponent as CopyIcon } from '../../../images/copy.svg';
import { AvailabilityEntity } from '../../../types/Availability';
import { Actions } from './Actions';

const useStyles = makeStyles(styles);

const SYSTEM_VALUE = 'system';
const MY_EMAIL_VALUE = 'myEmail';

export interface CardProps {
  readonly item: GroupSchedulerEntity;
  readonly setGroupLinkMembers: Dispatch<SetStateAction<AvailabilityEntity[]>>;
  readonly groupLinkMembers: AvailabilityEntity[];
  readonly refetch: () => void;

  // Collected Props
  isDragging: boolean;
  connectDragSource: ConnectDragSource;
  canDrag: boolean;
}

export const GroupSchedulerCard = ({ item, connectDragSource, canDrag, setGroupLinkMembers, refetch }: CardProps) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [sendMe, setSendMe] = useState(false);
  const { mutateAsync: deleteGroupScheduler } = useGroupSchedulerDelete();
  const {
    user: { availability, availabilityId: userAvailabilityId } = { availabilityId: 0, availability: undefined },
  } = useAuthUser();
  const [sendFrom, setSendFrom] = useState(!!item.sendFrom);
  const [sendFromEmail, setSendFromEmail] = useState(item.sendFrom);
  const sendFromIsAvailable = availability?.resource === RESOURCES.GOOGLE && !!availability?.meta.length;
  const emailOptions = availability?.meta.map(({ googleEmail }) => ({ id: googleEmail, name: googleEmail }));
  const { mutate: updateScheduler } = useGroupSchedulerUpdate();
  const queryClient = useQueryClient();

  const isRecruiterIncludes = useMemo(() => !!item.availabilities.find(({ id }) => id === userAvailabilityId), [item]);
  const restUsers = useMemo(
    () => item.availabilities.slice(isRecruiterIncludes ? 3 : 2, item.availabilities.length),
    [item, isRecruiterIncludes],
  );
  const { firstUser, secondUser, lastUser } = useMemo(() => {
    const [firstUser, second, third] = item.availabilities;
    return {
      firstUser,
      secondUser: isRecruiterIncludes && third ? second : undefined,
      lastUser: third ?? second,
    };
  }, [item, isRecruiterIncludes]);

  const moveToGroupScheduler = () => {
    setGroupLinkMembers(prevState => {
      const result = [...prevState];
      const ids = prevState.map(({ id }) => id);

      (item as GroupSchedulerEntity).availabilities.forEach(availability => {
        if (!ids.includes(availability.id)) {
          result.push(availability);
        }
      });

      return result;
    });
  };

  const copy = (m: number) => () => {
    copyTextToClipboard(
      `${window.location.origin}/interview-invite/${INVITE_LINK_TYPE.GROUP_LINK}.${
        sendMe ? INVITE_LINK_SEND_ME.YES : INVITE_LINK_SEND_ME.NO
      }.${m}.${item.token}`,
    );
    success(t('groupSchedulerLink.form.create.copiedMessage'), { autoHideDuration: 3000 });
  };

  const handleDelete = useCallback(async () => {
    await deleteGroupScheduler({ id: item.id });
    refetch();
    success(t('availabilities.messages.groupSchedulerDeleted'));
  }, [deleteGroupScheduler, item.id, refetch, t]);

  const updateSendFrom = async (value: ChangeEvent<HTMLInputElement>) => {
    if (value.target.value === MY_EMAIL_VALUE) {
      const email = availability?.meta[0].googleEmail;

      setSendFrom(true);
      setSendFromEmail(email);
      setSendMe(false);
      updateScheduler({ sendFrom: email, id: item.id });
    } else {
      setSendFrom(false);
      setSendFromEmail(undefined);
      updateScheduler({ sendFrom: null, id: item.id });
    }

    queryClient.setQueriesData(GROUP_SCHEDULER_LIST_KEY, data =>
      produce(data, (draft: GroupSchedulerEntity[]) => {
        const index = draft.findIndex(datum => datum.id === item.id);

        draft[index].sendFrom = value.target.value === MY_EMAIL_VALUE ? availability?.meta[0].googleEmail : undefined;

        return draft;
      }),
    );
  };

  const updateSendFromEmail = useCallback(
    async ({ sendFromEmail }) => {
      setSendFromEmail(sendFromEmail);
      updateScheduler({ sendFrom: sendFromEmail, id: item.id });

      queryClient.setQueriesData(GROUP_SCHEDULER_LIST_KEY, data =>
        produce(data, (draft: GroupSchedulerEntity[]) => {
          const index = draft.findIndex(datum => datum.id === item.id);

          draft[index].sendFrom = sendFromEmail;

          return draft;
        }),
      );
    },
    [item.id],
  );
  const initialValues = useMemo(() => ({ sendFromEmail }), [sendFromEmail]);

  useEffect(() => {
    if (
      item.sendFrom &&
      availability &&
      (!sendFromIsAvailable || !availability?.meta?.find(({ googleEmail }) => item.sendFrom === googleEmail))
    ) {
      setSendFrom(false);
      setSendFromEmail(undefined);
      updateScheduler({ sendFrom: null, id: item.id });
    }
  }, [item, sendFromIsAvailable, availability]);

  return (
    <>
      <MaterialCard
        variant="outlined"
        ref={connectDragSource}
        className={cx(classes.cardRoot, canDrag ? classes.draggableCard : '')}
      >
        <CardHeader
          className={classes.header}
          classes={{ action: classes.headerAction }}
          subheader={t('groupSchedulerLink.card.title')}
          action={
            <>
              <Chip status={item.status} label={t(`groupSchedulerLink.status.${item.status}`)} />
              <Actions onDelete={handleDelete} onMove={moveToGroupScheduler} canDrag={canDrag} canDelete isGroup />
            </>
          }
        />
        <CardContent className={classes.content}>
          <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
            <div>
              <Typography variant="h4" gutterBottom>
                {item.subject}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <div className={classes.groupLinkItem}>
                  <div className={classes.groupLinkName}>
                    <div className={classes.groupLinkStatus}>
                      <Chip status={firstUser.status} iconOnly round />
                    </div>
                    <div>
                      <Typography variant="h6">{firstUser.name}</Typography>
                      <div>{firstUser.email}</div>
                    </div>
                  </div>
                </div>
                {secondUser && (
                  <div className={classes.groupLinkItem}>
                    <div className={classes.groupLinkName}>
                      <div className={classes.groupLinkStatus}>
                        <Chip status={secondUser.status} iconOnly round />
                      </div>
                      <div>
                        <Typography variant="h6">{secondUser.name}</Typography>
                        <div>{secondUser.email}</div>
                      </div>
                    </div>
                  </div>
                )}
                {lastUser ? (
                  <div className={classes.groupLinkItem}>
                    <div className={classes.groupLinkName}>
                      <div className={classes.groupLinkStatus}>
                        <Chip status={lastUser.status} iconOnly round />
                      </div>
                      <div>
                        <Typography variant="h6">{lastUser.name}</Typography>
                        <div>{lastUser.email}</div>
                      </div>
                    </div>

                    {restUsers.length ? (
                      <Tooltip
                        interactive
                        classes={{
                          tooltip: cx(classes.groupLinkTooltipWrapper, {
                            [classes.groupLinkTooltipWrapperLong]: item.availabilities.some(
                              ({ status }) => status !== STATUSES.ACTIVE,
                            ),
                          }),
                        }}
                        title={
                          <>
                            {item.availabilities.map(user => (
                              <div key={user.email} className={classes.groupLinkItem}>
                                <div className={classes.groupLinkName}>
                                  <div className={classes.groupLinkStatus}>
                                    <Chip status={user.status} iconOnly round />
                                  </div>
                                  <div>
                                    <Typography variant="h6">{user.name}</Typography>
                                    <div>{user.email}</div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        }
                      >
                        <Typography className={classes.groupLinkMore} color="textSecondary">
                          {t('general.more', { count: restUsers.length })}
                        </Typography>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </Typography>
            </div>

            <FormControl>
              <InputLabel>{t(`availabilities.list.sendFrom`)}</InputLabel>
              <RadioGroupMaterial
                value={sendFrom ? MY_EMAIL_VALUE : SYSTEM_VALUE}
                name="sendFrom"
                onChange={updateSendFrom}
              >
                <Box>
                  <Tooltip title={<>{t('availabilities.list.sendFromSystemEmailTooltip')}</>}>
                    <FormControlLabel
                      value={SYSTEM_VALUE}
                      label={t(`availabilities.list.sendFromSystemEmail`)}
                      control={<Radio color="primary" required />}
                    />
                  </Tooltip>
                  <Tooltip
                    title={
                      <>
                        {t(
                          `availabilities.list.${
                            sendFromIsAvailable ? 'sendFromMyEmailTooltip' : 'sendFromIsUnavailable'
                          }`,
                        )}
                      </>
                    }
                  >
                    <span>
                      <FormControlLabel
                        value={MY_EMAIL_VALUE}
                        label={t(`availabilities.list.sendFromMyEmail`)}
                        control={<Radio color="primary" required />}
                        disabled={!sendFromIsAvailable}
                      />
                    </span>
                  </Tooltip>
                </Box>
              </RadioGroupMaterial>
              {!isRecruiterIncludes && !sendFrom && (
                <FormControlLabel
                  control={
                    <Checkbox value={sendMe} onChange={() => setSendMe(prevState => !prevState)} color="primary" />
                  }
                  label={t('availabilities.list.sendInvite')}
                  labelPlacement="end"
                />
              )}
              {sendFrom && (
                <Formik initialValues={initialValues} enableReinitialize onSubmit={updateSendFromEmail}>
                  {({ submitForm }) => (
                    <Text
                      disabled={!sendFromIsAvailable}
                      name="sendFromEmail"
                      margin="none"
                      select
                      required
                      options={emailOptions}
                      onChange={submitForm}
                    />
                  )}
                </Formik>
              )}
            </FormControl>
          </Box>
        </CardContent>
        <CardActions className={classes.actions}>
          <Grid container className={classes.actionsContainer}>
            <Grid item xs={4} className={classes.actionsButtonContainer}>
              <Tooltip title={<>{t('availabilities.groupSchedulerTooltip', { time: t('availabilities.time.30') })}</>}>
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<CopyIcon />}
                  className={classes.actionsButton}
                  onClick={copy(30)}
                >
                  {t('availabilities.time.30')}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={4} className={classes.actionsButtonContainer}>
              <Tooltip title={<>{t('availabilities.groupSchedulerTooltip', { time: t('availabilities.time.60') })}</>}>
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<CopyIcon />}
                  className={classes.actionsButton}
                  onClick={copy(60)}
                >
                  {t('availabilities.time.60')}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={4} className={classes.actionsButtonContainer}>
              <Tooltip title={<>{t('availabilities.groupSchedulerTooltip', { time: t('availabilities.time.90') })}</>}>
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<CopyIcon />}
                  className={classes.actionsButton}
                  onClick={copy(90)}
                >
                  {t('availabilities.time.90')}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </CardActions>
      </MaterialCard>
    </>
  );
};
