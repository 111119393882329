import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';

import { InterviewEntity } from '@app/interview/types/Interview';
import { SearchParams } from '@app/interview/hooks/useSearch';
import { useAuthUser } from '@app/auth';
import { usePlan } from '@app/payments/hooks/usePlan';

import { useTimezonesIndex } from '@app/timezones/hooks/crud';
import { getUserTimezoneName, getUserTimezoneOffset } from '@app/interview/utils';
import { Card } from './Card';
import { Filter } from './Filter';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export interface ListProps {
  readonly items: InterviewEntity[];
  readonly pageCount: number;
  readonly page: number;
  readonly onSearch: (values: SearchParams) => void;
  readonly refetch: () => void;
}

export const InterviewsList = ({ items, onSearch, pageCount, page, refetch }: ListProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const respUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const respUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const { user } = useAuthUser();
  const { isBasic } = usePlan();
  const { data: { data: timezones } = { data: [] } } = useTimezonesIndex();

  const currentUserTimezone = (timezones ?? []).find(
    ({ id }) => user?.availability?.meta && user?.availability?.meta[0]?.timezoneId === id,
  );
  const userOffset = getUserTimezoneOffset(currentUserTimezone);

  const userTimezoneName = getUserTimezoneName(userOffset, currentUserTimezone);

  return (
    <>
      <Grid container spacing={3} alignItems="center" className={classes.filterWrapper}>
        <Grid item xs={12} sm>
          <Filter onSearch={onSearch} noNew={isBasic} />
        </Grid>
      </Grid>
      <Grid container spacing={respUpLg ? 3 : 2}>
        {items.map(item => (
          <Grid key={item.id} item xs={12}>
            <Card
              item={item}
              refetch={refetch}
              userOffset={userOffset}
              userTimezoneName={userTimezoneName}
              noActions={isBasic}
            />
          </Grid>
        ))}
      </Grid>
      <Box mt={2}>
        <Pagination
          className={!items.length ? classes.pagination : ''}
          count={pageCount}
          variant="outlined"
          shape="rounded"
          size={respUpMd ? 'medium' : 'small'}
          showFirstButton
          showLastButton
          color="primary"
          page={page}
          onChange={(e, page) => onSearch({ page })}
        />
      </Box>
    </>
  );
};
