import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Button, Grid } from '@material-ui/core';
import { FormikHelpers } from 'formik';

import { useModal } from '@app/ui/modal';
import { useAuthUser } from '@app/auth';
import { error, success } from '@app/snackbars';
import { LimitModal } from '@app/payments/components/Basic/LimitModal';

import { Form } from './Form';
import { AVAILABILITY_LIST_KEY } from '../../../constants';
import { useAvailabilityBulkStore } from '../../../hooks/crud';
import { BulkAvailability } from '../../../types/BulkAvailability';

interface Props {
  showLimitPopup: boolean;
}

export const Request = ({ showLimitPopup }: Props) => {
  const { t } = useTranslation('common');
  const { isOpen, show, hide } = useModal();
  const { isOpen: isOpenLimitModal, show: showLimitModal, hide: hideLimitModal } = useModal();
  const [sendRequest, setSendRequest] = useState(true);
  const queryClient = useQueryClient();
  const { user } = useAuthUser();

  const { mutateAsync, isLoading } = useAvailabilityBulkStore({
    onSuccess: () => queryClient.invalidateQueries(AVAILABILITY_LIST_KEY),
  });

  const handleSubmit = useCallback(
    async (data: BulkAvailability, { setErrors }: FormikHelpers<BulkAvailability>) => {
      try {
        const result = await mutateAsync({
          bulk: data.bulk.map(item => ({ ...item, userId: user?.id })),
          sendRequest,
        });
        hide();
        success(t(sendRequest ? 'availabilities.messages.requestSent' : 'availabilities.messages.created'));
        if (!sendRequest && result) {
          const [{ token }] = result;
          window.open(`/a/${token}`, '_blank');
        }
      } catch (e) {
        if (e.statusCode === 400) {
          setErrors(e.errors);
        } else {
          error(e?.message);
        }
      }

      return undefined;
    },
    [t, mutateAsync, user, sendRequest],
  );

  const handleSendRequest = useCallback(() => {
    if (showLimitPopup) {
      showLimitModal();
    } else {
      setSendRequest(true);
      show();
    }
  }, [showLimitPopup]);
  const handleJustCreate = useCallback(() => {
    if (showLimitPopup) {
      showLimitModal();
    } else {
      setSendRequest(false);
      show();
    }
  }, [showLimitPopup]);

  return (
    <Grid container direction="column" spacing={1}>
      {isOpen && <Form onCancel={hide} sendRequest={sendRequest} onSubmit={handleSubmit} isLoading={isLoading} />}
      {isOpenLimitModal && <LimitModal onCancel={hideLimitModal} />}
      <Grid item>
        <Button color="primary" variant="contained" onClick={handleSendRequest} fullWidth>
          {t('availabilities.buttons.sendRequest')}
        </Button>
      </Grid>
      <Grid item>
        <Button color="primary" variant="contained" onClick={handleJustCreate} fullWidth>
          {t('availabilities.buttons.createAvailability')}
        </Button>
      </Grid>
    </Grid>
  );
};
