import React, { useCallback, useState } from 'react';
import { Link as RouterLink, matchPath, useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { Drawer } from '@app/ui/drawer';
import { Menu } from '@app/ui/menu';
import { Avatar } from '@app/ui/avatar';
import { Breadcrumbs, BreadcrumbsOption } from '@app/ui/breadcrubms';
import { useAuthUser } from '@app/auth/hooks/useAuthUser';
import logoImage from '@app/app/images/logo.svg';
import { DaysLeft } from '@app/payments/components/Trial/DaysLeft';
import { FirstLoginModal } from '@app/payments/components/Trial/FirstLoginModal';
import { ReminderBanner } from '@app/payments/components/Trial/ReminderBanner';

import { styles } from '../styles';

const useStyles = makeStyles(styles);
const checkOnActive = (currentPath: string, checkingPath: string) => matchPath(currentPath, checkingPath);

export interface Props {
  children: React.ReactNode;
  breadcrumbs?: BreadcrumbsOption[];
  rightSidebar?: React.ReactNode;
  className?: string;
  mainContainerClassName?: string;
}

export function MainLayout({ children, breadcrumbs, rightSidebar, className, mainContainerClassName }: Props) {
  const classes = useStyles();
  const { push } = useHistory();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { logout } = useAuthUser();
  const { user } = useAuthUser();
  const respIsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const respIsTablet = useMediaQuery(theme.breakpoints.down('sm'));

  const [mainMenuOpen, setMainMenuOpen] = useState(false);

  const handleMainMenuToggle = useCallback(() => {
    setMainMenuOpen(!mainMenuOpen);
  }, [mainMenuOpen]);

  const menu = [
    {
      name: 'Availability',
      to: '/',
      active: Boolean(checkOnActive(pathname, '/')?.isExact),
      onClick: respIsMobile ? handleMainMenuToggle : undefined,
    },
    {
      name: 'Interviews',
      to: '/interviews',
      active: Boolean(checkOnActive(pathname, '/interviews')),
      onClick: respIsMobile ? handleMainMenuToggle : undefined,
    },
    // {
    //   name: 'Tutorials',
    //   to: '/',
    //   active: Boolean(checkOnActive(pathname, '/tutorials')),
    //   onClick: respIsMobile ? handleMainMenuToggle : undefined,
    // },
  ];

  const userName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;

  const handleLogout = () => {
    logout();
  };

  return (
    <div className={cx(classes.rootContainer, className)}>
      {respIsMobile && (
        <Drawer open={mainMenuOpen} handleClose={handleMainMenuToggle}>
          <Menu menu={menu} variant="vertical" />

          <Divider className={classes.mobileMenuDivider} />

          <Button className={cx(classes.logoutMobileButton, 'ta-logout-button')} onClick={handleLogout}>
            Logout
            <ExitToAppIcon />
          </Button>
        </Drawer>
      )}

      <AppBar className={cx(classes.header, respIsMobile ? classes.stickyTop : '')} color="default" position="static">
        <Container maxWidth="lg">
          <Toolbar className={classes.headerToolbar} disableGutters>
            {respIsMobile && (
              <IconButton className={cx(classes.menuButton, 'ta-button-menu')} onClick={handleMainMenuToggle}>
                <MenuIcon fontSize="inherit" />
              </IconButton>
            )}
            <Box display="flex" alignItems="center">
              <Link className={classes.logoBlock} to="/" component={RouterLink}>
                <img className={classes.siteLogo} src={logoImage} alt="Bettermeeting" />
              </Link>

              {!respIsMobile && <Menu className={classes.headerMenu} menu={menu} />}
            </Box>

            <div className={classes.headerActionBlock}>
              <DaysLeft />
              <div role="button" tabIndex={0} className={classes.headerUserBlock} onClick={() => push('/profile')}>
                {!respIsMobile && <div>{userName}</div>}
                <Avatar name={userName} size={respIsTablet ? 'small' : 'normal'} />
              </div>
              {!respIsMobile && (
                <IconButton className={cx(classes.logoutButton, 'ta-logout-button')} onClick={handleLogout}>
                  <ExitToAppIcon fontSize="inherit" />
                </IconButton>
              )}
            </div>
          </Toolbar>
        </Container>
      </AppBar>

      <Container
        maxWidth="lg"
        className={cx(classes.mainContainer, mainContainerClassName, {
          [classes.withRightSidebar]: rightSidebar,
        })}
      >
        <main className={cx(classes.mainContent, 'main-content')}>
          <ReminderBanner />
          {breadcrumbs && <Breadcrumbs options={breadcrumbs} />}
          {children}
        </main>
        {rightSidebar && (
          <div className={classes.rightSidebarWrapper}>
            <div className={classes.rightSidebar}>{rightSidebar}</div>
          </div>
        )}
      </Container>
      <FirstLoginModal />
    </div>
  );
}
