import React from 'react';

import { GuestLayout } from '@app/app/components/GuestLayout';
import { Request } from '@app/availabilities/components/Request';
import { GoogleAuthProvider } from '@app/google/components/GoogleAuthProvider';

export const RequestPage = () => (
  <GoogleAuthProvider>
    <GuestLayout>
      <Request />
    </GuestLayout>
  </GoogleAuthProvider>
);
