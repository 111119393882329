import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import { Modal } from '@app/ui/modal';
import { useDetailedUpcomingPaymentInfo } from '@app/payments/hooks/crud';
import { Table } from '@app/ui/table/components/Table';
import { DetailedPayment } from '@app/payments/types';
import { numberWithCommas } from '@app/payments/utils/formatter';
import { UPCOMING_INVOICE_TYPES } from '@app/payments/constants';
import { useDate } from '@app/app/hooks/useDate';

import { styles } from './styles';
import { DescriptionCell } from './Cells/DescriptionCell';
import { DateCell } from './Cells/DateCell';
import { AmountCell } from './Cells/AmountCell';

const useStyles = makeStyles(styles);

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const DetailedPriceModal = ({ isOpen, onClose }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { data: { data, total } = { data: [], total: 0 } } = useDetailedUpcomingPaymentInfo();
  const { formatCustom } = useDate();

  const columns = [
    {
      Header: t('user.profile.tabs.billing.detailedInfo.columns.description'),
      accessor: (record: DetailedPayment) => record,
      Cell: DescriptionCell,
      id: 'description',
      disableSortBy: true,
    },
    {
      Header: t('user.profile.tabs.billing.detailedInfo.columns.date'),
      accessor: (record: DetailedPayment) => record,
      Cell: DateCell,
      id: 'date',
      disableSortBy: true,
      width: 70,
    },
    {
      Header: t('user.profile.tabs.billing.detailedInfo.columns.amount'),
      accessor: (record: DetailedPayment) => record,
      Cell: AmountCell,
      id: 'amount',
      disableSortBy: true,
      width: 35,
      classNames: classes.alignmentRight,
    },
  ];

  const date = formatCustom(
    (data ?? []).find(({ type }) => type === UPCOMING_INVOICE_TYPES.SUBSCRIPTION)?.dateEnd,
    'MMM DD',
  );

  return (
    <Modal
      isOpen={isOpen}
      hide={onClose}
      title={t('user.profile.tabs.billing.detailedInfo.modalTitle')}
      maxWidth="md"
      description={t('user.profile.tabs.billing.detailedInfo.modalDescription', { date })}
    >
      <Table
        columns={columns}
        data={data}
        CustomFooter={
          <div className={classes.footerWrapper}>
            <div>{t('user.profile.tabs.billing.detailedInfo.total')}</div>{' '}
            <div>{`$${numberWithCommas(total / 100)}`}</div>
          </div>
        }
      />
    </Modal>
  );
};
