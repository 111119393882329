import { createStyles } from '@material-ui/core/styles';

import { borderPrimaryColor } from '@app/app/configs/variables';

export const styles = () =>
  createStyles({
    cardRoot: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      wordBreak: 'break-word',
    },
    header: {
      paddingBottom: 5,
    },
    headerAction: {
      margin: 0,
    },
    content: {
      paddingTop: 0,
      wordBreak: 'break-word',
      flex: 1,
    },
    actions: {
      padding: 0,
    },
    actionsContainer: {
      borderTop: `1px solid ${borderPrimaryColor}`,
    },
    actionsButton: {
      border: 'none',
      borderRadius: 0,
      padding: 10,
    },
    actionsButtonContainer: {
      '&:not(:first-child)': {
        borderLeft: `1px solid ${borderPrimaryColor}`,
      },
    },
    draggableCard: {
      cursor: 'pointer',
    },
    groupLinkTooltipWrapper: {
      maxWidth: 344,
    },
    groupLinkTooltipWrapperLong: {
      width: 344,
    },
    groupLinkItem: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: 10,

      '&:last-child': {
        marginBottom: 0,
      },
    },
    groupLinkName: {
      display: 'flex',
    },
    groupLinkRequestAgain: {
      display: 'flex',
      alignItems: 'flex-start',
      cursor: 'pointer',
      marginLeft: 5,
      justifyContent: 'flex-end',

      '& svg': {
        marginRight: 5,
      },
    },
    groupLinkStatus: {
      marginRight: 5,
    },
    groupLinkMore: {
      whiteSpace: 'nowrap',
    },
  });
