import React from 'react';

import { Alert, Props } from '@app/ui/alert';

// @ts-ignore
const Message = React.forwardRef((props: Props, ref: React.RefObject<HTMLElement>) => (
    <span ref={ref}>
      <Alert {...props} />
    </span>
  ));

export default Message;
