import { Trans, useTranslation } from 'react-i18next';

import { Confirmation } from '@app/ui/modal';
import { Loader } from '@app/ui/loader';
import { useDeleteCurrentSubscription } from '@app/payments/hooks/crud';
import { useAuthUser } from '@app/auth';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const DowngradePlanConfirmation = ({ onClose, isOpen }: Props) => {
  const { mutateAsync: deleteCurrentSubscription, isLoading } = useDeleteCurrentSubscription();
  const { refetch: refetchCurrentUser } = useAuthUser();
  const { t } = useTranslation('common');

  const onAccept = async () => {
    await deleteCurrentSubscription();
    refetchCurrentUser();
    onClose();
  };

  return (
    <Confirmation
      onAccept={onAccept}
      onCancel={onClose}
      isOpen={isOpen}
      title={t('payments.downgradeConfirmation.title')}
      description={
        <>
          {isLoading && <Loader showOverlay />}
          <Trans
            t={t}
            defaults="payments.downgradeConfirmation.description"
            components={{ br: <b /> }}
            values={{
              fromPlan: t('payments.plans.pro'),
              toPlan: t('payments.plans.basic'),
            }}
          />
        </>
      }
      acceptButton={t('general.buttons.downgrade')}
    />
  );
};
