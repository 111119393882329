import { useMutation, UseMutationOptions, useQuery } from 'react-query';

import { apiClient, ApiError } from '@app/query';
import { Form, Registration } from '@app/auth/interfaces/registration';
import { Token } from '@app/auth/interfaces';

const endpoint = '/auth';

export const usePasswordReset = ({ onSuccess }: { onSuccess: () => void }) =>
  useMutation(data => apiClient.post(`${endpoint}/reset-password`, data), { onSuccess });

export const useValidateToken = ({ token, onError }: { token: string; onError?: () => void }) =>
  useQuery('validateResetToken', () => apiClient.get(`${endpoint}/reset-password/${token}`), { onError, retry: false });

export const usePasswordResetConfirm = ({ onSuccess }: { onSuccess: () => void }) =>
  useMutation(data => apiClient.post(`${endpoint}/reset-password-confirm`, data), { onSuccess });

export const useValidateRegistrationToken = ({ token, onError }: { token?: string; onError?: () => void }) =>
  useQuery<Token, ApiError, Token>(
    'validateRegistrationToken',
    () => apiClient.get(`${endpoint}/registration/${token}`),
    {
      enabled: !!token,
      onError,
      retry: false,
    },
  );

export const useRegistration = (options?: UseMutationOptions<Token, ApiError, Registration>) =>
  useMutation<Token, ApiError, Registration>(data => apiClient.post(`${endpoint}/registration`, data), options);

export const useResendRegistrationEmail = ({ token }: { token?: string }) =>
  useQuery<Token, ApiError, Token>(
    'resendConfirmationEmail',
    () => apiClient.get(`${endpoint}/registration/resend-confirmation-email/${token}`),
    {
      enabled: false,
      retry: false,
    },
  );

export const useDeleteUserByToken = () =>
  useMutation<any, ApiError, { token: string }>(
    'deleteUserByToken',
    ({ token }: { token?: string }) => apiClient.delete(`${endpoint}/${token}`),
    {},
  );

export const useCompanyInfoByToken = ({ token }: { token?: string }) =>
  useQuery<
    Token,
    ApiError,
    {
      name: string;
      domain: string;
      countMembers: number;
      teamAdmin: { firstName: string; lastName: string; email: string };
    }
  >('companyInfoByToken', () => apiClient.get(`${endpoint}/company-info/${token}`), {});

export const useCompleteRegistration = ({ token }: { token: string }) =>
  useMutation<Form, ApiError, Form>(data => apiClient.post(`${endpoint}/registration-complete/${token}`, data));

export const useGoogleInfo = (options: UseMutationOptions<{ code: string }, ApiError, Record<string, any>>) =>
  useMutation<{ code: string }, ApiError, Record<string, any>>(
    data => apiClient.post(`${endpoint}/google-info`, data),
    options,
  );
