import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useEffectOnce } from 'react-use';
import cx from 'classnames';

import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InfoIcon from '@material-ui/icons/Info';

import { DaysLeft } from '@app/payments/components/Trial/DaysLeft';
import { UserEntity } from '@app/users/interfaces';
import { useMyTeamAdmin } from '@app/users/hooks/crud';
import { PAYMENT_PLANS, PAYMENT_USER_PLAN_STATUSES } from '@app/payments/constants';
import { ROLES } from '@app/users/constants/roles';
import { useModal } from '@app/ui/modal';
import { UpgradePlanConfirmation } from '@app/payments/components/UpdatePlanConfirmation/UpgradePlanConfirmation';
import { DowngradePlanConfirmation } from '@app/payments/components/UpdatePlanConfirmation/DowngradePlanConfirmation';
import { useUpcomingPaymentInfo } from '@app/payments/hooks/crud';
import { useDate } from '@app/app/hooks/useDate';
import { numberWithCommas } from '@app/payments/utils/formatter';
import { useBillingPortal } from '@app/payments/hooks/useBillingPortal';
import { Loader } from '@app/ui/loader';
import { usePlan } from '@app/payments/hooks/usePlan';

import { DetailedPriceModal } from './DetailedPriceModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

interface Props {
  user: UserEntity;
}

export const Billing = ({
  user: {
    trial,
    paymentPlan: { nextPayment, plan, status },
    role,
    teamId,
  },
}: Props) => {
  const { isProTrial, isPro, isTrialUser, isBasic, isTrial } = usePlan();
  const classes = useStyles();
  const { t } = useTranslation('common');
  const theme = useTheme();
  const {
    isOpen: isOpenUpgradePlanConfirmation,
    show: showUpgradePlanConfirmation,
    hide: hideUpgradePlanConfirmation,
  } = useModal();
  const {
    isOpen: isOpenDowngradePlanConfirmation,
    show: showDowngradePlanConfirmation,
    hide: hideDowngradePlanConfirmation,
  } = useModal();
  const { isOpen: isOpenDetailedPrice, show: showDetailedPrice, hide: hideDetailedPrice } = useModal();
  const respIsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { data: myTeamAdmin } = useMyTeamAdmin();
  const { mutateAsync: fetchPaymentInfo, data: { price, teamMembersCount } = { teamMembersCount: 1, price: 0 } } =
    useUpcomingPaymentInfo();
  const { openBillingPortal, isLoading } = useBillingPortal();

  const isAdmin = role === ROLES.ADMIN || !teamId;
  const showBillingInfo = isAdmin && isPro && !isTrial;
  const showDowngradeButton = isAdmin && isPro && !isTrial;
  const showUpgradeButton = isBasic || isTrial;
  const showPendingStatus = !isTrial && status === PAYMENT_USER_PLAN_STATUSES.UPDATE_PENDING;
  const showBillingPortalButton = role === ROLES.ADMIN && teamId && isPro && !isProTrial;
  const showRightBillingOwnerBlock = myTeamAdmin && isPro && !isProTrial && (!teamId || role !== ROLES.ADMIN);
  const { formatDate } = useDate();

  useEffectOnce(() => {
    fetchPaymentInfo();
  });

  const description = useMemo(() => {
    if (isProTrial) {
      return t('user.profile.tabs.billing.descriptionProTrial');
    }
    if (isPro) {
      return t('user.profile.tabs.billing.descriptionPro');
    }

    if (isBasic) {
      return t('user.profile.tabs.billing.descriptionBasic');
    }

    return '';
  }, [isBasic, isPro, isProTrial]);

  const onUpdatePlan = () => {
    if (isBasic || isTrial) {
      showUpgradePlanConfirmation();
    } else {
      showDowngradePlanConfirmation();
    }
  };

  const onClickManageBilling = () => {
    openBillingPortal();
  };

  return (
    <div>
      <Typography gutterBottom variant="h2">
        {t('user.profile.tabs.billing.subtitle')}
      </Typography>
      {isLoading && <Loader showOverlay />}
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          className={cx(classes.infoContainer, { [classes.infoContainerWithBilling]: showRightBillingOwnerBlock })}
        >
          <Grid container alignItems="center">
            <Grid item xs={12} sm={5} md={3} className={classes.header}>
              <Typography variant="h4">{t('user.profile.tabs.billing.currentPlan')}</Typography>
            </Grid>
            <Grid item xs={12} md={showPendingStatus ? 5 : 3} className={classes.header}>
              {showPendingStatus ? (
                <div className={classes.updateSoonContainer}>{t('user.profile.tabs.billing.updateSoon')}</div>
              ) : (
                <DaysLeft />
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={6} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={3} className={classes.item}>
                <div>
                  <Typography color="textSecondary">{t('user.profile.tabs.billing.planName')}</Typography>
                  <Typography variant="h4">{t(`payments.plans.${plan}`)}</Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} className={classes.item}>
                <div>
                  <Typography color="textSecondary">{t('user.profile.tabs.billing.totalMembers')}</Typography>
                  <Typography variant="h4">{teamMembersCount ?? 1}</Typography>
                </div>
              </Grid>
              {showBillingInfo && (
                <Grid item xs={12} sm={4} md={3} className={classes.item}>
                  <div>
                    <Typography color="textSecondary">
                      {t('user.profile.tabs.billing.totalPrice')}
                      <InfoIcon className={classes.totalPriceIcon} color="primary" onClick={showDetailedPrice} />
                    </Typography>
                    <Typography variant="h4">
                      {isPro && !trial && price ? `$${numberWithCommas(price / 100)}` : t('general.notAvailable')}
                    </Typography>
                  </div>
                </Grid>
              )}
              {showBillingInfo && (
                <Grid item xs={12} sm={4} md={3} className={classes.item}>
                  <div>
                    <Typography color="textSecondary"> {t('user.profile.tabs.billing.nextBillingDate')}</Typography>
                    <Typography variant="h4">
                      {isPro && !isProTrial && nextPayment ? formatDate(nextPayment) : t('general.notAvailable')}
                    </Typography>
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} className={classes.description}>
              <Typography color="textSecondary">
                {description}
                <Link href="https://bettermeeting.io" target="_blank" color="textSecondary" underline="always">
                  {t('user.profile.tabs.billing.learnMore')}
                </Link>
              </Typography>
            </Grid>
            {!isTrialUser && (
              <Grid item xs={12} className={classes[isBasic || isTrial ? 'upgradeButton' : 'downgradeButton']}>
                {showDowngradeButton && (
                  <Button color="primary" fullWidth={respIsMobile} onClick={onUpdatePlan}>
                    {t('general.buttons.downgradeTo', { to: t(`payments.plans.${PAYMENT_PLANS.BASIC}`) })}
                  </Button>
                )}
                {showUpgradeButton && (
                  <Button color="primary" variant="contained" fullWidth={respIsMobile} onClick={onUpdatePlan}>
                    {t('general.buttons.upgradeTo', { to: t(`payments.plans.${PAYMENT_PLANS.PRO}`) })}
                  </Button>
                )}
                {showBillingPortalButton && (
                  <Button color="primary" variant="outlined" onClick={onClickManageBilling}>
                    {t('user.profile.tabs.billing.manageBilling')}
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        {showRightBillingOwnerBlock && (
          <Grid item xs={12} md={6} className={classes.billingOwnerBlockContainer}>
            <Typography variant="h4" gutterBottom>
              {t('user.profile.tabs.billing.billingOwner')}
            </Typography>
            <Box display="flex" className={classes.billingOwnerContainer}>
              <div className={classes.billingOwnerName}>{`${myTeamAdmin?.firstName} ${myTeamAdmin?.lastName}`}</div>
              <Typography color="textSecondary" className={classes.billingOwnerEmail}>
                {myTeamAdmin?.email}
              </Typography>
            </Box>
            {isAdmin && (
              <Button color="primary" onClick={onClickManageBilling}>
                {t('user.profile.tabs.billing.manageBilling')}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      <UpgradePlanConfirmation isOpen={isOpenUpgradePlanConfirmation} onClose={hideUpgradePlanConfirmation} />
      <DowngradePlanConfirmation isOpen={isOpenDowngradePlanConfirmation} onClose={hideDowngradePlanConfirmation} />
      {showBillingInfo && <DetailedPriceModal isOpen={isOpenDetailedPrice} onClose={hideDetailedPrice} />}
    </div>
  );
};
