import { CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';
import { AxiosRequestConfig } from 'axios';

import { apiClient } from '@app/query';

import { Timezone } from '../interfaces';

const endpoint = '/timezones';

export const getTimezones = <T = Timezone[]>(
  params: CreateQueryParams = {},
  options: AxiosRequestConfig = {},
): Promise<T> => apiClient.get(endpoint, { ...options, params: RequestQueryBuilder.create(params).queryObject });

export const getTimezone = <T = Timezone>(
  id: number,
  params: CreateQueryParams = {},
  options: AxiosRequestConfig = {},
): Promise<T> =>
  apiClient.get(`${endpoint}/${id}`, { ...options, params: RequestQueryBuilder.create(params).queryObject });
