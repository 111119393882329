import { createStyles } from '@material-ui/core/styles';

import { colorWhite } from '@app/app/configs/variables';

export const styles = () =>
  createStyles({
    paper: {
      backgroundColor: colorWhite,
      padding: '24px 50px 27px 32px',
    },
  });
