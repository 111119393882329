import React from 'react';
import ContentLoader from 'react-content-loader';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  showOverlay?: boolean;
}

export const Loader = ({ showOverlay = false }: Props) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.loaderContainer, showOverlay ? classes.loaderContainerOverlay : '')}>
      <ContentLoader
        viewBox="0 0 400 160"
        height={160}
        width={400}
        speed={2}
        className={classes.loader}
        backgroundColor="transparent"
      >
        <circle cx="150" cy="86" r="8" />
        <circle cx="194" cy="86" r="8" />
        <circle cx="238" cy="86" r="8" />
      </ContentLoader>
    </div>
  );
};
