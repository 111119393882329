import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Button, Box } from '@material-ui/core';

import { Search } from '@app/interview/components/List/Search';
import { Text } from '@app/ui/forms';
import { STATUSES } from '@app/interview/constants';
import { DateRangePicker } from '@app/ui/dateRangePicker';
import { SearchParams } from '@app/interview/hooks/useSearch';

interface Props {
  readonly onSearch: (values: SearchParams) => void;
  readonly noNew: boolean;
}

export const Filter = ({ onSearch, noNew }: Props) => {
  const theme = useTheme();
  const respUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const { t } = useTranslation('common');
  const { push } = useHistory();
  const statusesOptions = [
    { id: '0', name: t('interviews.list.statuses.all') },
    { id: STATUSES.SCHEDULED, name: t('interviews.list.statuses.scheduled') },
    { id: STATUSES.FINISHED, name: t('interviews.list.statuses.finished') },
    { id: STATUSES.CANCELLED, name: t('interviews.list.statuses.canceled') },
  ];

  const onSubmit = (filter: SearchParams['filter']) => {
    onSearch({ filter });
  };

  return (
    <Formik initialValues={{ status: '0', startDate: null, endDate: null, participants: null }} onSubmit={onSubmit}>
      {({ handleSubmit, setFieldValue, values }) => (
        <Grid container spacing={2} alignItems="flex-end" wrap={respUpMd ? 'nowrap' : 'wrap'}>
          <Grid item xs={12} sm={6} md={3}>
            <Search margin="none" />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DateRangePicker
              defaultStartDate={values.startDate}
              defaultEndDate={values.endDate}
              onChange={({ startDate, endDate }) => {
                setFieldValue('startDate', startDate);
                setFieldValue('endDate', endDate);

                handleSubmit();
              }}
              minDate={null}
              maxDate={null}
              label={t('interviews.list.filter.date')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Text
              name="status"
              select
              options={statusesOptions}
              onChange={() => handleSubmit()}
              label={t('interviews.list.filter.status')}
              margin="none"
            />
          </Grid>

          <Grid item xs={12} sm={6} md>
            <Box textAlign="right">
              <Button
                color="primary"
                variant="contained"
                onClick={() => push('interviews/new')}
                fullWidth={!respUpMd}
                disabled={noNew}
              >
                {`+ ${t('interviews.list.buttonNew')}`}
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};
