// Variables - Styles
const fontFamily = '"Rubik", Arial, sans-serif';
const boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.25)';
const transition = '0.2s cubic-bezier(0.685, 0.0473, 0.346, 1)';

// Sizes
const borderRadius = 6;
const borderRadiusSmall = 4;
const headerHeight = 60;

const dialogHorizontal = 36;
const dialogVertical = 30;
const dialogHorizontalMobile = 30;
const dialogVerticalMobile = 24;

const sliderHeight = 4;

// System Color
const colorPrimary = '#4E79E9';
const colorPrimaryDark = '#456CD0';
const colorPrimaryLight = '#E3EBFF';
const colorSecondary = '#19BF6F';
const colorSecondaryDark = '#19AB4F';
const colorSecondaryLight = '#CCFFE7';
const colorDisabled = '#EFEEED';

const colorSuccess = '#56BC8B';
const colorSuccessLight = '#DDF2E8';
const colorDanger = '#E9604E';
const colorDangerLight = '#FBDDDD';
const colorWarning = '#F2994A';
const colorWarningLight = '#FFEBD9';
const colorWhite = '#FFFFFF';
const colorGrey = '#8F91A0';
const colorGreyLight = '#E8E9F1';
const colorGreyLighter = '#F3F6F8';
const colorGrey10 = '#FCFCFC';
const colorGreyDark = '#828282';
const colorBlack = '#2F3350';

// Layout Background Color
const bgBody = '#F9F9F9';
const bgPaper = '#F0F3F6';
const bgGreyLight = '#F3F3F3';

// Avatar Colors
const avatarColors = ['#6FAFDD', '#FFBB6B', '#AA8CFF', '#F7DE59'];

// Text Color
const textPrimary = '#2F3350';
const textSecondary = '#696B80';
const textGrey = '#BDBDBD';
const textDisabled = '#CDCCCA';

// Form
const inputBackground = '#FFFFFF';
const inputBackgroundDisabled = '#F0F0F0';
const inputLabelColor = '#696B80';
const inputLabelDisabled = '#CDCCCA';
const inputVerticalPadding = 8;
const inputHorizontalPadding = 12;

// Border Color
const borderPrimaryColor = '#DDDDE0';
const borderSecondaryColor = '#E7EBEE';

export {
  fontFamily,
  boxShadow,
  transition,
  // Sizes
  borderRadiusSmall,
  borderRadius,
  headerHeight,
  dialogHorizontal,
  dialogHorizontalMobile,
  dialogVertical,
  dialogVerticalMobile,
  sliderHeight,
  // System Color
  colorPrimary,
  colorPrimaryDark,
  colorPrimaryLight,
  colorSecondary,
  colorSecondaryDark,
  colorSecondaryLight,
  colorDisabled,
  colorSuccess,
  colorSuccessLight,
  colorDanger,
  colorDangerLight,
  colorWarning,
  colorWarningLight,
  colorWhite,
  colorGrey,
  colorGreyLighter,
  colorGreyLight,
  colorGrey10,
  colorGreyDark,
  colorBlack,
  // Layout Background Color
  bgBody,
  bgPaper,
  bgGreyLight,
  // Text Color
  textPrimary,
  textSecondary,
  textGrey,
  textDisabled,
  // Border Color
  borderPrimaryColor,
  borderSecondaryColor,
  // Avatar Colors
  avatarColors,
  // Form
  inputBackground,
  inputBackgroundDisabled,
  inputLabelColor,
  inputLabelDisabled,
  inputVerticalPadding,
  inputHorizontalPadding,
};
