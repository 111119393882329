import { useCallback, useState } from 'react';
import djs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import { useLocalAuth } from '@app/auth/hooks/useAuth';
import { getBrowserTimezone, toTimezone } from '@app/app/utils/date';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from '@app/app/constants/global';
import { useTimezone } from '@app/timezones/hooks/crud';
import { Timezone } from '@app/timezones/interfaces';

djs.extend(relativeTime);
djs.extend(utc);

export interface Options {
  userTimezone?: boolean;
}

export const useDate = () => {
  const { user } = useLocalAuth();
  const [timezone, setTimezone] = useState<Timezone | undefined>();

  const { availability: { meta = [] } = {} } = user || {};

  useTimezone({
    id: meta[0]?.timezoneId?.id || meta[0]?.timezoneId,
    queryBuilderOptions: {
      enabled: !!meta[0]?.timezoneId,
      onSuccess: data => {
        setTimezone(data);
      },
    },
  });

  const formatCustom = useCallback(
    (value, format: string = DATE_FORMAT, { userTimezone = true }: Options = {}): string => {
      if (!value) return '';

      if (userTimezone) {
        return djs(toTimezone(value, timezone?.key || getBrowserTimezone())).format(format);
      }

      return djs.utc(value).format(format);
    },
    [timezone],
  );

  const formatDate = useCallback(
    (value, options: Options = {}): string => formatCustom(value, DATE_FORMAT, options),
    [formatCustom],
  );

  const formatTime = useCallback(
    (value, options: Options = {}): string => formatCustom(value, TIME_FORMAT, options),
    [formatCustom],
  );

  const formatDateTime = useCallback(
    (value, options: Options = {}): string => formatCustom(value, DATE_TIME_FORMAT, options),
    [formatCustom],
  );

  const fromNow = useCallback(
    (value, { withoutSuffix = false }: { withoutSuffix?: boolean } = {}): string =>
      djs(timezone ? toTimezone(value, timezone?.key || getBrowserTimezone()) : value).fromNow(withoutSuffix),
    [timezone],
  );

  return {
    djs,
    fromNow,
    formatCustom,
    formatDate,
    formatTime,
    formatDateTime,
    timezone,
  };
};
