import { useCallback } from 'react';
import { useQueryClient, QueryKey } from 'react-query';
import { produce } from 'immer';

type Item = { id: string | number };

export function useQueryData(queryKey: QueryKey) {
  const queryClient = useQueryClient();

  const onUpdate = useCallback(
    (id, callback) => {
      queryClient.setQueriesData(
        queryKey,
        produce(draft => {
          if (draft?.id === id) {
            callback(draft);
          }

          if (draft.length) {
            const founded = draft?.find(({ id: dataId }: Item) => dataId === id);
            if (founded) {
              callback(founded);
            }
          }
        }),
      );
    },
    [queryKey],
  );

  const onRemove = useCallback(
    ({ id }) => {
      queryClient.setQueriesData(
        queryKey,
        produce(draft => {
          draft.pages?.find(({ data }: { data: Item[] }) => {
            const index = data?.findIndex(({ id: dataId }: Item) => dataId === id);
            if (index !== -1) {
              data.splice(index, 1);
              return true;
            }
            return false;
          });
        }),
      );
    },
    [queryKey],
  );

  return { onUpdate, onRemove };
}
