import React from 'react';
import ReactDOM from 'react-dom';

import '@app/language/configs/i18n';

import { App } from './pages/_App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
