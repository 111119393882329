import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common from '../locales/en/common.json';

export const resources = {
  en: {
    common,
  },
} as const;

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['b', 'br'],
  },
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});
