import { useMutation, UseMutationOptions, useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';

import { ApiError } from '@app/query';

import { DetailedPaymentResponse, PaymentInfo } from '../types';
import { getPlanInfo, getUpcomingPaymentInfo, deleteCurrentSubscription, getDetailedUpcomingPaymentInfo } from '../api';
import { PAYMENT_PLANS } from '../constants';

export const usePlanInfo = (options?: UseMutationOptions<PaymentInfo, ApiError, { plan: PAYMENT_PLANS }>) =>
  useMutation<PaymentInfo, ApiError, { plan: PAYMENT_PLANS }>(
    (data: { plan: PAYMENT_PLANS }) => getPlanInfo(data),
    options,
  );

export const useUpcomingPaymentInfo = (options?: UseMutationOptions<PaymentInfo, ApiError>) =>
  useMutation<PaymentInfo, ApiError>(() => getUpcomingPaymentInfo(), options);

export const useDetailedUpcomingPaymentInfo = <T = DetailedPaymentResponse>({
  options = {},
}: {
  options?: AxiosRequestConfig;
} = {}) =>
  useQuery<T, ApiError>('DetailedUpcomingPaymentInfo', () => getDetailedUpcomingPaymentInfo<T>(), {
    cacheTime: 1000,
    ...options,
  });

export const useDeleteCurrentSubscription = (options?: UseMutationOptions<PaymentInfo, ApiError>) =>
  useMutation<PaymentInfo, ApiError>(() => deleteCurrentSubscription(), options);
