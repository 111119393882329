import { createStyles, Theme } from '@material-ui/core/styles';

import { borderPrimaryColor, bgGreyLight, colorGrey10 } from '@app/app/configs/variables';

import imgAuthBottomLeft from '@app/app/images/img-auth-bottom-left.svg';
import imgAuthTopRight from '@app/app/images/img-auth-top-right.svg';

const widthMedium = 790;
const widthLarge = 980;

export const styles = (theme: Theme) =>
  createStyles({
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
    },
    logoBlock: {
      display: 'block',
      lineHeight: 1,
      textDecoration: 'none',
      cursor: 'pointer',

      '&:hover, &:focus': {
        opacity: 0.9,
        textDecoration: 'none',
      },
    },
    siteLogo: {
      width: 123,
      height: 18,
      marginTop: 3,

      [theme.breakpoints.up('md')]: {
        width: 157,
        height: 23,
      },
    },

    // Guest Layout
    guestRootContainer: {
      backgroundColor: bgGreyLight,
    },
    guestContainer: {
      padding: '40px 16px',

      [theme.breakpoints.up('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: 60,
        paddingBottom: 60,
      },
      '& $siteLogo': {
        width: 205,
        height: 30,
        margin: 0,
      },
    },

    mediumSize: {
      maxWidth: widthMedium + 40,

      '&$guestDecorate': {
        maxWidth: widthMedium + 60,

        [theme.breakpoints.up('md')]: {
          maxWidth: widthMedium + 120,
        },
      },
      '& $guestContent': {
        [theme.breakpoints.up('md')]: {
          padding: '45px 50px',
        },
      },
    },
    largeSize: {
      maxWidth: widthLarge + 40,

      '&$guestDecorate': {
        maxWidth: widthLarge + 60,

        [theme.breakpoints.up('md')]: {
          maxWidth: widthLarge + 120,
        },
      },
      '& $guestContent': {
        [theme.breakpoints.up('md')]: {
          padding: '50px 54px',
        },
      },
    },

    guestHeader: {
      textAlign: 'center',
      marginBottom: 22,
    },
    guestContentBlock: {},
    guestContent: {
      backgroundColor: colorGrey10,
      border: `1px solid ${borderPrimaryColor}`,
      padding: '24px 16px',
      position: 'relative',
      minHeight: 360,
      zIndex: 1,

      [theme.breakpoints.up('sm')]: {
        padding: 24,
      },
      [theme.breakpoints.up('md')]: {
        padding: '30px 34px',
      },
    },
    guestDecorate: {
      paddingLeft: 30,
      paddingRight: 30,

      [theme.breakpoints.up('md')]: {
        paddingLeft: 60,
        paddingRight: 60,
      },
      '& $guestContentBlock': {
        position: 'relative',

        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        },
        '&:before': {
          backgroundImage: `url(${imgAuthTopRight})`,
          width: 211,
          height: 171,
          top: -30,
          right: -25,

          [theme.breakpoints.up('md')]: {
            top: -50,
            right: -45,
          },
        },
        '&:after': {
          backgroundImage: `url(${imgAuthBottomLeft})`,
          width: 185,
          height: 210,
          bottom: '20%',
          left: -28,

          [theme.breakpoints.up('md')]: {
            bottom: '25%',
            left: -58,
          },
        },
      },
      '& $guestHeader': {
        textAlign: 'left',
        marginBottom: 40,
      },
    },
  });
