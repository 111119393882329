export enum STATUSES {
  ACTIVE = 'active',
  WAITING = 'waiting',
  EXPIRED = 'expired',
  DISCONNECTED = 'disconnected',
}

export enum ROLES {
  ADMIN = 'admin',
  CANDIDATE = 'candidate',
  INTERVIEWER = 'interviewer',
  RECRUITER = 'recruiter',
}

export enum RESOURCES {
  GOOGLE = 'google',
  CALENDLY = 'calendly',
  MANUAL = 'manual',
}

export const AVAILABILITY_LIST_KEY = 'availabilityList';

export const AVAILABILITY_REQUEST_ITEM_KEY = 'availabilityRequestItem';

export const AVAILABILITY_REQUEST_CHECK_ITEM_KEY = 'availabilityRequestCheckItem';

export const AVAILABILITY_INVITE_INFO_KEY = 'availabilityInviteInfo';

export const EVENT_SLOT_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss[Z]';
