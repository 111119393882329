import { createStyles } from '@material-ui/core/styles';

import { colorPrimary, colorWhite, textGrey, textPrimary, borderRadius } from '@app/app/configs/variables';

export default () =>
  createStyles({
    container: {
      '& .DateRangePicker': {
        borderRadius,
      },
      '& .DateRangePickerInput': {
        height: 37,
        borderRadius,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
      '& .DateInput': {
        height: 25,

        '&:first-child': {
          '&, & .DateInput_input': {
            borderRadius: `${borderRadius}px 0 0 ${borderRadius}px`,
          },
        },
      },
      '& .DateInput_input': {
        fontSize: 14,
        height: 35,
        paddingBottom: 5,
      },
      '& .DateInput_input__focused': {
        borderColor: colorPrimary,
      },
      '& .CalendarDay__default': {
        color: textPrimary,
      },
      '& .CalendarDay__selected': {
        '&, &:active, &:hover': {
          backgroundColor: colorPrimary,
          borderColor: colorPrimary,
          color: colorWhite,
        },
      },
      '& .CalendarDay__blocked_out_of_range': {
        '&, &:active, &:hover': {
          color: textGrey,
        },
      },
      '& .CalendarDay__selected_span': {
        backgroundColor: `${colorPrimary}4D`, // opacity 0.3
        borderColor: `${colorPrimary}4D`, // opacity 0.3
      },
      '& .CalendarDay__hovered_span': {
        '&, &:hover': {
          backgroundColor: `${colorPrimary}4D`, // opacity 0.3
          borderColor: `${colorPrimary}4D`, // opacity 0.3
        },
      },
      '& .DateRangePickerInput_arrow': {
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 15,
      },
      '& .DateRangePickerInput_calendarIcon': {
        height: 30,
        paddingBottom: 0,
        paddingTop: 10,
      },
      '& .DayPickerNavigation__verticalDefault': {
        height: 40,
      },
      '& .DayPickerNavigation_button__verticalDefault': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
      },
      '& .DayPickerNavigation_svg__vertical': {
        height: 24,
        width: 24,
      },
    },
  });
