import { memo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { InterviewEntity } from '@app/interview/types/Interview';
import { useInterviewTime } from '@app/interview/hooks/useInterviewTime';
import { useDate } from '@app/app/hooks/useDate';
import { INVITE_LINK_TYPE } from '@app/interview/constants';

import { getUserTimezoneName, getUserTimezoneOffset } from '@app/interview/utils';
import { AvailabilityDescription } from './AvailabilityDescription';
import { GroupDescription } from './GroupDescription';

interface InviteSuccessMessageProps {
  invite: InterviewEntity;
  token: string;
  linkType: string;
}

export const InviteSuccessMessage = memo(({ invite, token, linkType }: InviteSuccessMessageProps) => {
  const { t } = useTranslation('common');
  const { toTime } = useInterviewTime();
  const { formatDate, timezone, djs } = useDate();

  const userOffset = getUserTimezoneOffset(timezone);
  const userTimezoneName = getUserTimezoneName(userOffset, timezone);

  const timeRange = `${toTime(invite.time.from, userOffset)} - ${toTime(invite.time.to, userOffset)}`;

  return (
    <Box textAlign="center" mt={3}>
      <Typography variant="h1" gutterBottom>
        {t('interviews.form.create.confirmedInterviewMessage.title')}
      </Typography>
      <Typography variant="h4" gutterBottom>
        {linkType === INVITE_LINK_TYPE.AVAILABILITY && <AvailabilityDescription token={token} />}
        {linkType === INVITE_LINK_TYPE.GROUP_LINK && <GroupDescription token={token} />}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {`${timeRange} ${formatDate(
          djs.utc(invite.date).add(invite.time.from, 'hours').format('MM/DD/YYYY HH:mm [Z]'),
        )} ${userTimezoneName}`}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {t('interviews.form.create.confirmedInterviewMessage.message')}
      </Typography>
    </Box>
  );
});
