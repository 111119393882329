import createStyles from '@material-ui/core/styles/createStyles';

import { bgBody, bgPaper, borderPrimaryColor, colorGrey } from '@app/app/configs/variables';

export default () =>
  createStyles({
    btnSignUp: {
      textAlign: 'right',
    },
    paper: {
      backgroundColor: bgPaper,
      padding: 20,
      marginBottom: 24,
    },
    cantConnectWrapper: {
      marginTop: 7,
      textAlign: 'right',
    },
    activeStep: {
      fontSize: 32,
      fontWeight: 500,
    },
    totalSteps: {
      fontSize: 30,
      fontWeight: 400,
    },
    connectionIcon: {
      fontSize: 30,
      marginRight: 10,
    },
    connectionSuccessMessage: {
      fontWeight: 500,
    },
    successImageContainer: {
      marginTop: 15,
      textAlign: 'right',
    },
    disconnectButton: {
      color: colorGrey,
      marginTop: 5,
    },
    connectGoogleTitleInfoIcon: {
      width: 18,
      height: 18,
      marginLeft: 8,
    },
    accountPaper: {
      backgroundColor: bgPaper,
      marginBottom: 24,
      padding: '20px 0',
    },
    calendarSelect: {
      margin: '10px 0',
      padding: '10px 20px',
    },
    calendarItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: bgBody,
      padding: '20px 20px 10px',
      borderBottom: `1px solid ${borderPrimaryColor}`,
    },
  });
