import { createStyles, Theme } from '@material-ui/core/styles';

import { borderPrimaryColor } from '@app/app/configs/variables';

export const styles = (theme: Theme) =>
  createStyles({
    availabilityField: {
      '& .input-text-label': {
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
      },
    },
    availabilityRowFirst: {
      '& $availabilityField .input-text-label': {
        display: 'flex',
      },
      '& $availabilityDeleteButton': {
        top: 30,
      },
    },
    availabilityRow: {
      position: 'relative',
      paddingRight: 40,
      marginBottom: 8,

      [theme.breakpoints.down('xs')]: {
        '&:not(:first-child)': {
          borderTop: `1px solid ${borderPrimaryColor}`,
          marginTop: 20,
          paddingTop: 12,
        },
      },
    },
    availabilityDeleteButton: {
      position: 'absolute',
      top: 33,
      right: 0,

      [theme.breakpoints.up('sm')]: {
        top: 10,
      },
    },
  });
