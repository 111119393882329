import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';

import { useModal, Modal } from '@app/ui/modal';

import { UpgradePlanConfirmation } from '../UpdatePlanConfirmation/UpgradePlanConfirmation';

interface Props {
  onCancel: () => void;
}

export const LimitModal = ({ onCancel }: Props) => {
  const { t } = useTranslation('common');
  const { isOpen, hide, show } = useModal();

  return (
    <>
      <Modal
        isOpen
        fullWidth
        title={t('availabilities.limit.modalTitle')}
        description={t('availabilities.limit.modalDescription')}
        hide={onCancel}
        maxWidth="md"
        scroll="body"
        actions={
          <>
            <Button color="primary" onClick={onCancel}>
              {t('general.buttons.cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={() => show()}>
              {t('general.buttons.upgrade')}
            </Button>
          </>
        }
      />
      <UpgradePlanConfirmation onClose={hide} isOpen={isOpen} />
    </>
  );
};
