import produce, { Draft } from 'immer';

import { AUTH_ACTIONS } from '../constants';
import { AuthState, AuthAction } from '../interfaces';

export const initialState: AuthState = {
  user: undefined,
  token: undefined,
};

export default function reducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case AUTH_ACTIONS.SET_USER:
      return produce(state, (draft: Draft<AuthState>) => {
        draft.user = action.payload;
      });
    case AUTH_ACTIONS.SET_TOKEN:
      return produce(state, (draft: Draft<AuthState>) => {
        draft.token = action.payload;
      });
    case AUTH_ACTIONS.CLEAR:
      return initialState;
    default:
      throw new Error();
  }
}
