import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { SvgIcon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAuthUser } from '@app/auth';
import { AvailabilityEntity } from '@app/availabilities/types/Availability';
import { Chip } from '@app/ui/chip';

import { styles } from '../styles';

const useStyles = makeStyles(styles);

interface Props {
  item: AvailabilityEntity & { isDisabled?: boolean };
  removeMember: (email: string) => void;
}

export const Participant = ({ item: { status, name, email, isDisabled = false }, removeMember }: Props) => {
  const { user } = useAuthUser();
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <div className={classes.participantContainer}>
      <div className={classes.participantInfoContainer}>
        <div>{`${name} - ${email}`}</div>
        {status && (
          <div className={classes.participantStatus}>
            <Chip iconOnly round status={status} />
            {email === user?.availability?.email && (
              <span className={classes.participantOwner}>{t('availabilities.fields.role.options.owner')}</span>
            )}
          </div>
        )}
      </div>
      <div>
        {!isDisabled && (
          <SvgIcon
            component={ClearIcon}
            color="primary"
            fontSize="small"
            className={classes.participantClearIcon}
            onClick={() => removeMember(email)}
          />
        )}
      </div>
    </div>
  );
};
