import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiBreadcrumbs, { BreadcrumbsProps } from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as longRightIcon } from '@app/app/images/icons/icon-long-right.svg';

import { BreadcrumbsOption } from '../types';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export interface Props extends BreadcrumbsProps {
  options: BreadcrumbsOption[];
}

export function Breadcrumbs({ options }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const respUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <MuiBreadcrumbs
      classes={{
        root: classes.breadcrumbsBlock,
        ol: classes.breadcrumbs,
        li: classes.breadcrumbsItem,
        separator: classes.breadcrumbsSeparator,
      }}
      maxItems={respUpSm ? 6 : 3}
      separator={<SvgIcon component={longRightIcon} viewBox="0 0 15.5 7.36" fontSize="inherit" />}
      component="div"
      aria-label="breadcrumb"
    >
      {options.map(({ title, to }) =>
        to ? (
          <Link to={to} key={title} component={RouterLink} color="inherit">
            {title}
          </Link>
        ) : (
          <div key={title} className={classes.breadcrumbsCurrent}>
            {title}
          </div>
        ),
      )}
    </MuiBreadcrumbs>
  );
}
