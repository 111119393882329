import { createStyles, Theme } from '@material-ui/core/styles';

import { textSecondary, colorPrimary } from '@app/app/configs/variables';

export const styles = (theme: Theme) =>
  createStyles({
    breadcrumbsBlock: {
      marginBottom: 16,

      [theme.breakpoints.up('md')]: {
        marginTop: -10,
      },
    },
    breadcrumbs: {
      padding: 0,
      margin: 0,
      flexWrap: 'nowrap',
    },
    breadcrumbsItem: {
      fontSize: 14,
      color: textSecondary,
      lineHeight: 1.2,
      padding: 0,
      marginBottom: 0,

      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
    },
    breadcrumbsSeparator: {
      color: colorPrimary,
      fontSize: 18,
      padding: 0,
      marginBottom: 0,

      [theme.breakpoints.up('md')]: {
        marginLeft: 14,
        marginRight: 14,
      },
    },
    breadcrumbsCurrent: {
      fontWeight: 500,
    },
  });
