import React from 'react';
import cx from 'classnames';

import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export interface Props extends AlertProps {
  hideIcon?: boolean;
  className?: string;
  inner?: boolean;
}

export function Alert({ children, severity, onClose, className, hideIcon = false, inner = false }: Props) {
  return (
    <MuiAlert
      classes={{
        root: cx(className, 'main-alert', { 'alert-inner': inner }),
      }}
      severity={severity}
      onClick={onClose}
      onClose={onClose}
      variant="outlined"
      iconMapping={{
        error: <ReportProblemOutlinedIcon fontSize="inherit" />,
        success: <DoneIcon fontSize="inherit" />,
        warning: <NotInterestedIcon fontSize="inherit" />,
        info: <InfoOutlinedIcon fontSize="inherit" />,
      }}
      icon={hideIcon ? false : null}
    >
      <div>{children}</div>
    </MuiAlert>
  );
}
