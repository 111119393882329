import React, { useEffect } from 'react';
import { useHistory, Route, RouteProps } from 'react-router-dom';
import { useAuthUser } from '@app/auth/hooks/useAuthUser';

const defaultRedirect = '/login';

export const ProtectedRoute = (props: RouteProps) => {
  const { isLoading, isAuthorized } = useAuthUser();
  const history = useHistory();

  useEffect(() => {
    if (!isAuthorized && !isLoading) {
      history.push(defaultRedirect);
    }
  }, [isAuthorized, isLoading, history]);

  if (!isAuthorized) {
    return null;
  }

  return <Route {...props} />;
};
