import React, { memo } from 'react';
import { useField } from 'formik';

import { FormControlLabel, Checkbox as MuiCheckbox, SelectProps, FormHelperText } from '@material-ui/core';

export interface Props extends SelectProps {
  name: string;
  labelPlacement?: 'top' | 'start' | 'bottom' | 'end';
  label?: React.ReactNode;
  value?: number | string;
  className?: string;
}

export const Checkbox: React.FC<Props> = memo(
  ({
    id,
    label,
    required,
    placeholder,
    autoFocus,
    disabled,
    name,
    onChange,
    labelPlacement = 'end',
    value,
    className,
  }) => {
    const [{ value: formikValue }, { touched, error }, { setValue }] = useField<boolean | string | number | undefined>(
      name,
    );

    const isError = Boolean(touched && error);

    const control = (
      <MuiCheckbox
        className="ta-checkbox"
        color="primary"
        id={id}
        required={required}
        placeholder={placeholder}
        autoFocus={autoFocus}
        disabled={disabled}
        checked={Boolean(formikValue)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
          const realValue = value === undefined ? checked : checked ? value : undefined;
          setValue(realValue);
          if (onChange) {
            onChange(event, realValue);
          }
        }}
      />
    );
    return (
      <>
        <FormControlLabel
          className={className}
          value={labelPlacement}
          label={label}
          labelPlacement={labelPlacement}
          control={control}
        />
        {isError && <FormHelperText error={isError}>{error}</FormHelperText>}
      </>
    );
  },
);
