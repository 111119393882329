import { FirstStep, SecondStep } from '@app/auth/interfaces/registration';

import { GOOGLE_REJECT_REASONS } from '.';

export const registrationInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  cantConnect: false,
  googleRejectReason: GOOGLE_REJECT_REASONS.OTHER,
  meta: [],
};

export const completeRegistrationInitialValues: Partial<FirstStep & SecondStep> = {
  teamName: '',

  timezone: '',
  workingHours: {
    from: '09',
    to: '18',
  },
  workingDays: {
    sunday: false,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
  },
};
