import { Modal } from '@app/ui/modal';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';
import { Text, yup } from '@app/ui/forms';

import { Option } from '@app/ui/forms/types';

const initialValues = { accountName: undefined };
const validationSchema = yup.object().shape({
  accountName: yup.string().required(),
});

export interface DefaultAccountModalProps {
  readonly onCancel: () => void;
  readonly onSubmit: (values: { accountName?: string | undefined }) => void | Promise<any>;
  readonly options: Option[];
}

export const DefaultAccountModal = ({ options, onCancel, onSubmit }: DefaultAccountModalProps) => {
  const { t } = useTranslation('common');

  return (
    <Formik<{ accountName?: string }>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Modal
          isOpen
          title={t('user.registration.form.firstStep.googleConnect.accountTitle')}
          description={t('user.registration.form.firstStep.googleConnect.accountModalDescription')}
          hide={onCancel}
          maxWidth="md"
          scroll="body"
          actions={
            <>
              <Button color="primary" onClick={onCancel}>
                {t('general.buttons.cancel')}
              </Button>
              <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
                {t('general.buttons.done')}
              </Button>
            </>
          }
        >
          <form>
            <Text
              options={options}
              name="accountName"
              label={t('user.registration.form.firstStep.googleConnect.fieldAccount')}
              margin="none"
              required
              select
            />
          </form>
        </Modal>
      )}
    </Formik>
  );
};
