import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Paper, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

import { AvailabilityEntity } from '@app/availabilities/types/Availability';
import { Chip } from '@app/ui/chip';

import { styles } from './styles';

const useStyles = makeStyles(styles);

interface Props {
  readonly item: AvailabilityEntity;
  readonly setItems: Dispatch<SetStateAction<AvailabilityEntity[]>>;
}

export const Item = ({ item: { id, status, name, role }, setItems }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const handleDelete = () => {
    setItems(prevState => prevState.filter(({ id: itemId }) => id !== itemId));
  };

  return (
    <Paper elevation={0} className={classes.itemContainer}>
      <div className={classes.itemStatus}>
        <Chip status={status} iconOnly round />
      </div>
      <div className={classes.itemValue}>
        <div className={classes.itemValuePosition}>{t(`availabilities.fields.role.options.${role}`)}</div>
        <div>{name}</div>
      </div>
      <div className={classes.itemActions}>
        <IconButton onClick={handleDelete} color="inherit">
          <ClearIcon fontSize="small" color="error" />
        </IconButton>
      </div>
    </Paper>
  );
};
