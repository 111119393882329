import { useMemo } from 'react';
import { useField, useFormikContext, FieldInputProps, FieldMetaProps } from 'formik';
// @ts-ignore
import update from 'immutability-helper';

export function useFieldArray<Value = any>(
  fieldName: string,
): [
  FieldInputProps<Value[]>,
  FieldMetaProps<Value[]>,
  {
    push: (value: Value) => void;
    swap: (indexA: number, indexB: number) => void;
    move: (from: number, to: number) => void;
    insert: (index: number, value: Value) => void;
    unshift: (value: Value) => void;
    remove: (index: number) => Value;
    pop: () => Value;
    replace: (index: number, value: Value) => void;
    clear: () => void;
    setField: (name: string, value: any) => void;
  },
] {
  const [field, meta] = useField<Value[]>(fieldName);
  const { setFieldValue } = useFormikContext();

  const arrayHelpers = useMemo(
    () => ({
      push: (value: Value) => {
        setFieldValue(
          field.name,
          update(field.value, {
            $push: [value],
          }),
        );
      },
      swap: (indexA: number, indexB: number) => {
        const swapA = field.value[indexA];
        const swapB = field.value[indexB];

        setFieldValue(
          field.name,
          update(field.value, {
            $splice: [
              [indexA, 1, swapB],
              [indexB, 1, swapA],
            ],
          }),
        );
      },
      move: (from: number, to: number) => {
        const toMove = field.value[from];

        setFieldValue(
          field.name,
          update(field.value, {
            $splice: [
              [from, 1],
              [to, 0, toMove],
            ],
          }),
        );
      },
      insert: (index: number, value: Value) => {
        setFieldValue(
          field.name,
          update(field.value, {
            $splice: [[index, 0, value]],
          }),
        );
      },
      unshift: (value: Value) => {
        setFieldValue(
          field.name,
          update(field.value, {
            $unshift: [value],
          }),
        );
      },
      remove: (index: number) => {
        const removedItem = field.value[index];

        setFieldValue(
          field.name,
          update(field.value, {
            $splice: [[index, 1]],
          }),
        );
        return removedItem;
      },
      pop: () => {
        const lastIndex = field.value.length - 1;
        const poppedItem = field.value[lastIndex];

        setFieldValue(
          field.name,
          update(field.value, {
            $splice: [[lastIndex, 1]],
          }),
        );
        return poppedItem;
      },
      replace: (index: number, value: Value) => {
        field.value = update(field.value, {
          $splice: [[index, 1, value]],
        });

        setFieldValue(field.name, field.value);
      },
      clear: () => {
        setFieldValue(field.name, []);
      },
      setField: (name: string, value: any) => {
        setFieldValue(`${field.name}.${name}`, value);
      },
    }),
    [field.value],
  );

  return [field, meta, arrayHelpers];
}
