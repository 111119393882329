import React from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import AutocompleteCustom, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { AutocompleteChangeReason, Value } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import SearchIcon from '@material-ui/icons/Search';

import { styles } from '../styles';

const useStyles = makeStyles(styles);

export interface Props<T, M extends boolean | undefined>
  extends Omit<AutocompleteProps<T, M, false, true>, 'renderInput' | 'onChange'> {
  readonly label?: string;
  readonly placeholder?: string;
  readonly helperText?: string;
  readonly autoCreate?: boolean;
  readonly textFieldProps?: Omit<
    StandardTextFieldProps,
    'label' | 'placeholder' | 'InputLabelProps' | 'helperText' | 'isError'
  >;
  readonly iconField?: React.ReactNode;
  readonly margin?: 'none' | 'dense' | 'normal';
  readonly autoCreateFilter?: (inputValue: string) => boolean;
  readonly onChange?: (value: any) => void;
  readonly className?: string;
  readonly classNamePaper?: string;
}

export function Autocomplete<T, M extends boolean | undefined>({
  label,
  placeholder,
  multiple,
  helperText,
  fullWidth = true,
  autoCreate = false,
  textFieldProps = {},
  iconField,
  autoCreateFilter = () => true,
  margin = 'normal',
  onChange = () => {},
  className,
  classNamePaper,
  value,
  ...props
}: Props<T, M>) {
  const classes = useStyles();

  // @ts-ignore
  const defaultValue: Value<T, M, false, true> = multiple ? [] : null;

  return (
    <AutocompleteCustom<T, M, false, true>
      classes={{
        focused: 'text-input-focused',
        paper: cx(classes.selectPaper, classNamePaper, 'native-custom-scrollbar'),
        listbox: 'select-list',
        option: 'select-option',
      }}
      ChipProps={{
        classes: {
          root: classes.autocompleteChip,
          deleteIcon: classes.autocompleteChipDeleteIcon,
        },
        deleteIcon: <ClearRoundedIcon />,
      }}
      {...props}
      fullWidth={fullWidth}
      multiple={multiple}
      freeSolo
      openOnFocus={false}
      forcePopupIcon
      popupIcon={<SearchIcon />}
      value={value || defaultValue}
      onChange={(
        event: React.ChangeEvent<{}>,
        newValue: Value<T, M, false, true>,
        reason: AutocompleteChangeReason,
      ) => {
        if (
          (autoCreate &&
            // @ts-ignore
            (!multiple || !value.find(id => id === newValue[newValue.length - 1])) &&
            // @ts-ignore
            autoCreateFilter(newValue[newValue.length - 1])) ||
          reason !== 'create-option'
        ) {
          onChange(newValue || defaultValue);
        }
      }}
      renderInput={params => (
        <TextField
          margin={margin}
          variant="standard"
          {...textFieldProps}
          {...params}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
          }}
          helperText={helperText}
        />
      )}
    />
  );
}
