import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';

import { UserEntity } from '@app/users/interfaces';
import { ROLES } from '@app/users/constants/roles';

interface Props {
  cell: Cell<UserEntity>;
}

export const StatusCell = ({
  cell: {
    row: { original },
  },
}: Props) => {
  const { t } = useTranslation('common');
  const isAdmin =
    original.role === ROLES.ADMIN &&
    original.paymentPlan &&
    original.paymentPlan.stripeMeta &&
    original.paymentPlan.stripeMeta.subscriptionId;

  return (
    <span>
      {isAdmin ? t('user.profile.tabs.members.table.billingOwner') : t('user.profile.tabs.members.table.member')}
    </span>
  );
};
