export enum PAYMENT_PLANS {
  BASIC = 'basic',
  PRO = 'pro',
  TRIAL = 'trial',
}

export enum PAYMENT_USER_PLAN_STATUSES {
  ACTIVE = 'active',
  UPDATE_PENDING = 'updatePending',
}

export enum UPCOMING_INVOICE_TYPES {
  INVOICE_ITEM = 'invoiceitem',
  SUBSCRIPTION = 'subscription',
}
