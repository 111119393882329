import { createStyles, Theme } from '@material-ui/core/styles';

import {
  textPrimary,
  textGrey,
  headerHeight,
  bgGreyLight,
  borderPrimaryColor,
  colorWhite,
} from '@app/app/configs/variables';

export const styles = (theme: Theme) =>
  createStyles({
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
    },
    stickyTop: {
      position: 'sticky',
      top: 0,
    },

    // Header
    header: {},
    headerToolbar: {
      justifyContent: 'space-between',
    },
    logoBlock: {
      display: 'block',
      lineHeight: 1,
      textDecoration: 'none',
      cursor: 'pointer',

      '&:hover, &:focus': {
        opacity: 0.9,
        textDecoration: 'none',
      },
    },
    siteLogo: {
      width: 123,
      height: 18,
      marginTop: 3,

      [theme.breakpoints.up('md')]: {
        width: 157,
        height: 23,
      },
    },
    menuButton: {
      color: textPrimary,
      fontSize: 24,
      padding: 6,
      marginLeft: -6,
      marginRight: 5,
    },
    logoutButton: {
      color: textGrey,
      fontSize: 18,
      padding: 6,
      marginLeft: 10,
      marginRight: -6,
    },
    logoutMobileButton: {
      fontWeight: 400,
      padding: 3,

      '& svg': {
        fontSize: 18,
        marginLeft: 8,
      },
    },
    headerMenu: {
      '& .nav-menu-item': {
        height: headerHeight,
      },
      [theme.breakpoints.up('sm')]: {
        marginLeft: 30,
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: 70,
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: 95,
      },
    },
    headerActionBlock: {
      display: 'flex',
      alignItems: 'center',
    },
    headerUserBlock: {
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',

      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
      '& .avatar': {
        marginLeft: 16,
      },
    },
    mobileMenuDivider: {
      marginTop: 10,
      marginBottom: 10,
    },

    // Main
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: 0,
    },
    withRightSidebar: {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
      '& $mainContent': {
        [theme.breakpoints.up('md')]: {
          marginRight: 330,
          paddingRight: 40,
        },
        [theme.breakpoints.up('lg')]: {
          marginRight: 385,
          paddingRight: 70,
        },
      },
    },
    rightSidebarWrapper: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      maxHeight: '100%',
      width: '100%',
      maxWidth: 1600,
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        maxWidth: 1280,
      },
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
      },
    },
    rightSidebar: {
      backgroundColor: colorWhite,
      padding: '20px 16px',
      borderTop: `1px solid ${borderPrimaryColor}`,
      paddingTop: 80,

      [theme.breakpoints.up('sm')]: {
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
      },
      [theme.breakpoints.up('md')]: {
        padding: '80px 24px 30px',
        width: 330,
        minWidth: 330,
        borderTop: 0,
        borderLeft: `1px solid ${borderPrimaryColor}`,
      },
      [theme.breakpoints.up('lg')]: {
        padding: '85px 38px 30px',
        borderRight: `1px solid ${borderPrimaryColor}`,
        width: 385,
        minWidth: 385,
      },
    },
    mainContent: {
      minWidth: 0,
      flexGrow: 1,
      padding: '20px 16px',
      zIndex: 1,

      [theme.breakpoints.up('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 40,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: 30,
        paddingLeft: 50,
        paddingRight: 50,
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: 40,
        paddingLeft: 90,
        paddingRight: 90,
      },
    },

    // Guest Layout
    guestRootContainer: {
      backgroundColor: bgGreyLight,
    },
    guestContainer: {
      padding: '40px 16px',

      [theme.breakpoints.up('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: 60,
        paddingBottom: 60,
      },
      '& $siteLogo': {
        width: 205,
        height: 30,
        margin: 0,
      },
    },
    guestHeader: {
      textAlign: 'left',
      padding: 16,
      [theme.breakpoints.up('sm')]: {
        padding: '30px 34px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '50px 54px',
      },
      marginBottom: 22,
    },
    guestContent: {
      padding: 16,

      [theme.breakpoints.up('sm')]: {
        padding: '30px 34px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '50px 54px',
      },
    },
  });
