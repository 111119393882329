import React from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import MUIDialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { styles } from './styles';

const useStyles = makeStyles(styles);

export interface Props extends Omit<DialogProps, 'title' | 'description'> {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  text?: string | React.ReactNode;
  actions: React.ReactNode;
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  dialogActionsClassName?: string;
  paperClass?: string;
}

export const Dialog = ({
  title,
  text,
  actions,
  open,
  onClose,
  children,
  maxWidth,
  fullWidth,
  description,
  scroll = 'body',
  dialogActionsClassName,
  paperClass,
}: Props) => {
  const classes = useStyles();

  return (
    <MUIDialog
      disableBackdropClick
      open={open}
      onClose={onClose}
      classes={{ paper: paperClass || classes.dialogPaper }}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      scroll={scroll}
    >
      <DialogTitle disableTypography className={cx({ [classes.dialogNoTitle]: !title })}>
        {title && (
          <Typography className={classes.dialogTitleTypography} variant="h2">
            {title}
          </Typography>
        )}
        {description && (
          <Typography className={classes.dialogDescriptionTypography} variant="subtitle1">
            {description}
          </Typography>
        )}

        <IconButton className={classes.buttonClose} onClick={onClose}>
          <CloseIcon className={classes.iconClose} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {text ? <DialogContentText className={classes.dialogText}>{text}</DialogContentText> : children}
      </DialogContent>
      {!!actions && (
        <DialogActions>
          <div className={cx(classes.dialogActionsContainer, dialogActionsClassName)}>{actions}</div>
        </DialogActions>
      )}
    </MUIDialog>
  );
};
