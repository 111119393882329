import { useTranslation } from 'react-i18next';
import { CondOperator } from '@nestjsx/crud-request';

import { Grid, Typography } from '@material-ui/core';

import { useUsersIndex } from '@app/users/hooks/crud';
import { UserEntity } from '@app/users/interfaces';
import { Loader } from '@app/ui/loader';
import { Table } from '@app/ui/table/components/Table';
import { STATUSES } from '@app/users/constants/roles';

import { StatusCell } from './cells/StatusCell';

export const Members = () => {
  const { t } = useTranslation('common');
  const { data, isFetching } = useUsersIndex({
    params: {
      join: ['paymentPlan'],
      filter: [{ field: 'status', operator: CondOperator.EQUALS, value: STATUSES.ACTIVE }],
    },
  });

  const columns = [
    {
      Header: t('user.profile.tabs.members.table.columns.name'),
      accessor: ({ firstName, lastName }: UserEntity) => `${firstName} ${lastName}`,
      id: 'name',
      disableSortBy: true,
    },
    {
      Header: t('user.profile.tabs.members.table.columns.email'),
      accessor: 'email',
      id: 'email',
      disableSortBy: true,
    },
    {
      Header: t('user.profile.tabs.members.table.columns.status'),
      accessor: 'status',
      id: 'status',
      Cell: StatusCell,
      disableSortBy: true,
    },
  ];

  return (
    <>
      <Typography gutterBottom variant="h2">
        {t('user.profile.tabs.members.title')}
      </Typography>
      {isFetching && <Loader showOverlay />}
      <Grid container>
        <Grid item xs={12} md={10} lg={8}>
          <Table
            columns={columns}
            data={data}
            isPagination={false}
            total={(data ?? []).length}
            textTotal={t('user.profile.tabs.members.table.total')}
            respUpParent="md"
          />
        </Grid>
      </Grid>
    </>
  );
};
