import { Trans, useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useFreeSlots } from '@app/interview/hooks/useFreeSlots';
import { useAvailabilityViaInviteLink } from '@app/availabilities/hooks/crud';
import { Duration } from '@app/interview/types/Interview';
import { InterviewSelectDate } from '@app/interview/components/New/ScheduleInterview/SelectDate';
import { RESOURCES, ROLES } from '@app/availabilities/constants';

import { Participants } from './Participants';
import { styles } from './styles';

const useStyles = makeStyles(styles);

interface Props {
  token: string;
  duration: Duration;
}

export const Availability = ({ token, duration }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { data } = useAvailabilityViaInviteLink({ token });
  const durationTrans = {
    30: t('availabilities.time.30'),
    60: t('availabilities.time.60'),
    90: t('availabilities.time.90'),
  };

  const isCalendly = data && data.resource === RESOURCES.CALENDLY && (!data.meta || !data.meta[0]?.calendlyAPIToken);

  const { slots, timezones, loadFreeSlots, isLoading } = useFreeSlots({
    duration,
    availabilities: data ? [data.id] : [],
    isInvite: true,
    token,
    isCalendly,
  });

  const onCalendarChange = useCallback(
    date => {
      if (isCalendly) {
        return loadFreeSlots(date);
      }

      return Promise.resolve();
    },
    [isCalendly, loadFreeSlots],
  );

  const candidates = data && data.role === ROLES.CANDIDATE ? [data] : [];
  const interviewers = data && [ROLES.INTERVIEWER, ROLES.RECRUITER, ROLES.ADMIN].includes(data.role) ? [data] : [];

  return (
    <div>
      <Typography variant="h5">
        <Trans
          t={t}
          defaults="interviews.invite.description.single"
          values={{ name: data?.name, duration: durationTrans[duration] }}
          components={{ b: <b /> }}
        />
      </Typography>
      {!!data && (
        <Paper className={classes.datesPaper}>
          <InterviewSelectDate
            isLoading={isLoading}
            slots={slots}
            timezones={timezones}
            onLoad={loadFreeSlots}
            onCalendarChange={onCalendarChange}
            isCalendly={isCalendly}
          />
        </Paper>
      )}
      <Participants candidates={candidates} interviewers={interviewers} />
    </div>
  );
};
