import { createStyles, Theme } from '@material-ui/core/styles';

import { borderPrimaryColor, colorPrimary, colorPrimaryLight } from '@app/app/configs/variables';

export const styles = ({ breakpoints }: Theme) =>
  createStyles({
    daysLeftContainer: {
      minWidth: 115,
      height: 31,
      backgroundColor: '#FFEBD9',
      borderRadius: 6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 12,
      padding: '9px 12px 8px 10px',

      [breakpoints.up('sm')]: {
        marginRight: 17,
      },
    },
    reminderContainer: {
      borderColor: colorPrimary,
      backgroundColor: colorPrimaryLight,
      marginBottom: 35,
      padding: '22px 30px 21px 20px',
      border: `1px solid ${borderPrimaryColor}`,
      borderRadius: 8,
      '& .text': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .icon': {
        backgroundColor: colorPrimaryLight,
        marginRight: 15,

        '&, & $chipIcon': {
          color: colorPrimary,
        },
      },
      '& button': {
        [breakpoints.up('md')]: {
          width: '100%',
        },
      },
    },
    logoBlock: {
      display: 'block',
      lineHeight: 1,
      textDecoration: 'none',
      marginBottom: 30,
    },
    siteLogo: {
      width: 123,
      height: 18,
      marginTop: 3,

      [breakpoints.up('md')]: {
        width: 157,
        height: 23,
      },
    },
    welcomeImgBlock: {
      textAlign: 'right',
    },
    welcomeImg: {
      [breakpoints.down('sm')]: {
        width: 278,
        height: 190,
      },
      width: 315,
      height: 210,
      marginTop: 3,
    },
    welcomeModalContent: {
      maxWidth: 770,
    },
  });
