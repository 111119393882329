import { useState, MouseEvent, useMemo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Popover from '@material-ui/core/Popover';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';

import { Confirmation, useModal } from '@app/ui/modal';

interface Props {
  readonly onDelete: () => void;
  readonly onMove: () => void;
  readonly onOpen?: () => void;
  readonly isGroup?: boolean;
  readonly canDrag?: boolean;
  readonly canDelete?: boolean;
}

export const Actions = ({ onDelete, onMove, onOpen, isGroup = false, canDrag = false, canDelete = false }: Props) => {
  const { t } = useTranslation('common');
  const { isOpen: isOpenDeleteConfirm, hide: hideDeleteConfirm, show: showDleleteConfirm } = useModal();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const options = useMemo(() => {
    const menus = [];

    if (!isGroup) {
      menus.push({
        key: 'open',
        title: t('availabilities.buttons.open'),
        icon: <OpenInNewIcon />,
        onClick: () => {
          handleClose();
          if (onOpen) {
            onOpen();
          }
        },
      });
    }

    if (canDelete) {
      menus.push({
        key: 'delete',
        title: t('availabilities.buttons.delete'),
        icon: <DeleteOutlineIcon />,
        onClick: showDleleteConfirm,
      });
    }

    if (canDrag) {
      menus.push({
        key: 'moveToScheduler',
        title: t('availabilities.buttons.moveToScheduler'),
        icon: <FlipToFrontIcon />,
        onClick: () => {
          handleClose();
          onMove();
        },
      });
    }

    return menus;
  }, [canDrag, isGroup, onMove, onOpen, showDleleteConfirm, t]);

  const handleDelete = useCallback(() => {
    onDelete();
    hideDeleteConfirm();
    handleClose();
  }, [hideDeleteConfirm, onDelete]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component="nav">
          {options.map(({ key, title, icon, onClick }) => (
            <ListItem button key={key} onClick={onClick}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          ))}
        </List>
      </Popover>
      <Confirmation
        onAccept={handleDelete}
        onCancel={hideDeleteConfirm}
        isOpen={isOpenDeleteConfirm}
        title={t(isGroup ? 'availabilities.groupSchedulerDeleteConfirm.title' : 'availabilities.deleteConfirm.title')}
        description={
          <Trans>
            {t(
              isGroup
                ? 'availabilities.groupSchedulerDeleteConfirm.description'
                : 'availabilities.deleteConfirm.description',
            )}
          </Trans>
        }
        acceptButton={t(
          isGroup ? 'availabilities.groupSchedulerDeleteConfirm.button' : 'availabilities.deleteConfirm.button',
        )}
      />
    </>
  );
};
