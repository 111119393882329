import { useCallback, useState } from 'react';
import { CondOperator, CreateQueryParams } from '@nestjsx/crud-request';

import { useAvailabilityIndex } from '@app/availabilities/hooks/crud';
import { useAuthUser } from '@app/auth';
import { sortAvailabilitiesRecruiterFirst } from '@app/availabilities/utils/sortAvailabilitiesRecruiterFirst';

const defaultFilter: CreateQueryParams = {
  join: [{ field: 'groupSchedulers' }, { field: 'groupSchedulers.availabilities' }],
};

export const useSearch = () => {
  const [filter, setFilter] = useState<CreateQueryParams>(defaultFilter);
  const { user } = useAuthUser();

  const { data = [], refetch } = useAvailabilityIndex({ params: filter });

  const search = useCallback((values: string[]) => {
    const params: CreateQueryParams = {
      or: [],
      join: [{ field: 'groupSchedulers' }, { field: 'groupSchedulers.availabilities' }],
    };

    if (values) {
      values.forEach(value => {
        const isDomain = value[0] === '@';

        // @ts-ignore
        params.or.push({
          field: 'email',
          operator: isDomain ? CondOperator.ENDS_LOW : CondOperator.EQUALS,
          value: isDomain ? value : value.replace(/[\S\s]+\(/g, '').replace(')', ''),
        });
      });
    }

    setFilter(params);
  }, []);

  return { data: sortAvailabilitiesRecruiterFirst(data, user?.availabilityId!), search, refetch };
};
