import { useQuery, useMutation, UseMutationOptions, UseQueryOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { CreateQueryParams } from '@nestjsx/crud-request';

import { ApiError } from '@app/query';

import { GroupSchedulerEntity, GroupScheduler } from '../types/GroupScheduler';
import { GROUP_SCHEDULER_LIST_KEY, GROUP_SCHEDULER_INVITE_INFO_KEY } from '../constants';
import {
  getGroupSchedulers,
  createGroupScheduler,
  deleteGroupScheduler,
  updateGroupScheduler,
  getGroupViaInviteLink,
} from '../api';

export const useGroupSchedulerIndex = <T = GroupSchedulerEntity[]>({
  params,
  options = {},
}: {
  params?: CreateQueryParams;
  options?: AxiosRequestConfig;
} = {}) =>
  useQuery<T, ApiError>([GROUP_SCHEDULER_LIST_KEY, params], () => getGroupSchedulers<T>(params, options), {
    cacheTime: 1000,
  });

export const useGroupSchedulerStore = (
  options?: UseMutationOptions<GroupSchedulerEntity, ApiError, GroupSchedulerEntity>,
) =>
  useMutation<GroupSchedulerEntity, ApiError, GroupSchedulerEntity>(
    (data: GroupScheduler) => createGroupScheduler(data),
    options,
  );

export const useGroupSchedulerUpdate = (
  options?: UseMutationOptions<GroupSchedulerEntity, ApiError, Partial<GroupSchedulerEntity>>,
) =>
  useMutation<GroupSchedulerEntity, ApiError, Partial<GroupSchedulerEntity>>(
    data => updateGroupScheduler(data),
    options,
  );

export const useGroupSchedulerDelete = (options?: UseMutationOptions<any, ApiError, { id: number }>) =>
  useMutation<any, ApiError, { id: number }>(({ id }: { id: number }) => deleteGroupScheduler(id), options);

export const useGroupSchedulerViaInviteLink = <T = GroupSchedulerEntity>({
  token,
  params,
  options = {},
  queryOptions = {},
}: {
  token: string;
  params?: CreateQueryParams;
  options?: AxiosRequestConfig;
  queryOptions?: UseQueryOptions<T, ApiError>;
}) =>
  useQuery<T, ApiError>(
    [GROUP_SCHEDULER_INVITE_INFO_KEY, params],
    () => getGroupViaInviteLink<T>(token, params, options),
    {
      cacheTime: 1000,
      ...queryOptions,
    },
  );
