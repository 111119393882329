import { GroupSchedulerEntity } from '@app/groupScheduler/types/GroupScheduler';
import { STATUSES as GROUP_STATUSES } from '@app/groupScheduler/constants';
import { useQueryData } from '@app/query/hooks/useQueryData';

import { AvailabilityEntity } from '../types/Availability';
import { AVAILABILITY_LIST_KEY, RESOURCES, STATUSES } from '../constants';
import { useAvailabilityRequestCheck } from './crudRequest';

export function useUpdateStatusToDisconnect(item: AvailabilityEntity) {
  const { onUpdate } = useQueryData(AVAILABILITY_LIST_KEY);
  const { isLoading } = useAvailabilityRequestCheck(item.token, {
    enabled: !!item?.token && item?.resource === RESOURCES.CALENDLY,
    onSuccess: response => {
      if (response) {
        onUpdate(response, (result: AvailabilityEntity) => {
          result.status = STATUSES.DISCONNECTED;
          result.resource = undefined;
          result.groupSchedulers?.forEach((draft: GroupSchedulerEntity) => {
            draft?.availabilities.forEach(state => {
              if (state.id === response) {
                state.resource = undefined;
                state.status = STATUSES.DISCONNECTED;
              }
            });
            draft.status = GROUP_STATUSES.INCOMPLETE;
          });
        });
      }
    },
  });
  return isLoading;
}
