import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import { useAuthUser } from '@app/auth';
import { PAYMENT_PLANS } from '@app/payments/constants';

import { styles } from './styles';

const useStyles = makeStyles(styles);

export const DaysLeft = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const { user: { trial, paymentPlan } = {} } = useAuthUser();

  if (trial && paymentPlan?.plan !== PAYMENT_PLANS.TRIAL) {
    const { daysLeft, hoursLeft } = trial;

    let value = t('payments.trial.widget.lestThanHour');

    if (daysLeft) {
      value = t('payments.trial.widget.daysLeft', { count: daysLeft });
    } else if (hoursLeft) {
      value = t('payments.trial.widget.hoursLeft', { count: hoursLeft });
    }

    return <div className={classes.daysLeftContainer}>{`${t('payments.trial.widget.title')}: ${value}`}</div>;
  }

  return null;
};
