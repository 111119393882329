import { useCallback, useState } from 'react';

interface ModalHelpers {
  isOpen: boolean;
  show: () => void;
  hide: () => void;
  toggle: () => void;
  setOpen: (value: boolean) => void;
}

export const useModal = (initialOpen: boolean = false): ModalHelpers => {
  const [isOpen, setOpen] = useState<boolean>(initialOpen);

  const show = useCallback(() => setOpen(true), [setOpen]);

  const hide = useCallback(() => setOpen(false), [setOpen]);

  const toggle = useCallback(() => {
    setOpen(isOpenState => !isOpenState);
  }, [setOpen]);

  return { isOpen, show, hide, toggle, setOpen };
};
