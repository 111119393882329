import { memo } from 'react';

import { Text, Props as TextProps } from './Text';
import { Option } from '../types';

export interface Props extends TextProps {}

const options: Option[] = [];

for (let i = 0; i <= 24; i += 1) {
  const id = i <= 9 ? `0${i}` : `${i}`;
  const name = `${id}:00`;

  options.push({ id, name });
}

export const Time = memo((props: Props) => <Text {...props} options={options} select />);
