import { createStyles, Theme } from '@material-ui/core/styles';

import { bgPaper } from "@app/app/configs/variables";

export const styles = ({ breakpoints }: Theme) =>
  createStyles({
    paper: {
      backgroundColor: bgPaper,
      padding: '18px 24px',
    },
    calendarPreviewImage: {
      width: 616,
      maxWidth: '100%',
    },
    calendlyButtons: {
      [breakpoints.up('md')]: {
        marginTop: 20,
      },
    },
    connectionIcon: {
      fontSize: 30,
      marginRight: 10,
      marginTop: 5
    },
    connectionSuccessMessage: {
      fontWeight: 500,
    },
    disconnectButton: {
      marginTop: 5,
    },
    inputWithTooltip: {
      '& label .MuiInputLabel-asterisk': {
        display: 'none',
      },
    },
  });
