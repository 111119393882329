import { AxiosRequestConfig } from 'axios';
import { CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';

import { apiClient } from '@app/query';

import { UpdateUserForm, UserEntity } from '../interfaces';

const USERS_URL = '/users';

export const getUser = <T = UserEntity>(
  id: number,
  { params, options }: { params?: CreateQueryParams; options?: AxiosRequestConfig } = {},
): Promise<T> =>
  apiClient.get(`${USERS_URL}/${id}`, { ...options, params: RequestQueryBuilder.create(params).queryObject });

export const getUsers = <T = UserEntity[]>(
  params: CreateQueryParams = {},
  options: AxiosRequestConfig = {},
): Promise<T> => apiClient.get(USERS_URL, { ...options, params: RequestQueryBuilder.create(params).queryObject });

export const updateUser = (
  { id, ...data }: Partial<UpdateUserForm> & { id: number },
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
): Promise<UserEntity> =>
  apiClient.patch(`${USERS_URL}/${id}`, data, {
    params: params && RequestQueryBuilder.create(params).queryObject,
    ...options,
  });

export const updateUserProfile = (
  data: Partial<UpdateUserForm>,
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
): Promise<UserEntity> =>
  apiClient.patch(`${USERS_URL}/profile`, data, {
    params: params && RequestQueryBuilder.create(params).queryObject,
    ...options,
  });

export const updateUserPassword = (
  options: AxiosRequestConfig & { params?: CreateQueryParams } = {},
): Promise<UserEntity> => apiClient.get(`${USERS_URL}/change-password`, options);

export const updateUserFirstLogin = (
  data: { isFirstLogin: boolean },
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
): Promise<UserEntity> =>
  apiClient.patch(`${USERS_URL}/first-login`, data, {
    params: params && RequestQueryBuilder.create(params).queryObject,
    ...options,
  });

export const getMyTeamMembersCount = <T = number>({
  options,
}: { params?: CreateQueryParams; options?: AxiosRequestConfig } = {}): Promise<T> =>
  apiClient.get(`${USERS_URL}/team-members-count`, { ...options });

export const getMyTeamAdmin = <T = number>({
  options,
}: { params?: CreateQueryParams; options?: AxiosRequestConfig } = {}): Promise<T> =>
  apiClient.get(`${USERS_URL}/team-admin`, { ...options });
