import { RouteComponentProps } from 'react-router-dom';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { GoogleAuthProvider } from '@app/google/components/GoogleAuthProvider';
import { MainLayout } from '@app/app/components/MainLayout';
import { TabPanel, Tabs, useRouterTabs } from '@app/ui/tabs';
import { General } from '@app/users/components/Profile/General';
import { Billing } from '@app/users/components/Profile/Billing';
import { Members } from '@app/users/components/Profile/Members';
import { useAuthUser } from '@app/auth';

import { styles } from './styles';

const useStyles = makeStyles(styles);

enum TABS {
  general = 'general',
  billing = 'billing',
  members = 'members',
}

export const ProfilePage = ({
  match: {
    params: { tab },
  },
}: RouteComponentProps<{ tab: string }>) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const initTab = TABS[tab as TABS] ?? TABS.general;
  const { user } = useAuthUser();

  const { value: step, onChange: handleTabsChange } = useRouterTabs({ initialValue: initTab, url: '/profile/:tab' });

  const renderTabs = useMemo(() => {
    const tabs = [
      {
        id: TABS.general,
        label: t('user.profile.tabs.general.title'),
      },
      {
        id: TABS.billing,
        label: t('user.profile.tabs.billing.title'),
      },
    ];

    if (user && user.teamId) {
      tabs.push({
        id: TABS.members,
        label: t('user.profile.tabs.members.title'),
      });
    }

    return tabs;
  }, [t, user]);

  return (
    <GoogleAuthProvider>
      <MainLayout>
        {user && (
          <Paper elevation={0} className={classes.paper}>
            <Typography variant="h1" gutterBottom>
              {t('user.profile.title')}
            </Typography>
            <Tabs value={step} tabs={renderTabs} onChangeTab={handleTabsChange} variant="scrollable" />
            <TabPanel value={step} index={TABS.general}>
              <General user={user} />
            </TabPanel>
            <TabPanel value={step} index={TABS.billing}>
              <Billing user={user} />
            </TabPanel>
            <TabPanel value={step} index={TABS.members}>
              <Members />
            </TabPanel>
          </Paper>
        )}
      </MainLayout>
    </GoogleAuthProvider>
  );
};
