import { useAuthUser } from '@app/auth';
import { PAYMENT_PLANS } from '@app/payments/constants';

export const usePlan = () => {
  const { user: { trial, paymentPlan: { plan } = { plan: PAYMENT_PLANS.BASIC } } = {} } = useAuthUser();

  const isPro = plan === PAYMENT_PLANS.PRO;
  const isBasic = plan === PAYMENT_PLANS.BASIC;
  const isTrial = !!trial;
  const isTrialUser = plan === PAYMENT_PLANS.TRIAL;
  const isProTrial = isPro && isTrial;

  return { isPro, isBasic, isTrialUser, isProTrial, isTrial };
};
