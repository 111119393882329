import { useTranslation } from 'react-i18next';
import { useCallback, ReactNode } from 'react';
import { useFormikContext } from 'formik';
import GoogleButton from 'react-google-button';
import { useGoogleLogin } from '@react-oauth/google';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Tooltip, Link, Box } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { error } from '@app/snackbars';
import { useGoogleInfo } from '@app/auth/hooks/api';
import { Checkbox } from '@app/ui/forms';
import { useModal } from '@app/ui/modal';
import { GOOGLE_REJECT_REASONS } from '@app/auth/constants';
import { HelpModal } from '@app/availabilities/components/Request/Google/HelpModal';
import { SCOPE, MAX_GOOGLE_CALENDARS } from '@app/google/constants';

import styles from './styles';
import { CantConnectModal } from './CantConnectModal';

const useStyles = makeStyles(styles);

export interface Props {
  readonly onUpdate: (field: string, value: Record<string, any>) => void;
  readonly cantConnect: boolean;
  readonly googleRejectReason: GOOGLE_REJECT_REASONS | null;
  readonly calendarsCount: number;
  readonly availabilityLink?: string;
  readonly isRecruiter?: boolean;
  readonly isProfile?: boolean;
  readonly isDisabled?: boolean;
  readonly FooterContent?: ReactNode;
}

export const GoogleConnect = ({
  onUpdate,
  cantConnect,
  googleRejectReason,
  calendarsCount,
  availabilityLink,
  isRecruiter = false,
  FooterContent,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { isOpen, hide, show } = useModal();
  const { isOpen: isOpenHelp, hide: hideHelp, show: showHelp } = useModal();
  const { setFieldValue } = useFormikContext();

  const { mutateAsync: getGoogleInfo } = useGoogleInfo({
    onSuccess: userInfo => {
      onUpdate('meta', userInfo);
    },
    onError: () => {
      error(t('general.messages.somethingWentWrong'));
    },
  });

  const hideCantConnect = useCallback(() => {
    hide();
    setFieldValue('cantConnect', false);
  }, []);

  const handleGoogle = useGoogleLogin({
    onSuccess: async ({ code }: { code: string }) => {
      if (code) {
        await getGoogleInfo({ code });
      }
    },
    onError: ({ error_description }) => {
      if (error_description) {
        error(error_description);
      }
    },
    scope: SCOPE,
    flow: 'auth-code',
  });

  const openAvailabilityLink = () => {
    window.open(availabilityLink, '_blank');
  };

  const onUpdateCantConnect = useCallback(
    (reason: string) => {
      setFieldValue('googleRejectReason', reason);
    },
    [setFieldValue],
  );

  const onChangeCantConnect = () => {
    onUpdateCantConnect(GOOGLE_REJECT_REASONS.OTHER);
    show();
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm>
          <Typography variant="h3" gutterBottom>
            Connect your Google{' '}
            <Box display="inline-flex" alignItems="center">
              Calendar
              {!availabilityLink && (
                <Tooltip
                  title={<span>{t('user.registration.form.firstStep.googleConnect.tooltip')}</span>}
                  placement="top"
                >
                  <InfoIcon className={classes.connectGoogleTitleInfoIcon} color="primary" />
                </Tooltip>
              )}
            </Box>
          </Typography>
          <Typography gutterBottom>
            {!isRecruiter ? (
              <>
                {t('availabilities.request.google.recruiterDescription1')}{' '}
                <Link onClick={showHelp}>{t('general.this')}</Link>
                {t('availabilities.request.google.recruiterDescription2')}{' '}
                {t('availabilities.request.google.calendarsCount')}
              </>
            ) : (
              <>
                {t('availabilities.request.google.description')}{' '}
                <Link onClick={showHelp}>{t('availabilities.request.buttons.howSeeCalendar')}</Link>{' '}
                {t('availabilities.request.google.calendarsCount')}
              </>
            )}
          </Typography>
          {availabilityLink && (
            <Link onClick={openAvailabilityLink}>{t('user.profile.tabs.general.availabilityLink')}</Link>
          )}
        </Grid>
        <Grid item xs={12} sm="auto">
          <GoogleButton
            onClick={handleGoogle}
            disabled={calendarsCount >= MAX_GOOGLE_CALENDARS}
            label={t(
              `user.registration.form.firstStep.googleConnect.${
                calendarsCount ? 'buttonAddGoogle' : 'buttonConnectGoogle'
              }`,
            )}
            type="dark"
          />
          <div className={classes.cantConnectWrapper}>
            {cantConnect ? (
              <Tooltip
                title={
                  <>
                    {`${t('user.registration.form.firstStep.googleConnect.cantConnect.reason')}: 
                      ${t(`user.registration.form.firstStep.googleConnect.cantConnect.reasons.${googleRejectReason}`)}`}
                  </>
                }
              >
                <span>
                  <Checkbox name="cantConnect" label={t('user.registration.form.firstStep.fieldCantConnect')} />
                </span>
              </Tooltip>
            ) : (
              <Checkbox
                name="cantConnect"
                label={t('user.registration.form.firstStep.fieldCantConnect')}
                onChange={onChangeCantConnect}
              />
            )}
          </div>
        </Grid>
      </Grid>
      {FooterContent}

      <CantConnectModal reset={hideCantConnect} hide={hide} isOpen={isOpen} onUpdate={onUpdateCantConnect} />
      <HelpModal isRegistration isOpen={isOpenHelp} hide={hideHelp} />
    </Paper>
  );
};
