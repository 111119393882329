import { memo, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { useTimezonesIndex } from '@app/timezones/hooks/crud';

import { Autocomplete, Props as AutocompleteProps } from './Autocomplete';
import { Option } from '../types';

const getOptionLabel = (option: Option) => option.name;

export interface Props extends Omit<AutocompleteProps<Option, false>, 'options' | 'defaultValue'> {
  defaultValue?: number | string;
  required?: boolean;
}

export const Timezones = memo(({ defaultValue, required, ...props }: Props) => {
  const { data, isFetched } = useTimezonesIndex();
  const { setFieldValue, values } = useFormikContext<Record<any, any>>();

  useEffect(() => {
    if (isFetched) {
      const shortName = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const formValue = values[props.name];

      if (!formValue) {
        const value = defaultValue
          ? (data?.data ?? []).find(({ id }) => id === defaultValue)
          : (data?.data ?? []).find(({ key }) => key === shortName);

        setFieldValue(props.name, value);
      } else {
        setFieldValue(
          props.name,
          (data?.data ?? []).find(({ id }) => id === formValue),
        );
      }
    }
  }, [isFetched]);

  const options: Option[] = useMemo(
    () =>
      (data?.data ?? []).map(({ name, id, offset, shortNameKeyForUSA, gmtName }) => ({
        id,
        name,
        offset,
        gmtName,
        shortNameKeyForUSA,
        label: name,
      })),
    [data],
  );
  const textFieldProps = useMemo(() => ({ required }), []);

  return <Autocomplete {...props} options={options} getOptionLabel={getOptionLabel} textFieldProps={textFieldProps} />;
});
