import React, { ReactNode } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Modal } from '@app/ui/modal';

export interface Props {
  readonly title?: string;
  readonly description?: ReactNode | string;
  readonly onAccept: () => void;
  readonly onCancel: () => void;
  readonly isOpen: boolean;
  readonly isLoading?: boolean;
  readonly withCancel?: boolean;
  readonly acceptButton?: string;
  children?: ReactNode;
}

export const Confirmation = ({
  onAccept,
  title,
  description,
  isOpen,
  onCancel,
  isLoading = false,
  acceptButton,
  withCancel = true,
  children,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <Modal
      isOpen={isOpen}
      content={description ?? t('general.messages.confirm')}
      title={title}
      fullWidth
      maxWidth="xs"
      actions={
        <>
          {withCancel && (
            <Button color="primary" onClick={onCancel} disabled={isLoading}>
              {t('general.buttons.cancel')}
            </Button>
          )}

          {withCancel ? (
            <Button variant="contained" color="primary" onClick={onAccept} disabled={isLoading}>
              {isLoading ? <CircularProgress color="inherit" size={16} /> : acceptButton || t('general.buttons.yes')}
            </Button>
          ) : (
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Button variant="contained" color="primary" onClick={onAccept} disabled={isLoading}>
                {isLoading ? <CircularProgress color="inherit" size={16} /> : acceptButton || t('general.buttons.yes')}
              </Button>
            </Box>
          )}
        </>
      }
      onClose={isLoading ? undefined : onCancel}
      hide={isLoading ? () => {} : onCancel}
    >
      {children}
    </Modal>
  );
};
