import { createStyles, Theme } from '@material-ui/core/styles';

import { borderPrimaryColor } from '@app/app/configs/variables';

export const styles = ({ breakpoints }: Theme) =>
  createStyles({
    divider: {
      margin: '15px 0 13px 0',
      borderTop: `1px solid ${borderPrimaryColor}`,

      [breakpoints.up('md')]: {
        margin: '36px 0 31px 0',
      },
    },
    datesPaper: {
      padding: '18px 24px',
      boxShadow: 'none',
      border: `1px solid ${borderPrimaryColor}`,
      margin: '30px 0',
    },

    participantName: {
      wordBreak: 'break-all',
    },
  });
