import { useUpdateEffect } from 'react-use';
import { useCallback, useState } from 'react';

export default function useSteps(initialStep: number | string) {
  const [step, setStep] = useState(initialStep);

  const onChange = useCallback((event, tab) => setStep(tab), [setStep]);
  const isActive = (tab: number | string) => step === tab;

  const reset = useCallback(() => setStep(initialStep), [initialStep]);

  useUpdateEffect(() => {
    if (initialStep) {
      setStep(initialStep);
    }
  }, [initialStep]);

  return {
    step,
    onChange,
    isActive,
    reset,
    setStep,
  };
}
