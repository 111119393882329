import { ConnectDragSource, DragSource, DragSourceConnector, DragSourceMonitor } from 'react-dnd';
import { Dispatch, SetStateAction } from 'react';

import { GroupSchedulerEntity } from '@app/groupScheduler/types/GroupScheduler';
import { Timezone } from '@app/timezones/interfaces';

import { AvailabilityEntity } from '../../../types/Availability';
import { GroupSchedulerCard } from './GroupSchedulerCard';
import { AvailabilityCard } from './AvailabilityCard';

export interface CardProps {
  readonly timezones: Timezone[];
  readonly item: AvailabilityEntity | GroupSchedulerEntity;
  readonly setGroupLinkMembers: Dispatch<SetStateAction<AvailabilityEntity[]>>;
  readonly groupLinkMembers: AvailabilityEntity[];
  readonly isGroupLink: boolean;
  readonly noActions?: boolean;
  readonly refetch: () => void;

  // Collected Props
  isDragging: boolean;
  connectDragSource: ConnectDragSource;
  canDrag: boolean;
}

const Component = ({ isGroupLink, item, ...rest }: CardProps) => {
  if (isGroupLink) {
    return <GroupSchedulerCard item={item as GroupSchedulerEntity} {...rest} />;
  }

  return <AvailabilityCard item={item as AvailabilityEntity} {...rest} />;
};

export const Card = DragSource(
  'card',
  {
    beginDrag: ({ item, groupLinkMembers, setGroupLinkMembers }: CardProps) => ({
      item,
      groupLinkMembers,
      setGroupLinkMembers,
    }),
    endDrag({ item, setGroupLinkMembers, isGroupLink }: CardProps, monitor: DragSourceMonitor) {
      const dropResult = monitor.getDropResult<{ item: AvailabilityEntity }>();

      if (dropResult) {
        if (isGroupLink) {
          setGroupLinkMembers(prevState => {
            const result = [...prevState];
            const ids = prevState.map(({ id }) => id);

            (item as GroupSchedulerEntity).availabilities.forEach(availability => {
              if (!ids.includes(availability.id)) {
                result.push(availability);
              }
            });

            return result;
          });
        } else {
          setGroupLinkMembers(prevState => [...prevState, item as AvailabilityEntity]);
        }
      }
    },
    canDrag: ({ item, groupLinkMembers, isGroupLink, noActions }: CardProps) => {
      if (noActions) {
        return false;
      }

      if (isGroupLink) {
        const ids = groupLinkMembers.map(({ id }) => id);

        return !(item as GroupSchedulerEntity).availabilities.every(({ id }) => ids.includes(id));
      }

      return !groupLinkMembers.find(({ id }) => id === item.id);
    },
  },
  (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    canDrag: monitor.canDrag(),
  }),
)(Component);
