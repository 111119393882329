import { Theme, createStyles } from '@material-ui/core/styles';

import {
  borderPrimaryColor,
  colorDanger,
  colorPrimary,
  textGrey,
  textPrimary,
  borderRadius,
  transition,
  textSecondary,
} from '@app/app/configs/variables';

export const styles = (theme: Theme) =>
  createStyles({
    interviewModal: {
      maxWidth: '1100px !important',
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 64px)',
        maxWidth: '600px !important',
      },
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% - 30px)',
      },
      [theme.breakpoints.up('md')]: {
        minWidth: 860,
      },
    },
    interviewModalHeaderSteps: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    interviewModalHeaderTitle: {
      display: 'inline-flex',
      alignItems: 'flex-end',
    },
    interviewModalHeaderTime: {
      display: 'inline-flex',
      alignItems: 'center',
      marginLeft: 15,
      marginBottom: 2,
      '& svg': {
        marginRight: 3,
      },
    },
    interviewModalHeaderDateTime: {
      display: 'flex',
      alignItems: 'center',
      '& .interview-date': {
        fontSize: 14,
        marginRight: 7,
      },
    },
    interviewStepNumbersBlock: {
      fontSize: '1.35em',
      fontWeight: 500,
      lineHeight: 1,
      color: colorPrimary,
      marginLeft: 15,
    },
    interviewStepNumbers: {
      fontSize: '0.7em',
      fontWeight: 400,
    },
    interviewModalContentPaper: {
      [theme.breakpoints.up('md')]: {
        paddingBottom: 22,
      },
    },
    interviewAvatarItem: {
      display: 'flex',
      alignItems: 'center',
      '& .avatar': {
        marginRight: 10,
      },
      '&$error': {
        '& .avatar': {
          boxShadow: `0 0 0 2px ${colorDanger}`,
        },
        '& $interviewAvatarName': {
          color: colorDanger,
        },
      },
    },
    interviewTimeBlock: {},
    interviewTimeHeader: {
      '& $interviewTimeItem': {
        '&:nth-child(2)': {
          marginLeft: 17,
        },
        '&:first-child': {
          paddingLeft: 0,
        },
        '&:last-child': {
          paddingRight: 0,
        },
        '& h6': {
          fontSize: '0.875em',
        },
      },
    },
    interviewTimeZone: {
      fontSize: 14,
      textTransform: 'uppercase',
      marginBottom: 2,
    },
    interviewTimeZoneType: {
      fontSize: 14,
      marginBottom: 2,
      fontWeight: 500,
      color: textSecondary,
    },
    interviewTimeBody: {
      maxHeight: 220,
      padding: '0 5px 5px',
    },
    interviewTimeRow: {
      display: 'flex',
      flexWrap: 'nowrap',
      padding: '10px 0',
      minWidth: '100%',
    },
    interviewTimeItem: {
      minWidth: 100,
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      padding: '4px 6px',
      fontSize: 13,
      whiteSpace: 'nowrap',
      [theme.breakpoints.only('md')]: {
        fontSize: 14,
        minWidth: 100,
        padding: '4px 6px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        minWidth: 140,
        padding: '4px 10px',
      },
    },
    interviewTimeValue: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      position: 'relative',
      textAlign: 'left',
      '&:not(:first-child)': {
        borderLeft: `1px solid ${borderPrimaryColor}`,
      },
    },
    interviewTimeValueDate: {
      fontSize: 10,
      color: textGrey,
      [theme.breakpoints.up('lg')]: {
        fontSize: 12,
      },
    },
    active: {},
    interviewTimeButtonRow: {
      border: `1px solid ${colorPrimary}`,
      fontSize: 16,
      color: textPrimary,
      marginBottom: 8,
      borderRadius,
      transition,

      [theme.breakpoints.up('md')]: {
        paddingLeft: 7,
        paddingRight: 7,
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 14,
        paddingRight: 14,
      },
      '&:hover, &$active': {
        backgroundColor: '#EDF2FD',
        color: colorPrimary,
      },
    },
    activeStep: {
      fontSize: 26,
      fontWeight: 500,

      [theme.breakpoints.up('sm')]: {
        fontSize: 32,
      },
    },
    totalSteps: {
      fontSize: 24,
      fontWeight: 400,
      marginRight: 20,

      [theme.breakpoints.up('sm')]: {
        fontSize: 30,
      },
    },
    divider: {
      margin: '15px 0 13px 0',
      borderTop: `1px solid ${borderPrimaryColor}`,

      [theme.breakpoints.up('md')]: {
        margin: '36px 0 31px 0',
      },
    },
    descriptionContainer: {
      display: 'flex',
      marginBottom: 40,
    },
    descriptionDuration: {
      marginLeft: 25,
    },
    descriptionIcon: {
      marginRight: 10,
    },
    detailsContainer: {
      color: textPrimary,
    },
    detailsTitleContainer: {
      marginBottom: 19,
    },
    detailsDescriptionContainer: {
      marginBottom: 33,
    },
    detailsInterviewersContainer: {
      paddingTop: 5,
      marginBottom: 11,
    },
    participantStatus: {
      marginLeft: 7,
    },
    participantInfoContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    detailsRightContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    datetimeContainer: {
      marginBottom: 10,
    },
    customTimezoneContainerHidden: {
      opacity: 0,
    },
  });
