import React, { useEffect } from 'react';
import { useHistory, Route, RouteProps } from 'react-router-dom';

import { useAuthUser } from '@app/auth/hooks/useAuthUser';

const defaultRedirect = '/';
const nullRender = {
  render: () => null,
};

export const GuestRoute = (props: RouteProps) => {
  const { isLoading, isAuthorized } = useAuthUser();
  const history = useHistory();

  useEffect(() => {
    if (isAuthorized && !isLoading) {
      history.push(defaultRedirect);
    }
  }, [isAuthorized, isLoading, history]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...props} {...(isLoading && nullRender)} />;
};
