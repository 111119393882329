import { Modal } from '@app/ui/modal';
import { makeStyles } from '@material-ui/core/styles';

import { styles } from '../styles';
import imgCalendarPreview from '../../../images/img-onboarding-calendar-preview.png';

const useStyles = makeStyles(styles);

export interface HelpModalProps {
  readonly isOpen: boolean;
  readonly hide: () => void;
  readonly isRegistration?: boolean;
}

export const HelpModal = ({ hide, isOpen, isRegistration = false }: HelpModalProps) => {
  const classes = useStyles();

  return (
    <Modal
      hide={hide}
      isOpen={isOpen}
      title={isRegistration ? 'How other users see your calendar' : 'How recruiters see your calendar'}
      maxWidth="lg"
    >
      <img className={classes.calendarPreviewImage} src={imgCalendarPreview} alt="Preview" />
    </Modal>
  );
};
