import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import copyTextToClipboard from 'copy-text-to-clipboard';
import cx from 'classnames';

import { Card as MaterialCard, Grid, Typography, Button, Tooltip, useMediaQuery, Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FileCopy';

import { InterviewEntity } from '@app/interview/types/Interview';
import { Chip } from '@app/ui/chip';
import { useDate } from '@app/app/hooks/useDate';
import { useInterviewTime } from '@app/interview/hooks/useInterviewTime';
import { useInterviewCancel } from '@app/interview/hooks/crud';
import { STATUSES } from '@app/interview/constants';
import { Modal, useModal } from '@app/ui/modal';
import { success, error, warning } from '@app/snackbars';
import { Loader } from '@app/ui/loader';
import { RESOURCES } from '@app/availabilities/constants';
import { getInterviewers, getCandidates } from '@app/interview/utils';
import { useAuthUser } from '@app/auth';

import { Reschedule } from './Reschedule';
import styles from './styles';

const useStyles = makeStyles(styles);

interface Props {
  readonly item: InterviewEntity;
  readonly refetch: () => void;
  readonly userTimezoneName: string;
  readonly userOffset: number;
  readonly noActions: boolean;
}

export const Card = ({ item, refetch, userTimezoneName, noActions, userOffset }: Props) => {
  const { title, status, date, description, interviewsParticipants, time, meet, id } = item;
  const theme = useTheme();
  const { t } = useTranslation('common');
  const classes = useStyles();
  const { formatDate, djs } = useDate();
  const { toTime } = useInterviewTime();
  const respUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const { isOpen, show, hide } = useModal();
  const { isOpen: isOpenInterviewCancel, show: showInterviewCancel, hide: hideInterviewCancel } = useModal();
  const { mutateAsync, isLoading } = useInterviewCancel();
  const { push } = useHistory();
  const { user: { availability } = { availability: undefined } } = useAuthUser();
  const isGoogleAccountDisconnected =
    item.sentFrom &&
    (availability?.resource !== RESOURCES.GOOGLE ||
      !availability?.meta?.find(({ googleEmail }) => googleEmail === item.sentFrom));

  const timeRange = `${toTime(time.from, userOffset)} - ${toTime(time.to, userOffset)}`;

  const { candidates, interviewers, others, participants, isCalendly } = useMemo(() => {
    const others = (interviewsParticipants ?? [])
      .filter(({ type }) => !type)
      .map(({ availability, name, id, email }) => {
        if (availability) {
          return { name: availability.name, id, status: availability.status };
        }

        return { name: name || email, id, status: undefined };
      });

    const participants = (interviewsParticipants ?? [])
      .filter(({ availabilityId }) => availabilityId)
      .map(({ availabilityId }) => availabilityId) as number[];

    const isCalendly = !!(interviewsParticipants ?? [])
      .filter(({ availabilityId }) => availabilityId)
      .find(({ availability: { resource } = {} }) => resource === RESOURCES.CALENDLY);

    return {
      candidates: getCandidates(interviewsParticipants || []),
      interviewers: getInterviewers(interviewsParticipants || []),
      others,
      participants,
      isCalendly,
    };
  }, [interviewsParticipants]);

  const cancelInterview = async () => {
    try {
      await mutateAsync({ id: item.id });

      refetch();
      hideInterviewCancel();

      success(t('interviews.list.cancelConfirmation.successMessage'));
    } catch (e) {
      error(t('general.messages.somethingWentWrong'));
    }
  };
  const handleCopy = () => {
    if (meet?.link) {
      copyTextToClipboard(meet.link);
      success(t('availabilities.messages.copied'));
    }
  };

  const handleEdit = () => {
    if (isGoogleAccountDisconnected) {
      warning(t('interviews.list.connectGoogleAccount', { email: item.sentFrom }));
    } else {
      push(`interviews/edit/${id}`);
    }
  };
  const handleCancel = () => {
    if (isGoogleAccountDisconnected) {
      warning(t('interviews.list.connectGoogleAccount', { email: item.sentFrom }));
    } else {
      showInterviewCancel();
    }
  };
  const handleReschedule = () => {
    if (isGoogleAccountDisconnected) {
      warning(t('interviews.list.connectGoogleAccount', { email: item.sentFrom }));
    } else {
      show();
    }
  };

  return (
    <MaterialCard variant="outlined" className={classes.paper}>
      <Box position="relative">
        {status === STATUSES.SCHEDULED && !noActions && (
          <Tooltip title={<span>{t('interviews.list.editTooltip')}</span>} placement="right-end">
            <Edit className={cx(classes.editIcon, 'iconEdit')} onClick={handleEdit} />
          </Tooltip>
        )}
        <Grid container>
          <Grid item xs={12} md={4}>
            <div className={classes.gridItemDatesWrapper}>
              <div className={classes.title}>
                <Box className={classes.titleWrapper}>
                  <Typography variant="h1">{title}</Typography>
                </Box>
                <div>
                  {!respUpMd && <Chip status={status} label={t(`interviews.list.statuses.${status}`)} icon={<></>} />}
                </div>
              </div>

              {respUpMd && (
                <div className={classes.statusWrapper}>
                  <Chip status={status} label={t(`interviews.list.statuses.${status}`)} icon={<></>} />
                </div>
              )}
              <div className={classes.fieldWrapper}>
                {formatDate(djs.utc(date).add(time.from, 'hours').format('MM/DD/YYYY HH:mm [Z]'))}
              </div>
              <Grid container justifyContent="space-between">
                <Grid item xs={6}>
                  <div className={classes.fieldWrapper}>
                    {timeRange} {userTimezoneName}
                  </div>
                </Grid>
                {status === STATUSES.SCHEDULED && (
                  <Grid item xs={6}>
                    <Grid container spacing={2} justifyContent="flex-end">
                      <Grid item>
                        <Button color="primary" onClick={handleCancel} disabled={noActions}>
                          {t('general.buttons.cancel')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button color="primary" onClick={handleReschedule} disabled={noActions}>
                          {t('general.buttons.reschedule')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <div className={classes.gridItemParticipantsWrapper}>
              <Grid container spacing={1}>
                <Grid item xs={6} md={6} lg={4}>
                  {(interviewers.length || respUpMd) && (
                    <Typography color="textSecondary" gutterBottom>
                      {t('interviews.form.create.participants.interviewer')}
                    </Typography>
                  )}
                  {interviewers.map(({ name, id, status }) => (
                    <div key={`${id}-${name}`} className={classes.participantInfoContainer}>
                      <div className={classes.participantStatus}>
                        {status && <Chip iconOnly round status={status} />}
                      </div>
                      <div>{name}</div>
                    </div>
                  ))}
                </Grid>
                <Grid item xs={6} md={6} lg={4}>
                  {(candidates.length || respUpMd) && (
                    <Typography color="textSecondary" gutterBottom>
                      {t('interviews.form.create.participants.candidate')}
                    </Typography>
                  )}
                  {candidates.map(({ name, id, status }) => (
                    <div key={`${id}-${name}`} className={classes.participantInfoContainer}>
                      <div className={classes.participantStatus}>
                        {status && <Chip iconOnly round status={status} />}
                      </div>
                      <div>{name}</div>
                    </div>
                  ))}
                </Grid>
                {!!others.length && (
                  <Grid item xs={6} md={6} lg={4}>
                    <Typography color="textSecondary" gutterBottom>
                      {t('interviews.form.create.participants.other')}
                    </Typography>
                    {others.map(({ name, id }) => (
                      <div key={`${id}-${name}`} className={classes.participantInfoContainer}>
                        <div className={classes.participantStatus} />
                        <div>{name}</div>
                      </div>
                    ))}
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box className={classes.gridItemDescriptionTitleWrapper}>
              <Typography color="textSecondary" gutterBottom>
                {t('interviews.list.descriptionLabel')}
              </Typography>
            </Box>
            <Tooltip title={<div className={classes.gridItemDescriptionTooltip}>{description}</div>}>
              <div className={classes.gridItemDescriptionWrapper}>{description}</div>
            </Tooltip>
            {meet?.link && (
              <Box className={classes.gridItemDescriptionWrapper} mt={description ? 1 : 0}>
                <Link to={{ pathname: meet.link }} target="_blank" rel="noopener noreferrer">
                  {t('interviews.list.zoomLink')}
                </Link>
                <CopyIcon onClick={handleCopy} className={classes.copyButton} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      {isOpen && (
        <Reschedule participants={participants} hide={hide} data={item} refetch={refetch} isCalendly={isCalendly} />
      )}
      <Modal
        isOpen={isOpenInterviewCancel}
        hide={hideInterviewCancel}
        title={t('interviews.list.cancelConfirmation.title')}
        description={t('interviews.list.cancelConfirmation.description')}
        actions={
          <>
            <Button fullWidth={!respUpMd} color="primary" onClick={() => hideInterviewCancel()} disabled={isLoading}>
              {t('general.buttons.keep')}
            </Button>

            <Button
              fullWidth={!respUpMd}
              variant="contained"
              color="primary"
              onClick={cancelInterview}
              disabled={isLoading}
            >
              {t('interviews.list.cancelConfirmation.cancelButton')}
            </Button>
          </>
        }
      >
        {isLoading && <Loader showOverlay />}
      </Modal>
    </MaterialCard>
  );
};
