import { createGlobalStyle } from 'styled-components';

import { colorPrimary, colorGrey, colorGreyLight, fontFamily, colorBlack, bgPaper } from '@app/app/configs/variables';

const scrollbarSize = 6;

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    // Disable iOS Safari font scaling in landscape mode
    -webkit-text-size-adjust: 100%;
  }
  body, body > #root {
    height: 100%;
  }
  img {
    max-width: 100%;
  }
  p, ul, ol {
    margin-top: 0;
    margin-bottom: 0.45em;
  }
  ul, ol {
    padding: 0;
    list-style-position: inside;

    & > li {
      padding-left: 2em;
    }
  }
  a {
    color: ${colorPrimary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  a:focus,
  [tabindex]:focus {
    outline: 0;
  }

  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }
  input, select {
    font-family: inherit;
    font-size: inherit;
  }
  
  //Custom scrollbar
  .native-custom-scrollbar {
    cursor: default;
    scrollbar-color: ${colorGrey} ${colorGreyLight}; /* thumb and track color for Firefox */
    scrollbar-width: thin;
    overflow: auto;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 ${scrollbarSize}px rgba(0, 0, 0, 0.1);
      background-color: ${colorGreyLight};
    }

    &::-webkit-scrollbar {
      width: ${scrollbarSize}px;
      height: ${scrollbarSize}px;
      background-color: ${colorGreyLight};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      -webkit-box-shadow: inset 0 0 ${scrollbarSize}px rgba(0, 0, 0, 0.1);
      background-color: ${colorGrey};
    }
  }
  
  // fullcalendar
  .fc {
    font-family: ${fontFamily}
  }
  
  .fc th {
    text-align: left;
    background-color: #F0F3F6;
  }

  .fc .fc-timegrid-slot-label {
    background-color: #F0F3F6;
  }
  
  .fc .fc-toolbar {
    justify-content: center;
  }

  .fc .fc-toolbar-title {
    color: ${colorBlack};
    font-size: 16px;
    font-weight: normal;
    margin: 0 15px;
  }

  .fc .fc-button {
    background-color: ${bgPaper};
    color: ${colorBlack};
    border: none;
  }
  .fc .fc-button:disabled {
    background-color: ${bgPaper};
    color: ${colorBlack};
    border: none;
  }
  
  // perfect scroll
  .ps--active-y {
    padding-right: 14px;
  }
  .ps .ps-outer-scroll .ps--active-x {
    padding-bottom: 14px;
  }

  .MuiPagination-ul li {
    padding-left: 0;
  }
`;
