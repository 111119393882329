import React from 'react';

export interface Props {
  index: any;
  value: any;
  children: React.ReactNode;
}

export function TabPanel({ children, index, value }: Props) {
  return value === index ? <>{children}</> : null;
}
