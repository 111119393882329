import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { MenuItem } from '../types';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  menu: MenuItem[];
  variant?: 'vertical' | 'horizontal';
  className?: string;
}

export function Menu({ menu, variant = 'horizontal', className }: Props) {
  const classes = useStyles();

  return (
    <List
      className={cx(classes.navMenu, className, {
        [(classes as any)[`${variant}Menu`]]: variant,
      })}
      component="nav"
    >
      {menu.map(
        ({ name, to, show = true, exact = false, disabled = false, onClick, active }) =>
          show && (
            <ListItem
              className={cx(classes.navMenuItem, 'nav-menu-item', {
                [classes.navMenuItemActive]: active,
              })}
              button
              key={name}
              component={NavLink}
              to={to}
              onClick={onClick}
              exact={exact}
              disabled={disabled}
              selected={false}
            >
              <div className={classes.navMenuText}>{name}</div>
            </ListItem>
          ),
      )}
    </List>
  );
}
