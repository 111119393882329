import { createStyles, Theme } from '@material-ui/core/styles';

import { borderPrimaryColor, colorDanger, textSecondary } from '@app/app/configs/variables';

export const styles = ({ breakpoints }: Theme) =>
  createStyles({
    descriptionContainer: {
      display: 'flex',
      marginBottom: 40,
    },
    descriptionDuration: {
      marginLeft: 25,
    },
    descriptionIcon: {
      marginRight: 10,
    },
    stepFirst: {
      opacity: 1,
      '&.hidden': {
        height: 0,
        opacity: 0,
      },
    },
    divider: {
      margin: '15px 0 13px 0',
      borderTop: `1px solid ${borderPrimaryColor}`,

      [breakpoints.up('md')]: {
        margin: '36px 0 31px 0',
      },
    },
    participantContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '15px 0',
      borderBottom: `1px solid ${borderPrimaryColor}`,
    },
    participantInfoContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    participantStatus: {
      marginLeft: 7,
    },
    participantClearIcon: {
      color: colorDanger,
      cursor: 'pointer',
    },
    participantOwner: {
      color: textSecondary,
      marginLeft: 5,
    },
    newParticipantContainer: {
      display: 'flex',
      paddingTop: 10,
      borderBottom: `1px solid ${borderPrimaryColor}`,
    },
    addNewParticipantsButton: {
      marginTop: 10,
    },
    activeStep: {
      fontSize: 32,
      fontWeight: 500,
    },
    totalSteps: {
      fontSize: 30,
      fontWeight: 400,
      marginRight: 20,
    },
  });
