import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import { InterviewsList } from '@app/interview/components/List';
import { MainLayout } from '@app/app/components/MainLayout';
import { useSearch } from '@app/interview/hooks/useSearch';
import { Loader } from '@app/ui/loader';

export const InterviewsListPage = () => {
  const { t } = useTranslation('common');
  const { data, search, pageCount, page, isFetching, refetch } = useSearch();

  return (
    <MainLayout>
      <Typography variant="h1" gutterBottom>
        {t('interviews.list.title')}
      </Typography>
      {isFetching && <Loader showOverlay />}
      <InterviewsList items={data} onSearch={search} pageCount={pageCount} page={page} refetch={refetch} />
    </MainLayout>
  );
};
