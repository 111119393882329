import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

import logoImage from '@app/app/images/logo.svg';

import { styles } from './styles';

const useStyles = makeStyles(styles);

export interface Props {
  children: React.ReactNode;
  size?: 'medium' | 'large';
  decorate?: boolean;
  className?: string;
}

export function GuestLayout({ children, className, size = 'large', decorate = false }: Props) {
  const classes = useStyles();

  return (
    <div className={cx(classes.rootContainer, classes.guestRootContainer, className)}>
      <Container
        className={cx(classes.guestContainer, {
          [(classes as any)[`${size}Size`]]: size,
          [classes.guestDecorate]: decorate,
        })}
      >
        <div className={classes.guestHeader}>
          <Link className={classes.logoBlock} to="/" component={RouterLink}>
            <img className={classes.siteLogo} src={logoImage} alt="Bettermeeting" />
          </Link>
        </div>
        <div className={classes.guestContentBlock}>
          <Paper className={cx(classes.guestContent, 'guest-content')} elevation={0}>
            {children}
          </Paper>
        </div>
      </Container>
    </div>
  );
}
