import { UserEntity } from '@app/users/interfaces';

import { AUTH_ACTIONS } from '../constants';

export enum TOKEN_TYPE {
  Bearer = 'Bearer',
}

export interface Token {
  type: TOKEN_TYPE;
  token: string;
  refreshToken?: string;
  userId?: number;
  meta: Record<any, any>;
}

export interface AuthState {
  user?: UserEntity;
  token?: Token;
}

export type AuthAction =
  | { type: AUTH_ACTIONS.SET_TOKEN; payload: Token }
  | { type: AUTH_ACTIONS.SET_USER; payload: UserEntity }
  | { type: AUTH_ACTIONS.CLEAR };

export interface LocalAuthenticateData {
  email: string;
  password: string;
}
