import { Grid, Box } from '@material-ui/core';
import { useField } from 'formik';

import { Time, Props as TimeProps } from './Time';

interface Props extends TimeProps {}

export function TimeRange({ name, label, ...props }: Props) {
  const startName = `${name}.from`;
  const endName = `${name}.to`;
  const [, { touched, error }] = useField<string>(name);
  const isError: boolean = Boolean(touched && error);

  return (
    <Grid container alignItems={isError ? 'center' : 'flex-end'}>
      <Grid item xs>
        <Time {...props} label={label} name={startName} />
      </Grid>
      <Grid item xs="auto">
        <Box mb={1}>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;</Box>
      </Grid>
      <Grid item xs>
        <Time {...props} label={<>&nbsp;</>} name={endName} required={false} />
      </Grid>
    </Grid>
  );
}
