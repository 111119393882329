import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import PubSub from 'pubsub-js';

import { Debug } from '@app/debug';

import Message from './Message';
import { SNACKBAR_SUBMISSION } from '../constants';
import { MessageOptions } from '../types';

export default function SnackbarsPubSub() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    PubSub.subscribe(SNACKBAR_SUBMISSION, (msg: string, { message = '', severity, ...options }: MessageOptions) => {
      enqueueSnackbar(message, {
        ...options,
        content: key => (
          <Message onClose={() => closeSnackbar(key)} severity={severity}>
            {typeof message === 'string' ? (
              message.split('\n').map(line => <div key={line}>{line}</div>)
            ) : process.env.NODE_ENV === 'development' ? (
              <Debug value={message} />
            ) : (
              'Something went wrong!'
            )}
          </Message>
        ),
      });
    });

    return () => {
      PubSub.unsubscribe(SNACKBAR_SUBMISSION);
    };
  }, []);

  return null;
}
