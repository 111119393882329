import axios from 'axios';

import { getToken } from '@app/auth/utils/tokenStorage';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  responseType: 'json',
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
});

// Request:
apiClient.interceptors.request.use(async config => {
  if (!config.headers.common.Authorization) {
    try {
      const { token } = (await getToken()) ?? {};

      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }
    } catch (e) {
      // nothing
    }
  }

  return config;
});

// Response:
apiClient.interceptors.response.use(
  response => response.data || response,
  error => Promise.reject(error?.response?.data),
);

export default apiClient;
