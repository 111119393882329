import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { FormControlLabel, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Checkbox } from '@app/ui/forms';

import styles from '../styles';

const useStyles = makeStyles(styles);

interface Props {
  onChange?: () => void;
}

export const AvailableDays = ({ onChange }: Props) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const days = [
    { name: 'sunday', label: 'sundays' },
    { name: 'monday', label: 'mondays' },
    { name: 'tuesday', label: 'tuesdays' },
    { name: 'wednesday', label: 'wednesdays' },
    { name: 'thursday', label: 'thursdays' },
    { name: 'friday', label: 'fridays' },
    { name: 'saturday', label: 'saturdays' },
  ];

  return (
    <>
      <InputLabel>{t('user.registration.form.completeRegistration.secondStep.fieldAvailableDays')}*</InputLabel>
      <div className={cx(classes.availableDaysContainer, 'native-custom-scrollbar')}>
        {days.map(({ name, label }) => (
          <FormControlLabel
            className={classes.availableDay}
            key={name}
            control={<Checkbox className={classes.availableDayCheckbox} name={`workingDays.${name}`} />}
            label={t(`general.days.${label}`)}
            labelPlacement="bottom"
            onChange={onChange}
          />
        ))}
      </div>
    </>
  );
};
