import { AxiosRequestConfig } from 'axios';
import { CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';

import { apiClient } from '@app/query';

import { GroupSchedulerEntity, GroupScheduler } from '../types/GroupScheduler';

const PATH = '/group-schedulers';

export const getGroupSchedulers = <T = GroupSchedulerEntity[]>(
  params: CreateQueryParams = {},
  options: AxiosRequestConfig = {},
): Promise<T> => apiClient.get(PATH, { ...options, params: RequestQueryBuilder.create(params).queryObject });

export const createGroupScheduler = <T = GroupSchedulerEntity, D = GroupScheduler>(
  data: D,
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
): Promise<T> =>
  apiClient.post(`${PATH}`, data, {
    params: params && RequestQueryBuilder.create(params).queryObject,
    ...options,
  });

export const updateGroupScheduler = (
  { id, ...data }: Partial<GroupSchedulerEntity>,
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
): Promise<GroupSchedulerEntity> =>
  apiClient.patch(`${PATH}/${id}`, data, {
    params: params && RequestQueryBuilder.create(params).queryObject,
    ...options,
  });

export const deleteGroupScheduler = (
  id: number,
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
) =>
  apiClient.delete(`${PATH}/${id}`, {
    params: params && RequestQueryBuilder.create(params).queryObject,
    ...options,
  });

export const getGroupViaInviteLink = <T = GroupSchedulerEntity>(
  token: string,
  params: CreateQueryParams = {},
  options: AxiosRequestConfig = {},
): Promise<T> =>
  apiClient.get(`${PATH}/interview-invite/${token}`, {
    ...options,
    params: RequestQueryBuilder.create(params).queryObject,
  });
