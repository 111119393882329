import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getBillingPortal } from '@app/payments/api';
import { error } from '@app/snackbars';

export const useBillingPortal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('common');

  const openBillingPortal = useCallback(async () => {
    setIsLoading(true);

    try {
      const result = await getBillingPortal();

      if (result && result.url) {
        window.open(result.url, '_self');
      } else {
        throw new Error();
      }
    } catch (e) {
      error(t('general.messages.somethingWentWrong'));
    }

    setIsLoading(false);
  }, []);

  return { openBillingPortal, isLoading };
};
