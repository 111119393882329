import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';

import { Box, Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';

import { useUserUpdatePassword, useUserUpdateProfile } from '@app/users/hooks/crud';
import { UpdateUserForm, UserEntity } from '@app/users/interfaces';
import { ROLES } from '@app/users/constants/roles';
import { Loader } from '@app/ui/loader';
import { Checkbox, Text, TimeRange, Timezones } from '@app/ui/forms';
import { AvailableDays } from '@app/auth/components/completeRegistration/SecondStep/AvailableDays';
import { GoogleConnect } from '@app/auth/components/registration/GoogleConnect';
import { Confirmation, useModal } from '@app/ui/modal';
import { error, success } from '@app/snackbars';
import { RESOURCES } from '@app/availabilities/constants';
import { GoogleAccountForm } from '@app/auth/components/registration/GoogleAccountForm';
import { checkIsEmailExist } from '@app/auth/utils/checkIsEmailExist';

import { styles } from './styles';

const useStyles = makeStyles(styles);

interface Props {
  user: UserEntity;
}

export const General = ({ user }: Props) => {
  const { mutateAsync: updateUser, isLoading } = useUserUpdateProfile();
  const { mutateAsync: updatePasswordRequest } = useUserUpdatePassword();
  const [resource, setResource] = useState<undefined | RESOURCES>(user.availability.resource);
  const { t } = useTranslation('common');
  const { goBack } = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { isOpen, show, hide } = useModal();
  const respIsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const showTeamField = user.teamId;
  const disabledTeamField = user.role !== ROLES.ADMIN || !user.teamId;
  const validateTeamField = user.role === ROLES.ADMIN && user.teamId;
  const initialValues = useMemo<UpdateUserForm>(() => {
    const { firstName, lastName, email, phone, googleRejectReason, team, availability } = user;

    let meta: Record<string, any>[] = [];
    let defaultAccount;
    let workingDays;
    let workingHours;
    let from;
    let to;
    let timezoneId;

    if (availability && availability.meta) {
      defaultAccount = availability.meta.find(({ defaultAccount }) => !!defaultAccount)?.googleEmail;
      workingDays = availability.meta[0]?.workingDays;
      workingHours = availability.meta[0]?.workingHours;
      timezoneId = availability.meta[0]?.timezoneId?.id || availability.meta[0]?.timezoneId;
      if (availability.resource === RESOURCES.GOOGLE) {
        meta = availability.meta;
      }

      from = workingHours?.from <= 9 ? `0${+workingHours?.from}` : `${+workingHours?.from}`;
      to = workingHours?.to <= 9 ? `0${+workingHours?.to}` : `${+workingHours?.to}`;
    }

    return {
      firstName,
      lastName,
      workingDays,
      workingHours: { from, to },
      email,
      phone,
      googleRejectReason,
      teamName: team?.name ?? '',
      timezoneId,
      cantConnect: !!googleRejectReason,
      defaultAccount,
      meta,
      isNotificationsEnabled: user.isNotificationsEnabled,
    };
  }, [user.id]);

  const schema = useMemo(() => {
    const yupSchema: Record<any, any> = {
      firstName: yup.string().required(t('validation.required')),
      lastName: yup.string().required(t('validation.required')).nullable(),
      isNotificationsEnabled: yup.boolean().nullable(),
      workingHours: yup.object().shape(
        {
          from: yup
            .string()
            .ensure()
            // @ts-ignore
            .when('to', (to, _, { value: from }) => {
              if (from > to) {
                return schema.typeError(t('general.validations.startTimeAfter'));
              }

              return undefined;
            })
            .nullable(),
          to: yup
            .string()
            .ensure()
            // @ts-ignore
            .when('from', (from, _, { value: to }) => {
              if (from > to) {
                return schema.typeError(t('general.validations.endTimeBefore'));
              }

              return undefined;
            })
            .nullable(),
        },
        [['from', 'to']],
      ),
      ...(validateTeamField ? { teamName: yup.string().required(t('general.validations.required')) } : {}),
      meta: yup.array().of(
        yup.object().shape({
          googleEmail: yup.string().nullable(),
          googleCode: yup.string().nullable(),
        }),
      ),
    };
    if (!user.availability.meta.length) {
      yupSchema.timezoneId = yup.mixed().required(t('validation.required')).nullable();
    }
    return yup.object().shape(yupSchema);
  }, [validateTeamField, user]);

  const onSubmit = async (formData: UpdateUserForm, { setFieldValue }: FormikHelpers<UpdateUserForm>) => {
    const data = { ...formData };

    if (formData.googleCode) {
      data.cantConnect = false;
    }

    if (data.timezoneId && typeof data.timezoneId === 'object') {
      data.timezoneId = data.timezoneId.id;
    }

    await updateUser(data);

    setFieldValue('googleCode', '');

    success(t('user.profile.tabs.general.successUpdateMessage'));
  };

  const updateUserPassword = async () => {
    try {
      await updatePasswordRequest(undefined);

      success(t('user.profile.tabs.general.changePassword.successMessage'));

      hide();
    } catch (e) {
      error(t('general.messages.somethingWentWrong'));
    }
  };

  const availabilityLink = `${window.location.origin}/a/${user.availability.token}`;

  const googleFooterContent = useMemo(() => {
    if (resource) {
      return (
        <div className={classes.availabilityBlock}>
          <Typography color="textSecondary">
            <Trans
              t={t}
              defaults={`user.profile.tabs.general.${
                resource === RESOURCES.GOOGLE ? 'googleResource' : 'notGoogleResource'
              }`}
              values={{
                resource: t(`user.profile.tabs.general.resources.${resource}`),
                secondResource: t(`user.profile.tabs.general.secondResources.${resource}`),
                thirdResource: t(`user.profile.tabs.general.thirdResources.${resource}`),
              }}
              components={{ b: <b /> }}
            />
          </Typography>
        </div>
      );
    }

    return undefined;
  }, [resource]);

  return (
    <>
      <Formik<UpdateUserForm> onSubmit={onSubmit} initialValues={initialValues} validationSchema={schema}>
        {({ handleSubmit, setFieldValue, values }) => (
          <Form noValidate onSubmit={handleSubmit}>
            {isLoading && <Loader showOverlay />}
            <Grid container spacing={4}>
              <Grid item xs={12} sm={9}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Text
                      name="firstName"
                      label={t('user.profile.tabs.general.fields.firstName')}
                      required
                      margin="none"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Text
                      name="lastName"
                      label={t('user.profile.tabs.general.fields.lastName')}
                      required
                      margin="none"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Text
                      disabled
                      name="email"
                      label={t('user.profile.tabs.general.fields.email')}
                      required
                      margin="none"
                    />
                  </Grid>
                  {respIsMobile && (
                    <Grid item xs={12}>
                      <Button color="primary" onClick={() => show()}>
                        <span className={classes.changePasswordIcon}>
                          <VpnKeyOutlinedIcon />
                        </span>
                        {t('user.profile.tabs.general.changePassword.button')}
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <Text name="phone" label={t('user.profile.tabs.general.fields.phone')} margin="none" />
                  </Grid>
                  {showTeamField ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Text
                          name="teamName"
                          label={t('user.profile.tabs.general.fields.teamName')}
                          required
                          margin="none"
                          disabled={disabledTeamField}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Timezones
                          name="timezoneId"
                          label={t('user.registration.form.completeRegistration.secondStep.fieldTimeZone')}
                          required
                          margin="none"
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TimeRange
                          name="workingHours"
                          label={t('user.registration.form.completeRegistration.secondStep.fieldAvailableHours')}
                          required
                          margin="none"
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={6}>
                        <TimeRange
                          name="workingHours"
                          label={t('user.registration.form.completeRegistration.secondStep.fieldAvailableHours')}
                          required
                          margin="none"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Timezones
                          name="timezoneId"
                          label={t('user.registration.form.completeRegistration.secondStep.fieldTimeZone')}
                          required
                          margin="none"
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <AvailableDays />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom>
                      {t('user.profile.tabs.general.fields.notifications.title')}
                    </Typography>
                    <Checkbox
                      name="isNotificationsEnabled"
                      placeholder={t('user.profile.tabs.general.fields.notifications.title')}
                      label={t('user.profile.tabs.general.fields.notifications.label')}
                    />
                  </Grid>
                  {!!values.meta.length && resource === RESOURCES.GOOGLE && (
                    <Grid item xs={12}>
                      <GoogleAccountForm accounts={values.meta} setFieldValue={setFieldValue} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <GoogleConnect
                      onUpdate={(field, value) => {
                        if (value) {
                          setResource(RESOURCES.GOOGLE);
                        } else {
                          setResource(undefined);
                        }
                        if (field === 'meta' && value && typeof value === 'object') {
                          if (!checkIsEmailExist(values.meta, value.googleEmail)) {
                            if (!values.meta.length) {
                              setFieldValue('defaultAccount', value.googleEmail);
                            }
                            setFieldValue(field, [
                              ...values.meta,
                              {
                                ...value,
                                timezoneId: values.timezoneId,
                                defaultAccount: !values.meta.length,
                              },
                            ]);
                          } else {
                            error(t('user.registration.form.firstStep.googleConnect.emailExist'));
                          }
                        } else {
                          setFieldValue(field, value);
                        }
                      }}
                      isDisabled={values?.meta?.length >= 4}
                      calendarsCount={values.meta.length}
                      cantConnect={values.cantConnect}
                      googleRejectReason={values.googleRejectReason}
                      isRecruiter
                      isProfile
                      availabilityLink={availabilityLink}
                      FooterContent={googleFooterContent}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {!respIsMobile && (
                <Grid item sm={3}>
                  <Button color="primary" className={classes.changePasswordButton} onClick={() => show()}>
                    <span className={classes.changePasswordIcon}>
                      <VpnKeyOutlinedIcon />
                    </span>
                    {t('user.profile.tabs.general.changePassword.button')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <div className={classes.divider} />
            <Box
              display={respIsMobile ? 'block' : 'flex'}
              justifyContent={respIsMobile ? 'center' : 'space-between'}
              alignItems="center"
            >
              <Button color="primary" fullWidth={respIsMobile} onClick={() => goBack()}>
                {t('general.buttons.back')}
              </Button>
              <Button type="submit" color="primary" variant="contained" fullWidth={respIsMobile}>
                {t('general.buttons.save')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Confirmation
        onAccept={updateUserPassword}
        onCancel={hide}
        isOpen={isOpen}
        description={t('user.profile.tabs.general.changePassword.modal.description', { email: user.email })}
        title={t('user.profile.tabs.general.changePassword.modal.title')}
        acceptButton={t('user.profile.tabs.general.changePassword.modal.sendButton')}
      />
    </>
  );
};
