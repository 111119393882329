import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useTheme } from '@material-ui/core/styles';
import { Box, Typography, useMediaQuery } from '@material-ui/core';

import {
  useAvailabilityRequest,
  useAvailabilityRequestDelete,
  useAvailabilityRequestUpdate,
} from '@app/availabilities/hooks/crudRequest';
import { Loader } from '@app/ui/loader';
import { error, success } from '@app/snackbars';
import { TabPanel, Tabs, useSteps } from '@app/ui/tabs';

import { Confirmation, useModal } from '@app/ui/modal';
import { Google } from './Google';
import { Calendly } from './Calendly';
import { Manual } from './Manual';
import { AVAILABILITY_REQUEST_ITEM_KEY, RESOURCES, STATUSES } from '../../constants';
import { Availability } from '../../types/Availability';

export const Request = () => {
  const theme = useTheme();
  const { t } = useTranslation('common');
  const respMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { token } = useParams<{ token: string }>();
  const queryClient = useQueryClient();

  const [nextTab, setNextTab] = useState<number | string | undefined>();
  const { isOpen, hide: hideResetConfirm, show: showResetConfirm } = useModal();

  const { step, onChange: handleTabsChange, setStep } = useSteps(RESOURCES.GOOGLE);

  const renderTabs = useMemo(
    () => [
      {
        id: RESOURCES.GOOGLE,
        label: t('availabilities.request.tabs.google'),
      },
      {
        id: RESOURCES.CALENDLY,
        label: t('availabilities.request.tabs.calendly'),
      },
      {
        id: RESOURCES.MANUAL,
        label: t('availabilities.request.tabs.manual'),
      },
    ],
    [t],
  );

  const { data = {}, isLoading: isRequestLoading } = useAvailabilityRequest(token, {
    onError: e => {
      error(e?.message as string);
    },
  });

  const { mutateAsync: update, isLoading: isUpdateLoading } = useAvailabilityRequestUpdate({
    onError: e => {
      error(e?.message as string);
    },
  });

  const { mutateAsync: destroy, isLoading: isDeleteLoading } = useAvailabilityRequestDelete({
    onSuccess: response => {
      queryClient.setQueryData([AVAILABILITY_REQUEST_ITEM_KEY, token], {
        ...data,
        resource: undefined,
        meta: undefined,
        ...response,
      });

      if (nextTab) {
        handleTabsChange(undefined, nextTab);
        setNextTab(undefined);
        hideResetConfirm();
      }

      success(t('availabilities.request.messages.deleted'));
    },
    onError: e => {
      error(e?.message as string);
    },
  });

  useEffect(() => {
    if (data.resource) {
      setStep(data.resource);
    }
  }, [data.resource]);

  const handleUpdate = useCallback(
    async (resource: RESOURCES | null, meta: Availability['meta'], withMessage = true) => {
      if (resource === null) {
        await destroy({ token });
      } else {
        const response = await update({ token, meta, resource });

        queryClient.setQueryData([AVAILABILITY_REQUEST_ITEM_KEY, token], response);

        if (withMessage) {
          success(t('availabilities.request.messages.saved'));
        }
      }
    },
    [update],
  );

  const handleDelete = useCallback(async () => {
    await destroy({ token });
  }, [destroy, token]);

  const handleResetRequest = useCallback(
    (e, tab) => {
      if (step !== tab && (data.resource || (data.status && data.status === STATUSES.ACTIVE))) {
        setNextTab(tab);
        showResetConfirm();
      } else {
        handleTabsChange(undefined, tab);
      }
    },
    [nextTab, data, step],
  );

  const isLoading = isDeleteLoading || isUpdateLoading || isRequestLoading

  return (
    <>
      {isLoading && <Loader showOverlay />}
      <Box mb={4}>
        <Typography className="title-large" variant="h1" gutterBottom>
          {t('availabilities.request.title')}
        </Typography>
        <Typography variant="subtitle1">
          <Trans>{t('availabilities.request.description')}</Trans>
        </Typography>
      </Box>
      <Tabs
        value={step}
        tabs={renderTabs}
        disabled={isLoading}
        onChangeTab={handleResetRequest}
        variant={respMobile ? 'scrollable' : 'fullWidth'}
      />
      <TabPanel value={step} index={RESOURCES.GOOGLE}>
        {!isRequestLoading && <Google data={data} onDelete={handleDelete} onUpdate={handleUpdate} />}
      </TabPanel>
      <TabPanel value={step} index={RESOURCES.CALENDLY}>
        <Calendly data={data} onDelete={handleDelete} onUpdate={handleUpdate} />
      </TabPanel>
      <TabPanel value={step} index={RESOURCES.MANUAL}>
        <Manual data={data} onDelete={handleDelete} onUpdate={handleUpdate} />
      </TabPanel>
      <Confirmation
        onAccept={handleDelete}
        onCancel={hideResetConfirm}
        isOpen={isOpen}
        isLoading={isLoading}
        title={t('availabilities.resetConfirm.body', {
          resource: t(`availabilities.resetConfirm.resource.${nextTab}`),
        })}
        description=""
        acceptButton={t('availabilities.resetConfirm.confirm')}
      />
    </>
  );
};
