import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { IconButton } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import styles from './styles';

const useStyles = makeStyles(styles);

export interface Props {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

export const PaginationActions = ({ count, page, rowsPerPage, onChangePage }: Props) => {
  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page + 1);
  };

  const classes = useStyles();

  return (
    <div className={classes.buttonsContainer}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        classes={{ root: classes.button, disabled: classes.buttonDisabled }}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        classes={{ root: classes.button, disabled: classes.buttonDisabled }}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </div>
  );
};
