import { createStyles } from '@material-ui/core/styles';

import { textPrimary, boxShadow, bgGreyLight, borderSecondaryColor } from '@app/app/configs/variables';

export const style = () =>
  createStyles({
    selectWrapper: {},
    selectMain: {
      '&:focus': {
        backgroundColor: 'inherit',
        borderRadius: 'inherit',
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    selectPaper: {
      margin: '8px 0',
      padding: '6px 0',
      overflowX: 'hidden',
      overflowY: 'auto',
      boxShadow,

      '& .select-list': {
        padding: 0,
        maxHeight: '200px !important',

        '& .select-option': {
          fontSize: 14,
          minHeight: 'auto',
          color: textPrimary,
          padding: '6px 12px',

          '&[data-focus="true"]': {
            backgroundColor: bgGreyLight,
          },
          '&[aria-selected="true"], &[data-focus="true"][aria-selected="true"]': {
            fontWeight: 500,
          },
          '&:not(:last-child)': {
            borderBottom: `1px solid ${borderSecondaryColor}`,
          },
          '& .select-option-icon': {
            fontSize: '1em',
            marginLeft: '0.35em',
            marginTop: -2,
          },
        },
        '& .select-option-button:not(.select-option-disabled)': {},
        '& .select-option-disabled': {},
      },
    },
    selectArrowDownIcon: {
      top: '50%',
      margin: '-10px 8px 0 0',
      fontSize: '1.25rem',
    },
  });
