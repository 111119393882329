// @ts-nocheck
import * as yup from 'yup';
import get from 'lodash.get';

yup.setLocale({
  mixed: {
    required: { key: 'required' },
  },
  string: {
    email: { key: 'email' },
    max: ({ max }: { max: number }) => ({ key: 'max', values: { max } }),
    min: ({ min }: { min: number }) => ({ key: 'min', values: { min } }),
  },
  number: {
    max: ({ max }: { max: number }) => ({ key: 'maxNumber', values: { max } }),
  },
  array: {
    min: ({ min }: { min: number }) => ({ key: 'arrayMin', values: { min } }),
  },
});

const uniquePropertyTest = function(testedObject, propertyName, message, compareFunction = null) {
  if (
    this.parent
      // Exclude the same object (by reference)
      .filter(object => object !== testedObject)

      // Check for property match among some of the other objects
      .some(object => {
        const objectValue = get(object, propertyName);
        const testedObjectValue = get(testedObject, propertyName);

        return compareFunction ? compareFunction(objectValue, testedObjectValue) : objectValue === testedObjectValue;
      })
  ) {
    throw this.createError({
      path: `${this.path}.${propertyName}`,
      message,
    });
  }

  return true;
};

yup.addMethod(yup.object, 'unique', function(propertyName, message, compareFunction = null) {
  return this.test('unique', message, function(value) {
    return uniquePropertyTest.call(this, value, propertyName, message, compareFunction);
  });
});

export default yup;
