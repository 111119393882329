import { useState } from 'react';

import { List } from '@app/availabilities/components/List';
import { GroupScheduler } from '@app/groupScheduler/components/List';
import { MainLayout } from '@app/app/components/MainLayout';
import { useSearch } from '@app/availabilities/hooks/useSearch';
import { AvailabilityEntity, AvailabilityListItem } from '@app/availabilities/types/Availability';
import { usePlan } from '@app/payments/hooks/usePlan';
import { GroupSchedulerEntity } from '@app/groupScheduler/types/GroupScheduler';
import { STATUSES } from '@app/groupScheduler/constants';

function formList(items: AvailabilityEntity[]): AvailabilityListItem[] {
  const groupSchedulersData: Record<number, GroupSchedulerEntity> = {};

  items.forEach(({ groupSchedulers = [] }) => {
    groupSchedulers.forEach(groupScheduler => {
      if (
        !groupSchedulersData[groupScheduler.id] ||
        (groupSchedulersData[groupScheduler.id].status !== groupScheduler.status &&
          groupScheduler.status === STATUSES.INCOMPLETE)
      ) {
        groupSchedulersData[groupScheduler.id] = { ...groupScheduler };
      }
    });
  });

  return [
    ...items.map(item => ({ item, isGroupLink: false })),
    ...Object.values(groupSchedulersData).map(item => ({
      item,
      isGroupLink: true,
    })),
  ];
}

export const Availability = () => {
  const { data, search, refetch } = useSearch();
  const items = formList(data);
  const [groupLinkMembers, setGroupLinkMembers] = useState<AvailabilityEntity[]>([]);
  const { isBasic } = usePlan();

  const showLimitPopup = isBasic && items.length > 5;

  return (
    <MainLayout
      rightSidebar={
        <GroupScheduler
          setGroupLinkMembers={setGroupLinkMembers}
          groupLinkMembers={groupLinkMembers}
          onSubmit={refetch}
          showLimitPopup={showLimitPopup}
        />
      }
    >
      <List
        items={items}
        setGroupLinkMembers={setGroupLinkMembers}
        groupLinkMembers={groupLinkMembers}
        onSearch={search}
        refetch={refetch}
        showLimitPopup={showLimitPopup}
      />
    </MainLayout>
  );
};
