import { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Grid, Paper } from '@material-ui/core';
import GoogleButton from 'react-google-button';
import { useGoogleLogin } from '@react-oauth/google';

import { error } from '@app/snackbars';
import { useModal } from '@app/ui/modal';
import { TimeRange } from '@app/ui/forms';
import { AvailableDays } from '@app/auth/components/completeRegistration/SecondStep/AvailableDays';
import { completeRegistrationInitialValues } from '@app/auth/constants/registration';
import { SCOPE, MAX_GOOGLE_CALENDARS } from '@app/google/constants';
import { GoogleAccountForm } from '@app/auth/components/registration/GoogleAccountForm';

import { styles } from '../styles';
import { HelpModal } from './HelpModal';
import { RESOURCES } from '../../../constants';
import { Availability } from '../../../types/Availability';

const useStyles = makeStyles(styles);

export interface GoogleProps {
  readonly onUpdate: (resource: RESOURCES, data: Availability['meta'], withMessage?: boolean) => Promise<void>;
  readonly onDelete: () => Promise<void>;
  readonly data: Partial<Availability>;
}

const getFormValues = (data: Partial<Availability>) => {
  let workingDaysResult = completeRegistrationInitialValues.workingDays;
  let workingHoursResult = completeRegistrationInitialValues.workingHours;

  if (data?.meta && data?.meta.length) {
    const { workingHours, workingDays } = data.meta[0];

    if (workingHours) {
      const from = workingHours.from <= 9 ? `0${+workingHours?.from}` : `${+workingHours?.from}`;
      const to = workingHours.to <= 9 ? `0${+workingHours?.to}` : `${+workingHours?.to}`;

      workingHoursResult = { from, to };
    }

    if (workingDays) {
      workingDaysResult = workingDays;
    }
  }

  return { workingDays: workingDaysResult, workingHours: workingHoursResult };
};

const getDefaultAccount = (accounts: Availability['meta'] = []) =>
  accounts.find(({ defaultAccount }) => defaultAccount)?.googleEmail;

export const Google = ({ data, onUpdate }: GoogleProps) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { isOpen, hide, show } = useModal();
  const initialFormValues = useMemo(
    () => ({ ...getFormValues(data), defaultAccount: getDefaultAccount(data.meta ?? []) }),
    [data],
  );

  // @ts-ignore
  const schema = useMemo(
    () =>
      yup.object().shape({
        workingHours: yup.object().shape(
          {
            from: yup
              .string()
              .ensure()
              // @ts-ignore
              .when('to', (to, _, { value: from }) => {
                if (from > to) {
                  return schema.typeError(t('general.validations.startTimeAfter'));
                }

                return undefined;
              })
              .nullable(),
            to: yup
              .string()
              .ensure()
              // @ts-ignore
              .when('from', (from, _, { value: to }) => {
                if (from > to) {
                  return schema.typeError(t('general.validations.endTimeBefore'));
                }

                return undefined;
              })
              .nullable(),
          },
          [['from', 'to']],
        ),
        meta: yup.array().of(
          yup.object().shape({
            googleEmail: yup.string().nullable(),
            googleCode: yup.string().nullable(),
          }),
        ),
        defaultAccount: yup.string().required(t('general.validations.required')),
      }),
    [],
  );

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async ({ code }: { code: string }) => {
      if (code) {
        const tempMeta = (data?.meta || []).filter(({ googleEmail }) => !!googleEmail);
        await onUpdate(RESOURCES.GOOGLE, [
          ...tempMeta,
          {
            code,
            defaultAccount: !tempMeta.length,
            ...getFormValues(data),
          },
        ]);
      }
    },
    onError: ({ error_description }) => {
      if (error_description) {
        error(error_description);
      }
    },
    scope: SCOPE,
    flow: 'auth-code',
  });

  const onSubmit = useCallback(
    (formData: { workingDays: any; workingHours: any; meta?: Availability['meta'] }) => {
      if (data.resource) {
        onUpdate(
          RESOURCES.GOOGLE,
          ((data && data.meta) || []).map(account => ({
            ...account,
            workingHours: formData.workingHours,
            workingDays: formData.workingDays,
          })),
        );
      }
    },
    [data],
  );

  const memoizedMeta = useMemo(() => data.meta ?? [], [data]);

  const handleUpdateField = useCallback(
    (field, values) => {
      if (field === 'meta') {
        onUpdate(RESOURCES.GOOGLE, values);
      }
    },
    [onUpdate],
  );

  return (
    <Formik initialValues={initialFormValues} onSubmit={onSubmit} validationSchema={schema} enableReinitialize>
      {({ handleSubmit }) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md>
              {data.resource === RESOURCES.GOOGLE && !!memoizedMeta.length && (
                <GoogleAccountForm accounts={memoizedMeta} setFieldValue={handleUpdateField} />
              )}
            </Grid>
          </Grid>
          <Paper className={classes.paper} elevation={0}>
            <Typography variant="h3" gutterBottom>
              {t('availabilities.request.google.title')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md>
                {t('availabilities.request.google.description')}{' '}
                <Button color="primary" onClick={show}>
                  {t('availabilities.request.buttons.howSeeCalendar')}
                </Button>
              </Grid>
              <Grid item xs={12} md="auto">
                <GoogleButton
                  onClick={handleGoogleLogin}
                  disabled={memoizedMeta.length >= MAX_GOOGLE_CALENDARS}
                  label={t('availabilities.request.buttons.connectGoogle')}
                  type="dark"
                />
              </Grid>
              {data.resource && (
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={7}>
                      <TimeRange
                        name="workingHours"
                        label={t('user.registration.form.completeRegistration.secondStep.fieldAvailableHours')}
                        required
                        margin="none"
                        onChange={() => handleSubmit()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AvailableDays onChange={() => handleSubmit()} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <HelpModal isOpen={isOpen} hide={hide} />
          </Paper>
        </>
      )}
    </Formik>
  );
};
