import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Confirmation } from '@app/ui/modal';
import { useUpgradeToPro } from '@app/payments/hooks/useUpgradeToPro';
import { Loader } from '@app/ui/loader';
import { usePlanInfo } from '@app/payments/hooks/crud';
import { PAYMENT_PLANS } from '@app/payments/constants';
import { numberWithCommas } from '@app/payments/utils/formatter';
import { usePlan } from '@app/payments/hooks/usePlan';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  plan?: PAYMENT_PLANS;
}

export const UpgradePlanConfirmation = ({ onClose, isOpen, plan = PAYMENT_PLANS.PRO }: Props) => {
  const { upgradePlan, isLoading: isUpgrading } = useUpgradeToPro();
  const { t } = useTranslation('common');
  const { mutate, data: { price } = { teamMembersCount: 1, price: 0 }, isLoading } = usePlanInfo();
  const { isProTrial } = usePlan();

  const fromPlan = isProTrial ? t('payments.plans.pro') : t('payments.plans.basic');
  const fromCost = isProTrial ? 'trial period' : 'free';

  useEffect(() => {
    if (isOpen) {
      mutate({ plan });
    }
  }, [isOpen]);

  const onAccept = async () => {
    await upgradePlan();
  };

  return (
    <Confirmation
      onAccept={onAccept}
      onCancel={onClose}
      isOpen={isOpen}
      isLoading={isLoading || isUpgrading}
      title={t('payments.upgradeConfirmation.title')}
      description={
        <>
          <Trans
            t={t}
            defaults="payments.upgradeConfirmation.description"
            components={{ br: <b /> }}
            values={{
              fromPlan,
              fromCost,
              toPlan: t('payments.plans.pro'),
              price: `$${numberWithCommas(price / 100)}`,
            }}
          />
          {(isLoading || isUpgrading) && <Loader showOverlay />}
        </>
      }
      acceptButton={t('general.buttons.upgrade')}
    />
  );
};
