import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';

import { Box, Button, SvgIcon, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import EmailIcon from '@material-ui/icons/Email';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { Duration, InterviewEntity } from '@app/interview/types/Interview';
import { InterviewSelectDate } from '@app/interview/components/New/ScheduleInterview/SelectDate';
import { Modal } from '@app/ui/modal';
import { Loader } from '@app/ui/loader';
import { useInterviewReschedule } from '@app/interview/hooks/crud';
import { error, success } from '@app/snackbars';
import { useFreeSlots } from '@app/interview/hooks/useFreeSlots';

import { styles } from '@app/interview/components/New/ScheduleInterview/styles';

const useStyles = makeStyles(styles);

interface Props {
  readonly hide: () => void;
  readonly data: InterviewEntity;
  readonly participants: number[];
  readonly refetch: () => void;
  readonly isCalendly: boolean;
}

export const Reschedule = ({
  hide,
  data: { time, title, id, date, sentFrom },
  participants,
  refetch,
  isCalendly,
}: Props) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const theme = useTheme();
  const respIsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const initialValues = { id, date, time: null };

  const { mutateAsync, isLoading: isUpdating } = useInterviewReschedule();

  const durationOptions = {
    30: t('availabilities.time.30'),
    60: t('availabilities.time.60'),
    90: t('availabilities.time.90'),
  };

  const handleSubmit = useCallback(async formData => {
    try {
      await mutateAsync(formData);

      refetch();

      hide();

      success(t('interviews.form.reschedule.successMessage'));
    } catch (e) {
      error(t('general.messages.somethingWentWrong'));
    }
  }, []);
  const duration = ((time.to - time.from) * 60) as Duration;

  const { slots, timezones, loadFreeSlots, isLoading } = useFreeSlots({
    duration,
    availabilities: participants,
    isCalendly,
  });

  const onCalendarChange = useCallback(
    date => {
      if (isCalendly) {
        return loadFreeSlots(date);
      }

      return Promise.resolve();
    },
    [isCalendly, loadFreeSlots],
  );

  return (
    <Modal
      isOpen
      hide={hide}
      maxWidth="xl"
      paperClass={classes.interviewModal}
      title={<Typography variant="h1">{t('interviews.form.reschedule.title')}</Typography>}
      description={
        <div className={classes.descriptionContainer}>
          <div>
            <SvgIcon className={classes.descriptionIcon} component={SearchIcon} fontSize="small" /> {title}
          </div>
          <div className={classes.descriptionDuration}>
            <SvgIcon className={classes.descriptionIcon} component={AccessTimeIcon} fontSize="small" />
            {durationOptions[duration]}
          </div>
          {!!sentFrom && (
            <div className={classes.descriptionDuration}>
              <SvgIcon className={classes.descriptionIcon} component={EmailIcon} fontSize="small" />
              {sentFrom}
            </div>
          )}
        </div>
      }
    >
      {isUpdating && <Loader showOverlay />}
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ values }) => (
          <Form>
            <InterviewSelectDate
              isLoading={isLoading}
              slots={slots}
              timezones={timezones}
              onLoad={loadFreeSlots}
              onCalendarChange={onCalendarChange}
              isCalendly={isCalendly}
            />
            <div className={classes.divider} />
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth={respIsMobile}
                disabled={!values.date || !values.time}
              >
                {t('general.buttons.reschedule')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
