import { createStyles, Theme } from '@material-ui/core/styles';

import { borderPrimaryColor } from '@app/app/configs/variables';

export const styles = ({ breakpoints }: Theme) =>
  createStyles({
    infoContainer: {
      border: `1px solid ${borderPrimaryColor}`,
      borderRadius: 8,
      padding: '30px 38px 27px 30px !important',
    },
    infoContainerWithBilling: {
      [breakpoints.up('md')]: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: 'none',
      },
    },
    header: {
      marginBottom: 12,

      [breakpoints.up('md')]: {
        textAlign: 'left',
      },
    },
    item: {
      display: 'flex',
      justifyContent: 'flex-start',

      [breakpoints.up('md')]: {
        justifyContent: 'center',

        '&:not(:last-child)': {
          borderRight: `1px solid ${borderPrimaryColor}`,
        },
        '&:first-child': {
          justifyContent: 'flex-start',
        },
      },
    },
    description: {
      marginTop: 20,
      marginBottom: 15,
    },
    billingOwnerBlockContainer: {
      border: `1px solid ${borderPrimaryColor}`,
      borderRadius: 8,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      padding: '30px 38px 27px 30px !important',
    },
    billingOwnerContainer: {
      marginBottom: 14,
      flexDirection: 'column',

      [breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    billingOwnerName: {
      marginRight: 16,
    },
    billingOwnerEmail: {
      wordBreak: 'break-all',
    },
    upgradeButton: {
      textAlign: 'right',
    },
    downgradeButton: {
      display: 'flex',
      justifyContent: 'space-between',
      textAlign: 'left',
    },
    updateSoonContainer: {
      minWidth: 115,
      height: 31,
      backgroundColor: '#EDF2FD',
      borderRadius: 6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 12,
      padding: '9px 12px 8px 10px',
    },
    totalPriceIcon: {
      width: 18,
      height: 18,
      marginLeft: 8,
      cursor: 'pointer',
    },
  });
