export const INTERVIEWS_LIST_KEY = 'interviewsList';
export const INTERVIEWS_PARTICIPANTS_LIST_KEY = 'interviewsParticipantsList';

export enum STATUSES {
  SCHEDULED = 'scheduled',
  FINISHED = 'finished',
  CANCELLED = 'canceled',
}

export enum PARTICIPANT_TYPES {
  CANDIDATE = 'candidate',
  INTERVIEWER = 'interviewer',
  RECRUITER = 'recruiter',
}

export const FREE_SLOTS_KEY_FORMAT = 'YYYY-M';
export const CALENDLY_FREE_SLOTS_KEY_FORMAT = 'YYYY-MM-DD';
export const FREE_SLOTS_DAY_FORMAT = 'dddd, MMMM D';

export enum INVITE_LINK_TYPE {
  AVAILABILITY = 'a',
  GROUP_LINK = 'g',
}

export enum INVITE_LINK_SEND_ME {
  YES = 'y',
  NO = 'n',
}

export const MIN_LENGTH_FIELD = 3;
export const MAX_LENGTH_FIELD = 255;

export interface EditingField {
  name: string;
  value: string;
  isMultiline: boolean;
  isFieldRequired: boolean;
}

export const userQueryFields = ['email', 'name', 'id', 'status', 'resource', 'meta'];
