import { ReactNode, memo } from 'react';
import { CircularProgress } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface MiniLoaderProps {
  readonly isLoading?: boolean;
  readonly message?: string;
  readonly children?: ReactNode;
}

export const CardLoader = memo(({ isLoading = true, message, children }: MiniLoaderProps) => {
  const classes = useStyles();
  return (
    <div className={classes.miniLoaderRoot}>
      {isLoading && (
        <div className={classes.miniLoaderSpin}>
          {message && <div className={classes.miniLoaderMessage}>{message}</div>}
          <CircularProgress color="inherit" size={16} />
        </div>
      )}
      {children}
    </div>
  );
});
