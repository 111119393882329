import { Timezone } from '@app/timezones/interfaces';
import { InterviewParticipantsEntity } from '@app/interview/types/InterviewsParticipants';
import { PARTICIPANT_TYPES } from '@app/interview/constants';
import { AvailabilityEntityForm } from '@app/availabilities/types/Availability';

export function getUserTimezoneOffset(timezone?: Timezone) {
  return timezone ? timezone.offset : -new Date().getTimezoneOffset() / 60;
}

export function getUserTimezoneName(userOffset: number, timezone?: Timezone) {
  return timezone
    ? `(${timezone.gmtName})${timezone.shortNameKeyForUSA ? ` ${timezone.shortNameKeyForUSA}` : ''}`
    : `(GMT${userOffset < 0 ? '-' : '+'}${Math.abs(userOffset) < 10 ? '0' : ''}${Math.abs(userOffset)}:00)`;
}

export const getParticipantsByType = (
  participants: InterviewParticipantsEntity[] = [],
  type: PARTICIPANT_TYPES[],
): AvailabilityEntityForm[] | [] => {
  const filteredParticipant = participants.filter(p => type.includes(p.type));
  const result: AvailabilityEntityForm[] = [];

  if (filteredParticipant.length) {
    filteredParticipant.forEach(p => {
      if (p.availability) {
        result.push({ ...p.availability, isDisabled: true });
      } else {
        // @ts-ignore
        result.push({ email: p.email, name: p.name, id: p.id, isDisabled: true });
      }
    });
  }
  return result;
};

export const getCandidates = (participants: InterviewParticipantsEntity[] = []): AvailabilityEntityForm[] | [] =>
  getParticipantsByType(participants, [PARTICIPANT_TYPES.CANDIDATE]);

export const getInterviewers = (participants: InterviewParticipantsEntity[] = []): AvailabilityEntityForm[] | [] =>
  getParticipantsByType(participants, [PARTICIPANT_TYPES.INTERVIEWER, PARTICIPANT_TYPES.RECRUITER]);
