import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Clear } from '@material-ui/icons';
import { Button, Grid, IconButton } from '@material-ui/core';

import { Text, useFieldArray } from '@app/ui/forms';

import { ROLES, STATUSES } from '../../../constants';
import { Availability } from '../../../types/Availability';

import { styles } from './styles';

const useStyles = makeStyles(styles);

export interface AvailabilitiesProps {
  readonly name: string;
  readonly sendRequest: boolean;
}

export const Availabilities = ({ name, sendRequest }: AvailabilitiesProps) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const [{ value: availabilities = [] }, , { push, remove }] = useFieldArray<Partial<Availability>>(name);

  const options = useMemo(
    () => [ROLES.CANDIDATE, ROLES.INTERVIEWER].map(id => ({ id, name: t(`availabilities.fields.role.options.${id}`) })),
    [t],
  );

  return (
    <>
      {availabilities.map((availability, index) => (
        <div className={cx(classes.availabilityRow, { [classes.availabilityRowFirst]: !index })} key={index}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Text
                className={classes.availabilityField}
                name={`${name}.${index}.email`}
                label={t('general.fields.email.label')}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Text
                className={classes.availabilityField}
                name={`${name}.${index}.name`}
                label={t('general.fields.name.label')}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Text
                className={classes.availabilityField}
                select
                options={options}
                name={`${name}.${index}.role`}
                label={t('availabilities.fields.role.label')}
                margin="none"
              />
            </Grid>
          </Grid>
          {availabilities.length > 1 && (
            <IconButton
              className={cx(classes.availabilityDeleteButton, 'button-danger')}
              size="small"
              onClick={() => remove(index)}
            >
              <Clear />
            </IconButton>
          )}
        </div>
      ))}
      {sendRequest && (
        <Button
          color="primary"
          onClick={() => push({ name: '', email: '', role: ROLES.CANDIDATE, status: STATUSES.WAITING })}
        >
          {t('availabilities.buttons.addParticipants')}
        </Button>
      )}
    </>
  );
};
