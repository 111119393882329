import React, { memo, useMemo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiChip, { ChipProps } from '@material-ui/core/Chip';

import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import { styles } from '../styles';

const useStyles = makeStyles(styles);

export interface Props extends ChipProps {
  colors?: 'primary' | 'secondary' | 'danger';
  status?: 'waiting' | 'active' | 'expired' | 'incomplete' | 'finished' | 'disconnected' | 'scheduled' | 'canceled';
  iconOnly?: boolean;
  round?: boolean;
  className?: string;
}

const Chip = ({ label, variant, size, colors, onDelete, icon, iconOnly, status, className = '', round }: Props) => {
  const classes = useStyles();

  const statusIconMap = useMemo(() => {
    switch (status) {
      case 'waiting':
        return <WatchLaterOutlinedIcon />;
      case 'active':
      case 'scheduled':
        return <DoneIcon />;
      case 'expired':
        return <CloseIcon />;
      case 'incomplete':
        return <ReportProblemOutlinedIcon />;
      case 'finished':
        return <ScheduleIcon />;
      case 'disconnected':
      case 'canceled':
        return <NotInterestedIcon />;
      default:
        return undefined;
    }
  }, [status]);

  return (
    <MuiChip
      classes={{
        root: cx(classes.chipRoot, className, 'chip', {
          [classes.chipIconOnly]: iconOnly,
          [classes.chipRound]: round,
          [(classes as any)[`${colors}Color`]]: colors,
          [(classes as any)[`${status}Status`]]: status,
        }),
        deletable: classes.chipDeletable,
        outlined: classes.chipOutlined,
        icon: classes.chipIcon,
        label: classes.chipLabel,
      }}
      icon={icon || statusIconMap}
      onDelete={onDelete}
      variant={variant}
      size={size}
      label={label}
    />
  );
};

export default memo(Chip);
