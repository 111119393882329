import { useEffect } from 'react';

export const LouAssistScript = () => {
  useEffect(() => {
    if (process.env.REACT_APP_LOU_ASSIST_SCRIPT) {
      const script = document.createElement('script');

      script.src = process.env.REACT_APP_LOU_ASSIST_SCRIPT;
      script.type = 'text/javascript';
      script.async = true;

      document.head.prepend(script);
    }
  });
  return null;
};
