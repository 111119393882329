import { createStyles } from '@material-ui/core/styles';

import { colorGreyDark, colorBlack } from '@app/app/configs/variables';

export const styles = () =>
  createStyles({
    calendarContainer: {
      marginBottom: 23,
      '& .fc-timegrid-event.fc-v-event': {
        backgroundColor: '#EDF2FD',
        borderRadius: 0,
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',

      '& :first-child': {
        marginRight: 10,
        display: 'block',
        textAlign: 'center',
      },
    },
    eventContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    dayHeaderContainer: {
      textAlign: 'left',
    },
    dayHeaderDayName: {
      fontSize: 12,
      color: colorGreyDark,
      fontWeight: 'normal',
    },
    dayHeaderDayNumber: {
      fontSize: 18,
      color: colorBlack,
      fontWeight: 'normal',
    },
    dayHeaderDayMonth: {
      fontSize: 12,
    },
  });
