import { useQuery, useMutation, UseMutationOptions, UseQueryOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { CreateQueryParams } from '@nestjsx/crud-request';

import { ApiError } from '@app/query';

import { UpdateUserForm, UserEntity } from '../interfaces';
import { USERS_LIST_KEY } from '../constants';
import {
  getUser,
  updateUser,
  getUsers,
  updateUserPassword,
  updateUserProfile,
  getMyTeamMembersCount,
  getMyTeamAdmin,
} from '../api';

export const useUsersIndex = <T = UserEntity[]>({
  params,
  options = {},
}: {
  params?: CreateQueryParams;
  options?: AxiosRequestConfig;
} = {}) =>
  useQuery<T, ApiError>([USERS_LIST_KEY, params], () => getUsers<T>(params, options), {
    cacheTime: 1000,
  });

export const useUserItem = <T = UserEntity>({
  id,
  params,
  options = {},
}: {
  id: number;
  params?: CreateQueryParams;
  options?: AxiosRequestConfig;
}) =>
  useQuery<T, ApiError>([USERS_LIST_KEY, params], () => getUser<T>(id, { params, options }), {
    cacheTime: 1000,
  });

export const useUserUpdate = (
  options?: UseMutationOptions<UserEntity, ApiError, Partial<UpdateUserForm>> & { id: number },
) => useMutation<UserEntity, ApiError, Partial<UpdateUserForm> & { id: number }>(data => updateUser(data), options);

export const useUserUpdateProfile = (options?: UseMutationOptions<UserEntity, ApiError, Partial<UpdateUserForm>>) =>
  useMutation<UserEntity, ApiError, Partial<UpdateUserForm>>(data => updateUserProfile(data), options);

export const useUserUpdatePassword = (options?: UseMutationOptions<unknown, ApiError, unknown>) =>
  useMutation<unknown, ApiError, unknown>(() => updateUserPassword(), options);

export const useMyTeamMembersCount = <T = number>(options: AxiosRequestConfig = {}) =>
  useQuery<T, ApiError>('myTeamMembers', () => getMyTeamMembersCount<T>({ options }), {
    cacheTime: 1000,
  });
export const useMyTeamAdmin = <T = UserEntity>({
  options = {},
  queryOptions = {},
}: {
  options?: AxiosRequestConfig;
  queryOptions?: UseQueryOptions<T, ApiError>;
} = {}) =>
  useQuery<T, ApiError>('myTeamAdmin', () => getMyTeamAdmin<T>({ options }), {
    cacheTime: 1000,
    ...queryOptions,
  });
