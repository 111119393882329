import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { Button, Grid } from '@material-ui/core';

import { useAuthUser } from '@app/auth';
import { useModal } from '@app/ui/modal';
import { UpgradePlanConfirmation } from '@app/payments/components/UpdatePlanConfirmation/UpgradePlanConfirmation';
import { PAYMENT_PLANS } from '@app/payments/constants';

import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ReminderBanner = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const { user: { trial, paymentPlan } = {} } = useAuthUser();
  const { isOpen, hide, show } = useModal();

  if (trial && trial.daysLeft <= 4 && paymentPlan?.plan !== PAYMENT_PLANS.TRIAL) {
    const { daysLeft, hoursLeft } = trial;

    let value = t('payments.trial.reminderBanner.lessThanHour');

    if (daysLeft) {
      value = t('payments.trial.reminderBanner.days', { count: daysLeft });
    } else if (hoursLeft) {
      value = t('payments.trial.reminderBanner.hours', { count: hoursLeft });
    }

    // @ts-ignore
    const text = t('payments.trial.reminderBanner.text', { count: value });

    return (
      <>
        <Grid
          container
          className={classes.reminderContainer}
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={12} md="auto" className="text">
            <InfoIcon className="icon" />
            {text}
          </Grid>
          <Grid item xs={12} md="auto">
            <Button color="primary" onClick={() => show()}>
              {t('general.buttons.upgradeTo', { to: t('payments.plans.pro') })}
            </Button>
          </Grid>
        </Grid>
        <UpgradePlanConfirmation onClose={hide} isOpen={isOpen} />
      </>
    );
  }

  return null;
};
