import { useCallback } from 'react';

import { useDate, Options } from '@app/app/hooks/useDate';
import { DATE_FORMAT, DATE_SHORT_FORMAT, TIME_FORMAT } from '@app/app/constants/global';
import { Slot } from '@app/interview/types/Slot';

export interface InterviewOptions extends Options {
  readonly offset?: number;
  readonly shortDate?: boolean;
}

export const useInterviewTime = () => {
  const { formatCustom, djs } = useDate();

  const toTime = useCallback((time: number, offset = 0) => {
    let t = time + offset;

    if (t < 0) {
      t += 24;
    } else if (t > 23.5) {
      t -= 24;
    }

    const hours = Math.trunc(t);

    return `${hours < 10 ? '0' : ''}${hours}:${hours === t ? '00' : Math.abs(t - hours) * 60}`;
  }, []);

  const toDateTime = useCallback(
    (
      date: string | number | Date,
      time: number,
      { offset = 0, shortDate = false, ...options }: InterviewOptions = {},
    ): [string, string, boolean] => {
      let d = djs.utc(date);

      let isChanged = false;
      let t = time + offset;

      if (t < 0) {
        d = d.subtract(1, 'day');
        t += 24;
        isChanged = true;
      } else if (t > 23) {
        d = d.add(1, 'day');
        t -= 24;
        isChanged = true;
      }

      const [formattedDate, formattedTime] = formatCustom(
        `${d.format('YYYY-MM-DD')}T${toTime(t)}:00Z`,
        `${shortDate ? DATE_SHORT_FORMAT : DATE_FORMAT}|${TIME_FORMAT}`,
        options,
      ).split('|');

      return [formattedDate, formattedTime, isChanged];
    },
    [toTime, formatCustom],
  );

  const toRangeDateTime = useCallback(
    (date: string | number | Date, time: Slot, options: InterviewOptions = {}) => {
      const [fromDate, fromTime] = toDateTime(date, time.from, options);
      const [, toTime] = toDateTime(date, time.to, options);

      return [fromDate, `${fromTime} - ${toTime}`];
    },
    [toTime, toDateTime],
  );

  return {
    toTime,
    toDateTime,
    toRangeDateTime,
  };
};
