// @ts-nocheck
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useCallback, useMemo, useEffect, RefObject, SyntheticEvent, Fragment, ReactElement } from 'react';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useFlexLayout,
  useRowSelect,
  useExpanded,
} from 'react-table';
import { RequestQueryBuilder, CreateQueryParams, QuerySort } from '@nestjsx/crud-request';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TablePagination from '@material-ui/core/TablePagination';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import SvgIcon from '@material-ui/core/SvgIcon';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import { PaginationActions } from '../PaginationActions';
import { useFiltersState } from '../../hooks/useFilterState';
import { TableQueryData, TableSortObject } from '../../interfaces/indes';
import styles from './styles';

const useStyles = makeStyles(styles);

type PaginatorOption = number | { label: string; value: number };

export type QuerySortItem = {
  field: string;
  order: 'ASC' | 'DESC';
};

type CustomSortOptions = {
  [key: string]: (obj: TableSortObject) => QuerySortItem[];
};

const defaultData = [];

export interface Props {
  columns: any;
  ExpandedComponent?: any;
  data?: Array<any>;
  page?: number;
  total?: number;
  pageCount?: number;
  isPagination?: boolean;
  isSort?: boolean;
  renderGlobalFilter?: (params: any) => React.FC | JSX.Element;
  fetchData?: (data: TableQueryData) => void;
  initialState?: any;
  isScrollToTop?: boolean;
  textTotal?: string;
  className?: string;
  mobileTitleColumnNumber?: number;
  isSelectRows?: boolean;
  handleSelectedRows?: (rowsData: any[]) => void;
  contentCenter?: boolean;
  paginatorOptions?: PaginatorOption[];
  emptyRowsMessage?: string;
  customSortOptions?: CustomSortOptions;
  variant?: 'light' | 'default' | 'expanded';
  respUpLgParent?: boolean;
  respUpParent?: string;
  CustomFooter?: ReactElement;
}

const stopPropagation = (e: SyntheticEvent) => e.stopPropagation();

const IndeterminateCheckbox = React.forwardRef(
  (
    {
      indeterminate,
      ...rest
    }: {
      indeterminate?: boolean;
      checked: boolean;
      style: object;
      title: string;
      onChange: (event: any) => void;
      onClick?: (event: any) => void;
    },
    ref,
  ) => {
    const defaultRef = React.useRef();
    const resolvedRef = (ref as RefObject<any> | null) || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    const classes = useStyles();

    return (
      <Checkbox
        ref={resolvedRef}
        icon={<SvgIcon component={CheckBoxOutlineBlankIcon} viewBox="0 0 18 18" />}
        checkedIcon={<SvgIcon component={CheckBoxIcon} viewBox="0 0 18 18" />}
        className={classes.checkbox}
        {...rest}
      />
    );
  },
);

export const Table = ({
  columns,
  data = defaultData,
  ExpandedComponent,
  total = 0,
  pageCount: controlledPageCount = -1,
  isPagination = true,
  isSort = true,
  fetchData,
  renderGlobalFilter,
  initialState,
  isScrollToTop = true,
  textTotal = 'Total',
  className = '',
  mobileTitleColumnNumber = 1,
  isSelectRows = false,
  handleSelectedRows,
  contentCenter = false,
  paginatorOptions,
  emptyRowsMessage,
  customSortOptions,
  variant = 'default',
  respUpLgParent,
  respUpParent,
  CustomFooter,
}: Props) => {
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up(respUpParent ?? 'md'));
  const respDesktop = respUpLgParent ?? mediaQuery;
  const respMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('common');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
    state: { pageIndex, pageSize, globalFilter, sortBy },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState,
      manualPagination: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
  );

  const { filtersState, changePage, changeFilter } = useFiltersState({
    pageIndex,
    globalFilter: { ...globalFilter },
  });

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      gotoPage(newPage);
      changePage(newPage);
    },
    [gotoPage, changePage],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPageSize(parseInt(event.target.value, 10));
      gotoPage(0);
      changePage(0);
    },
    [setPageSize, gotoPage, changePage],
  );

  const displayedIndex = useMemo(() => pageIndex + 1, [pageIndex]); // non zero-based

  const labelDisplayedRows = useCallback(
    () => `${t('general.table.page')} ${displayedIndex} ${t('general.table.of')} ${controlledPageCount}`,
    [displayedIndex, controlledPageCount, t],
  );

  const sort = useMemo<QuerySort[]>(
    () =>
      sortBy.reduce((acc: QuerySort[], item: TableSortObject) => {
        const isCustom = customSortOptions && Object.keys(customSortOptions).includes(item.id);

        if (isCustom) {
          const customSortQueryObjects = customSortOptions![item.id](item);

          return [...acc, ...customSortQueryObjects];
        }

        return [
          ...acc,
          {
            field: item.id,
            order: item.desc ? 'DESC' : 'ASC',
          },
        ];
      }, []),
    [sortBy, customSortOptions],
  );

  const querySearch = useMemo(() => {
    const xQuerySearch = { ...filtersState.globalFilter };
    Object.entries(xQuerySearch).forEach(([key, value]) => {
      if (!value) {
        delete xQuerySearch[key];
      }
    });

    return xQuerySearch;
  }, [filtersState.globalFilter]);

  const paginationQuery = useMemo(() => {
    if (isScrollToTop) {
      window.scrollTo(0, 0);
    }

    const page = displayedIndex;
    const limit = pageSize > 0 ? pageSize : 0;

    return { page, limit };
  }, [pageSize, displayedIndex, isScrollToTop]);

  const queryObject = useMemo<TableQueryData>(() => {
    const { page, limit } = paginationQuery;

    const queryBuilderParams: CreateQueryParams = {
      sort,
      search: querySearch,
    };

    if (isPagination) {
      queryBuilderParams.limit = limit;
      queryBuilderParams.page = page;
    }

    const { queryObject: qo } = RequestQueryBuilder.create(queryBuilderParams);

    return { ...qo, sort } as TableQueryData;
  }, [paginationQuery, querySearch, sort, isPagination]);

  useEffect(() => {
    if (fetchData) {
      fetchData(queryObject);
    }
  }, [fetchData, queryObject]);

  useEffect(() => {
    if (handleSelectedRows) {
      handleSelectedRows(selectedFlatRows);
    }
  }, [selectedFlatRows, handleSelectedRows]);

  const handleSetGlobalFilter = useCallback(
    input => {
      setGlobalFilter(input);
      changeFilter(input);
    },
    [setGlobalFilter, changeFilter],
  );

  const translatedPaginatorOptions = useMemo(
    () => paginatorOptions || [5, 10, 25, { label: t('general.table.all'), value: -1 }],
    [paginatorOptions, t],
  );

  const translatedEmptyRowsMessage = useMemo(
    () => emptyRowsMessage || t('general.table.emptyRows'),
    [t, emptyRowsMessage],
  );

  const isEmptyTable = rows.length === 0;
  const isPaginationVisible = useMemo(() => isPagination && total >= 5, [isPagination, total]);

  const classes = useStyles();

  return (
    <div className={cx({ [classes[`${variant}VariantTable`]]: variant }, className, classes.tableContainer)}>
      {renderGlobalFilter && renderGlobalFilter({ globalFilter, setGlobalFilter: handleSetGlobalFilter })}
      <MaUTable {...getTableProps()} className={classes.table}>
        <TableHead className={classes.tableThead}>
          {headerGroups.map(headerGroup => (
            // eslint-disable-next-line react/jsx-key
            <TableRow {...headerGroup.getHeaderGroupProps()} className={cx(classes.tableRow, classes.tableRowHead)}>
              {headerGroup.headers.map((column, columnIndex) => (
                // eslint-disable-next-line react/jsx-key
                <TableCell
                  className={cx(classes.tableCell, classes.tableCellCenter, column.classNames)}
                  {...(isSort ? column.getHeaderProps(column.getSortByToggleProps()) : column.getHeaderProps())}
                >
                  {isSelectRows && columnIndex === 0 && (
                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} onClick={stopPropagation} />
                  )}
                  {column.render('Header')}

                  {column.canSort && (
                    <span className={classes.sortedIconContainer}>
                      {!column.isSorted && <UnfoldMoreIcon />}

                      {column.isSorted && (column.isSortedDesc ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />)}
                    </span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()} className={classes.tableBody}>
          {rows.map(row => {
            prepareRow(row);

            const mobileTitleColumn = mobileTitleColumnNumber - 1;

            return (
              <Fragment key={row.getRowProps().key}>
                <TableRow {...row.getRowProps()} className={classes.tableRow}>
                  {respDesktop ? (
                    row.cells.map((cell, cellIndex) => (
                      // eslint-disable-next-line react/jsx-key
                      <TableCell
                        {...cell.getCellProps()}
                        className={cx(classes.tableCell, contentCenter ? classes.tableCellCenter : '', 'table-cell')}
                      >
                        {isSelectRows && cellIndex === 0 && (
                          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        )}
                        {cell.render('Cell')}
                      </TableCell>
                    ))
                  ) : (
                    <TableCell className={classes.accordionContainer}>
                      <Accordion classes={{ root: classes.accordion, rounded: classes.accordionRounded }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}
                          classes={{
                            root: classes.accordionSummary,
                            expanded: classes.accordionSummaryExpanded,
                            content: classes.accordionSummaryContent,
                            expandIcon: classes.accordionSummaryExpandedIcon,
                          }}
                        >
                          <div className={classes.accordionTitleContainer}>
                            {isSelectRows && (
                              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} onClick={stopPropagation} />
                            )}

                            <p className={classes.accordionTitle}>{row.cells[mobileTitleColumn].render('Cell')}</p>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          {row.cells.map((cell, index) => {
                            if (index === mobileTitleColumn) {
                              return null;
                            }

                            if (cell.column.id === 'expander') {
                              return null;
                            }

                            return (
                              <TableCell
                                key={cell.column.Header}
                                {...cell.getCellProps()}
                                className={cx(classes.tableCell, 'table-cell')}
                                component="span"
                              >
                                {!!cell.column.render('Header') && (
                                  <div className={classes.mobileCellLabel}>{cell.column.render('Header')}</div>
                                )}

                                {cell.render('Cell')}
                              </TableCell>
                            );
                          })}
                          <>
                            {row.cells.map(cell => {
                              if (cell.column.id === 'expander') {
                                return (
                                  <TableCell
                                    key={cell.column.Header}
                                    {...cell.getCellProps()}
                                    className={cx(classes.tableCell, classes.expanderMobileCell, 'table-cell')}
                                    component="span"
                                  >
                                    {cell.render('Cell')}
                                  </TableCell>
                                );
                              }

                              return null;
                            })}
                            {row.isExpanded && ExpandedComponent && (
                              <TableRow {...row.getRowProps()} className={classes.tableRow}>
                                <ExpandedComponent row={row} respUpLgParent={respDesktop} />
                              </TableRow>
                            )}
                          </>
                        </AccordionDetails>
                      </Accordion>
                    </TableCell>
                  )}
                </TableRow>

                {row.isExpanded && ExpandedComponent && respDesktop && (
                  <TableRow {...row.getRowProps()} className={classes.tableRow}>
                    <ExpandedComponent row={row} respUpLgParent={respDesktop} />
                  </TableRow>
                )}
              </Fragment>
            );
          })}
        </TableBody>
      </MaUTable>

      {isEmptyTable ? (
        <div className={cx(classes.tableEmpty, respMobile ? classes.accordionEmpty : '')}>
          <Typography variant="body2" color="textSecondary">
            {translatedEmptyRowsMessage}
          </Typography>
        </div>
      ) : (
        <>
          {CustomFooter ?? (
            <div className={classes.tableFooter}>
              <Typography variant="body2" className={classes.tableTotal} color="textSecondary">
                {textTotal || t('general.table.totalRecords')}: {total}
              </Typography>
              {isPagination && (
                <>
                  {isPaginationVisible && (
                    <TablePagination
                      rowsPerPageOptions={respDesktop ? translatedPaginatorOptions : []}
                      count={total}
                      rowsPerPage={pageSize}
                      page={pageIndex}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={PaginationActions}
                      labelRowsPerPage={t(translations.table.resultsPerPage)}
                      labelDisplayedRows={labelDisplayedRows}
                      component="div"
                      SelectProps={{ IconComponent: KeyboardArrowDownIcon }}
                      classes={{
                        root: classes.tablePagination,
                        selectIcon: classes.selectIcon,
                        select: classes.select,
                        toolbar: classes.toolbar,
                      }}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
