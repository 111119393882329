import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@material-ui/core';

import { Modal } from '@app/ui/modal';
import { RadioGroup } from '@app/ui/forms';
import { GOOGLE_REJECT_REASONS } from '@app/auth/constants';

export interface HelpModalProps {
  readonly isOpen: boolean;
  readonly hide: () => void;
  readonly reset: () => void;
  readonly onUpdate: (value: string) => void;
}

export const CantConnectModal = ({ hide, isOpen, onUpdate, reset }: HelpModalProps) => {
  const { t } = useTranslation('common');

  const options = Object.values(GOOGLE_REJECT_REASONS).map(id => ({
    id,
    label: t(`user.registration.form.firstStep.googleConnect.cantConnect.reasons.${id}`),
  }));

  const handleCancel = () => {
    onUpdate(GOOGLE_REJECT_REASONS.OTHER);

    reset();
  };
  const handleSave = () => {
    hide();
  };

  return (
    <Modal
      hide={handleCancel}
      isOpen={isOpen}
      title={t('user.registration.form.firstStep.googleConnect.cantConnect.title')}
      maxWidth="sm"
      actions={
        <>
          <Button color="primary" variant="outlined" onClick={handleCancel}>
            {t('general.buttons.cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            {t('general.buttons.save')}
          </Button>
        </>
      }
    >
      <Typography gutterBottom>
        {t('user.registration.form.firstStep.googleConnect.cantConnect.description')}
      </Typography>
      <Typography>{t('user.registration.form.firstStep.googleConnect.cantConnect.specification')}</Typography>
      <RadioGroup name="googleRejectReason" required options={options} />
    </Modal>
  );
};
