import { useCallback, useMemo } from 'react';

import { useFreeSlots } from '@app/interview/hooks/useFreeSlots';
import { RESOURCES } from '@app/availabilities/constants';
import { AvailabilityEntity } from '@app/availabilities/types/Availability';

import { InterviewSelectDate } from './SelectDate';

interface Props {
  interviewers: AvailabilityEntity[];
  candidates: AvailabilityEntity[];
  duration: number;
}

export const ScheduleInterview = ({ interviewers, candidates, duration }: Props) => {
  const calendlyAvailabilities = useMemo(
    () => [...(interviewers || []), ...(candidates || [])].filter(({ resource }) => resource === RESOURCES.CALENDLY),
    [interviewers, candidates],
  );

  const availabilitiesMemo = useMemo(
    () => [...(interviewers || []).map(({ id }) => id), ...(candidates || []).map(({ id }) => id)].filter(Boolean),
    [interviewers, candidates],
  );
  const hasTokens = calendlyAvailabilities.filter(({ meta }) => meta && meta.length && meta[0].calendlyAPIToken);

  const isCalendly = !!(calendlyAvailabilities.length && calendlyAvailabilities.length > hasTokens.length);

  const { slots, timezones, loadFreeSlots, isLoading } = useFreeSlots({
    duration,
    availabilities: availabilitiesMemo,
    isCalendly,
  });

  const onCalendarChange = useCallback(
    date => {
      if (isCalendly) {
        return loadFreeSlots(date);
      }

      return Promise.resolve();
    },
    [isCalendly, loadFreeSlots],
  );

  return (
    <InterviewSelectDate
      onCalendarChange={onCalendarChange}
      isCalendly={isCalendly}
      onLoad={loadFreeSlots}
      timezones={timezones}
      isLoading={isLoading}
      slots={slots}
    />
  );
};
