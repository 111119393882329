import { useReducer, ReactNode } from 'react';

import { AuthContext } from '@app/auth/context';
import authReducer, { initialState } from '@app/auth/reducers';

export interface Props {
  readonly children: ReactNode;
}

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
}
