import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';

import { Button, Grid, Hidden, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InsertLinkIcon from '@material-ui/icons/InsertLink';

import { AvailabilityEntity } from '@app/availabilities/types/Availability';
import { Modal, useModal } from '@app/ui/modal';
import { Loader } from '@app/ui/loader';
import { Text, yup } from '@app/ui/forms';
import { useGroupSchedulerStore } from '@app/groupScheduler/hooks/crud';
import { error, success } from '@app/snackbars';
import { INVITE_LINK_SEND_ME, INVITE_LINK_TYPE } from '@app/interview/constants';
import { STATUSES } from '@app/availabilities/constants';
import { LimitModal } from '@app/payments/components/Basic/LimitModal';
import { usePlan } from '@app/payments/hooks/usePlan';

import { styles } from './styles';
import { DropArea } from './DropArea';

const useStyles = makeStyles(styles);

const validationSchema = yup.object().shape({
  subject: yup.string().required(),
});

interface Props {
  readonly setGroupLinkMembers: Dispatch<SetStateAction<AvailabilityEntity[]>>;
  readonly groupLinkMembers: AvailabilityEntity[];
  readonly onSubmit: () => void;
  readonly showLimitPopup: boolean;
}

export const GroupScheduler = ({
  groupLinkMembers,
  setGroupLinkMembers,
  onSubmit: onFormSubmit,
  showLimitPopup,
}: Props) => {
  const classes = useStyles();
  const { push } = useHistory();
  const theme = useTheme();
  const { t } = useTranslation('common');
  const respIsTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const { isOpen, hide, show } = useModal();
  const {
    isOpen: isOpenInterviewConfirmation,
    hide: hideInterviewConfirmation,
    show: showInterviewConfirmation,
  } = useModal();
  const { isOpen: isOpenLimitModal, show: showLimitModal, hide: hideLimitModal } = useModal();
  const { mutateAsync, isLoading } = useGroupSchedulerStore();
  const [step, setStep] = useState(1);
  const [token, setToken] = useState('');
  const { isBasic } = usePlan();

  const close = useCallback(
    resetForm => () => {
      setStep(1);
      resetForm();
      hide();
    },
    [],
  );

  const showCreateSchedulerLink = () => {
    if (showLimitPopup) {
      showLimitModal();
    } else {
      show();
    }
  };

  const onClickInterview = () => {
    if (showLimitPopup || isBasic) {
      showLimitModal();
    } else if (groupLinkMembers.some(({ status }) => status !== STATUSES.ACTIVE)) {
      showInterviewConfirmation();
    } else {
      push(`/interviews/new?participants=${groupLinkMembers.map(({ id }) => id).join()}`);
    }
  };

  const goToInterviewCreation = () => {
    if (showLimitPopup) {
      showLimitModal();
    } else {
      push(`/interviews/new?participants=${groupLinkMembers.map(({ id }) => id).join()}`);
    }
  };

  const copy = (m: number) => () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/interview-invite/${INVITE_LINK_TYPE.GROUP_LINK}.${INVITE_LINK_SEND_ME.NO}.${m}.${token}`,
    );
    success(t('groupSchedulerLink.form.create.copiedMessage'), { autoHideDuration: 3000 });
  };

  const isDisabled = groupLinkMembers.length < 2;

  const onSubmit = useCallback(
    async (data, { setErrors, resetForm }: FormikHelpers<{ subject: string }>) => {
      if (showLimitPopup) {
        showLimitModal();

        return undefined;
      }
      if (step === 1) {
        try {
          const { token: resultToken } = await mutateAsync({
            ...data,
            groupSchedulerAvailabilities: groupLinkMembers.map(({ id }) => id),
          });

          setToken(resultToken);
          setStep(2);
          onFormSubmit();
        } catch (e) {
          if (e.statusCode === 400) {
            setErrors(e.errors);
          } else {
            error(e?.message);
          }
        }
      } else {
        close(resetForm)();
        setGroupLinkMembers([]);
      }

      return undefined;
    },
    [groupLinkMembers, step],
  );

  return (
    <div className={classes.groupSchedulerContainer}>
      <div className={classes.title}>
        <Typography variant="h3">{t('groupSchedulerLink.list.title')}</Typography>
      </div>
      {isOpenLimitModal && <LimitModal onCancel={hideLimitModal} />}
      <div className={classes.description}>
        <Typography>
          {t('groupSchedulerLink.list.description1')}
          <Tooltip title={<>{t('groupSchedulerLink.list.linkTooltip')}</>}>
            <span className={classes.descriptionLink}>{t('groupSchedulerLink.list.description2')}</span>
          </Tooltip>
          {t('groupSchedulerLink.list.description3')}
        </Typography>
      </div>
      <DropArea items={groupLinkMembers} setItems={setGroupLinkMembers} />
      <div className={classes.actions}>
        <Button
          color="secondary"
          variant="contained"
          disabled={isDisabled}
          onClick={showCreateSchedulerLink}
          fullWidth
          className={classes.actionButton}
        >
          {t('groupSchedulerLink.list.buttons.scheduleLink')}
        </Button>
        <Button color="primary" variant="contained" disabled={isDisabled} fullWidth onClick={onClickInterview}>
          {t('groupSchedulerLink.list.buttons.interview')}
        </Button>
      </div>
      <Formik initialValues={{ subject: '' }} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ handleSubmit, isValid, resetForm }) => (
          <Modal
            isOpen={isOpen}
            fullWidth
            hide={close(resetForm)}
            title={t('groupSchedulerLink.form.create.title')}
            maxWidth="sm"
            dialogActionsClassName={classes.modalActions}
            actions={
              <Button
                variant="contained"
                color="primary"
                disabled={!isValid}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t(`general.buttons.${step === 1 ? 'generate' : 'done'}`)}
              </Button>
            }
          >
            {isLoading && <Loader />}
            {step === 1 ? (
              <>
                <div className={classes.modalDescription}>
                  <Typography>{t('groupSchedulerLink.form.create.description1')}</Typography>
                </div>

                <Text required name="subject" label={t('groupSchedulerLink.form.create.subjectField')} />
                <div className={classes.divider} />
                <Typography>{t('groupSchedulerLink.form.create.description2')}</Typography>
              </>
            ) : (
              <>
                <Typography className={classes.copyLinkDescription}>
                  {t('groupSchedulerLink.form.create.description3')}:
                </Typography>
                <Grid container alignItems="center" alignContent="center" spacing={1}>
                  <Hidden only="xs">
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h3">{t('groupSchedulerLink.form.create.copyLink')}:</Typography>
                    </Grid>
                  </Hidden>
                  <Grid item xs={4} sm={3}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<InsertLinkIcon />}
                      className={classes.actionsButton}
                      onClick={copy(30)}
                    >
                      30m
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<InsertLinkIcon />}
                      className={classes.actionsButton}
                      onClick={copy(60)}
                    >
                      1h
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<InsertLinkIcon />}
                      className={classes.actionsButton}
                      onClick={copy(90)}
                    >
                      1.5h
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Modal>
        )}
      </Formik>
      <Modal
        isOpen={isOpenInterviewConfirmation}
        hide={hideInterviewConfirmation}
        title={t('groupSchedulerLink.interviewConfirmation.title')}
        description={t('groupSchedulerLink.interviewConfirmation.description')}
        actions={
          <>
            <Button fullWidth={respIsTablet} color="primary" onClick={hideInterviewConfirmation}>
              {t('general.buttons.cancel')}
            </Button>

            <Button fullWidth={respIsTablet} variant="contained" color="primary" onClick={goToInterviewCreation}>
              {t('general.buttons.continue')}
            </Button>
          </>
        }
      />
    </div>
  );
};
