import { useQuery, useMutation, UseMutationOptions, UseQueryOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { CreateQueryParams } from '@nestjsx/crud-request';

import { ApiError } from '@app/query';

import { Availability, AvailabilityEntity } from '../types/Availability';
import { AVAILABILITY_LIST_KEY, AVAILABILITY_INVITE_INFO_KEY } from '../constants';
import {
  getAvailabilities,
  createAvailabilities,
  updateAvailability,
  deleteAvailability,
  createAvailability,
  resendAvailabilityRequest,
  getAvailabilityViaInviteLink,
} from '../api';

export const useAvailabilityIndex = <T = AvailabilityEntity[]>({
  index = '',
  params,
  options = {},
  queryOptions = {},
}: {
  index?: string;
  params?: CreateQueryParams;
  options?: AxiosRequestConfig;
  queryOptions?: UseQueryOptions<T, ApiError>;
} = {}) =>
  useQuery<T, ApiError>([`${AVAILABILITY_LIST_KEY}${index}`, params], () => getAvailabilities<T>(params, options), {
    cacheTime: 1000,
    ...queryOptions,
  });

export const useAvailabilityResend = (options?: UseMutationOptions<number, ApiError, number>) =>
  // @ts-ignore
  useMutation<number, ApiError, number>(id => resendAvailabilityRequest(id), options);

export const useAvailabilityStore = (options?: UseMutationOptions<Availability, ApiError, Availability>) =>
  useMutation<Availability, ApiError, Availability>(data => createAvailability(data), options);

export const useAvailabilityBulkStore = (
  options?: UseMutationOptions<Availability[], ApiError, { bulk: Partial<Availability>[]; sendRequest: boolean }>,
) =>
  useMutation<Availability[], ApiError, { bulk: Partial<Availability>[]; sendRequest: boolean }>(
    data => createAvailabilities(data),
    options,
  );

export const useAvailabilityUpdate = (options?: UseMutationOptions<Availability, ApiError, Partial<Availability>>) =>
  useMutation<Availability, ApiError, Partial<Availability>>(data => updateAvailability(data), options);

export const useAvailabilityDelete = (options?: UseMutationOptions<any, ApiError, { id: number }>) =>
  useMutation<any, ApiError, { id: number }>(({ id }: { id: number }) => deleteAvailability(id), options);

export const useAvailabilityViaInviteLink = <T = AvailabilityEntity>({
  token,
  params,
  options = {},
  queryOptions = {},
}: {
  token: string;
  params?: CreateQueryParams;
  options?: AxiosRequestConfig;
  queryOptions?: UseQueryOptions<T, ApiError>;
}) =>
  useQuery<T, ApiError>(
    [AVAILABILITY_INVITE_INFO_KEY, params],
    () => getAvailabilityViaInviteLink<T>(token, params, options),
    {
      cacheTime: 1000,
      ...queryOptions,
    },
  );
