import { produce } from 'immer';
import { useCallback, useMemo, useState } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { useDate } from '@app/app/hooks/useDate';

import { FreeSlotsResponse, FreeSlots, Slot, FreeSlotsResponseTimezone } from '../types/Slot';
import { CALENDLY_FREE_SLOTS_KEY_FORMAT, FREE_SLOTS_KEY_FORMAT } from '../constants';
import { getFreeSlots, getInviteFreeSlots } from '../api';

import { useFormSlotName } from './useFormSlotName';

const utcTimezone = {
  id: 1,
  name: '(GMT+00:00) GMT (no daylight saving)',
  shortName: 'GMT',
  key: 'Etc/GMT',
  offset: 0,
  gmtName: 'GMT+00:00',
};

export interface Props {
  readonly duration: number;
  readonly availabilities: number[];
  readonly isInvite?: boolean;
  readonly token?: string;
  readonly isCalendly?: boolean;
  readonly initialLoading?: boolean;
}

export const useFreeSlots = ({ duration, availabilities, isInvite = false, token = '', isCalendly = false }: Props) => {
  const { formatCustom, djs } = useDate();
  const formSlotName = useFormSlotName();

  const filter = useMemo(
    () => ({
      availabilities,
      duration,
      month: formatCustom(new Date(), FREE_SLOTS_KEY_FORMAT),
    }),
    [availabilities, duration],
  );

  const [slots, setSlots] = useState<FreeSlots>({});
  const [timezones, setTimezones] = useState<FreeSlotsResponseTimezone[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadFreeSlots = useCallback(
    async (date: MaterialUiPickersDate) => {
      const key = (date ?? djs()).format(isCalendly ? CALENDLY_FREE_SLOTS_KEY_FORMAT : FREE_SLOTS_KEY_FORMAT);

      if (!(key in slots)) {
        setIsLoading(true);

        const response = await (isInvite
          ? getInviteFreeSlots<FreeSlotsResponse>(token, { filter: { ...filter, date: key } })
          : getFreeSlots<FreeSlotsResponse>({ filter: { ...filter, date: key } }));

        if (response) {
          setSlots(state =>
            produce(state, draft => {
              draft[key] = {};
              Object.entries(response.slots).forEach(([day, values]) => {
                draft[key][day] = values.map((value: Slot) => ({
                  value,
                  name: formSlotName({
                    day,
                    value,
                    timezones: response.timezones.length ? response.timezones : [{ timezone: utcTimezone }],
                  }),
                }));
              });
            }),
          );

          setTimezones(
            response.timezones.length ? response.timezones : [{ timezone: utcTimezone, availabilities: [] }],
          );
        }
        setIsLoading(false);
      }
    },
    [filter, slots],
  );

  return { slots, timezones, loadFreeSlots, isLoading };
};
