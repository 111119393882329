import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { useTimezonesIndex } from '@app/timezones/hooks/crud';

import { Card } from './Card';
import { styles } from './styles';
import { Request } from './Request';
import { Search } from './Search';
import { AvailabilityEntity, AvailabilityListItem } from '../../types/Availability';

const useStyles = makeStyles(styles());

export interface ListProps {
  readonly items: AvailabilityListItem[];
  readonly onSearch: (value: string[]) => void;
  readonly setGroupLinkMembers: Dispatch<SetStateAction<AvailabilityEntity[]>>;
  readonly groupLinkMembers: AvailabilityEntity[];
  readonly refetch: () => void;
  readonly showLimitPopup: boolean;
}

export const List = ({
  items,
  groupLinkMembers,
  setGroupLinkMembers,
  onSearch,
  refetch,
  showLimitPopup,
}: ListProps) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const theme = useTheme();
  const respUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const { data: { data: timezones } = { data: [] } } = useTimezonesIndex();

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm>
          <Typography variant="h1">{t('availabilities.list.title')}</Typography>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Typography variant="subtitle1">{t('availabilities.list.description')}</Typography>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Request showLimitPopup={showLimitPopup} />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm>
          <Search onSearch={onSearch} />
        </Grid>
      </Grid>
      <Grid container spacing={respUpLg ? 3 : 2}>
        {items.map(({ item, isGroupLink }) => (
          <Grid key={`${item.id}${isGroupLink}`} item xs={12} sm={6} lg={6} className={classes.card}>
            <Card
              refetch={refetch}
              timezones={timezones}
              item={item}
              groupLinkMembers={groupLinkMembers}
              setGroupLinkMembers={setGroupLinkMembers}
              isGroupLink={isGroupLink}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
