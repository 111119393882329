import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { Box, Button, Grid, SvgIcon, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { error, success, warning } from '@app/snackbars';
import { Text } from '@app/ui/forms';

import { InterviewEntity, UpdateInterviewForm } from '@app/interview/types/Interview';
import { useInterviewsUpdate } from '@app/interview/hooks/crud';
import { getCandidates, getInterviewers } from '@app/interview/utils';
import { InterviewDetails as InterviewFinishDetails } from '@app/interview/components/New/ScheduleInterview/Details';
import { useAuthUser } from '@app/auth';
import { RESOURCES } from '@app/availabilities/constants';

import { MIN_LENGTH_FIELD } from '@app/interview/constants';
import { styles } from './styles';

const useStyles = makeStyles(styles);

interface InterviewProps {
  interview: InterviewEntity;
}

export const EditInterview = ({ interview }: InterviewProps) => {
  const { t } = useTranslation('common');
  const { push } = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const respIsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { user: { availability } = { availability: undefined } } = useAuthUser();
  const isGoogleAccountDisconnected =
    interview.sentFrom &&
    (availability?.resource !== RESOURCES.GOOGLE ||
      !availability?.meta?.find(({ googleEmail }) => googleEmail === interview.sentFrom));

  const handleCancel = () => push('/interviews');

  const { interviewers, candidates, others, participantsEmails } = useMemo(
    () => ({
      interviewers: getInterviewers(interview?.interviewsParticipants || []),
      candidates: getCandidates(interview?.interviewsParticipants || []),
      others: (interview?.interviewsParticipants || [])
        .filter(({ type }) => !type)
        .map(p => ({ name: p.name, email: p.email, id: p.id, isDisabled: true })),
      participantsEmails: (interview?.interviewsParticipants || []).map(ip => ip?.availability?.email || ip?.email),
    }),
    [interview],
  );

  const interviewDuration = useMemo(() => {
    const durations = {
      0.5: t('availabilities.time.30'),
      1: t('availabilities.time.60'),
      1.5: t('availabilities.time.90'),
    };
    //  @ts-ignore
    return durations[interview.time.to - interview.time.from];
  }, [interview, t]);

  const { mutateAsync: updateAsync, isLoading: isUpdating } = useInterviewsUpdate({
    onSuccess: () => {
      success(t('interviews.form.create.successMessage'));
      push('/interviews');
    },
    onError: () => {
      error(t('general.messages.somethingWentWrong'));
    },
  });

  // @ts-ignore
  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(t('validation.required')),
        description: yup.string().nullable(),
        isZoomIncluded: yup.boolean().oneOf([true, false]),
        newOthers: yup.array().of(
          yup
            .object()
            // @ts-ignore
            .unique('email', t('availabilities.validations.uniqueEmail'))
            .shape({
              name: yup
                .string()
                .trim()
                .required(t('validation.required'))
                .min(MIN_LENGTH_FIELD, () => `${t('validation.minTextField', { min: MIN_LENGTH_FIELD })}`),
              email: yup
                .string()
                .email()
                .test(
                  'email',
                  t('availabilities.validations.uniqueEmail'),
                  value => !(value && participantsEmails.length && participantsEmails?.includes(value)),
                )
                .required(),
            }),
        ),
      }),
    [participantsEmails],
  );

  const initialValues = useMemo<UpdateInterviewForm>(
    () => ({
      title: interview?.title || '',
      description: interview?.description || '',
      date: interview.date,
      time: interview.time,
      newOthers: [],
      isZoomIncluded: interview?.id ? !!interview?.meet?.id : true,
    }),
    [interview],
  );

  const onSubmit = useCallback(
    async (formData: UpdateInterviewForm) => {
      if (isGoogleAccountDisconnected) {
        warning(t('interviews.list.connectGoogleAccount', { email: interview.sentFrom }));
      } else if (interview && interview.id) {
        await updateAsync({
          id: interview.id,
          description: formData.description,
          isZoomIncluded: formData.isZoomIncluded,
          others: formData.others,
          title: formData.title,
        });
      }
    },
    [interview, isGoogleAccountDisconnected],
  );

  return (
    <>
      <Formik<UpdateInterviewForm> onSubmit={onSubmit} initialValues={initialValues} validationSchema={schema}>
        {({ handleSubmit }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12} md={10}>
                    <Text name="title" label={t('interviews.form.create.interviewDetails.fields.title')} required />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.descriptionDuration}>
                  <SvgIcon className={classes.descriptionIcon} component={AccessTimeIcon} fontSize="small" />
                  {interviewDuration}
                </div>
              </Grid>
            </Grid>
            <InterviewFinishDetails
              interviewers={interviewers}
              candidates={candidates}
              others={others}
              isHeaderHidden
              isForm
              sentFrom={interview.sentFrom}
            />
            <div className={classes.divider} />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Button color="primary" fullWidth={respIsMobile} onClick={handleCancel}>
                {t('general.buttons.cancel')}
              </Button>
              <Button disabled={isUpdating} fullWidth={respIsMobile} variant="contained" color="primary" type="submit">
                {t('general.buttons.update')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
