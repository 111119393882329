import { AxiosRequestConfig } from 'axios';

import { apiClient } from '@app/query';

import { Availability } from '../types/Availability';

const PATH = '/availabilities-request';

export const getAvailabilityRequest = (
  token: string,
  options: AxiosRequestConfig = {},
): Promise<Partial<Availability>> => apiClient.get(`${PATH}/${token}`, options);

export const updateAvailabilityRequest = (
  { token, ...data }: Partial<Availability>,
  options: AxiosRequestConfig = {},
): Promise<Availability> => apiClient.patch(`${PATH}/${token}`, data, options);

export const deleteAvailabilityRequest = (token: string, options: AxiosRequestConfig = {}) =>
  apiClient.delete(`${PATH}/${token}`, options);

export const checkAvailabilityRequest = (
  token: string,
  options: AxiosRequestConfig = {},
): Promise<number | undefined> => apiClient.get(`${PATH}/check/${token}`, options);
