import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { EditInterview } from '@app/interview/components/Edit';
import { MainLayout } from '@app/app/components/MainLayout';
import { usePlan } from '@app/payments/hooks/usePlan';
import { error } from '@app/snackbars';

import { Loader } from '@app/ui/loader';
import { useInterviewStore } from '@app/interview/hooks/crud';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EditInterviewPage = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const { push, goBack } = useHistory();
  const { isBasic } = usePlan();
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useInterviewStore(+id, {
    enabled: !!id,
    onError: () => {
      error(t('general.messages.somethingWentWrong'));
      goBack();
    },
  });

  if (isBasic || !id) {
    push('/');
  }

  return (
    <MainLayout
      breadcrumbs={data ? [{ title: t('interviews.list.title'), to: '/interviews' }, { title: data.title }] : []}
    >
      {!data && isLoading ? (
        <Box minHeight={300}>
          <Loader />
        </Box>
      ) : (
        <Paper className={classes.paper}>{data && <EditInterview interview={data} />}</Paper>
      )}
    </MainLayout>
  );
};
