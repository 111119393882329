import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Grid, Typography, Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { AvailabilityEntity } from '@app/availabilities/types/Availability';
import { useInterviewTime } from '@app/interview/hooks/useInterviewTime';
import { StepsNewInterviewForm } from '@app/interview/types/Interview';
import { useDate } from '@app/app/hooks/useDate';
import { FREE_SLOTS_DAY_FORMAT } from '@app/interview/constants';
import { Chip } from '@app/ui/chip';
import { Checkbox, Text } from '@app/ui/forms';

import { getUserTimezoneName, getUserTimezoneOffset } from '@app/interview/utils';
import { ListOther } from '@app/interview/components/New/Participants/ListOther';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export interface Props {
  readonly interviewers: AvailabilityEntity[];
  readonly candidates: AvailabilityEntity[];
  readonly others?: Array<{ name?: string; email?: string; id?: number }>;
  readonly isHeaderHidden?: boolean;
  readonly isForm?: boolean;
  readonly sentFrom?: string;
}

export const InterviewDetails = ({ interviewers, candidates, others, isHeaderHidden, isForm, sentFrom }: Props) => {
  const classes = useStyles();
  const { toTime } = useInterviewTime();
  const { djs, timezone } = useDate();
  const {
    values: { date, time, others: formOthers },
  } = useFormikContext<StepsNewInterviewForm & { others?: Array<{ email: string; name: string }> }>();
  const theme = useTheme();
  const respIsMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const { t } = useTranslation('common');

  const userOffset = getUserTimezoneOffset(timezone);

  const userTimezoneName = getUserTimezoneName(userOffset, timezone);

  const timeRange = time ? `${toTime(time.from, userOffset)} - ${toTime(time.to, userOffset)}` : '';

  return (
    <div className={classes.detailsContainer}>
      {!isHeaderHidden && (
        <Typography variant="h3" color="textPrimary" className={classes.detailsTitleContainer}>
          {t('interviews.form.create.modal.secondStepTitle')}
        </Typography>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div className={classes.detailsDescriptionContainer}>
            <Checkbox name="isZoomIncluded" label={t('interviews.form.create.isZoomIncluded')} />
            <Grid container>
              <Grid item xs={12} md={10}>
                <Text
                  name="description"
                  multiline
                  rows={4}
                  label={t('interviews.form.create.interviewDetails.fields.description')}
                />
              </Grid>
            </Grid>
          </div>
          <Box display={respIsMobile ? 'block' : 'flex'}>
            <Box>
              <Typography color="textSecondary">{t('interviews.form.create.modal.fields.date')}</Typography>
              <div>{djs(date).format(FREE_SLOTS_DAY_FORMAT)}</div>
              <div>
                {timeRange} {userTimezoneName}
              </div>
            </Box>
            <Box ml={respIsMobile ? 0 : 8} mt={respIsMobile ? 2 : 0}>
              {isForm && (
                <div>
                  <Typography color="textSecondary">{t('interviews.form.create.modal.fields.sentFrom')}</Typography>
                  {sentFrom || 'BetterMeeting'}
                </div>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={classes.detailsRightContainer}>
            <Box>
              {!!interviewers?.length && (
                <div className={classes.detailsInterviewersContainer}>
                  <Typography color="textSecondary">{t('interviews.form.create.modal.fields.interviewers')}</Typography>
                  {interviewers.map(({ name, status, id }) => (
                    <div key={`${id}-${name}`} className={classes.participantInfoContainer}>
                      <div>{name}</div>
                      {status && (
                        <div className={classes.participantStatus}>
                          <Chip iconOnly round status={status} />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {!!candidates?.length && (
                <div className={classes.detailsInterviewersContainer}>
                  <Typography color="textSecondary">{t('interviews.form.create.modal.fields.candidates')}</Typography>
                  {candidates.map(({ name, status, id }) => (
                    <div key={`${id}-${name}`} className={classes.participantInfoContainer}>
                      <div>{name}</div>
                      {status && (
                        <div className={classes.participantStatus}>
                          <Chip iconOnly round status={status} />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {!!others?.length && (
                <div>
                  <Typography color="textSecondary">{t('interviews.form.create.modal.fields.others')}</Typography>
                  {others.map(({ name, id }) => (
                    <div key={`${id}-${name}`} className={classes.participantInfoContainer}>
                      <div>{name}</div>
                    </div>
                  ))}
                </div>
              )}
            </Box>
            {isForm && (
              <Box mt={2} className={classes.detailsInterviewersContainer}>
                {!!formOthers?.length && (
                  <Typography color="textSecondary">
                    {t('interviews.form.create.participants.newParticipants')}
                  </Typography>
                )}
                <ListOther name="others" newName="newOthers" />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
