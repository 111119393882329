import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { useFieldArray } from '@app/ui/forms';
import { NewInterviewForm } from '@app/interview/types/Interview';

import { styles } from '../styles';
import { NewParticipant } from './NewParicipant';

const useStyles = makeStyles(styles);

interface Props {
  name: keyof NewInterviewForm;
  onAddNew: (item: { name: string; email: string }) => void;
  existingEmails: string[];
}

export const NewParticipants = ({ name, onAddNew, existingEmails }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [{ value: newInterviewers = [] }, , { push, remove }] = useFieldArray<{ email: string; name: string }>(name);

  return (
    <div>
      {newInterviewers.map((newInterviewer, index) => (
        <NewParticipant
          item={newInterviewer}
          name={name}
          removeMember={remove}
          index={index}
          key={index}
          onAddNew={onAddNew}
          existingEmails={existingEmails}
        />
      ))}

      <Button
        onClick={() => push({ email: '', name: '' })}
        color="primary"
        className={classes.addNewParticipantsButton}
      >
        + {t(`interviews.form.create.participants.buttons.add${name}`)}
      </Button>
    </div>
  );
};
