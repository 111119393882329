import { createStyles, Theme } from '@material-ui/core/styles';

import { textPrimary, textSecondary, colorPrimary, borderPrimaryColor } from '@app/app/configs/variables';

export const styles = (theme: Theme) =>
  createStyles({
    tabsRoot: {
      borderBottom: `1px solid ${borderPrimaryColor}`,
      marginBottom: 24,
    },
    tabsIndicator: {
      height: 3,
      zIndex: 1,
    },

    tabRoot: {
      color: textPrimary,
      fontSize: 14,
      fontWeight: 400,
      minHeight: 0,
      padding: '16px 20px',
      minWidth: 0,
      maxWidth: '100%',
      justifyContent: 'flex-start',
      textAlign: 'left',
      opacity: '1 !important',

      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
      '&:hover': {
        color: colorPrimary,
      },
      '& + &': {
        marginLeft: 0,
      },
    },
    tabSelected: {
      fontWeight: 500,

      '&, &:hover': {
        color: textPrimary,
      },
    },
    tabDisabled: {
      '&, &:hover': {
        color: textSecondary,
      },
    },
    tabLabelWrapper: {
      whiteSpace: 'nowrap',
    },
  });
