import { useTranslation } from 'react-i18next';

import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AvailabilityEntity } from '@app/availabilities/types/Availability';
import { Text } from '@app/ui/forms';
import { styles } from './styles';

const useStyles = makeStyles(styles);

interface Props {
  readonly interviewers: AvailabilityEntity[];
  readonly candidates: AvailabilityEntity[];
  readonly recruiter?: AvailabilityEntity[];
}

export const Participants = ({ candidates, interviewers, recruiter }: Props) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const isEmptyList = !candidates.length && !interviewers.length;

  return (
    <div>
      {!isEmptyList && (
        <Typography variant="h4" gutterBottom>
          {t('interviews.form.create.participants.title')}
        </Typography>
      )}

      <Grid container spacing={2}>
        {!isEmptyList && (
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              {interviewers.length > 0 && (
                <Grid item xs={12} md={6}>
                  <Typography color="textSecondary">{t('interviews.form.create.participants.interviewer')}</Typography>
                  {interviewers.map(({ name, id }) => (
                    <div className={classes.participantName} key={id}>{name}</div>
                  ))}
                </Grid>
              )}
              {candidates.length > 0 && (
                <Grid item xs={12} md={6}>
                  <Typography color="textSecondary">{t('interviews.form.create.participants.candidate')}</Typography>
                  {candidates.map(({ name, id }) => (
                    <div className={classes.participantName}  key={id}>{name}</div>
                  ))}
                </Grid>
              )}
              {recruiter && recruiter?.length > 0 && (
                <Grid item xs={12} md={6}>
                  <Typography color="textSecondary">{t('interviews.form.create.participants.recruiter')}</Typography>
                  {recruiter?.map(({ name, id }) => (
                    <div className={classes.participantName} key={id}>{name}</div>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Text name="name" label={t('general.fields.name.label')} required />
            </Grid>
            <Grid item xs={12} md={7}>
              <Text name="email" label={t('general.fields.yourEmail.label')} required />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
