import { useTranslation, Trans } from 'react-i18next';
import { useCallback } from 'react';

import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useFreeSlots } from '@app/interview/hooks/useFreeSlots';
import { useGroupSchedulerViaInviteLink } from '@app/groupScheduler/hooks/crud';
import { Duration } from '@app/interview/types/Interview';
import { InterviewSelectDate } from '@app/interview/components/New/ScheduleInterview/SelectDate';
import { RESOURCES, ROLES } from '@app/availabilities/constants';

import { Participants } from './Participants';
import { styles } from './styles';

const useStyles = makeStyles(styles);

interface Props {
  token: string;
  duration: Duration;
}

export const Group = ({ token, duration }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { data } = useGroupSchedulerViaInviteLink({ token });

  const durationTrans = {
    30: t('availabilities.time.30'),
    60: t('availabilities.time.60'),
    90: t('availabilities.time.90'),
  };

  const recruiter = (data?.availabilities ?? []).filter(({ role }) => [ROLES.RECRUITER, ROLES.ADMIN].includes(role));
  const candidates = (data?.availabilities ?? []).filter(({ role }) => role === ROLES.CANDIDATE);
  const interviewers = (data?.availabilities ?? []).filter(({ role }) => role === ROLES.INTERVIEWER);
  const calendlyAvailabilities = (data?.availabilities ?? []).filter(({ resource }) => resource === RESOURCES.CALENDLY);
  const hasTokens = calendlyAvailabilities.filter(({ meta }) => meta && meta[0]?.calendlyAPIToken);

  const isCalendly = !!(calendlyAvailabilities.length && calendlyAvailabilities.length > hasTokens.length);

  const availabilitiesIds = [...interviewers, ...candidates, ...recruiter].map(({ id }) => id);

  const { slots, timezones, loadFreeSlots, isLoading } = useFreeSlots({
    duration,
    availabilities: availabilitiesIds,
    isInvite: true,
    token,
    isCalendly,
  });

  const onCalendarChange = useCallback(
    date => {
      if (isCalendly) {
        return loadFreeSlots(date);
      }

      return Promise.resolve();
    },
    [isCalendly, loadFreeSlots],
  );

  return (
    <div>
      <Typography variant="h5">
        <Trans
          t={t}
          defaults="interviews.invite.description.group"
          values={{ subject: data?.subject, duration: durationTrans[duration] }}
          components={{ b: <b /> }}
        />
      </Typography>
      {availabilitiesIds.length > 0 && (
        <Paper className={classes.datesPaper}>
          <InterviewSelectDate
            isLoading={isLoading}
            slots={slots}
            timezones={timezones}
            onLoad={loadFreeSlots}
            onCalendarChange={onCalendarChange}
            isCalendly={isCalendly}
          />
        </Paper>
      )}
      <Participants candidates={candidates} interviewers={interviewers} recruiter={recruiter} />
    </div>
  );
};
