import { createStyles } from '@material-ui/core/styles';

import { borderPrimaryColor, colorWhite } from '@app/app/configs/variables';

export const styles = () =>
  createStyles({
    paper: {
      backgroundColor: colorWhite,
      padding: '40px 41px',
      border: `1px solid ${borderPrimaryColor}`,
    },
  });
