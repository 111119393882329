import { createStyles, Theme } from '@material-ui/core/styles';

import { textPrimary, colorPrimary } from '@app/app/configs/variables';

export const styles = (theme: Theme) =>
  createStyles({
    navMenu: {
      padding: 0,
    },
    navMenuItem: {
      color: textPrimary,
      fontSize: 14,
      paddingLeft: 3,
      paddingRight: 3,
      position: 'relative',

      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 4,
        backgroundColor: 'transparent',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        zIndex: 1,
      },
      '&:hover': {
        color: colorPrimary,
        backgroundColor: 'transparent',
      },
    },
    navMenuItemActive: {
      fontWeight: 500,

      '&, &:hover': {
        color: textPrimary,
      },
      '&:after': {
        backgroundColor: colorPrimary,
      },
    },
    navMenuText: {},

    // Menu View
    horizontalMenu: {
      display: 'flex',
      alignItems: 'center',

      '& $navMenuItem': {
        '&:not(:last-child)': {
          marginRight: 15,

          [theme.breakpoints.up('md')]: {
            marginRight: 40,
          },
          [theme.breakpoints.up('lg')]: {
            marginRight: 60,
          },
        },
      },
    },
    verticalMenu: {
      '& $navMenuItem': {
        '&:not(:last-child)': {
          marginBottom: 10,

          [theme.breakpoints.up('md')]: {
            marginBottom: 15,
          },
        },
      },
    },
  });
