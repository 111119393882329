import React from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiTabs, { TabsProps } from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';

import { Tab } from '../types';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export interface Props extends TabsProps {
  tabs: Tab[];
  onChangeTab?: (event: React.ChangeEvent<{}>, value: number | string) => void;
  className?: string;
}

export function Tabs({
  tabs,
  value,
  onChangeTab,
  variant = 'scrollable',
  scrollButtons = 'off',
  className,
  disabled: allDisabled,
}: Props) {
  const classes = useStyles();

  return (
    <MuiTabs
      classes={{
        root: cx(classes.tabsRoot, className),
        indicator: classes.tabsIndicator,
      }}
      value={value}
      onChange={onChangeTab}
      variant={variant}
      scrollButtons={scrollButtons}
      indicatorColor="primary"
    >
      {tabs.map(({ id, label, disabled = false }) => (
        <MuiTab
          classes={{
            root: cx(classes.tabRoot, 'ta-tab-button'),
            selected: classes.tabSelected,
            disabled: classes.tabDisabled,
            wrapper: classes.tabLabelWrapper,
          }}
          label={label}
          key={id}
          disabled={disabled || (value !== id && allDisabled)}
          value={id}
        />
      ))}
    </MuiTabs>
  );
}
