import { useQuery, useMutation, UseMutationOptions, UseQueryOptions } from 'react-query';

import { ApiError, getCacheMinutes } from '@app/query';

import { Availability } from '../types/Availability';
import { AVAILABILITY_REQUEST_CHECK_ITEM_KEY, AVAILABILITY_REQUEST_ITEM_KEY } from '../constants';
import {
  getAvailabilityRequest,
  updateAvailabilityRequest,
  deleteAvailabilityRequest,
  checkAvailabilityRequest,
} from '../api/request';

export const useAvailabilityRequest = (
  token: string,
  options: UseQueryOptions<Partial<Availability>, ApiError, Partial<Availability>> = {},
) =>
  useQuery<Partial<Availability>, ApiError, Partial<Availability>>(
    [AVAILABILITY_REQUEST_ITEM_KEY, token],
    () => getAvailabilityRequest(token),
    {
      cacheTime: getCacheMinutes(),
      ...options,
    },
  );

export const useAvailabilityRequestUpdate = (
  options?: UseMutationOptions<Availability, ApiError, Partial<Availability>>,
) => useMutation<Availability, ApiError, Partial<Availability>>(data => updateAvailabilityRequest(data), options);

export const useAvailabilityRequestDelete = (options?: UseMutationOptions<any, ApiError, { token: string }>) =>
  useMutation<any, ApiError, { token: string }>(
    ({ token }: { token: string }) => deleteAvailabilityRequest(token),
    options,
  );

export const useAvailabilityRequestCheck = (
  token: string,
  options?: UseQueryOptions<number | undefined, ApiError, number | undefined>,
) =>
  useQuery<number | undefined, ApiError, number | undefined>(
    [AVAILABILITY_REQUEST_CHECK_ITEM_KEY, token],
    () => checkAvailabilityRequest(token),
    { cacheTime: getCacheMinutes(3), ...options },
  );
