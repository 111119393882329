import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { destroyToken, getToken, storeToken } from '@app/auth/utils/tokenStorage';
import { apiClient, ApiError } from '@app/query';
import { UserEntity } from '@app/users/interfaces';
import { Token } from '@app/auth/interfaces';

import { AUTH_ACTIONS, ROLES } from '../constants';
import { AuthContext } from '../context';

interface AuthUser {
  setToken(token?: Token): void;
  logout(): void;
  error?: ApiError | null;
  user?: UserEntity;
  isAuthorized: boolean;
  isLoading: boolean;
  refetch: () => void;
  isAdmin: boolean;
  token?: Token;
}

const loadUser = () => apiClient.get('/auth/me');

export const useAuthUser = (): AuthUser => {
  const {
    dispatch,
    state: { user, token },
  } = useContext(AuthContext);
  const [isReady, setIsReady] = useState(false);
  const isAuthorized = !!user;

  const setToken = useCallback(
    (newToken: Token) => {
      dispatch({ type: AUTH_ACTIONS.SET_TOKEN, payload: newToken });
      storeToken(newToken);
    },
    [dispatch],
  );

  const logout = useCallback(async () => {
    await destroyToken();
    dispatch({ type: AUTH_ACTIONS.CLEAR });
  }, [dispatch]);

  const {
    isLoading: userIsLoading,
    isFetching,
    error,
    refetch,
  } = useQuery<UserEntity, ApiError>(
    ['authUser', token?.token],
    // @ts-ignore
    loadUser,
    {
      retry: false,
      enabled: Boolean(token),
      onSuccess: (payload: UserEntity) => dispatch({ type: AUTH_ACTIONS.SET_USER, payload }),
      // to check if user info was changed
      refetchInterval: 60000,
      refetchIntervalInBackground: 60000,
    },
  );

  const isAdmin = useMemo(() => Boolean(user?.role?.includes(ROLES.ADMIN)), [user?.role]);

  useEffect(() => {
    if (!isReady) {
      if (!token) {
        const restoreToken = async () => {
          try {
            const tokenFromStorage = await getToken();

            if (tokenFromStorage) {
              dispatch({ type: AUTH_ACTIONS.SET_TOKEN, payload: tokenFromStorage });
            }
          } catch (e) {
            // ¯\_(ツ)_/¯
          } finally {
            setIsReady(true);
          }
        };
        restoreToken();
      } else {
        setIsReady(true);
      }
    }
  }, [token, isReady, dispatch]);

  return {
    setToken,
    logout,
    error,
    user,
    isAuthorized,
    refetch,
    isLoading: !isReady || userIsLoading || isFetching,
    isAdmin,
    token,
  };
};
