import React, { useCallback } from 'react';
import { useField } from 'formik';
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { Calendar, useStaticState, Day, useUtils } from '@material-ui/pickers';
import { OutterCalendarProps } from '@material-ui/pickers/views/Calendar/Calendar';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export interface Props extends OutterCalendarProps {
  name: string;
  label?: string;
  helperText?: string;
  fullWidth?: boolean;
  required?: boolean;
  disablePast?: boolean;
  onChange: (date: MaterialUiPickersDate) => void;
  className?: string;
}

export const CalendarElement = ({
  name,
  label = '',
  helperText = '',
  fullWidth = false,
  required = false,
  onChange,
  shouldDisableDate = () => false,
  className = '',
  ...rest
}: Props) => {
  const [{ value }, { error, touched }, { setValue }] = useField<MaterialUiPickersDate>(name);

  const handleChange = useCallback(
    (date: MaterialUiPickersDate) => {
      if (onChange) {
        onChange(date);
      }

      setValue(date);
    },
    [onChange],
  );

  const { pickerProps } = useStaticState({
    value,
    onChange: handleChange,
  });

  const isError: boolean = Boolean(touched && error);
  const text = isError && error ? error : helperText;

  const utils = useUtils();
  const now = utils.date();

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      {Boolean(label) && (
        <InputLabel error={isError} required={required}>
          {label}
        </InputLabel>
      )}
      <Calendar
        {...pickerProps}
        {...rest}
        shouldDisableDate={shouldDisableDate}
        renderDay={(day: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, dayInCurrentMonth: boolean) => (
          <div className={`calendar-day ${Boolean(value) && utils.isSameDay(selectedDate, day) ? 'selected-day' : ''}`}>
            <Day
              disabled={shouldDisableDate(day)}
              current={utils.isSameDay(day, now)}
              hidden={!dayInCurrentMonth}
              selected={Boolean(value) && utils.isSameDay(selectedDate, day)}
            >
              {utils.getDayText(day)}
            </Day>
          </div>
        )}
      />
      {text ? <FormHelperText error={isError}>{text}</FormHelperText> : ''}
    </FormControl>
  );
};
