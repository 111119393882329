import createStyles from '@material-ui/core/styles/createStyles';

import { borderPrimaryColor, textSecondary } from '@app/app/configs/variables';

export default () =>
  createStyles({
    buttonsContainer: {
      display: 'inline-flex',
      marginLeft: 20,
    },
    button: {
      padding: 4,
      color: textSecondary,
      '&$buttonDisabled': {
        color: borderPrimaryColor,
      },
    },
    buttonDisabled: {},
  });
