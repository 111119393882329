export enum ROLES {
  RECRUITER = 'recruiter',
  ADMIN = 'admin',
}

export enum STATUSES {
  REGISTRATION_EMAIL_SENT = 'registrationEmailSent',
  ACTIVE = 'active',
  DELETED = 'deleted',
}
