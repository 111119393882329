import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import get from 'lodash.get';
import { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon, Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { Text } from '@app/ui/forms';
import { NewInterviewForm } from '@app/interview/types/Interview';

import { styles } from '../styles';

const useStyles = makeStyles(styles);

interface Props {
  removeMember: (index: number) => void;
  index: number;
  name: keyof NewInterviewForm;
  item: { name: string; email: string };
  onAddNew: (item: { name: string; email: string }) => void;
  existingEmails: string[];
}

export const NewParticipant = ({ index, removeMember, name, item, onAddNew, existingEmails }: Props) => {
  const classes = useStyles();
  const [emailError, setEmailError] = useState();
  const { t } = useTranslation('common');
  const { errors, setFieldTouched } = useFormikContext<NewInterviewForm>();

  const onBlur = () => {
    if (existingEmails.includes(item.email)) {
      setEmailError(t('availabilities.validations.uniqueEmail'));
    } else if (
      item.name &&
      item.email &&
      !get(errors, `${name}.${index}.name`) &&
      !get(errors, `${name}.${index}.email`)
    ) {
      onAddNew(item);
      removeMember(index);
      setFieldTouched(`${name}.${index}.name`, false);
      setFieldTouched(`${name}.${index}.email`, false);
    } else {
      setEmailError(undefined);
    }
  };

  return (
    <div className={classes.newParticipantContainer} onBlur={onBlur}>
      <Grid container spacing={3}>
        <Grid item>
          <Text
            name={`${name}.${index}.name`}
            placeholder={t('interviews.form.create.participants.placeholders.name')}
          />
        </Grid>
        <Grid item>
          <Text
            name={`${name}.${index}.email`}
            placeholder={t('interviews.form.create.participants.placeholders.email')}
            error={emailError}
            customErrorMessage={emailError}
          />
        </Grid>
      </Grid>

      <div>
        <SvgIcon
          component={ClearIcon}
          color="primary"
          fontSize="small"
          className={classes.participantClearIcon}
          onClick={() => removeMember(index)}
        />
      </div>
    </div>
  );
};
