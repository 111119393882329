import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';

import { ROLES } from '@app/availabilities/constants';
import { useAuthUser } from '@app/auth';

import { List } from './List';
import { ListOther } from './ListOther';

interface ParticipantProps {
  isEdit: boolean;
}

export const Participants = ({ isEdit = false }: ParticipantProps) => {
  const { t } = useTranslation('common');
  const { search } = useLocation();
  const { user } = useAuthUser();
  const params = new URLSearchParams(search);
  const participantsString = params.get('participants') ?? '';

  return (
    <>
      <Typography variant="h2" gutterBottom>
        {t('interviews.form.create.participants.title')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={isEdit ? 4 : 6}>
          <List
            name="interviewers"
            newName="newInterviewers"
            title={t('interviews.form.create.participants.interviewer')}
            searchPlaceholder={t('interviews.form.create.participants.placeholders.searchInterviewer')}
            participantsString={participantsString}
            roles={[ROLES.INTERVIEWER, ROLES.RECRUITER, ROLES.ADMIN]}
            recruiterAvailability={user?.availability}
            isAddRecruiter
          />
        </Grid>
        <Grid item xs={12} sm={6} md={isEdit ? 4 : 6}>
          <List
            name="candidates"
            newName="newCandidates"
            title={t('interviews.form.create.participants.candidate')}
            searchPlaceholder={t('interviews.form.create.participants.placeholders.searchCandidate')}
            participantsString={participantsString}
            roles={[ROLES.CANDIDATE]}
          />
        </Grid>
        {isEdit && (
          <Grid item xs={12} sm={6} md={4}>
            <ListOther name="others" newName="newOthers" title={t('interviews.form.create.participants.other')} />
          </Grid>
        )}
      </Grid>
    </>
  );
};
