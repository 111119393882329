import { createStyles, Theme } from '@material-ui/core/styles';

import { borderPrimaryColor } from '@app/app/configs/variables';

export const styles = ({ breakpoints }: Theme) =>
  createStyles({
    divider: {
      margin: '15px 0 13px 0',
      borderTop: `1px solid ${borderPrimaryColor}`,

      [breakpoints.up('md')]: {
        margin: '36px 0 31px 0',
      },
    },
    availabilityBlock: {
      marginTop: 15,
      paddingTop: 15,
      borderTop: `1px solid ${borderPrimaryColor}`,
    },
    changePasswordButton: {
      marginTop: 24,
    },
    changePasswordIcon: {
      marginRight: 10,
    },
  });
