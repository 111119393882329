import { createStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    card: {
      '@media (min-width: 1460px)': {
        flexGrow: 0,
        maxWidth: '33.333333%',
        flexBasis: '33.333333%',
      },
    },
  });
