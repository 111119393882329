import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGroupSchedulerViaInviteLink } from '@app/groupScheduler/hooks/crud';

export const GroupDescription = memo(({ token }: { token: string }) => {
  const { data } = useGroupSchedulerViaInviteLink({ token });
  const { t } = useTranslation('common');
  return <>{t('interviews.form.create.confirmedInterviewMessage.groupDescription', { subject: data?.subject })}</>;
});
