import cx from 'classnames';
import { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ConnectDropTarget, DropTargetMonitor, DropTarget, DropTargetConnector } from 'react-dnd';

import { makeStyles } from '@material-ui/core/styles';

import DndBackgroundImage from '@app/app/images/dndBackgrounImage.png';
import { AvailabilityEntity } from '@app/availabilities/types/Availability';

import { Item } from './Item';
import { styles } from './styles';

const useStyles = makeStyles(styles);

interface Props {
  readonly items: AvailabilityEntity[];
  readonly setItems: Dispatch<SetStateAction<AvailabilityEntity[]>>;

  // Collected Props
  // canDrop?: boolean;
  // isOver?: boolean;
  readonly connectDropTarget: ConnectDropTarget;
}

const Component = ({ items, connectDropTarget, setItems }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const filledList = items.length > 0;

  return (
    <div className={cx(classes.draggableArea, { [classes.filledDraggableArea]: filledList })} ref={connectDropTarget}>
      {filledList ? (
        <>
          {items.map(item => (
            <Item key={item.id} item={item} setItems={setItems} />
          ))}
        </>
      ) : (
        <>
          <div>
            <img src={DndBackgroundImage} alt="dnd" />
          </div>
          <div className={classes.draggableAreaText}>
            <Trans t={t} defaults="groupSchedulerLink.list.emptyDnDArea" components={{ br: <br /> }} />
          </div>
        </>
      )}
    </div>
  );
};

export const DropArea = DropTarget('card', {}, (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(Component);
