import { useDate } from '@app/app/hooks/useDate';
import { Timezone } from '@app/timezones/interfaces';

import { Slot } from '../types/Slot';
import { useInterviewTime } from './useInterviewTime';
import { FREE_SLOTS_KEY_FORMAT } from '../constants';

const utcTimezone = {
  id: 1,
  name: '(GMT+00:00) GMT (no daylight saving)',
  shortName: 'GMT',
  key: 'Etc/GMT',
  offset: 0,
  gmtName: 'GMT+00:00',
};

export const useFormSlotName = () => {
  const { djs } = useDate();
  const draftDate = djs.utc().format(FREE_SLOTS_KEY_FORMAT);
  const { toDateTime } = useInterviewTime();

  return ({ day, timezones, value }: { day: string; value: Slot; timezones: Array<{ timezone: Timezone }> }) =>
    (timezones.length ? timezones : [{ timezone: utcTimezone }]).map(({ timezone: { offset } }) => ({
      from: toDateTime(`${draftDate}-${day}`, value.from, {
        offset,
        userTimezone: false,
        shortDate: true,
      }),
      to: toDateTime(`${draftDate}-${day}`, value.to, { offset, userTimezone: false, shortDate: true }),
    }));
};
