import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAvailabilityViaInviteLink } from '@app/availabilities/hooks/crud';

export const AvailabilityDescription = memo(({ token }: { token: string }) => {
  const { data } = useAvailabilityViaInviteLink({ token });
  const { t } = useTranslation('common');
  return <>{t('interviews.form.create.confirmedInterviewMessage.availabilityDescription', { name: data?.name })}</>;
});
