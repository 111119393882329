import { memo, useCallback, ChangeEvent } from 'react';
import { useField } from 'formik';
import { RadioGroupProps } from '@material-ui/core/RadioGroup/RadioGroup';
import {
  FormHelperText,
  InputLabel,
  FormControl,
  FormControlLabel,
  RadioGroup as RadioGroupMaterial,
  Radio,
} from '@material-ui/core';

interface RadioOption {
  id: string;
  label: string;
}
export interface Props extends RadioGroupProps {
  name: string;
  label?: string;
  required: boolean;
  options: RadioOption[];
}

export const RadioGroup = memo(({ name, label, onChange: customOnChange, options = [], required = false }: Props) => {
  const [{ onChange, name: formikName, ...field }, meta] = useField<string>(name);
  const { touched, error } = meta;
  const isError: boolean = Boolean(touched && error);

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      onChange(event);

      if (customOnChange) {
        customOnChange(event, value);
      }
    },
    [customOnChange],
  );

  return (
    <FormControl error={isError}>
      {label && <InputLabel>{label}</InputLabel>}
      <RadioGroupMaterial name={name} onChange={onChangeHandler} {...field}>
        {options.map(({ id, label }) => (
          <FormControlLabel key={id} value={id} label={label} control={<Radio color="primary" required={required} />} />
        ))}
        {isError && error && <FormHelperText>{error}</FormHelperText>}
      </RadioGroupMaterial>
    </FormControl>
  );
});
