import { useCallback, useReducer } from 'react';

import { CHANGE_FILTER_ACTION, CHANGE_PAGE_ACTION } from '../actions';
import { State, filterStateReducer as reducer } from '../reducers/filterStateReducer';

export const useFiltersState = (initialState: State) => {
  const [filtersState, dispatch] = useReducer(reducer, initialState);
  const changePage = useCallback(page => {
    dispatch({
      type: CHANGE_PAGE_ACTION,
      payload: {
        pageIndex: page,
      },
    });
  }, []);

  const changeFilter = useCallback(data => {
    dispatch({
      type: CHANGE_FILTER_ACTION,
      payload: {
        globalFilter: data,
      },
    });
  }, []);

  return {
    filtersState,
    changePage,
    changeFilter,
  };
};
