import React, { useCallback, useEffect, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import cx from 'classnames';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, ButtonBase, FormHelperText, Grid, Typography, Box } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { CALENDLY_FREE_SLOTS_KEY_FORMAT, FREE_SLOTS_DAY_FORMAT, FREE_SLOTS_KEY_FORMAT } from '@app/interview/constants';
import { FreeSlots, Slot, FreeSlotsResponseTimezone } from '@app/interview/types/Slot';
import { Calendar, Checkbox, Timezones } from '@app/ui/forms';
import { useDate } from '@app/app/hooks/useDate';
import { Loader } from '@app/ui/loader';
import { useFormSlotName } from '@app/interview/hooks/useFormSlotName';

import { Timezone } from '@app/timezones/interfaces';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export interface Props {
  slots: FreeSlots;
  timezones: FreeSlotsResponseTimezone[];
  onLoad: (date: MaterialUiPickersDate) => Promise<void>;
  onCalendarChange?: (date: MaterialUiPickersDate) => Promise<void>;
  isLoading: boolean;
  isCalendly?: boolean;
}

export const InterviewSelectDate: React.FC<Props> = ({
  slots: defaultSlots,
  timezones,
  onLoad,
  isLoading,
  onCalendarChange,
  isCalendly,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const respDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation('common');
  const { djs } = useDate();
  const { values, setFieldValue } = useFormikContext<{
    date: string;
    time: {
      from: number;
      to: number;
    } | null;
  }>();
  const selectedDay = djs(values.date).format('D');
  const selectedKey = djs(values.date).format(isCalendly ? CALENDLY_FREE_SLOTS_KEY_FORMAT : FREE_SLOTS_KEY_FORMAT);
  const [{ value: isCustomTimezone }, , { setValue: setIsCustomTimezone }] = useField<boolean>('isCustomTimezone');
  const [{ value: customTimezone }, , { setValue: setCustomTimezone }] = useField<Timezone | undefined>(
    'customTimezone',
  );

  const formSlotName = useFormSlotName();

  useEffect(
    () => () => {
      setCustomTimezone(undefined);
      setIsCustomTimezone(false);
    },
    [],
  );

  const customTimezoneSlots = useMemo(() => {
    const result: FreeSlots = {};

    Object.entries(defaultSlots).forEach(([date, days]) => {
      result[date] = {};

      Object.entries(days).forEach(([day, values]) => {
        result[date][day] = values.map(({ value }) => ({
          value,
          name: formSlotName({ day, value, timezones: customTimezone ? [{ timezone: customTimezone }] : [] }),
        }));
      });
    });

    return result;
  }, [customTimezone, defaultSlots, formSlotName]);

  const slots = useMemo(
    () => (isCustomTimezone ? customTimezoneSlots : defaultSlots),
    [defaultSlots, isCustomTimezone, customTimezoneSlots],
  );

  useUpdateEffect(() => {
    if (!values?.date && Object.keys(slots).length) {
      const availableMonth = Object.entries(slots).find(([, month]) => !!Object.values(month).find(day => day.length));
      if (availableMonth) {
        const day = Object.entries(availableMonth[1]).find(([, day]) => !!day.length);
        setFieldValue('date', djs(`${availableMonth[0]}-${day ? day[0] : 1}`).format(CALENDLY_FREE_SLOTS_KEY_FORMAT));
      }
    }
  }, [slots]);

  const handleShouldDisableDate = useCallback(
    date => {
      const key = date.format(isCalendly ? CALENDLY_FREE_SLOTS_KEY_FORMAT : FREE_SLOTS_KEY_FORMAT);
      const day = date.format('D');

      if (isCalendly || !Object.keys(slots).length) {
        return date.format('YYYY-MM-DD') < djs.utc().format('YYYY-MM-DD');
      }

      return !slots[key] || !Array.isArray(slots[key][day]) || slots[key][day].length === 0;
    },
    [slots],
  );

  const [{ value }, { error, touched }, { setValue, setTouched: setTimeTouched }] = useField<Slot>('time');

  const handleCalendarChange = useCallback(
    date => {
      setFieldValue('time', null);
      setTimeTouched(false);

      if (onCalendarChange) {
        onCalendarChange(date);
      }
    },
    [onCalendarChange],
  );

  const isError: boolean = Boolean(touched && error);

  useEffect(() => {
    onLoad(djs(values.date));
  }, []);

  const inActive = (selectedValue: Slot) =>
    value && selectedValue && selectedValue.to === value.to && selectedValue.from === value.from;

  const currentDaySlot = slots?.[selectedKey]?.[selectedDay];

  return (
    <>
      <Grid
        container
        spacing={4}
        alignItems="center"
        wrap={respDesktop ? 'nowrap' : 'wrap'}
        className={classes.datetimeContainer}
      >
        {isLoading && <Loader showOverlay />}
        <Grid item xs={12} md={5}>
          <Typography variant="h3" color="textPrimary" gutterBottom>
            {t('interviews.form.create.modal.firstStepTitle')}
          </Typography>
          <Calendar
            name="date"
            disablePast
            onChange={handleCalendarChange}
            onMonthChange={!isCalendly ? onLoad : undefined}
            shouldDisableDate={handleShouldDisableDate}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          {values.date ? (
            currentDaySlot && !!currentDaySlot.length ? (
              <>
                <Typography variant="h5" color="textPrimary" gutterBottom>
                  {djs(values.date).format(FREE_SLOTS_DAY_FORMAT)}
                </Typography>
                <div className={cx(classes.interviewTimeBlock, 'native-custom-scrollbar')}>
                  <div className={cx(classes.interviewTimeRow, classes.interviewTimeHeader)}>
                    {(isCustomTimezone && customTimezone
                      ? [{ timezone: customTimezone, type: undefined }]
                      : timezones
                    ).map(({ timezone: { gmtName, id, shortNameKeyForUSA }, type }) => (
                      <div key={id} className={classes.interviewTimeItem}>
                        <div className={classes.interviewTimeZone}>{gmtName}</div>
                        {shortNameKeyForUSA && <div className={classes.interviewTimeZone}>{shortNameKeyForUSA}</div>}
                        <div className={classes.interviewTimeZoneType}>
                          {type && t(`interviews.form.create.modal.timezoneTypes.${type}`)}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={classes.interviewTimeBody}>
                    {currentDaySlot.map(option => (
                      <ButtonBase
                        key={JSON.stringify(option.value)}
                        onClick={() => setValue(option.value)}
                        className={cx(classes.interviewTimeRow, classes.interviewTimeButtonRow, 'ta-interview-time', {
                          [classes.active]: inActive(option.value),
                        })}
                      >
                        {option.name.map(
                          // ({ from: [fromDate, fromTime, isFromChanged], to: [toDate, toTime, isToChanged] }) => (
                          ({ from: [fromDate, fromTime], to: [, toTime] }, idx) => (
                            <div
                              key={`${idx}-${fromDate}-${fromTime}`}
                              className={cx(classes.interviewTimeItem, classes.interviewTimeValue)}
                            >
                              <div>
                                {fromTime}
                                {/* {isFromChanged && <div className={classes.interviewTimeValueDate}>{fromDate}</div>} */}
                              </div>
                              <div>&nbsp;-&nbsp;</div>
                              <div>
                                {toTime}
                                {/* {isToChanged && <div className={classes.interviewTimeValueDate}>{toDate}</div>} */}
                              </div>
                            </div>
                          ),
                        )}
                      </ButtonBase>
                    ))}
                  </div>
                </div>
                {isError ? <FormHelperText error={isError}>{error}</FormHelperText> : ''}
              </>
            ) : (
              <Box textAlign="center">
                <Typography color="textSecondary">
                  {t('interviews.form.create.modal.interviewEmptySlotHelpText')}
                </Typography>
              </Box>
            )
          ) : (
            <Box textAlign="center">
              <Typography color="textSecondary">
                {t('interviews.form.create.modal.interviewSelectDateHelpText')}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={6} md={5}>
          <Checkbox name="isCustomTimezone" label={t('interviews.form.create.modal.fields.showInMyTimezone')} />
        </Grid>
        <Grid item xs={6} md={7} className={!isCustomTimezone ? classes.customTimezoneContainerHidden : undefined}>
          <Timezones name="customTimezone" disableClearable />
        </Grid>
      </Grid>
    </>
  );
};
