import { useTranslation } from 'react-i18next';

import { Grid } from '@material-ui/core';

import { Text } from '@app/ui/forms';

export const InterviewDetails = () => {
  const { t } = useTranslation('common');

  const durationOptions = [
    { id: 30, name: t('availabilities.time.30') },
    { id: 60, name: t('availabilities.time.60') },
    { id: 90, name: t('availabilities.time.90') },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Text name="title" label={t('interviews.form.create.interviewDetails.fields.title')} required />
      </Grid>
      <Grid item xs={12} md={2}>
        <Text
          name="duration"
          select
          required
          options={durationOptions}
          label={t('interviews.form.create.interviewDetails.fields.duration')}
        />
      </Grid>
    </Grid>
  );
};
