import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';
import { memo } from 'react';

import { DetailedPayment } from '@app/payments/types';
import { UPCOMING_INVOICE_TYPES } from '@app/payments/constants';

interface Props {
  cell: Cell<DetailedPayment>;
}

export const DescriptionCell = memo(
  ({
    cell: {
      row: {
        original: { type, quantity },
      },
    },
  }: Props) => {
    const { t } = useTranslation('common');

    const description = t(
      `user.profile.tabs.billing.detailedInfo.${
        type === UPCOMING_INVOICE_TYPES.INVOICE_ITEM ? 'descriptionInvoice' : 'descriptionPlan'
      }`,
      { quantity },
    );

    return <span>{description}</span>;
  },
);
