import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { NewInterview } from '@app/interview/components/New';
import { MainLayout } from '@app/app/components/MainLayout';
import { usePlan } from '@app/payments/hooks/usePlan';

import { styles } from './styles';

const useStyles = makeStyles(styles);

export const NewInterviewPage = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const { push } = useHistory();
  const { isBasic } = usePlan();

  if (isBasic) {
    push('/');
  }

  return (
    <MainLayout
      breadcrumbs={[
        { title: t('interviews.list.title'), to: '/interviews' },
        { title: t('interviews.form.create.title') },
      ]}
    >
      <Typography variant="h1" gutterBottom>
        {t('interviews.form.create.title')}
      </Typography>
      <Paper className={classes.paper}>
        <NewInterview />
      </Paper>
    </MainLayout>
  );
};
