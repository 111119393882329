import { createStyles } from '@material-ui/core/styles';

import { colorDanger, borderRadius, textPrimary, colorGreyLight } from '@app/app/configs/variables';

import { style as selectStyle } from './select';

export const styles = () =>
  createStyles({
    ...selectStyle(),
    textFieldRoot: {},
    startAdornment: {
      marginRight: 10,
    },
    inputWrapper: {
      '& .error-icon': {
        color: colorDanger,
      },
      '&[rows]': {
        height: 'auto',
        minHeight: '130px',
      },
    },

    // Autocomplete
    autocompleteChip: {
      backgroundColor: colorGreyLight,
      color: textPrimary,
      height: 23,
      margin: '2px 5px 2px 0',
      maxWidth: 'calc(100% - 5px)',
      borderRadius,
    },
    autocompleteChipDeleteIcon: {
      color: textPrimary,
      width: 12,
      height: 12,
      margin: '0 8px 0 0',

      '&:hover': {
        opacity: 0.8,
      },
    },
  });
