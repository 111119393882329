import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { CondOperator, CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';

import Paper from '@material-ui/core/Paper';

import { Autocomplete } from '@app/ui/autocomplete';
import { apiClient } from '@app/query';
import { useDebouncedValue } from '@app/app/hooks/useDebouncedValue';
import { Availability } from '@app/availabilities/types/Availability';

export interface SearchProps {
  readonly onSearch: (value: string[]) => void;
}

export const Search = ({ onSearch }: SearchProps) => {
  const { t } = useTranslation('common');
  const [value, setValue] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');

  const debouncedInputValue = useDebouncedValue(inputValue, 500);

  const { data = [] } = useQuery(['/availabilities', debouncedInputValue], async ({ queryKey }) => {
    const [url, val] = queryKey;

    if (!val) {
      return [];
    }

    const filter: CreateQueryParams = {
      fields: ['email', 'name'],
      filter: [{ field: 'name', operator: CondOperator.CONTAINS_LOW, value: val }],
      or: {
        field: 'email',
        operator: CondOperator.CONTAINS_LOW,
        value: val,
      },
    };

    const result = await apiClient.get<any, Availability[]>(url, {
      params: RequestQueryBuilder.create(filter).queryObject,
    });

    if (val.includes('.') || val.includes('@')) {
      const matchNames = result.filter(i => {
        const [name] = i.email.split('@');
        return name.includes(val) || i.email.includes(val);
      });
      const matchDomains = result.filter(i => {
        const [, domain] = i.email.split('@');
        return `@${domain}`.includes(val);
      });
      return [
        ...new Set(matchDomains.map(item => item.email.replace(/\S+@/g, '@'))),
        ...matchNames.map(item => `${item.name} (${item.email})`),
      ];
    }

    return result.map(item => `${item.name} (${item.email})`);
  });

  const handleChange = useCallback(value => {
    setValue(value);
    setInputValue('');
    onSearch(value);
  }, []);

  return (
    <Autocomplete
      multiple
      // @ts-ignore
      options={data}
      label={t('availabilities.fields.search.label')}
      placeholder={t('availabilities.fields.search.placeholder')}
      PaperComponent={({ children, ...paperProps }) =>
        Array.isArray(data) && data.length > 0 ? <Paper {...paperProps}>{children}</Paper> : <></>
      }
      inputValue={inputValue}
      onInputChange={(e, val, reason) => {
        if (reason !== 'reset') {
          setInputValue(val);
        }
      }}
      filterOptions={options => {
        if (!value) {
          return options;
        }

        // @ts-ignore
        return options.filter(option => !value.find(val => val === option));
      }}
      value={value}
      onChange={handleChange}
    />
  );
};
