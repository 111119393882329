import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Formik } from 'formik';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Link,
  FormControlLabel,
  Checkbox,
  Radio,
  Switch,
  Container,
  Box,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { Text, Password, Autocomplete } from '@app/ui/forms';
import { Alert } from '@app/ui/alert';
import { Chip } from '@app/ui/chip';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      minHeight: '100%',
      margin: '0 auto',
      paddingTop: 30,
      paddingBottom: 30,
    },
    mainBlock: {
      marginBottom: 50,
    },
    subBlock: {
      marginBottom: 30,
    },
  }),
);

const selectOptions = [
  {
    id: 1,
    name: 'Option 1',
  },
  {
    id: 2,
    name: 'Option 2',
  },
  {
    id: 3,
    name: 'Option 3',
  },
];

export function ThemePage() {
  const classes = useStyles();

  const handleEmptyDemo = () => {};

  return (
    <Container className={classes.container} maxWidth="xl">
      <Box textAlign="center">
        <Typography variant="h1" gutterBottom>
          Theme Design Framework
        </Typography>
      </Box>

      <div className={classes.mainBlock}>
        <Typography variant="h2" gutterBottom>
          Fonts
        </Typography>
        <Typography className="title-large" variant="h1" gutterBottom>
          H 1 Large
        </Typography>
        <Typography variant="h1" gutterBottom>
          H 1
        </Typography>
        <Typography variant="h2" gutterBottom>
          H 2
        </Typography>
        <Typography variant="h3" gutterBottom>
          H 3
        </Typography>
        <Typography variant="h4" gutterBottom>
          H 4
        </Typography>
        <Typography paragraph>Body</Typography>
        <Typography component="p" variant="subtitle1" gutterBottom>
          Description
        </Typography>
      </div>

      <div className={classes.mainBlock}>
        <Typography variant="h2" gutterBottom>
          Buttons
        </Typography>
        <div className={classes.subBlock}>
          <Typography variant="h4" gutterBottom>
            Button Contained
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md="auto">
              <Button variant="contained">Default</Button>
            </Grid>
            <Grid item xs={12} sm={6} md="auto">
              <Button variant="contained" color="primary">
                Primary button
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md="auto">
              <Button variant="contained" color="primary" disabled>
                Primary button Disabled
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md="auto">
              <Button variant="contained" color="secondary">
                Secondary button
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md="auto">
              <Button variant="contained" color="secondary" disabled>
                Secondary button Disabled
              </Button>
            </Grid>
          </Grid>
        </div>

        <div className={classes.subBlock}>
          <Typography variant="h4" gutterBottom>
            Button Outlined
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md="auto">
              <Button variant="outlined">Default</Button>
            </Grid>
            <Grid item xs={12} sm={6} md="auto">
              <Button variant="outlined" color="primary">
                Primary
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md="auto">
              <Button variant="outlined" color="primary" disabled>
                Primary Disabled
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md="auto">
              <Button variant="outlined" color="secondary">
                Secondary button
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md="auto">
              <Button variant="outlined" color="secondary" disabled>
                Secondary button Disabled
              </Button>
            </Grid>
          </Grid>
        </div>

        <div className={classes.subBlock}>
          <Typography variant="h4" gutterBottom>
            Button Text
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md="auto">
              <Button>Default</Button>
            </Grid>
            <Grid item xs={12} sm={6} md="auto">
              <Button color="primary" startIcon={<EditIcon />}>
                Text with icon
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md="auto">
              <Button color="primary" startIcon={<EditIcon />} component={RouterLink} to="/theme">
                Button with Link
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md="auto">
              <Button color="primary" disabled startIcon={<EditIcon />}>
                Text with icon Disabled
              </Button>
            </Grid>
          </Grid>
        </div>

        <div className={classes.subBlock}>
          <Typography variant="h4" gutterBottom>
            Link
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md="auto">
              <Link to="/theme" component={RouterLink} underline="always">
                Link in the text
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={classes.mainBlock}>
        <Typography variant="h3" gutterBottom>
          Form Fields
        </Typography>
        <div className={classes.subBlock}>
          <Typography variant="h4" gutterBottom>
            Inputs
          </Typography>
          <Formik initialValues={handleEmptyDemo} onSubmit={handleEmptyDemo}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} md="auto">
                    <Text label="Label" name="Label" type="text" />
                  </Grid>
                  <Grid item xs={12} sm={6} md="auto">
                    <Text label="Placeholder" placeholder="Placeholder" name="placeholder" type="text" />
                  </Grid>
                  <Grid item xs={12} sm={6} md="auto">
                    <Password label="Password" name="password" />
                  </Grid>
                  <Grid item xs={12} sm={6} md="auto">
                    <Text label="Error" name="Error" type="text" error helperText="Validation Error Text" />
                  </Grid>
                  <Grid item xs={12} sm={6} md="auto">
                    <Text label="Disabled" name="Disabled" type="text" disabled />
                  </Grid>
                  <Grid item xs={12} sm={6} md="auto">
                    <Text label="Textarea" name="Textarea" type="textarea" multiline rows={5} />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>

        <div className={classes.subBlock}>
          <Typography variant="h4" gutterBottom>
            Selects
          </Typography>
          <Formik initialValues={handleEmptyDemo} onSubmit={handleEmptyDemo}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Text select options={selectOptions} label="Label" name="Label" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Text
                      select
                      options={selectOptions}
                      label="Error"
                      name="Error"
                      error
                      helperText="Validation Error Text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Text select options={selectOptions} label="Disabled" name="Disabled" disabled />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Autocomplete options={[]} label="Autocomplete" name="Autocomplete" />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </div>

      <div className={classes.mainBlock}>
        <Typography variant="h2" gutterBottom>
          Selection Controls
        </Typography>
        <div className={classes.subBlock}>
          <Typography variant="h4" gutterBottom>
            Checkboxes
          </Typography>
          <div>
            <FormControlLabel control={<Checkbox onChange={handleEmptyDemo} checked />} label="Checkbox" />
            <FormControlLabel control={<Checkbox onChange={handleEmptyDemo} />} label="Checkbox" />
            <FormControlLabel control={<Checkbox onChange={handleEmptyDemo} disabled />} label="Checkbox" />
            <FormControlLabel control={<Checkbox onChange={handleEmptyDemo} checked disabled />} label="Checkbox" />
          </div>
        </div>

        <div className={classes.subBlock}>
          <Typography variant="h4" gutterBottom>
            Radio
          </Typography>
          <div>
            <FormControlLabel control={<Radio onChange={handleEmptyDemo} checked />} label="Radio" />
            <FormControlLabel control={<Radio onChange={handleEmptyDemo} />} label="Radio" />
            <FormControlLabel control={<Radio onChange={handleEmptyDemo} disabled />} label="Radio" />
            <FormControlLabel control={<Radio onChange={handleEmptyDemo} checked disabled />} label="Radio" />
          </div>
        </div>

        <div className={classes.subBlock}>
          <Typography variant="h4" gutterBottom>
            Switch
          </Typography>
          <div>
            <FormControlLabel control={<Switch onChange={handleEmptyDemo} color="secondary" checked />} label="" />
            <FormControlLabel control={<Switch onChange={handleEmptyDemo} checked />} label="" />
            <FormControlLabel control={<Switch onChange={handleEmptyDemo} />} label="" />
            <FormControlLabel control={<Switch onChange={handleEmptyDemo} disabled />} label="" />
            <FormControlLabel control={<Switch onChange={handleEmptyDemo} checked disabled />} label="" />
          </div>
        </div>

        <div className={classes.mainBlock}>
          <Typography variant="h3" gutterBottom>
            Chip
          </Typography>
          <div className={classes.subBlock}>
            <Typography variant="h4" gutterBottom>
              Chip Color
            </Typography>
            <div>
              <Grid container spacing={4}>
                <Grid item xs={12} sm="auto">
                  <Chip label="Default" />
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Chip label="Primary" colors="primary" />
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Chip label="Secondary" colors="secondary" />
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Chip label="Danger" colors="danger" />
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Chip label="Disable" disabled />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={classes.subBlock}>
            <Typography variant="h4" gutterBottom>
              Chip Statuses
            </Typography>
            <div>
              <Grid container spacing={4}>
                <Grid item xs={12} sm="auto">
                  <Chip label="Waiting" status="waiting" />
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Chip label="Active" status="active" />
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Chip label="Expired" status="expired" />
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Chip label="Incomplete" status="incomplete" />
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Chip label="Finished" status="finished" />
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Chip label="Finished No Icon" status="finished" icon={<></>} />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>

        <div className={classes.mainBlock}>
          <Typography variant="h2" gutterBottom>
            Alert
          </Typography>
          <div className={classes.subBlock}>
            <Typography variant="h4" gutterBottom>
              Pop-up
            </Typography>
            <Box mb={3}>
              <Alert severity="warning">No Internet connection!</Alert>
            </Box>
            <Box mb={3}>
              <Alert severity="success">The scheduler link was copied</Alert>
            </Box>
            <Box mb={3}>
              <Alert severity="error">Server error</Alert>
            </Box>
            <Box mb={3}>
              <Alert severity="info">Important information message</Alert>
            </Box>
          </div>

          <div className={classes.subBlock}>
            <Typography variant="h4" gutterBottom>
              Without Icon
            </Typography>
            <Box mb={3}>
              <Alert severity="warning" hideIcon>
                No Icon! Warning
              </Alert>
            </Box>
            <Box mb={3}>
              <Alert severity="error" hideIcon>
                No Icon! Error
              </Alert>
            </Box>
          </div>
        </div>
      </div>
    </Container>
  );
}
