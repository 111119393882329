import { Theme, createStyles } from '@material-ui/core/styles';

import {
  bgPaper,
  borderPrimaryColor,
  borderRadiusSmall,
  colorPrimary,
  colorPrimaryLight,
} from '@app/app/configs/variables';

export default ({ breakpoints }: Theme) =>
  createStyles({
    btnSignUp: {
      textAlign: 'right',
    },
    paper: {
      backgroundColor: bgPaper,
      padding: 20,
      marginBottom: 24,
    },
    cantConnectWrapper: {
      textAlign: 'right',
    },
    regHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 21,
    },
    stepBlock: {
      color: colorPrimary,
      display: 'flex',
      alignItems: 'baseline',
      marginLeft: 10,
    },
    activeStep: {
      fontSize: 32,
      fontWeight: 500,
    },
    totalSteps: {
      fontSize: 22,
      fontWeight: 400,
    },

    connectionSuccessMessage: {
      fontWeight: 500,
    },
    availableDaysContainer: {
      border: `1px solid ${borderPrimaryColor}`,
      display: 'flex',
      borderRadius: borderRadiusSmall,
    },
    availableDay: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
      flex: 1,
      margin: 0,
      padding: '5px 5px 10px',
      minWidth: 90,

      '&:not(:first-child)': {
        borderLeft: `1px solid ${borderPrimaryColor}`,
      },
    },
    availableDayCheckbox: {
      margin: 0,
    },
    secondStepTooltip: {
      fontSize: 18,
      marginLeft: 10,
      cursor: 'pointer',
    },

    regRecruiterBlock: {
      marginTop: -30,
    },
    regRecruiterImageBlock: {
      textAlign: 'right',
      '& img': {
        [breakpoints.up('md')]: {
          marginRight: 40,
        },
      },
    },
    regRecruiterName: {
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 3,
    },
    regRecruiterEmail: {
      wordBreak: 'break-all',
    },
    regRecruiterAvatar: {
      fontSize: 24,
      backgroundColor: colorPrimaryLight,
      color: colorPrimary,
      height: 63,
      width: 63,
      marginRight: 20,
    },
    regRecruiterButtonBlock: {
      '& button:not(:first-child)': {
        marginLeft: 20,
      },
    },
    regButton: {
      marginTop: 24,
    },
  });
