import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';

import { borderSecondaryColor, borderPrimaryColor, textSecondary } from '@app/app/configs/variables';

export const styles = ({ breakpoints }: Theme) =>
  createStyles({
    dialogPaper: {
      border: `1px solid ${borderSecondaryColor}`,
    },
    dialogTitleTypography: {
      lineHeight: '30px',
    },
    dialogDescriptionTypography: {
      lineHeight: '22px',
      color: textSecondary,
      marginTop: 8,
      '& button': {},
    },
    dialogNoTitle: {},
    dialogText: {
      fontSize: 14,
      marginBottom: 0,

      [breakpoints.up('sm')]: {
        fontSize: 16,
      },
    },
    dialogActionsContainer: {
      width: '100%',
      paddingTop: 20,
      borderTop: `1px solid ${borderPrimaryColor}`,
      flexDirection: 'column-reverse',
      display: 'flex',

      [breakpoints.up('sm')]: {
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
      },
      '& button': {
        [breakpoints.down('xs')]: {
          width: '100%',
          '&:last-child': {
            marginBottom: 12,
          },
        },
        [breakpoints.up('sm')]: {
          '&:not(:last-child)': {
            marginRight: 12,
          },
        },
      },
    },
    buttonClose: {
      padding: 4,
      position: 'absolute',
      top: 4,
      right: 4,
      [breakpoints.up('sm')]: {
        top: 21,
        right: 21,
      },
    },
    iconClose: {
      fontSize: 18,
      color: '#938B7D',
    },
  });
