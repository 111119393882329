import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Grid, Paper, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { error } from '@app/snackbars';
import { Text, Timezones, yup } from '@app/ui/forms';

import { styles } from './styles';
import { RESOURCES } from '../../constants';
import { Availability } from '../../types/Availability';

const useStyles = makeStyles(styles);

export interface CalendlyProps {
  readonly onUpdate: (resource: RESOURCES, data: Availability['meta'], withMessage?: boolean) => Promise<void>;
  readonly onDelete: () => Promise<void>;
  readonly data: Partial<Availability>;
}

export const Calendly = ({ data, onUpdate, onDelete }: CalendlyProps) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const handleSend = async (formData: { link: string; timezoneId: number | { id: number } }) => {
    try {
      if (formData.link && formData.timezoneId) {
        await onUpdate(RESOURCES.CALENDLY, [{
          ...formData,
          timezoneId:
            formData.timezoneId && typeof formData.timezoneId === 'object'
              ? formData.timezoneId.id
              : formData.timezoneId,
        }]);
      }
    } catch (e) {
      error(e?.message);
    }
  };

  const handleTimezoneChange = async (handleSubmit: Function) => {
    try {
      if (data.resource === RESOURCES.CALENDLY) {
        setTimeout(() => handleSubmit(), 100);
      }
    } catch (e) {
      error(e?.message);
    }
  };
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        link: yup
          .string()
          .matches(/(?:https?:\/\/|www\.)+calendly.com\/\S{3,}\/\S+/, t('availabilities.validations.calendlyLink'))
          .required(t('general.validations.required')),
        timezoneId: yup.mixed().required(t('general.validations.required')).nullable(),
      }),
    [t],
  );

  const initialValues = useMemo(() => {
    const { link = '', timezoneId } = data.meta && data.meta.length ? data.meta[0] : { timezoneId: undefined };
    return { link, timezoneId };
  }, [data]);

  const onDisconnect = useCallback(
    setFieldValue => () => {
      onDelete();
      setFieldValue('link', undefined);
    },
    [onDelete],
  );

  return (
    <Paper className={classes.paper} elevation={0}>
      <Formik onSubmit={handleSend} initialValues={initialValues} validationSchema={validationSchema}>
        {({ handleSubmit, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h3" gutterBottom>
                  {t('availabilities.request.calendly.title')}
                </Typography>
                {t('availabilities.request.calendly.description')}
              </Grid>
              <Grid item xs={12} md={4}>
                <Text
                  className={classes.inputWithTooltip}
                  name="link"
                  label={
                    <>
                      <span>{t('availabilities.request.fields.calendlyLink.label')}*</span>
                      <Tooltip title={<span>{t('availabilities.request.calendly.tooltip')}</span>} placement="top">
                        <InfoIcon fontSize="small" color="inherit" />
                      </Tooltip>
                    </>
                  }
                  margin="none"
                  fullWidth
                  required
                  placeholder={t('availabilities.request.fields.calendlyLink.placeholder')}
                  disabled={data.resource === RESOURCES.CALENDLY}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Timezones
                  required
                  margin="none"
                  name="timezoneId"
                  label={t('availabilities.request.fields.timezone')}
                  onChange={() => handleTimezoneChange(handleSubmit)}
                />
              </Grid>
              <Grid item xs={12} md={3} className={classes.calendlyButtons}>
                {data.resource === RESOURCES.CALENDLY ? (
                  <Button color="primary" variant="outlined" onClick={onDisconnect(setFieldValue)} fullWidth>
                    {t('availabilities.request.buttons.disconnect')}
                  </Button>
                ) : (
                  <Button color="primary" variant="contained" onClick={() => handleSubmit()} fullWidth>
                    {t('availabilities.request.buttons.confirmAndSend')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};
