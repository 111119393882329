export const AUTH_KEY = 'authToken';

export enum ROLES {
  RECRUITER = 'recruiter',
  ADMIN = 'admin',
}

export enum AUTH_ACTIONS {
  SET_TOKEN,
  SET_USER,
  CLEAR,
}

export enum GOOGLE_REJECT_REASONS {
  OUTLOOK_USING = 'outlookUsing',
  ICALENDAR_USING = 'icalendarUsing',
  OTHER_SERVICE_USING = 'otherServiceUsing',
  DONT_WANT_TO_GIVE_ACCESS = 'dontWantToGiveAccess',
  CONNECTION_DIDNT_WORK = 'connectionDidntWork',
  OTHER = 'other',
}
