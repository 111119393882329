import { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

export interface Props {
  readonly initialValue: number | string;
  readonly url: string;
}

export default function useRouterTabs({ initialValue, url }: Props) {
  const { push } = useHistory();
  const { tab: value = initialValue } = useParams<{ tab: string }>();

  const onChange = useCallback((event, tab) => push(url.replace('/:tab', `/${tab}`)), [url, push]);
  const isActive = useCallback(tab => value === tab, [value]);

  return {
    value,
    onChange,
    isActive,
  };
}
