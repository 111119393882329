import { ConnectDragSource } from 'react-dnd';
import { Dispatch, SetStateAction, useCallback, useMemo, useState, ChangeEvent, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import copyTextToClipboard from 'copy-text-to-clipboard';
import cx from 'classnames';
import { Formik } from 'formik';
import { useQueryClient } from 'react-query';
import { produce } from 'immer';

import {
  Button,
  Card as MaterialCard,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
  InputLabel,
  RadioGroup as RadioGroupMaterial,
  Radio,
  FormControl,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Chip } from '@app/ui/chip';
import { error, success } from '@app/snackbars';
import { Confirmation, useModal } from '@app/ui/modal';
import { INVITE_LINK_SEND_ME, INVITE_LINK_TYPE } from '@app/interview/constants';
import { useDate } from '@app/app/hooks/useDate';
import { Timezone } from '@app/timezones/interfaces';
import { useAuthUser } from '@app/auth';
import { CardLoader } from '@app/ui/loader';
import { Text } from '@app/ui/forms';

import { styles } from './styles';
import { useAvailabilityDelete, useAvailabilityResend, useAvailabilityUpdate } from '../../../hooks/crud';
import { AVAILABILITY_LIST_KEY, RESOURCES, STATUSES } from '../../../constants';
import { useUpdateStatusToDisconnect } from '../../../hooks/useUpdateStatusToDisconnect';
import { ReactComponent as ReminderIcon } from '../../../images/reminder.svg';
import { ReactComponent as AgainIcon } from '../../../images/again.svg';
import { ReactComponent as CopyIcon } from '../../../images/copy.svg';
import { AvailabilityEntity } from '../../../types/Availability';
import { Actions } from './Actions';

const useStyles = makeStyles(styles);

export interface CardProps {
  readonly timezones: Timezone[];
  readonly item: AvailabilityEntity;
  readonly setGroupLinkMembers: Dispatch<SetStateAction<AvailabilityEntity[]>>;
  readonly groupLinkMembers: AvailabilityEntity[];
  readonly noActions?: boolean;
  readonly refetch: () => void;

  // Collected Props
  isDragging: boolean;
  connectDragSource: ConnectDragSource;
  canDrag: boolean;
}

const SYSTEM_VALUE = 'system';
const MY_EMAIL_VALUE = 'myEmail';

export const AvailabilityCard = ({
  item,
  connectDragSource,
  canDrag,
  setGroupLinkMembers,
  timezones,
  noActions,
  refetch,
}: CardProps) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { formatCustom, djs } = useDate();
  const [sendMe, setSendMe] = useState(noActions);
  const {
    user: { availability, availabilityId: userAvailabilityId } = { availabilityId: 0, availability: undefined },
  } = useAuthUser();
  const [sendFrom, setSendFrom] = useState(!!item.sendFrom);
  const [sendFromEmail, setSendFromEmail] = useState(item.sendFrom);
  const sendFromIsAvailable = availability?.resource === RESOURCES.GOOGLE && !!availability?.meta.length;
  const emailOptions = availability?.meta.map(({ googleEmail }) => ({ id: googleEmail, name: googleEmail }));

  const manualUntil = useMemo(() => {
    if (item.status === STATUSES.ACTIVE && item.resource === RESOURCES.MANUAL && item.meta && timezones.length) {
      const { meta = [] } = item;
      const { events, timezoneId } = meta[0] || {};

      const userTimezone = timezones.find(({ id }) => id === timezoneId);

      if (events && events.length) {
        const items = [...events];
        (items as Array<{ start: string; end: string }>).sort((a, b) => {
          if (a.end < b.end) {
            return -1;
          }

          if (a.end > b.end) {
            return 1;
          }

          return 0;
        });

        const lastEvent = items[items.length - 1];

        const date = formatCustom(djs.utc(lastEvent.end).add(-(userTimezone?.offset ?? 0), 'hour'), 'MMM Do');

        return t('availabilities.manualUntil', { date });
      }
    }

    return undefined;
  }, [item, timezones]);

  const { isOpen, hide: hideResendConfirm, show: showResendConfirm } = useModal();
  const { mutateAsync, isLoading } = useAvailabilityResend();
  const { mutateAsync: deleteAvailability } = useAvailabilityDelete();
  const { mutate: updateAvailability } = useAvailabilityUpdate();
  const queryClient = useQueryClient();

  const handleDelete = useCallback(async () => {
    await deleteAvailability({ id: item.id });
    setGroupLinkMembers(prevState => [...prevState.filter(({ id }) => id !== item.id)]);
    refetch();
    success(t('availabilities.messages.deleted'));
  }, [deleteAvailability, item.id, refetch, setGroupLinkMembers, t]);
  const handleOpen = useCallback(() => {
    window.open(`/a/${item.token}`, '_blank');
  }, [item.token]);

  const isLoadingChecking = useUpdateStatusToDisconnect(item);

  const handleSendReminder = async () => {
    try {
      await mutateAsync(item.id);
      hideResendConfirm();
      refetch();
      success(t('availabilities.messages.resent'));
    } catch (e) {
      error(e?.message);
    }
  };

  const moveToGroupScheduler = () => {
    setGroupLinkMembers(prevState => [...prevState, item]);
  };

  const updateSendFrom = async (value: ChangeEvent<HTMLInputElement>) => {
    if (value.target.value === MY_EMAIL_VALUE) {
      const email = availability?.meta[0].googleEmail;

      setSendFrom(true);
      setSendFromEmail(email);
      setSendMe(false);
      updateAvailability({ sendFrom: email, id: item.id });
    } else {
      setSendFrom(false);
      setSendFromEmail(undefined);
      updateAvailability({ sendFrom: null, id: item.id });
    }

    queryClient.setQueriesData(AVAILABILITY_LIST_KEY, data =>
      produce(data, (draft: AvailabilityEntity[]) => {
        const index = draft.findIndex(datum => datum.id === item.id);

        draft[index].sendFrom = value.target.value === MY_EMAIL_VALUE ? availability?.meta[0].googleEmail : undefined;

        return draft;
      }),
    );
  };

  const updateSendFromEmail = useCallback(
    async ({ sendFromEmail }) => {
      setSendFromEmail(sendFromEmail);
      updateAvailability({ sendFrom: sendFromEmail, id: item.id });

      queryClient.setQueriesData(AVAILABILITY_LIST_KEY, data =>
        produce(data, (draft: AvailabilityEntity[]) => {
          const index = draft.findIndex(datum => datum.id === item.id);

          draft[index].sendFrom = sendFromEmail;

          return draft;
        }),
      );
    },
    [item.id],
  );
  const initialValues = useMemo(() => ({ sendFromEmail }), [sendFromEmail]);

  const copy = (m: number) => () => {
    copyTextToClipboard(
      `${window.location.origin}/interview-invite/${INVITE_LINK_TYPE.AVAILABILITY}.${
        sendMe ? INVITE_LINK_SEND_ME.YES : INVITE_LINK_SEND_ME.NO
      }.${m}.${item.token}`,
    );
    success(t('availabilities.messages.copied'));
  };

  useEffect(() => {
    if (
      item.sendFrom &&
      availability &&
      (!sendFromIsAvailable || !availability?.meta?.find(({ googleEmail }) => item.sendFrom === googleEmail))
    ) {
      setSendFrom(false);
      setSendFromEmail(undefined);
      updateAvailability({ sendFrom: null, id: item.id });
    }
  }, [item, sendFromIsAvailable, availability]);

  return (
    <CardLoader isLoading={isLoadingChecking} message={t('availabilities.list.messageCalendlyLoader')}>
      <MaterialCard
        variant="outlined"
        ref={connectDragSource}
        className={cx(classes.cardRoot, canDrag ? classes.draggableCard : '')}
      >
        <CardHeader
          className={classes.header}
          classes={{ action: classes.headerAction }}
          subheader={t(`availabilities.fields.role.options.${item?.id === userAvailabilityId ? 'owner' : item.role}`)}
          action={
            <>
              <Chip status={item.status} label={t(`availabilities.fields.status.options.${item.status}`)} />
              <Actions
                onOpen={handleOpen}
                onDelete={handleDelete}
                onMove={moveToGroupScheduler}
                canDrag={canDrag}
                canDelete={item.id !== userAvailabilityId}
              />
            </>
          }
        />
        <CardContent className={classes.content}>
          <Typography variant="h4" gutterBottom>
            {item.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {item.email}
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {manualUntil ?? (item.status === STATUSES.ACTIVE && t(`availabilities.resources.${item.resource}`))}
          </Typography>
          {item.status === STATUSES.ACTIVE && (
            <FormControl>
              <InputLabel>{t(`availabilities.list.sendFrom`)}</InputLabel>
              <RadioGroupMaterial
                value={sendFrom ? MY_EMAIL_VALUE : SYSTEM_VALUE}
                name="sendFrom"
                onChange={updateSendFrom}
              >
                <Box>
                  <Tooltip title={<>{t('availabilities.list.sendFromSystemEmailTooltip')}</>}>
                    <FormControlLabel
                      value={SYSTEM_VALUE}
                      label={t(`availabilities.list.sendFromSystemEmail`)}
                      control={<Radio color="primary" required />}
                    />
                  </Tooltip>
                  <Tooltip
                    title={
                      <>
                        {t(
                          `availabilities.list.${
                            sendFromIsAvailable ? 'sendFromMyEmailTooltip' : 'sendFromIsUnavailable'
                          }`,
                        )}
                      </>
                    }
                  >
                    <span>
                      <FormControlLabel
                        value={MY_EMAIL_VALUE}
                        label={t(`availabilities.list.sendFromMyEmail`)}
                        control={<Radio color="primary" required />}
                        disabled={!sendFromIsAvailable}
                      />
                    </span>
                  </Tooltip>
                </Box>
              </RadioGroupMaterial>
              {!noActions && !sendFrom && item.id !== userAvailabilityId && (
                <FormControlLabel
                  control={
                    <Checkbox value={sendMe} onChange={() => setSendMe(prevState => !prevState)} color="primary" />
                  }
                  label={t('availabilities.list.sendInvite')}
                  labelPlacement="end"
                />
              )}
              {sendFrom && (
                <Formik initialValues={initialValues} enableReinitialize onSubmit={updateSendFromEmail}>
                  {({ submitForm }) => (
                    <Text
                      disabled={!sendFromIsAvailable}
                      name="sendFromEmail"
                      margin="none"
                      select
                      required
                      options={emailOptions}
                      onChange={submitForm}
                    />
                  )}
                </Formik>
              )}
            </FormControl>
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          <Grid container className={classes.actionsContainer}>
            {item.status === STATUSES.WAITING && (
              <Grid item xs={12} className={classes.actionsButtonContainer}>
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<ReminderIcon />}
                  className={classes.actionsButton}
                  onClick={item.id === userAvailabilityId ? handleOpen : showResendConfirm}
                >
                  {t(`availabilities.buttons.${item.id === userAvailabilityId ? 'provide' : 'sendReminder'}`)}
                </Button>
              </Grid>
            )}
            {(item.status === STATUSES.EXPIRED || item.status === STATUSES.DISCONNECTED) && (
              <Grid item xs={12} className={classes.actionsButtonContainer}>
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<AgainIcon />}
                  className={classes.actionsButton}
                  onClick={item.id === userAvailabilityId ? handleOpen : showResendConfirm}
                >
                  {t(`availabilities.buttons.${item.id === userAvailabilityId ? 'renew' : 'requestAgain'}`)}
                </Button>
              </Grid>
            )}
            {item.status === STATUSES.ACTIVE && (
              <>
                <Grid item xs={4} className={classes.actionsButtonContainer}>
                  <Tooltip title={<>{t('availabilities.schedulerTooltip', { time: t('availabilities.time.30') })}</>}>
                    <Button
                      variant="outlined"
                      fullWidth
                      startIcon={<CopyIcon />}
                      className={classes.actionsButton}
                      onClick={copy(30)}
                    >
                      {t('availabilities.time.30')}
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={4} className={classes.actionsButtonContainer}>
                  <Tooltip title={<>{t('availabilities.schedulerTooltip', { time: t('availabilities.time.60') })}</>}>
                    <Button
                      variant="outlined"
                      fullWidth
                      startIcon={<CopyIcon />}
                      className={classes.actionsButton}
                      onClick={copy(60)}
                    >
                      {t('availabilities.time.60')}
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={4} className={classes.actionsButtonContainer}>
                  <Tooltip title={<>{t('availabilities.schedulerTooltip', { time: t('availabilities.time.90') })}</>}>
                    <Button
                      variant="outlined"
                      fullWidth
                      startIcon={<CopyIcon />}
                      className={classes.actionsButton}
                      onClick={copy(90)}
                    >
                      {t('availabilities.time.90')}
                    </Button>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </CardActions>
      </MaterialCard>
      <Confirmation
        onAccept={handleSendReminder}
        onCancel={hideResendConfirm}
        isOpen={isOpen}
        isLoading={isLoading}
        title={t('availabilities.resendConfirm.title')}
        description={<Trans>{t('availabilities.resendConfirm.description', { name: item.name })}</Trans>}
        acceptButton={t('availabilities.resendConfirm.button')}
      />
    </CardLoader>
  );
};
