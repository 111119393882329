import { Cell } from 'react-table';
import { memo } from 'react';

import { DetailedPayment } from '@app/payments/types';
import { useDate } from '@app/app/hooks/useDate';

interface Props {
  cell: Cell<DetailedPayment>;
}

export const DateCell = memo(
  ({
    cell: {
      row: {
        original: { dateEnd, dateStart },
      },
    },
  }: Props) => {
    const { formatCustom } = useDate();
    const value = `${formatCustom(dateStart, 'MMM DD')} - ${formatCustom(dateEnd, 'MMM DD, YYYY')}`;

    return <span>{value}</span>;
  },
);
