import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Modal, useModal } from '@app/ui/modal';
import { useAuthUser } from '@app/auth';
import { updateUserFirstLogin } from '@app/users/api';
import logoImage from '@app/app/images/logo.svg';
import welcomeImage from '@app/app/images/img-welcome.png';

import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FirstLoginModal = () => {
  const { user } = useAuthUser();
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { isOpen, hide } = useModal(user && user.trial && user.isFirstLogin);

  useEffect(() => {
    if (isOpen) {
      updateUserFirstLogin({ isFirstLogin: false });
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      hide={hide}
      maxWidth={false}
      actions={
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Button variant="contained" color="primary" onClick={() => hide()}>
            {t('payments.trial.firstLoginModal.startButton')}
          </Button>
        </Box>
      }
    >
      <div className={classes.logoBlock}>
        <img className={classes.siteLogo} src={logoImage} alt="Bettermeeting" />
      </div>
      <Grid container className={classes.welcomeModalContent} justifyContent="space-between">
        <Grid item xs={12} sm={12} md={7}>
          <Typography gutterBottom variant="h1" color="textPrimary">
            {t('payments.trial.firstLoginModal.title')}
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            {t('payments.trial.firstLoginModal.description1')}
          </Typography>
          <Typography variant="subtitle1">{t('payments.trial.firstLoginModal.description2')}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={5} className={classes.welcomeImgBlock}>
          <img className={classes.welcomeImg} src={welcomeImage} alt="Welcome" />
        </Grid>
      </Grid>
    </Modal>
  );
};
