import { createStyles } from '@material-ui/core/styles';

import {
  colorDanger,
  colorDangerLight,
  colorGrey,
  colorGreyLight,
  colorPrimary,
  colorPrimaryLight,
  colorSecondary,
  colorSuccess,
  colorSuccessLight,
  colorWarning,
  colorWarningLight,
  colorWhite,
} from '@app/app/configs/variables';

export const styles = () =>
  createStyles({
    chipRoot: {},
    chipOutlined: {},
    chipDeletable: {},
    chipLabel: {},
    chipIcon: {},
    chipIconOnly: {
      height: 18,
      width: 18,
      minWidth: 18,

      '& $chipLabel': {
        padding: 0,
        display: 'none',
      },
      '& $chipIcon': {
        fontSize: 12,
        padding: 0,
        margin: 0,
      },
    },
    chipRound: {
      borderRadius: '50%',
    },

    // Color
    primaryColor: {
      '&, &$chipDeletable:focus': {
        color: colorWhite,
        backgroundColor: colorPrimary,

        '&$chipOutlined': {
          color: colorPrimary,
          borderColor: colorPrimary,
          backgroundColor: colorWhite,
        },
      },
    },
    secondaryColor: {
      '&, &$chipDeletable:focus': {
        color: colorWhite,
        backgroundColor: colorSecondary,

        '&$chipOutlined': {
          color: colorSecondary,
          borderColor: colorSecondary,
          backgroundColor: colorWhite,
        },
      },
    },
    dangerColor: {
      '&, &$chipDeletable:focus': {
        color: colorWhite,
        backgroundColor: colorDanger,

        '&$chipOutlined': {
          color: colorDanger,
          borderColor: colorDanger,
          backgroundColor: colorWhite,
        },
      },
    },

    // Status
    waitingStatus: {
      backgroundColor: colorPrimaryLight,

      '&, & $chipIcon': {
        color: colorPrimary,
      },
    },
    activeStatus: {
      backgroundColor: colorSuccessLight,

      '&, & $chipIcon': {
        color: colorSuccess,
      },
    },
    scheduledStatus: {
      backgroundColor: colorSuccessLight,

      '&, & $chipIcon': {
        color: colorSuccess,
      },
    },
    expiredStatus: {
      backgroundColor: colorDangerLight,

      '&, & $chipIcon': {
        color: colorDanger,
      },
    },
    incompleteStatus: {
      backgroundColor: colorWarningLight,

      '&, & $chipIcon': {
        color: colorWarning,
      },
    },
    disconnectedStatus: {
      backgroundColor: colorGreyLight,

      '&, & $chipIcon': {
        color: colorGrey,
      },
    },
    canceledStatus: {
      backgroundColor: colorDangerLight,

      '&, & $chipIcon': {
        color: colorDanger,
      },
    },
    finishedStatus: {
      backgroundColor: colorGreyLight,

      '&, & $chipIcon': {
        color: colorGrey,
      },
    },
  });
