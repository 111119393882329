import QueryString from 'query-string';

import { storage } from '@app/app/configs/storage';
import { AUTH_KEY } from '../constants';

import { Token, TOKEN_TYPE } from '../interfaces';

const getFromUrl = (): Token | null => {
  const query = QueryString.parse(window.location.search);
  let result: Token | null = null;
  const token = query?.token as string;

  if (token) {
    try {
      const decoded = JSON.parse(token);

      if ('type' in decoded && 'token' in decoded) {
        result = decoded;
      }
    } catch (e) {
      // ¯\_(ツ)_/¯
    } finally {
      // @ts-ignore
      result = result ?? {
        token,
        type: TOKEN_TYPE.Bearer,
      };
    }
  }

  return result;
};

export const storeToken = (token: Token) => storage.set(AUTH_KEY, JSON.stringify(token));
export const getToken = async () => {
  let token: Token | undefined;
  const fromUrl = getFromUrl();

  if (fromUrl) {
    return fromUrl;
  }

  try {
    const stored = await storage.get(AUTH_KEY);
    token = typeof stored === 'string' ? JSON.parse(stored) : undefined;
  } catch (e) {
    // ¯\_(ツ)_/¯
  }

  return token;
};
export const destroyToken = () => storage.remove(AUTH_KEY);
