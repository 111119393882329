import { Cell } from 'react-table';
import { memo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { DetailedPayment } from '@app/payments/types';
import { numberWithCommas } from '@app/payments/utils/formatter';

import { styles } from '../styles';

const useStyles = makeStyles(styles);

interface Props {
  cell: Cell<DetailedPayment>;
}

export const AmountCell = memo(
  ({
    cell: {
      row: {
        original: { amount },
      },
    },
  }: Props) => {
    const classes = useStyles();

    return <span className={classes.alignmentCellRight}>{`$${numberWithCommas(amount / 100)}`}</span>;
  },
);
