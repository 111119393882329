import { AxiosRequestConfig } from 'axios';

import { apiClient } from '@app/query';

import { PaymentInfo } from '../types';
import { PAYMENT_PLANS } from '../constants';

const PAYMENTS_URL = '/payments';

export const getPlanInfo = <T = PaymentInfo>({
  plan,
  options = {},
}: {
  plan: PAYMENT_PLANS;
  options?: AxiosRequestConfig;
}): Promise<T> => apiClient.get(`${PAYMENTS_URL}/plan-info/${plan}`, { ...options });

export const getUpcomingPaymentInfo = <T = PaymentInfo>(options: AxiosRequestConfig = {}): Promise<T> =>
  apiClient.get(`${PAYMENTS_URL}/upcoming-payment-info`, { ...options });

export const getDetailedUpcomingPaymentInfo = <T = PaymentInfo>(options: AxiosRequestConfig = {}): Promise<T> =>
  apiClient.get(`${PAYMENTS_URL}/detailed-upcoming-payment-info`, { ...options });

export const subscribe = <T = { id: string }>(options: AxiosRequestConfig = {}): Promise<T> =>
  apiClient.get(`${PAYMENTS_URL}/subscribe`, { ...options });

export const getBillingPortal = <T = { url?: string }>(options: AxiosRequestConfig = {}): Promise<T> =>
  apiClient.get(`${PAYMENTS_URL}/billing-portal`, { ...options });

export const createStripeUser = <T = PaymentInfo>({
  plan,
  options = {},
}: {
  plan: PAYMENT_PLANS;
  options?: AxiosRequestConfig;
}): Promise<T> => apiClient.post(`${PAYMENTS_URL}/stripe-user`, { plan }, { ...options });

export const deleteCurrentSubscription = <T = PaymentInfo>(options: AxiosRequestConfig = {}): Promise<T> =>
  apiClient.delete(`${PAYMENTS_URL}/current-subscription`, { ...options });
