import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { SvgIcon } from '@material-ui/core';

import { styles } from '../styles';

const useStyles = makeStyles(styles);

interface Props {
  item: { email: string; name: string };
  removeMember: (email: string) => void;
}

export const NewParticipantItem = ({ item: { name, email }, removeMember }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.participantContainer}>
      <div className={classes.participantInfoContainer}>
        <div>{`${name} - ${email}`}</div>
      </div>
      <div>
        <SvgIcon
          component={ClearIcon}
          color="primary"
          fontSize="small"
          className={classes.participantClearIcon}
          onClick={() => removeMember(email)}
        />
      </div>
    </div>
  );
};
