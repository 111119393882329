import { CHANGE_FILTER_ACTION, CHANGE_PAGE_ACTION } from '../actions';

export interface State {
  globalFilter: any;
  pageIndex: number;
}

interface Payload {
  globalFilter?: any;
  pageIndex?: number;
}

export const filterStateReducer = (state: State, action: { type: string; payload: Payload }) => {
  switch (action.type) {
    case CHANGE_PAGE_ACTION:
      return { ...state, ...action.payload };
    case CHANGE_FILTER_ACTION:
      return { ...state, ...action.payload, pageIndex: 0 };
    default:
      return state;
  }
};
