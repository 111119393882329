import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup as RadioGroupMaterial,
  SvgIcon,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { InterviewEntity, FullInterviewForm } from '@app/interview/types/Interview';
import { useModal, Confirmation } from '@app/ui/modal';
import { Checkbox, Text } from '@app/ui/forms';

import { useAuthUser } from '@app/auth';
import { useInterviewsStore } from '@app/interview/hooks/crud';
import { error, success } from '@app/snackbars';
import { RESOURCES } from '@app/availabilities/constants';
import { useDate } from '@app/app/hooks/useDate';
import { API_DATE_FORMAT } from '@app/app/constants/global';

import { InterviewDetails } from './InterviewDetails';
import { InterviewDetails as InterviewFinishDetails } from './ScheduleInterview/Details';
import { ScheduleInterview } from './ScheduleInterview';
import { InterviewHeader } from './ScheduleInterview/InterviewHeader';
import { Participants } from './Participants';
import { getCandidates, getInterviewers } from '../../utils';

import { styles } from './styles';

const useStyles = makeStyles(styles);

interface InterviewProps {
  interview?: InterviewEntity;
}

export const NewInterview = ({ interview }: InterviewProps) => {
  const { t } = useTranslation('common');
  const [step, setStep] = useState<number>(1);
  const { goBack, push } = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const respIsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { isOpen: isOpenConfirm, show: showConfirm, hide: hideConfirm } = useModal();
  const { user } = useAuthUser();
  const sendFromIsAvailable = user?.availability?.resource === RESOURCES.GOOGLE && !!user?.availability?.meta.length;
  const emailOptions = user?.availability?.meta.map(({ googleEmail }) => ({ id: googleEmail, name: googleEmail }));
  const { djs } = useDate();
  const { mutateAsync: createInterview, isLoading: isCreating } = useInterviewsStore();

  const durationOptions = useMemo(
    () => ({
      30: t('availabilities.time.30'),
      60: t('availabilities.time.60'),
      90: t('availabilities.time.90'),
    }),
    [t],
  );

  const schema = yup.object().shape({
    title: yup.string().required(t('validation.required')),
    duration: yup.string().required(t('validation.required')),
    sendFrom: yup.string().nullable(),
    newInterviewers: yup.array().of(
      yup
        .object()
        // @ts-ignore
        .unique('email', t('availabilities.validations.uniqueEmail'))
        .shape({
          name: yup.string().required(),
          email: yup.string().email().required(),
        }),
    ),
    newCandidates: yup.array().of(
      yup
        .object()
        // @ts-ignore
        .unique('email', t('availabilities.validations.uniqueEmail'))
        .shape({
          name: yup.string().required(),
          email: yup.string().email().required(),
        }),
    ),
    date: yup.date().nullable(),
    time: yup
      .object()
      .shape({
        from: yup.number().nullable(),
        to: yup.number().nullable(),
      })
      .nullable(),
    newOthers: yup.array().of(
      yup
        .object()
        // @ts-ignore
        .unique('email', t('availabilities.validations.uniqueEmail'))
        .shape({
          name: yup.string().required(),
          email: yup.string().email().required(),
        }),
    ),
  });

  const initialValues = useMemo(() => {
    const values: FullInterviewForm = {
      title: interview?.title || '',
      description: interview?.description || '',
      duration: interview?.duration || 30,
      newInterviewers: [],
      interviewers: getInterviewers(interview?.interviewsParticipants || []),
      candidates: getCandidates(interview?.interviewsParticipants || []),
      newCandidates: [],
      addToParticipants: false,
      isZoomIncluded: interview?.id ? !!interview?.meet?.id : true,
      date: interview?.date || undefined,
      time: undefined,
      sendFrom: emailOptions?.length ? emailOptions[0].name : undefined,
      sendFromType: '',
    };
    if (interview?.id) {
      values.others = (interview?.interviewsParticipants || [])
        .filter(({ type }) => !type)
        .map(p => ({ name: p.name, email: p.email, id: p.id, isDisabled: true }));
      values.newOthers = [];
    }
    return values;
  }, [interview]);

  const onSubmit = useCallback(
    async (formData: FullInterviewForm) => {
      if (step === 1) {
        const hasCandidate = formData.candidates.length;
        const hasInterviewer = formData.interviewers.length;

        if ((!hasInterviewer || !hasCandidate) && !interview?.id) {
          showConfirm();
        } else {
          setStep(2);
        }
      } else if (step === 2) {
        if (!formData.time) {
          showConfirm();
        } else {
          setStep(3);
        }
      } else {
        try {
          const { sendFromType, sendFrom, addToParticipants, date: formDate, ...data } = formData;
          const date = djs(formDate).format(API_DATE_FORMAT);

          if (sendFromType) {
            await createInterview({ ...data, date, sendFrom, addToParticipants: false });
          } else {
            await createInterview({ ...data, date, sendFrom: undefined, addToParticipants });
          }

          success(t('interviews.form.create.successMessage'));

          push('/interviews');
        } catch (e) {
          error(t('general.messages.somethingWentWrong'));
        }
      }
    },
    [step, interview],
  );

  const handlePrev = useCallback(() => setStep(prevStep => prevStep - 1), []);

  const sandToMeDisabled = (values: FullInterviewForm) =>
    !!values.interviewers.find(({ id }) => id === user?.availability?.id);

  return (
    <>
      <Formik<FullInterviewForm> onSubmit={onSubmit} initialValues={initialValues} validationSchema={schema}>
        {({ handleSubmit, values, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <InterviewHeader step={step} />
            <div className={cx(classes.stepFirst, { hidden: step !== 1 })}>
              <InterviewDetails />
              <div className={classes.divider} />
              <Participants isEdit={!!interview?.id} />
              <div className={classes.divider} />
              <Box
                display={respIsMobile ? 'block' : 'flex'}
                justifyContent={respIsMobile ? 'center' : 'space-between'}
                alignItems="center"
              >
                {respIsMobile && (
                  <FormControl>
                    <InputLabel>{t(`availabilities.list.sendFrom`)}</InputLabel>
                    <RadioGroupMaterial
                      value={values.sendFromType}
                      name="sendFromType"
                      onChange={event => setFieldValue(event.target.name, event.target.value)}
                    >
                      <Box>
                        <Tooltip title={<>{t('availabilities.list.sendFromSystemEmailTooltip')}</>}>
                          <FormControlLabel
                            value=""
                            label={t(`availabilities.list.sendFromSystemEmail`)}
                            control={<Radio color="primary" required />}
                          />
                        </Tooltip>
                        <Tooltip
                          title={
                            <>
                              {t(
                                `availabilities.list.${
                                  sendFromIsAvailable ? 'sendFromMyEmailTooltip' : 'sendFromIsUnavailable'
                                }`,
                              )}
                            </>
                          }
                        >
                          <span>
                            <FormControlLabel
                              value="1"
                              label={t(`availabilities.list.sendFromMyEmail`)}
                              control={<Radio color="primary" required />}
                              disabled={!sendFromIsAvailable}
                            />
                          </span>
                        </Tooltip>
                      </Box>
                    </RadioGroupMaterial>
                    {!values.sendFromType && (
                      <Checkbox
                        name="addToParticipants"
                        label={t('interviews.form.create.addToParticipants')}
                        disabled={sandToMeDisabled(values)}
                      />
                    )}
                    {values.sendFromType && (
                      <Text disabled={!sendFromIsAvailable} name="sendFrom" select required options={emailOptions} />
                    )}
                  </FormControl>
                )}
                <Button color="primary" fullWidth={respIsMobile} onClick={() => goBack()}>
                  {t('general.buttons.back')}
                </Button>
                {respIsMobile ? (
                  <Button type="submit" color="primary" variant="contained" fullWidth={respIsMobile}>
                    {t('interviews.form.create.scheduleButton')}
                  </Button>
                ) : (
                  <Box>
                    <FormControl>
                      <Box display="flex">
                        <RadioGroupMaterial
                          value={values.sendFromType}
                          name="sendFromType"
                          onChange={event => setFieldValue(event.target.name, event.target.value)}
                        >
                          <Box display="flex">
                            <Tooltip title={<>{t('availabilities.list.sendFromSystemEmailTooltip')}</>}>
                              <FormControlLabel
                                value=""
                                label={t(`availabilities.list.sendFromSystemEmail`)}
                                control={<Radio color="primary" required />}
                              />
                            </Tooltip>
                            <Tooltip
                              title={
                                <>
                                  {t(
                                    `availabilities.list.${
                                      sendFromIsAvailable ? 'sendFromMyEmailTooltip' : 'sendFromIsUnavailable'
                                    }`,
                                  )}
                                </>
                              }
                            >
                              <FormControlLabel
                                value="1"
                                label={t(`availabilities.list.sendFromMyEmail`)}
                                control={<Radio color="primary" required />}
                                disabled={!sendFromIsAvailable}
                              />
                            </Tooltip>
                          </Box>
                        </RadioGroupMaterial>
                        {!values.sendFromType && (
                          <Checkbox
                            name="addToParticipants"
                            label={t('interviews.form.create.addToParticipants')}
                            disabled={sandToMeDisabled(values)}
                          />
                        )}
                        {values.sendFromType && (
                          <Box mr={1}>
                            <Text
                              disabled={!sendFromIsAvailable}
                              name="sendFrom"
                              select
                              required
                              margin="none"
                              options={emailOptions}
                            />
                          </Box>
                        )}
                      </Box>
                    </FormControl>
                    <Button type="submit" color="primary" variant="contained" fullWidth={respIsMobile}>
                      {t('interviews.form.create.scheduleButton')}
                    </Button>
                  </Box>
                )}
              </Box>
            </div>
            {step !== 1 && (
              <div className={classes.descriptionContainer}>
                <div>
                  <SvgIcon className={classes.descriptionIcon} component={SearchIcon} fontSize="small" /> {values.title}
                </div>
                <div className={classes.descriptionDuration}>
                  <SvgIcon className={classes.descriptionIcon} component={AccessTimeIcon} fontSize="small" />
                  {durationOptions[values.duration]}
                </div>
              </div>
            )}
            {step === 2 && (
              <ScheduleInterview
                interviewers={values.interviewers}
                candidates={values.candidates}
                duration={values.duration}
              />
            )}
            {step === 3 && (
              <InterviewFinishDetails
                interviewers={values.interviewers}
                candidates={values.candidates}
                others={values.others}
              />
            )}
            {step !== 1 && (
              <>
                <div className={classes.divider} />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Button color="primary" fullWidth={respIsMobile} onClick={handlePrev}>
                    {t('general.buttons.back')}
                  </Button>
                  <Button
                    disabled={isCreating}
                    fullWidth={respIsMobile}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {t(`general.buttons.${step === 3 ? 'sendInvitations' : 'continue'}`)}
                  </Button>
                </Box>
              </>
            )}
          </Form>
        )}
      </Formik>
      <Confirmation
        isOpen={isOpenConfirm}
        title={
          step === 1
            ? t('interviews.form.create.invalidParticipantsModal.title')
            : t('interviews.form.create.emptySlotsModal.title')
        }
        description={
          step === 1
            ? t('interviews.form.create.invalidParticipantsModal.description')
            : t('interviews.form.create.emptySlotsModal.description')
        }
        onAccept={hideConfirm}
        acceptButton={t('general.buttons.ok')}
        withCancel={false}
        onCancel={hideConfirm}
      />
    </>
  );
};
