import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { Box, Button, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';

import { INVITE_LINK_TYPE, INVITE_LINK_SEND_ME } from '@app/interview/constants';
import { InterviewInviteForm } from '@app/interview/types/InterviewInvite';
import { Duration, InterviewEntity } from '@app/interview/types/Interview';
import { useInterviewsStoreViaInvite } from '@app/interview/hooks/crud';
import { error } from '@app/snackbars';
import { Loader } from '@app/ui/loader';
import { useDate } from '@app/app/hooks/useDate';

import { API_DATE_FORMAT } from '@app/app/constants/global';
import { styles } from './styles';
import { Availability } from './Availability';
import { InviteSuccessMessage } from './InviteSuccessMessage/index';
import { Group } from './Group';
import { MIN_LENGTH_FIELD } from '../../constants';

const useStyles = makeStyles(styles);

export const Invite = () => {
  const { t } = useTranslation('common');
  const { token } = useParams<{ token: string }>();
  const [linkType, addToParticipants, duration, interviewToken] = token.split('.');
  const [confirmedInterview, setConfirmedInterview] = useState<InterviewEntity | null>(null);
  const { mutateAsync, isLoading } = useInterviewsStoreViaInvite();
  const { djs } = useDate();

  const classes = useStyles();
  const theme = useTheme();
  const respIsMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const schema = yup.object().shape({
    email: yup.string().email().required(t('validation.required')),
    name: yup
      .string()
      .trim()
      .required(t('validation.required'))
      .min(
        MIN_LENGTH_FIELD,
        ({ value }) => `${t('validation.minTextField', { min: MIN_LENGTH_FIELD, current: value.length })}`,
      ),
    date: yup.string().required(t('validation.required')).nullable(),
    time: yup.object().required(t('validation.required')).nullable(),
  });

  const initialValues = {
    email: '',
    name: '',
    date: undefined,
    time: undefined,
    duration: +duration,
    addToParticipants: addToParticipants === INVITE_LINK_SEND_ME.YES,
    token: interviewToken,
    isCustomTimezone: true,
    // customTimezone: undefined,
  } as unknown as InterviewInviteForm;

  const onSubmit = async (data: InterviewInviteForm, { setErrors }: FormikHelpers<InterviewInviteForm>) => {
    try {
      const date = djs(data.date).format(API_DATE_FORMAT);
      setConfirmedInterview(await mutateAsync({ ...data, date }));
    } catch (e) {
      if (e.message && e.message === 'email_already_in_list') {
        setErrors({ email: t('interviews.invite.errors.alreadyInUse') });
      } else {
        error(t('general.messages.somethingWentWrong'));
      }
    }
  };

  return confirmedInterview ? (
    <InviteSuccessMessage invite={confirmedInterview} token={interviewToken} linkType={linkType} />
  ) : (
    <>
      <Typography variant="h1" gutterBottom>
        {t('interviews.invite.title')}
      </Typography>
      {isLoading && <Loader showOverlay />}
      <Formik<InterviewInviteForm>
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={schema}
        isInitialValid={false}
      >
        {({ handleSubmit, isValid }) => (
          <Form noValidate onSubmit={handleSubmit}>
            {linkType === INVITE_LINK_TYPE.AVAILABILITY && (
              <Availability token={interviewToken} duration={+duration as Duration} />
            )}
            {linkType === INVITE_LINK_TYPE.GROUP_LINK && (
              <Group token={interviewToken} duration={+duration as Duration} />
            )}
            <div className={classes.divider} />
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth={respIsMobile}
                disabled={!isValid || isLoading}
              >
                {t('interviews.invite.scheduleButton')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
