import { createStyles, Theme } from '@material-ui/core/styles';

import {
  borderPrimaryColor,
  borderRadius,
  borderSecondaryColor,
  colorBlack,
  colorGreyLighter,
} from '@app/app/configs/variables';

export const styles = ({ breakpoints }: Theme) =>
  createStyles({
    footerWrapper: {
      backgroundColor: colorGreyLighter,
      color: colorBlack,
      fontSize: 14,
      fontWeight: 500,
      border: `1px solid ${borderSecondaryColor}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px 17px',
      borderRadius,

      [breakpoints.up('md')]: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
    divider: {
      margin: '10px 0',
      borderTop: `1px solid ${borderPrimaryColor}`,
    },
    alignmentRight: {
      justifyContent: 'flex-end',
    },
    alignmentCellRight: {
      [breakpoints.up('md')]: {
        width: '100%',
        textAlign: 'right',
      },
    },
    priceIcon: {
      marginLeft: 7,
      cursor: 'pointer',
    },
    noMaxWidth: {
      maxWidth: 'none',
    },
  });
