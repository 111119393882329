import { AxiosRequestConfig } from 'axios';
import { CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';

import { apiClient } from '@app/query';

import { Availability, AvailabilityEntity } from '../types/Availability';

const PATH = '/availabilities';

export const getAvailabilities = <T = AvailabilityEntity[]>(
  params: CreateQueryParams = {},
  options: AxiosRequestConfig = {},
): Promise<T> => apiClient.get(PATH, { ...options, params: RequestQueryBuilder.create(params).queryObject });

export const resendAvailabilityRequest = async (id: number, options: AxiosRequestConfig = {}): Promise<void> => {
  await apiClient.get(`${PATH}/${id}/resend-request`, options);
};

export const createAvailability = <T = Availability>(
  data: T,
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
): Promise<T> =>
  apiClient.post(PATH, data, {
    params: params && RequestQueryBuilder.create(params).queryObject,
    ...options,
  });

export const createAvailabilities = (
  { bulk, sendRequest }: { bulk: Partial<Availability>[]; sendRequest: boolean },
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
): Promise<Availability[]> =>
  apiClient.post(
    `${PATH}/bulk`,
    { bulk },
    {
      params: { sendRequest: sendRequest ? 1 : 0 },
      ...options,
    },
  );

export const updateAvailability = (
  { id, ...data }: Partial<Availability>,
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
): Promise<Availability> =>
  apiClient.patch(`${PATH}/${id}`, data, {
    params: params && RequestQueryBuilder.create(params).queryObject,
    ...options,
  });

export const deleteAvailability = (
  id: number,
  { params, ...options }: AxiosRequestConfig & { params?: CreateQueryParams } = {},
) =>
  apiClient.delete(`${PATH}/${id}`, {
    params: params && RequestQueryBuilder.create(params).queryObject,
    ...options,
  });

export const getAvailabilityViaInviteLink = <T = AvailabilityEntity>(
  token: string,
  params: CreateQueryParams = {},
  options: AxiosRequestConfig = {},
): Promise<T> =>
  apiClient.get(`${PATH}/interview-invite/${token}`, {
    ...options,
    params: RequestQueryBuilder.create(params).queryObject,
  });
