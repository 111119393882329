import { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';

import PubSub from './PubSub';

export interface SnackbarsProviderProps {
  readonly children: ReactNode;
}

export const SnackbarsProvider = ({ children, ...props }: SnackbarsProviderProps) => (
  <SnackbarProvider
    maxSnack={3}
    preventDuplicate
    autoHideDuration={10000}
    dense={false}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    disableWindowBlurListener
    resumeHideDuration={10}
    {...props}
  >
    <>
      {children}
      <PubSub />
    </>
  </SnackbarProvider>
);
