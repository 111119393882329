import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import uniqBy from 'lodash.uniqby';

import { Typography } from '@material-ui/core';

import { AvailabilityEntity } from '@app/availabilities/types/Availability';
import { NewInterviewForm } from '@app/interview/types/Interview';

import { NewParticipants } from './NewParicipants';
import { Participant } from './Paricipant';
import { NewParticipantItem } from './NewParicipantItem';

interface Props {
  name: keyof NewInterviewForm;
  newName: keyof NewInterviewForm;
  title?: string;
}

export const ListOther = ({ title, name, newName }: Props) => {
  const { values: initialValues, setFieldValue } = useFormikContext();
  // @ts-ignore
  const [values, setValues] = useState<AvailabilityEntity[]>(initialValues[name] || []);
  const [newValues, setNewValues] = useState<{ email: string; name: string }[]>([]);

  useEffect(() => {
    const uniqueValues = uniqBy([...values, ...newValues], 'email');

    setFieldValue(name, uniqueValues);
  }, [values, newValues]);

  const removeMember = useCallback((email: string) => {
    setValues(prevState => prevState.filter(item => item.email !== email));
  }, []);
  const removeNewMember = useCallback((email: string) => {
    setNewValues(prevState => prevState.filter(item => item.email !== email));
  }, []);

  const addMember = useCallback(async (item: { email: string; name: string }) => {
    setNewValues(prevState => [...prevState, item]);
  }, []);

  const existingEmails = useMemo(
    () => [...values.map(({ email }) => email), ...newValues.map(({ email }) => email)],
    [values, newValues],
  );

  return (
    <>
      {title && (
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
      )}
      {values.map(item => (
        <Participant item={item} key={item.id} removeMember={removeMember} />
      ))}
      {newValues.map(item => (
        <NewParticipantItem item={item} key={item.email} removeMember={removeNewMember} />
      ))}
      <NewParticipants name={newName} onAddNew={addMember} existingEmails={existingEmails} />
    </>
  );
};
