import { createStyles } from '@material-ui/core/styles';

import { borderPrimaryColor, headerHeight, colorWhite } from '@app/app/configs/variables';

export const styles = () =>
  createStyles({
    drawerRoot: {
      '&, & $drawerPaper': {
        top: `${headerHeight}px !important`,
        bottom: 0,
        height: 'auto',
      },
      '&$drawerRootTop': {
        '&, & $drawerPaper': {
          bottom: 'auto !important',
        },
      },
    },
    drawerRootTop: {},
    drawerPaper: {
      backgroundColor: colorWhite,
      borderTop: `1px solid ${borderPrimaryColor}`,
    },
    drawerAnchorLeft: {
      right: 0,
    },
    drawerAnchorRight: {
      left: 0,
    },
    drawerContent: {
      padding: 20,
      overflowY: 'auto',
    },
  });
