import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, Grid, Box } from '@material-ui/core';

import { styles } from './styles';

const useStyles = makeStyles(styles);

interface Props {
  step: number;
}

export const InterviewHeader = ({ step }: Props) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <Box mb={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h1">
            {t(step === 1 ? 'interviews.form.create.interviewDetails.title' : 'interviews.form.create.modal.title')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.activeStep} variant="h1" color="primary" display="inline">
            {step}
          </Typography>

          <Typography className={classes.totalSteps} variant="h1" color="primary" display="inline">
            /{3}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
