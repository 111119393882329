import { useCallback, useMemo, useRef, useState } from 'react';
import dayJs from 'dayjs';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FullCalendar, { DateSelectArg, EventClickArg, EventContentArg } from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { error } from '@app/snackbars';
import { RESOURCES } from '@app/availabilities/constants';
import { Timezones } from '@app/ui/forms';
import { Availability } from '@app/availabilities/types/Availability';

import * as yup from 'yup';
import { styles } from './styles';

const useStyles = makeStyles(styles);
const currentOffset = -new Date().getTimezoneOffset() / 60;

export interface ManualProps {
  readonly onUpdate: (resource: RESOURCES | null, data: Availability['meta']) => Promise<void>;
  readonly onDelete?: () => Promise<void>;
  readonly data: Partial<Availability>;
}

interface CalendarEvent {
  start: string;
  end: string;
}

export const Manual = ({ data, onUpdate }: ManualProps) => {
  const classes = useStyles();
  const calendar = useRef<FullCalendar>(null);
  const [resource, setResource] = useState<RESOURCES | null>(data.resource ?? null);
  const { t } = useTranslation('common');

  const handleDateSelect = useCallback((selectInfo: DateSelectArg) => {
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect();

    calendarApi.addEvent({
      start: selectInfo.startStr,
      end: selectInfo.endStr,
    });
    setResource(RESOURCES.MANUAL);
  }, []);

  const handleEventClick = useCallback((clickInfo: EventClickArg) => {
    clickInfo.event.remove();
  }, []);

  const handleSave = useCallback(
    async ({ timezoneId }: { timezoneId: number | string | { id: number } }) => {
      if (calendar.current) {
        // the weird situation: calendar brings us incorrect date( it adds one day) for 0 < offsets
        const events = calendar.current
          .getApi()
          .getEvents()
          .map(event => ({
            start:
              currentOffset < 0
                ? dayJs.utc(event.start).add(-1, 'day').format('YYYY-MM-DD[T]HH:mm:ss[Z]')
                : event.startStr,
            end:
              currentOffset < 0 ? dayJs.utc(event.end).add(-1, 'day').format('YYYY-MM-DD[T]HH:mm:ss[Z]') : event.endStr,
          }));

        try {
          await onUpdate(events.length ? resource : null, [{
            events,
            timezoneId: timezoneId && typeof timezoneId === 'object' ? timezoneId.id : timezoneId,
          }]);
        } catch (e) {
          error(e?.message);
        }
      }
    },
    [resource],
  );

  const handleClear = () => {
    if (calendar.current) {
      calendar.current.getApi().removeAllEvents();
      setResource(null);
    }
  };

  const calendarTimeFormat = useMemo(
    () => ({
      hour: '2-digit',
      minute: '2-digit',
      omitZeroMinute: false,
      meridiem: false,
      hour12: false,
    }),
    [],
  );

  // just as init data
  // the weird situation: calendar brings us incorrect date( it adds one day) for 0 < offsets
  const events = useMemo<Array<CalendarEvent>>(() => {
    const { events } = (data?.meta && data?.meta.length && data?.meta[0]) || {};
    return (events ?? []).map(({ start, end }: CalendarEvent) => ({
      start: currentOffset < 0 ? dayJs.utc(start).add(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss[Z]') : start,
      end: currentOffset < 0 ? dayJs.utc(end).add(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss[Z]') : end,
    }));
  }, []);

  const schema = yup.object().shape({
    timezoneId: yup.mixed().required(t('validation.required')).nullable(),
  });

  const { timezoneId, timezone } = (data?.meta && data?.meta.length && data?.meta[0]) || {};

  return (
    <Formik onSubmit={handleSave} initialValues={{ timezoneId }} validationSchema={schema}>
      {({ handleSubmit }) => (
        <Form>
          <div>
            <Typography variant="h3" gutterBottom>
              {t('availabilities.request.manual.title')}
            </Typography>
            <Typography gutterBottom>{t('availabilities.request.manual.description')}</Typography>

            <Grid container>
              <Grid item xs={12} md={6}>
                <Timezones
                  name="timezoneId"
                  label={t('availabilities.request.fields.timezone')}
                  required
                  defaultValue={timezone}
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.calendarContainer}>
            {/* @ts-ignore */}
            <FullCalendar
              ref={calendar}
              validRange={{
                start: new Date(),
              }}
              timeZone="UTC"
              allDaySlot={false}
              dayHeaderClassNames={classes.dayHeaderContainer}
              dayHeaderContent={date => (
                <div>
                  <div className={classes.dayHeaderDayName}>{dayJs(date.date).format('ddd')}</div>
                  <div className={classes.dayHeaderDayNumber}>
                    {dayJs(date.date).format('DD')}{' '}
                    <span className={classes.dayHeaderDayMonth}>{dayJs(date.date).format('MMM')}</span>
                  </div>
                </div>
              )}
              plugins={[timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                start: 'prev',
                center: 'title',
                end: 'next',
              }}
              dayHeaders
              initialView="timeGridWeek"
              editable
              selectable
              selectMirror
              dayMaxEvents
              weekends
              events={events}
              select={handleDateSelect}
              eventContent={(eventContent: EventContentArg) => (
                <div className={classes.eventContainer}>
                  <Typography color="primary">{eventContent.timeText}</Typography>
                </div>
              )}
              eventClick={handleEventClick}
              slotLabelFormat={calendarTimeFormat}
              eventTimeFormat={calendarTimeFormat}
            />
          </div>
          <div className={classes.buttonContainer}>
            <Button color="primary" onClick={handleClear}>
              {t('general.buttons.clearAll')}
            </Button>
            <Button color="primary" variant="contained" onClick={() => handleSubmit()}>
              {t('general.buttons.confirmAndSend')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
