import { useQuery, useMutation, UseMutationOptions, UseQueryOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { CreateQueryParams } from '@nestjsx/crud-request';

import { ApiError } from '@app/query';

import { InterviewEntity, FullInterviewForm, RescheduledInterview, UpdateInterviewForm } from '../types/Interview';
import { InterviewInviteForm } from '../types/InterviewInvite';
import { InterviewParticipantsEntity } from '../types/InterviewsParticipants';
import { INTERVIEWS_LIST_KEY, INTERVIEWS_PARTICIPANTS_LIST_KEY } from '../constants';
import {
  getInterviews,
  deleteInterview,
  updateInterview,
  createInterview,
  getInterviewsParticipants,
  createInterviewViaInvite,
  rescheduleInterview,
  cancelInterview,
  getInterview,
} from '../api';

export const useInterviewsIndex = <T = InterviewEntity[]>({
  params,
  options = {},
}: {
  params?: CreateQueryParams;
  options?: AxiosRequestConfig;
} = {}) =>
  useQuery<T, ApiError>([INTERVIEWS_LIST_KEY, params], () => getInterviews<T>(params, options), {
    cacheTime: 1000,
  });

export const useInterviewStore = (id: number, options?: UseQueryOptions<InterviewEntity, ApiError>) =>
  useQuery<InterviewEntity, ApiError>(['interview', id], () => getInterview(id), { cacheTime: 5000, ...options });

export const useInterviewsStore = (options?: UseMutationOptions<FullInterviewForm, ApiError, FullInterviewForm>) =>
  useMutation<FullInterviewForm, ApiError, FullInterviewForm>(
    (data: FullInterviewForm) => createInterview(data),
    options,
  );

export const useInterviewsUpdate = (
  options?: UseMutationOptions<UpdateInterviewForm, ApiError, UpdateInterviewForm & { id: number }>,
) =>
  useMutation<UpdateInterviewForm, ApiError, UpdateInterviewForm & { id: number }>(
    data => updateInterview(data),
    options,
  );

export const useInterviewsDelete = (options?: UseMutationOptions<any, ApiError, { id: number }>) =>
  useMutation<any, ApiError, { id: number }>(({ id }: { id: number }) => deleteInterview(id), options);

export const useInterviewCancel = (options?: UseMutationOptions<any, ApiError, { id: number }>) =>
  useMutation<any, ApiError, { id: number }>(({ id }: { id: number }) => cancelInterview(id), options);

export const useInterviewsParticipantsIndex = <T = InterviewParticipantsEntity[]>({
  index = '',
  params,
  options = {},
  queryOptions = {},
}: {
  index?: string;
  params?: CreateQueryParams;
  options?: AxiosRequestConfig;
  queryOptions?: UseQueryOptions<T, ApiError>;
} = {}) =>
  useQuery<T, ApiError>(
    [`${INTERVIEWS_PARTICIPANTS_LIST_KEY}${index}`, params],
    () => getInterviewsParticipants<T>(params, options),
    {
      cacheTime: 1000,
      ...queryOptions,
    },
  );

export const useInterviewsStoreViaInvite = (
  options?: UseMutationOptions<InterviewEntity, ApiError, InterviewInviteForm>,
) =>
  useMutation<InterviewEntity, ApiError, InterviewInviteForm>(
    (data: InterviewInviteForm) => createInterviewViaInvite(data),
    options,
  );

export const useInterviewReschedule = (options?: UseMutationOptions<InterviewEntity, ApiError, RescheduledInterview>) =>
  useMutation<InterviewEntity, ApiError, RescheduledInterview>(data => rescheduleInterview(data), options);
