import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { CondOperator, CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';
import { useFormikContext } from 'formik';

import Paper from '@material-ui/core/Paper';

import { Autocomplete } from '@app/ui/autocomplete';
import { apiClient } from '@app/query';
import { useDebouncedValue } from '@app/app/hooks/useDebouncedValue';
import { INTERVIEW_PARTICIPANTS_URL } from '@app/interview/api';
import { InterviewParticipantsEntity } from '@app/interview/types/InterviewsParticipants';
import { Option } from '@app/ui/forms/types';

interface Props {
  margin?: 'none' | 'dense' | 'normal';
}

export const Search = ({ margin = 'normal' }: Props) => {
  const { t } = useTranslation('common');
  const [value, setValue] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState('');
  const { setFieldValue, handleSubmit } = useFormikContext();

  const debouncedInputValue = useDebouncedValue(inputValue, 500);

  const { data = [] } = useQuery<Option[]>([INTERVIEW_PARTICIPANTS_URL, debouncedInputValue], async ({ queryKey }) => {
    const [url, val] = queryKey as [string, string];

    if (!val) {
      return [];
    }

    const field = val.includes('@') ? 'email' : 'name';
    const operator = CondOperator.CONTAINS_LOW;

    const filter: CreateQueryParams = {
      or: [
        { field, operator, value: val },
        {
          field: `availability.${field}`,
          operator,
          value: val,
        },
      ],
      join: [{ field: 'availability' }],
    };

    const participants = await apiClient.get<any, InterviewParticipantsEntity[]>(url, {
      params: RequestQueryBuilder.create(filter).queryObject,
    });

    return Object.values(
      [
        ...Object.values(
          participants
            .filter(({ availabilityId }) => availabilityId)
            .reduce<Record<any, any>>((acc, participant) => {
              const res = { ...acc };

              if (participant.availabilityId) {
                const name =
                  val[0] === '@'
                    ? (participant.availability?.email || '').replace(/\S+@/g, '@')
                    : `${participant.availability?.name} (${participant.availability?.email})`;

                const index = val[0] === '@' ? name : participant.availabilityId;

                if (!res[index]) {
                  res[index] = { id: participant.interviewId, name };
                } else {
                  res[index].id += `,${participant.interviewId}`;
                }
              }

              return res;
            }, {}),
        ),
        ...Object.values(
          participants
            .filter(({ availabilityId }) => !availabilityId)
            .reduce<Record<any, any>>((acc, participant) => {
              const res = { ...acc };

              if (participant.email) {
                const name =
                  val[0] === '@'
                    ? (participant?.email || '').replace(/\S+@/g, '@')
                    : `${participant?.name} (${participant?.email})`;
                const index = val[0] === '@' ? name : participant.email;

                if (!res[index]) {
                  res[index] = { id: participant.interviewId, name };
                } else {
                  res[index].id += `,${participant.interviewId}`;
                }
              }

              return res;
            }, {}),
        ),
      ].reduce((acc, { name, id }) => {
        const result = { ...acc };

        if (!acc[name]) {
          result[name] = { name, id };
        } else {
          result[name].id += `,${id}`;
        }

        return result;
      }, {}),
    );
  });

  const handleChange = useCallback((participants: Option[]) => {
    setValue(participants);
    setInputValue('');
    setFieldValue('participants', participants);
    handleSubmit();
  }, []);

  return (
    <Autocomplete
      multiple
      options={data}
      label={t('interviews.list.filter.participant')}
      PaperComponent={({ children, ...paperProps }) =>
        Array.isArray(data) && data.length > 0 ? <Paper {...paperProps}>{children}</Paper> : <></>
      }
      inputValue={inputValue}
      onInputChange={(e, val, reason) => {
        if (reason !== 'reset') {
          setInputValue(val);
        }
      }}
      getOptionLabel={(option: Option) => option.name}
      margin={margin}
      // filterOptions={options => {
      //   if (!value) {
      //     return options;
      //   }
      //
      //   return options.filter(option => !value.find(val => val === option));
      // }}
      value={value}
      onChange={handleChange}
    />
  );
};
