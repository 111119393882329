import { createStyles } from '@material-ui/core/styles';

import { avatarColors, colorPrimary, colorSecondary, colorGrey, colorWhite } from '@app/app/configs/variables';

export const styles = () =>
  createStyles({
    avatarRoot: {
      color: colorWhite,
      fontWeight: 500,
    },

    // Avatar Size
    small: {
      width: 30,
      height: 30,
      fontSize: 14,
      fontWeight: 400,
    },
    normal: {
      width: 40,
      height: 40,
      fontSize: 16,
    },
    large: {
      width: 100,
      height: 100,
      fontSize: 28,
    },

    // Avatar Color
    avatar0: {
      backgroundColor: avatarColors[0],
    },
    avatar1: {
      backgroundColor: avatarColors[1],
    },
    avatar2: {
      backgroundColor: avatarColors[2],
    },
    avatar3: {
      backgroundColor: avatarColors[3],
    },
    primaryColor: {
      backgroundColor: colorPrimary,
    },
    secondaryColor: {
      backgroundColor: colorSecondary,
    },
    greyColor: {
      backgroundColor: colorGrey,
    },
  });
