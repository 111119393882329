import { createContext, Dispatch } from 'react';

import { AuthAction, AuthState } from '../interfaces';
import { initialState } from '../reducers';

export interface Props {
  readonly state: AuthState;
  readonly dispatch: Dispatch<AuthAction>;
}

export const AuthContext = createContext<Props>({
  state: initialState,
  dispatch: () => {},
});
