import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { GoogleAccount } from '@app/auth/components/registration/GoogleAccount';
import { Text } from '@app/ui/forms';

import { DefaultAccountModal } from './DefaultAccountModal';
import styles from './styles';

const useStyles = makeStyles(styles);

export interface Props {
  accounts: Record<string, any>[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const GoogleAccountForm = memo(({ accounts, setFieldValue }: Props) => {
  const { t } = useTranslation('common');
  const [tempDelete, setTempDelete] = useState<string | undefined>();
  const classes = useStyles();
  const handleDelete = useCallback(
    email => {
      const currentAccount = accounts.find(({ googleEmail }) => googleEmail === email);
      if (currentAccount && currentAccount.defaultAccount && accounts.length > 1) {
        setTempDelete(email);
      } else {
        setFieldValue(
          'meta',
          accounts.filter(account => account.googleEmail !== email),
        );
      }
    },
    [accounts, setFieldValue],
  );
  const handleSwitch = useCallback(
    ({ accountName }) => {
      setFieldValue('defaultAccount', accountName);
      setFieldValue(
        'meta',
        accounts
          .filter(account => account.googleEmail !== tempDelete)
          .map(account => ({ ...account, defaultAccount: account.googleEmail === accountName })),
      );
      setTempDelete(undefined);
    },
    [accounts, setFieldValue, tempDelete, handleDelete],
  );
  const handleChangeMainAccount = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(
        'meta',
        accounts.map(account => ({ ...account, defaultAccount: account.googleEmail === value })),
      );
    },
    [accounts, setFieldValue],
  );

  const options = useMemo(
    () =>
      accounts.map(({ googleEmail }) => ({
        id: googleEmail,
        name: googleEmail,
      })),
    [accounts],
  );
  const filteredOptions = useMemo(
    () =>
      accounts
        .filter(({ googleEmail }) => googleEmail !== tempDelete)
        .map(({ googleEmail }) => ({
          id: googleEmail,
          name: googleEmail,
        })),
    [accounts, tempDelete],
  );

  return (
    <Paper className={classes.accountPaper} elevation={0}>
      {accounts.map(calendar => (
        <GoogleAccount
          key={calendar.googleEmail}
          handleDelete={() => handleDelete(calendar.googleEmail)}
          googleEmail={calendar.googleEmail}
        />
      ))}
      <Box className={classes.calendarSelect}>
        <Grid container>
          <Grid item xs={12} sm={7}>
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <CalendarToday />
              </Box>
              <Box maxWidth={300}>
                <Typography variant="h3">{t('user.registration.form.firstStep.googleConnect.accountTitle')}</Typography>
                <Typography variant="body1">
                  {t('user.registration.form.firstStep.googleConnect.accountDescription')}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box display="flex" alignItems="flex-end">
              <Text
                onChange={handleChangeMainAccount}
                options={options}
                name="defaultAccount"
                label={t('user.registration.form.firstStep.googleConnect.fieldAccount')}
                margin="none"
                required
                select
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {tempDelete && (
        <DefaultAccountModal
          onCancel={() => setTempDelete(undefined)}
          options={filteredOptions}
          onSubmit={handleSwitch}
        />
      )}
    </Paper>
  );
});
