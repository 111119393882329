import React, { useMemo } from 'react';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiAvatar, { AvatarProps } from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';

import googleName from '@app/app/utils/googleName';

import { styles } from '../styles';

const useStyles = makeStyles(styles);

export interface Props extends AvatarProps {
  name?: string;
  colorId?: number;
  color?: 'primary' | 'secondary' | 'grey';
  size?: 'small' | 'normal' | 'large';
  className?: string;
}

export function Avatar({ src, name, size = 'normal', colorId = 0, color, variant = 'circular', className }: Props) {
  const classes = useStyles();

  const avatarName = useMemo(() => {
    if (name) {
      return googleName(name);
    }

    return <PersonIcon />;
  }, [name]);

  return (
    <MuiAvatar
      classes={{
        root: cx(classes.avatarRoot, className, 'avatar', {
          [(classes as any)[`${color}Color`]]: color,
          [(classes as any)[`avatar${colorId % 4}`]]: !color,
          [classes[size]]: size,
        }),
      }}
      src={src}
      variant={variant}
    >
      {avatarName}
    </MuiAvatar>
  );
}
