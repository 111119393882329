import { useCallback, useState } from 'react';
import { CondOperator, CreateQueryParams } from '@nestjsx/crud-request';

import { useInterviewsIndex } from '@app/interview/hooks/crud';
import { STATUSES } from '@app/interview/constants';
import { InterviewEntity } from '@app/interview/types/Interview';

const defaultFilter: CreateQueryParams = {
  join: [{ field: 'interviewsParticipants' }, { field: 'interviewsParticipants.availability' }],
  filter: [],
  page: 1,
  limit: 4,
};

export interface Participant {
  id: string;
}

type Status = STATUSES & '0';

export interface SearchParams {
  filter?: {
    participants?: Participant[];
    status?: Status;
    startDate?: string | null;
    endDate?: string | null;
  };
  page?: number;
}

export const useSearch = () => {
  const [filter, setFilter] = useState<CreateQueryParams>(defaultFilter);
  const {
    data: { data, pageCount, page } = { data: [], pageCount: 0, page: 1 },
    isFetching,
    refetch,
  } = useInterviewsIndex<{
    data: InterviewEntity[];
    pageCount: number;
    page: number;
  }>({ params: filter });

  const search = useCallback(({ filter: newFilter, page }: SearchParams) => {
    setFilter(prevFilter => {
      const params: CreateQueryParams = { ...prevFilter };

      if (page) {
        params.page = page;
      }

      if (newFilter) {
        params.filter = [];
        params.page = 1;

        if (newFilter.participants && newFilter.participants.length) {
          const ids = newFilter.participants.reduce<string[]>(
            (acc, value) => [...acc, ...String(value.id).split(',')],
            [],
          );

          params.filter.push({ field: 'id', operator: CondOperator.IN, value: ids });
        }

        if (newFilter.status) {
          params.filter.push({
            field: 'status',
            operator: CondOperator.IN,
            value:
              newFilter.status === '0'
                ? [STATUSES.FINISHED, STATUSES.SCHEDULED, STATUSES.CANCELLED]
                : [newFilter.status],
          });
        }

        if (newFilter.startDate) {
          params.filter.push({
            field: 'date',
            operator: CondOperator.GREATER_THAN_EQUALS,
            value: newFilter.startDate,
          });
        }

        if (newFilter.endDate) {
          params.filter.push({
            field: 'date',
            operator: CondOperator.LOWER_THAN_EQUALS,
            value: newFilter.endDate,
          });
        }
      }

      return params;
    });
  }, []);

  return { data, search, pageCount, page, isFetching, refetch };
};
