import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useFormikContext } from 'formik';
import { CondOperator, CreateQueryParams } from '@nestjsx/crud-request';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash.uniqby';

import { Box, Button, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import { useDebouncedValue } from '@app/app/hooks/useDebouncedValue';

import { Autocomplete } from '@app/ui/autocomplete';
import { useAvailabilityIndex } from '@app/availabilities/hooks/crud';
import { Availability, AvailabilityEntity } from '@app/availabilities/types/Availability';
import { ROLES } from '@app/availabilities/constants';
import { NewInterviewForm } from '@app/interview/types/Interview';
import { getAvailabilities } from '@app/availabilities/api';
import { userQueryFields as fields } from '@app/interview/constants';

import { NewParticipants } from './NewParicipants';
import { Participant } from './Paricipant';
import { NewParticipantItem } from './NewParicipantItem';

const getOptionLabel = (option: AvailabilityEntity) => `${option.name} (${option.email})`;

interface Props {
  name: keyof NewInterviewForm;
  newName: keyof NewInterviewForm;
  title: string;
  participantsString: string;
  searchPlaceholder: string;
  roles: ROLES[];
  recruiterAvailability?: Availability;
  isAddRecruiter?: boolean;
}

export const List = ({
  title,
  name,
  newName,
  searchPlaceholder,
  participantsString,
  roles,
  recruiterAvailability,
  isAddRecruiter,
}: Props) => {
  const { values: initialValues, setFieldValue } = useFormikContext();
  const isInit = useRef(true);
  // @ts-ignore
  const [values, setValues] = useState<AvailabilityEntity[]>(initialValues[name] || []);
  const [newValues, setNewValues] = useState<{ email: string; name: string }[]>([]);
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation('common');
  const [filter, setFilter] = useState<CreateQueryParams>(() => {
    const params: CreateQueryParams = {
      fields,
      search: {
        $and: [
          {
            role: {
              [CondOperator.IN]: roles,
            },
          },
        ],
      },
    };

    if (participantsString) {
      // @ts-ignore
      params.search.$and.push({
        id: {
          [CondOperator.IN]: participantsString.split(','),
        },
      });
    }

    return params;
  });

  const { data = [] } = useAvailabilityIndex({
    index: name,
    params: filter,
    queryOptions: {
      onSuccess: result => {
        if (isInit.current) {
          if (participantsString) {
            setValues(result);
          }

          setFilter({
            fields,
            search: {
              $and: [
                {
                  role: {
                    [CondOperator.IN]: roles,
                  },
                },
              ],
            },
          });

          isInit.current = false;
        }
      },
    },
  });

  const debouncedInputValue = useDebouncedValue(inputValue, 500);

  const handleToggleCheckbox = useCallback(
    newItem => {
      if (recruiterAvailability && newItem?.id === recruiterAvailability?.id) {
        setFieldValue('addToParticipants', false);
      }
    },
    [recruiterAvailability],
  );

  useEffect(() => {
    if (inputValue) {
      const filter: CreateQueryParams = {
        fields,
        search: {
          $and: [
            {
              $or: [
                {
                  email: {
                    [CondOperator.CONTAINS_LOW]: inputValue,
                  },
                },
                {
                  name: {
                    [CondOperator.CONTAINS_LOW]: inputValue,
                  },
                },
              ],
            },
            {
              role: {
                [CondOperator.IN]: roles,
              },
            },
          ],
        },
      };

      if (values.length) {
        filter.search?.$and?.push({
          id: {
            [CondOperator.NOT_IN]: values.filter(item => !!item?.id).map(({ id }) => id),
          },
        });
      }

      setFilter(filter);
    } else if (!isInit.current) {
      setFilter({
        fields,
        filter: [
          {
            field: 'role',
            operator: CondOperator.IN,
            value: roles,
          },
        ],
      });
    }
  }, [debouncedInputValue]);

  useEffect(() => {
    const uniqueValues = uniqBy([...values, ...newValues], 'email');

    setFieldValue(name, uniqueValues);
  }, [values, newValues]);

  const handleChange = useCallback(
    value => {
      setValues(prevState => [...prevState, value]);
      handleToggleCheckbox(value);
      setInputValue('');
    },
    [handleToggleCheckbox],
  );

  const filterOptions = useCallback(
    options => {
      if (!values) {
        return options;
      }

      return options.filter((option: AvailabilityEntity) => !values.find(val => val.id === option.id));
    },
    [values],
  );

  const onInputChange = useCallback((e, val, reason) => {
    if (reason !== 'reset') {
      setInputValue(val);
    }
  }, []);

  const PaperComponent = useCallback(
    ({ children, ...paperProps }) =>
      filterOptions(data).length > 0 ? <Paper {...paperProps}>{children}</Paper> : <></>,
    [filterOptions, data],
  );

  const removeMember = useCallback((email: string) => {
    setValues(prevState => prevState.filter(item => item.email !== email));
  }, []);
  const removeNewMember = useCallback((email: string) => {
    setNewValues(prevState => prevState.filter(item => item.email !== email));
  }, []);

  const addMember = useCallback(
    async (item: { email: string; name: string }) => {
      getAvailabilities({
        filter: [
          { field: 'email', value: item.email, operator: CondOperator.EQUALS_LOW },
          { field: 'role', operator: CondOperator.IN, value: roles },
        ],
        fields,
      }).then(data => {
        const [newItem] = data;

        if (newItem) {
          setValues(prevState => [...prevState, newItem]);
        } else {
          setNewValues(prevState => [...prevState, item]);
        }

        handleToggleCheckbox(newItem || item);
      });
    },
    [handleToggleCheckbox],
  );

  const onAddRecruiter = useCallback(() => recruiterAvailability && addMember(recruiterAvailability), [recruiterAvailability, values]);

  const existingEmails = useMemo(
    () => [...values.map(({ email }) => email), ...newValues.map(({ email }) => email)],
    [values, newValues],
  );

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
      <Autocomplete
        options={data}
        placeholder={searchPlaceholder}
        PaperComponent={PaperComponent}
        inputValue={inputValue}
        onInputChange={onInputChange}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
      />
      {values.map(item => (
        <Participant item={item} key={item.id} removeMember={removeMember} />
      ))}
      {newValues.map(item => (
        <NewParticipantItem item={item} key={item.email} removeMember={removeNewMember} />
      ))}
      <NewParticipants name={newName} onAddNew={addMember} existingEmails={existingEmails} />
      {isAddRecruiter && !values.find(({ id }) => id === recruiterAvailability?.id) && (
        <Box my={1}>
          <Button color="primary" onClick={onAddRecruiter}>
            + {t('interviews.form.create.participants.buttons.addMe')}
          </Button>
        </Box>
      )}
    </>
  );
};
