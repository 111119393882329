import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';

import {
  colorPrimary,
  borderRadius,
  borderSecondaryColor,
  textSecondary,
  colorWhite,
  fontFamily,
  colorGreyLighter,
} from '@app/app/configs/variables';

export default ({ breakpoints }: Theme) =>
  createStyles({
    tableContainer: {
      width: '100%',
    },
    tableEmpty: {
      padding: '14px 17px',
      border: `1px solid ${borderSecondaryColor}`,
      borderTopWidth: 0,
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      display: 'flex',
      justifyContent: 'center',
    },
    table: {
      flex: 'auto 1',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 10,
      [breakpoints.down('md')]: {
        minWidth: '0 !important',
      },
      [breakpoints.up('md')]: {
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        border: `1px solid ${borderSecondaryColor}`,
        borderBottom: 0,
        marginBottom: 0,
      },
    },
    tableThead: {
      display: 'none',
      flexDirection: 'column',
      flex: '1 0 auto',
      backgroundColor: colorGreyLighter,
      borderBottom: `1px solid ${borderSecondaryColor}`,
      [breakpoints.down('sm')]: {
        minWidth: '0 !important',
      },
      // [breakpoints.up('lg')]: {
      [breakpoints.up('md')]: {
        display: 'flex',
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
      },
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flex: '1 0 auto',
      position: 'relative',

      '&:not(:last-child)': {
        marginBottom: 10,
      },
      '& $tableCell:not(:last-child)': {
        borderBottom: `1px solid ${borderSecondaryColor}`,
      },

      [breakpoints.up('md')]: {
        backgroundColor: colorWhite,
        flexDirection: 'row',
        '&:not(:last-child)': {
          borderBottom: `1px solid ${borderSecondaryColor}`,
          marginBottom: 0,
        },
        '& $tableCell:first-child': {
          paddingLeft: 17,
        },
        '& $tableCell:last-child': {
          paddingRight: 17,
        },
        '& $tableCell': {
          borderBottom: 0,
        },
        '& $tableCell:not(:last-child)': {
          borderBottom: 0,
        },
      },
    },
    tableRowHead: {
      backgroundColor: colorGreyLighter,
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
    },
    tableBody: {
      display: 'flex',
      flexDirection: 'column',
      flex: '99999 1 auto',
      [breakpoints.up('lg')]: {
        '& $tableRow': {
          '&:hover': {
            // backgroundColor: colorGreyLighter,
          },
          '& $tableCell:not(:last-child)': {
            // borderRight: `1px solid ${borderSecondaryColor}`,
          },
        },
      },
    },
    tableCell: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      wordBreak: 'break-word',
      padding: '0 0 10px',
      minHeight: 50,
      borderBottom: 0,
      lineHeight: 1.35,
      position: 'relative',
      '&:not(:last-child)': {
        marginBottom: 7,
      },
      [breakpoints.down('sm')]: {
        width: 'auto !important',
        '&:last-child': {
          padding: 0,
        },
      },
      [breakpoints.up('md')]: {
        flex: '1 0 0',
        flexDirection: 'row',
        padding: '16px 10px',
        '&::before': {
          content: 'none',
        },
        '&:not(:last-child)': {
          marginBottom: 0,
        },
      },
    },
    expanderMobileCell: {
      paddingTop: '0 !important',
      marginBottom: 7,
      borderBottom: 'none',
    },
    mobileCellLabel: {
      color: textSecondary,
      fontWeight: 600,
      marginBottom: 8,
    },
    tableCellCenter: {
      alignItems: 'center',
    },
    accordionContainer: {
      padding: 0,
      borderBottom: 0,
    },
    tableFooter: {
      backgroundColor: colorGreyLighter,
      fontSize: 14,
      border: `1px solid ${borderSecondaryColor}`,
      display: 'flex',
      justifyContent: 'center',
      flex: '1 0 auto',
      alignItems: 'center',
      padding: '0 17px',
      borderRadius,

      [breakpoints.up('md')]: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
    tableTotal: {
      fontSize: 14,
      flexGrow: 1,
      padding: '16px 0',
    },

    selectIcon: {
      color: textSecondary,
      fontSize: 18,
      marginRight: 4,
      top: 'calc(50% - 9px)',
    },

    tablePagination: {
      '& $select': {
        paddingRight: 34,
      },
    },
    select: {
      '&:focus': {
        backgroundColor: colorWhite,
      },
    },
    sortedIconContainer: {
      marginLeft: 8,
      lineHeight: 0,
      color: textSecondary,
      '& svg': {
        fontSize: 18,
      },
    },
    toolbar: {
      padding: 0,
      [breakpoints.up('lg')]: {
        paddingLeft: 16,
        paddingRight: 2,
      },
    },
    checkbox: {
      marginRight: 4,
    },

    // Accordion
    accordionEmpty: {
      borderTopWidth: 1,
      borderRadius,
      backgroundColor: colorGreyLighter,
    },
    accordion: {
      border: `1px solid ${borderSecondaryColor}`,
      boxShadow: 'unset',
      backgroundColor: colorGreyLighter,
      '&$accordionRounded': {
        borderRadius,
      },
    },
    accordionIcon: {
      fontSize: 32,
    },
    accordionRounded: {},
    accordionSummary: {
      display: 'flex',
      flexDirection: 'initial',
      alignItems: 'center',
      borderBottom: `1px solid transparent`,
      fontSize: 16,

      '&, &$accordionSummaryExpanded': {
        minHeight: 49,
      },
      '&$accordionSummaryExpanded': {
        borderColor: borderSecondaryColor,
      },
    },
    accordionSummaryExpanded: {},
    accordionSummaryContent: {
      overflow: 'hidden',
      '&, &$accordionSummaryExpanded': {
        margin: 0,
      },
    },
    accordionSummaryExpandedIcon: {
      padding: 0,
      margin: '0 2px 0 0',
    },
    accordionDetails: {
      flexDirection: 'column',
      backgroundColor: colorWhite,
      padding: '8px 16px',
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
    accordionTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
    },
    accordionTitle: {
      fontFamily,
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    // Table variant views
    defaultVariantTable: {},
    lightVariantTable: {
      [breakpoints.up('lg')]: {
        '& $table': {
          backgroundColor: colorWhite,
          borderRadius: 0,
          border: 'none',
        },
        '& $tableThead, & $tableRowHead, & $tableFooter': {
          backgroundColor: 'transparent',
          borderRadius: 0,
        },
        '& $tableCell': {
          borderRight: 'none !important',
          padding: 10,
        },
        '& $tableRowHead $tableCell': {
          minHeight: 0,
        },
        '& $tableFooter': {
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: 'none',
        },
        '& $tableEmpty': {
          borderRight: 'none',
          borderLeft: 'none',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },

    // Expanded
    expandedVariantTable: {
      backgroundColor: colorWhite,

      [breakpoints.up('lg')]: {
        boxShadow: 'inset 0 7px 9px -7px rgba(0,0,0,0.3)',
        paddingTop: 9,

        '& $table': {
          width: 'fit-content',
          backgroundColor: colorWhite,
          borderRadius: 0,
          border: 'none',
          paddingLeft: 20,
        },
        '& $tableThead, & $tableRowHead, & $tableFooter': {
          backgroundColor: 'transparent',
          borderRadius: 0,
        },
        '& $tableCell': {
          borderRight: 'none !important',
          padding: 10,
        },
        '& $tableRowHead $tableCell': {
          minHeight: 0,
        },
        '& thead $tableCell': {
          color: colorPrimary,
        },
        '& $tableFooter': {
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: 'none',
        },
        '& $tableEmpty': {
          borderRight: 'none',
          borderLeft: 'none',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
  });
