import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DoneIcon from '@material-ui/icons/Done';

import styles from './styles';

const useStyles = makeStyles(styles);

interface GoogleAccountProps {
  googleEmail?: string;
  handleDelete: () => void;
}

export const GoogleAccount = memo(({ googleEmail, handleDelete }: GoogleAccountProps) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  return (
    <Box className={classes.calendarItem}>
      <Grid container>
        <Grid item xs={12} sm={7}>
          <Box display="flex">
            <DoneIcon className={classes.connectionIcon} color="secondary" />
            <Box flex={1}>
              <Typography variant="body2">{t('user.registration.form.firstStep.googleConnect.google')}</Typography>
              <Typography variant="h4" className={classes.connectionSuccessMessage} gutterBottom>
                {googleEmail}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box display="flex" alignItems="center" justifyContent="flex-end" pr={2}>
            <Button variant="text" color="primary" className={classes.disconnectButton} onClick={handleDelete}>
              {t('availabilities.request.buttons.disconnect')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});
