import { memo, useState, useCallback, ReactNode, useMemo } from 'react';
// https://github.com/airbnb/react-dates
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment from 'moment';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { InputLabel, FormControl, SvgIcon } from '@material-ui/core';

import { DATE_FORMAT, API_DATE_FORMAT } from '@app/app/constants/global';
import { ReactComponent as CalendarIcon } from '@app/app/images/icons/calendar-icon.svg';

import styles from './styles';

const useStyles = makeStyles(styles);

interface Props {
  defaultStartDate?: string | null;
  defaultEndDate?: string | null;
  format?: string;
  apiFormat?: string;
  onChange: (value: { startDate: string | null; endDate: string | null }) => void;
  libraryParams?: Record<any, any>;
  labelText?: string | ReactNode;
  minDate?: string | null;
  maxDate?: string | null;
  label?: string;
}

export const DateRangePickerComponent = memo(
  ({
    defaultStartDate = null,
    defaultEndDate = null,
    libraryParams = {},
    apiFormat = API_DATE_FORMAT,
    onChange,
    minDate,
    maxDate = moment().add(1, 'day').format(API_DATE_FORMAT),
    label,
  }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const respIsMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const [state, setState] = useState<{
      startDate: moment.Moment | null;
      endDate: moment.Moment | null;
      focusedInput: FocusedInputShape | null;
      initialVisibleMonth: () => moment.Moment;
    }>({
      startDate: defaultStartDate ? moment(defaultStartDate, apiFormat) : null,
      endDate: defaultEndDate ? moment(defaultEndDate, apiFormat) : null,
      focusedInput: null,
      initialVisibleMonth: () => moment(),
    });
    const { startDate, endDate, initialVisibleMonth, focusedInput } = useMemo(() => {
      const newState = { ...state };

      newState.startDate = defaultStartDate ? moment(defaultStartDate, apiFormat) : null;
      newState.endDate = defaultEndDate ? moment(defaultEndDate, apiFormat) : null;
      newState.initialVisibleMonth = () => moment();

      return newState;
    }, [state, defaultEndDate, defaultStartDate, apiFormat]);

    const handleDateChange = useCallback(
      ({ startDate, endDate }: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
        let start = null;
        let end = null;
        if (startDate) {
          start = moment(startDate).format(apiFormat);
        }

        if (endDate) {
          end = moment(endDate).format(apiFormat);
        }

        onChange({ startDate: start, endDate: end });
      },
      [apiFormat, onChange],
    );

    const handleFocusChange = useCallback(focusedInput => {
      setState(prevState => ({ ...prevState, focusedInput }));
    }, []);

    const getIsOutsideRange = useCallback(date => date.isBefore(minDate) || date.isAfter(maxDate), [minDate, maxDate]);

    return (
      <FormControl className={classes.container} fullWidth>
        {label && <InputLabel>{label}</InputLabel>}
        <DateRangePicker
          {...libraryParams}
          block
          endDate={endDate}
          endDateId="endDate"
          endDatePlaceholderText={DATE_FORMAT}
          startDate={startDate}
          startDateId="startDate"
          startDatePlaceholderText={DATE_FORMAT}
          minimumNights={0}
          isOutsideRange={getIsOutsideRange}
          hideKeyboardShortcutsPanel
          onDatesChange={handleDateChange}
          focusedInput={focusedInput}
          initialVisibleMonth={initialVisibleMonth}
          onFocusChange={handleFocusChange}
          transitionDuration={100}
          inputIconPosition="after"
          showClearDates={!!(startDate || endDate)}
          customInputIcon={<SvgIcon component={CalendarIcon} />}
          customArrowIcon={<div>-</div>}
          orientation={respIsMobile ? 'vertical' : 'horizontal'}
        />
      </FormControl>
    );
  },
);
